import { defaultHeadersCustomViews, defaultResponses } from "../config/config";
import axios from "axios";
import {
  GET_COUNT_DATA_CUSTOM_VIEWS,
  GET_DATA_CUSTOM_VIEWS,
  GET_ERROR_DATA_CUSTOM_VIEWS,
  GET_COUNT_DATA_CUSTOM_VIEWS_MATERIALS,
  GET_DATA_CUSTOM_VIEWS_MATERIALS,
  GET_ERROR_DATA_CUSTOM_VIEWS_MATERIALS
} from "./types";
import { generateString } from "../utils/utils";

var ApiUrl = "";

export const setUrlMonithorService = (URL) => {
  ApiUrl = URL;
};

export const getCountSuppliers = (data) => async (dispatch) => {
  try {
    let myConfig = defaultHeadersCustomViews();
    Object.assign(myConfig.headers, {
      ...data,
    });

    var response = await axios.get(
      ApiUrl + "/countFindSuppliers",
      myConfig
    );
    dispatch({
      type: GET_COUNT_DATA_CUSTOM_VIEWS,
      payload: response.data !== undefined ? response.data.TotalRecords : 0,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GET_COUNT_DATA_CUSTOM_VIEWS,
      payload: 0,
    });
  }
};

export const getAllRecordsSuppliers = (data) => async (dispatch) => {
  try {
    let myConfig = defaultHeadersCustomViews();
    Object.assign(myConfig.headers, {
      ...data,
    });

    var response = await axios.get(
      ApiUrl + "/findSuppliers",
      myConfig
    );
    const responseTransform = [];
    response.data.map((item) => {
      responseTransform.push({
        ...item,
        Estado: item.Estado ? "Activo" : "Inactivo",
        mandante:
          item.Mandante1 === undefined ? item.Mandante2 : item.Mandante1,
      });
      return item;
    });

    dispatch({
      type: GET_DATA_CUSTOM_VIEWS,
      payload: { data: responseTransform, state: true },
    });
  } catch (err) {
    console.error(err);
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: GET_ERROR_DATA_CUSTOM_VIEWS,
      payload: response,
    });
  }
};

export const exportExcelSuppliers = async (data) => {
  try {
    let myConfig = defaultHeadersCustomViews();
    Object.assign(myConfig.headers, {
      ...data,
    });
    debugger;
    var response = await axios.get(
      ApiUrl + "/exportExcel",
      myConfig
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = "data:application/xlsx;base64," + response.data;
    downloadLink.download = `PROVEEDORES_${generateString(10, true)}.xlsx`;
    downloadLink.click();
  } catch (err) {
    console.error(err);
  }
};

export const getAllRecordsMaterials = (data) => async (dispatch) => {
  try {
    var response = await axios.post(
      ApiUrl + "/findMaterials",
      data,
      defaultHeadersCustomViews()
    );
    const responseTransform = [];
    response.data.map((item) => {
      responseTransform.push({
        ...item,
        idItemCoupa: `${item.idItemCoupa}`,
        item_number_sap: `${Number(item.item_number_sap)}`,
        status_item_sap: item.status_item_sap ? "Activo" : "Bloqueado",
      });
      return item;
    });

    dispatch({
      type: GET_DATA_CUSTOM_VIEWS_MATERIALS,
      payload: { data: responseTransform, state: true },
    });
  } catch (err) {
    console.error(err);
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: GET_ERROR_DATA_CUSTOM_VIEWS_MATERIALS,
      payload: response,
    });
  }
};

export const getCountMaterials = (data) => async (dispatch) => {
  try {
    var response = await axios.post(
      ApiUrl +  "/countMaterials",
      data,
      defaultHeadersCustomViews()
    );
    dispatch({
      type: GET_COUNT_DATA_CUSTOM_VIEWS_MATERIALS,
      payload: response.data !== undefined ? response.data.count : 0,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GET_COUNT_DATA_CUSTOM_VIEWS_MATERIALS,
      payload: 0,
    });
  }
};

export const exportExcelMaterials = async (data) => {
  try {
    var response = await axios.post(
      ApiUrl + "/exportMaterialExcel",
      data,
      defaultHeadersCustomViews()
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = "data:application/xlsx;base64," + response.data;
    downloadLink.download = `Materiales_${generateString(10, true)}.xlsx`;
    downloadLink.click();
  } catch (err) {
    console.error(err);
  }
};
