import { Provider } from "react-redux";

import store from "./store/store";
import Routes from "./routes/routes";
import { APP_HEADER_NAME } from "./config/config";

import { ThemeProvider } from "@material-ui/core/styles";
import { apps, muiTheme } from "./utils/muiTheme";
import { themeColors } from "./utils/themeColors";
import { useLocalStorage } from "./utils/proprietaryHooks";

import React from 'react';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';

//class App extends Component {
const App = () => {
  const [name, setName] = useLocalStorage("_appName");
  var appName = "";

  var request = new XMLHttpRequest();
  request.open("GET", document.location, false);
  request.send(null);
  appName = request.getResponseHeader(APP_HEADER_NAME) ?? new URLSearchParams(window.location.search).get("appName") ?? "PIF";

  /*if (!appName || !themeColors[appName]) {
    appName = "PIF";
  }*/
  if (name === undefined || name === null || name !== appName) {
    setName(appName);
    localStorage.setItem("APP_THEME", themeColors[appName].theme);
  }
  const title = apps.find((app) => app.name === appName).title;
  document.title = title ? title : "PIF";

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <ThemeProvider theme={muiTheme(appName)}>
      <SnackbarProvider maxSnack={3} ref={notistackRef}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>X</IconButton>
        )}>
          <Provider store={store}>
            <Routes appName={appName}></Routes>
          </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
