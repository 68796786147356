export const LOADING = 'SIGN_LOADING';
export const GET_CERTIFICATES = 'SIGN_GET_CERTIFICATES';
export const SAVE_CERTIFICATES = 'SIGN_SAVE_CERTIFICATES';
export const LIST_SIGNS = 'LIST_SIGNS';
export const POP = 'POP';
export const IS_UNIQUE_ELECTRONIC = 'IS_UNIQUE_ELECTRONIC';
export const LIST_MANUSCRITA = 'LIST_MANUSCRITA';
export const GET_DOCUMENT_TO_SIGN = 'GET_DOCUMENT_TO_SIGN';
export const ERROR_GET_DOCUMENT_TO_SIGN = 'ERROR_GET_DOCUMENT_TO_SIGN';
export const RUN_SIGNATURE_CIRCUIT_RESPONSE = 'RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE';
export const RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE = 'RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE';