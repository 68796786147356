/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
export function convertBytesToMbsOrKbs(filesize) {
  let size = '';
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = `${filesize / 1048576} megabytes`;
  } else if (filesize >= 1000) {
    size = `${filesize / 1048} kilobytes`;
  } else {
    size = `${filesize} bytes`;
  }
  return size;
}

export function kbToMb(filesize) { 
  let size = '';
  if (filesize >= 1000000) {
    size = `${filesize / 1000000} megabytes`;
  } else if (filesize >= 1000) {
    size = `${filesize / 1000} kilobytes`;
  } else {
    size = `${filesize} bytes`;
  }
  return size;
}

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body]);
  const fileName = `${filename}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function getMimeType(extension) {
  let type = 'text/plain';
  switch (extension) {
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'jpg':
    case 'bmp':
    case 'apng':
      type = `image/${extension}`;
      break;
    case 'tif':
    case 'tiff':
      type = 'image/tiff';
      break;
    case 'svg':
      type = 'image/svg+xml';
      break;
    case 'ico':
      type = 'image/x-icon';
      break;
    case 'pdf':
      type = 'application/pdf';
      break;
    case 'zip':
      type = 'application/zip';
      break;
    case 'mpeg':
    case 'mp4':
    case 'vorbis':
    case 'ogg':
      type = `audio/${extension}`;
      break;
    case 'txt':
      type = 'text/plain';
      break;
    case 'html':
      type = 'application/xhtml+xml';
      break;
    case 'doc':
      type = 'application/msword';
      break;
    case 'docx':
      type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'xls':
    case 'xlt':
      type = 'application/vnd.ms-excel';
      break;
    case 'xlsx':
      type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'ppt':
    case 'pps':
    case 'ppa':
      type = 'application/vnd.ms-powerpoint';
      break;
    case 'pptx':
      type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      break;
    default:
      break;
  }
  return `data:${type};base64,`;
}

export function downloadPDF(bodyBase64, filename, extension) {
  const mimeType = getMimeType(extension);
  const linkSource = `${mimeType}${bodyBase64}`;
  const downloadLink = document.createElement('a');
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function getLinkPdf(bodyBase64, extension) {
  const mimeType = getMimeType(extension);
  const linkSource = `${mimeType}${bodyBase64}`;
  return linkSource;
}

export function uuidGenerate() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g,
    (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}
