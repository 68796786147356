import * as React from 'react';
import * as _ from 'lodash';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { IntegrationNodeModel } from './IntegrationNodeModel';
import { DefaultPortLabel } from '@projectstorm/react-diagrams-defaults';
import styled from '@emotion/styled';
import { Select, MenuItem } from "@material-ui/core";

namespace S {
	export const Node = styled.div<{ background: string; selected: boolean }>`
		background-color: ${(p) => p.background};
		border-radius: 5px;
		font-family: sans-serif;
		color: white;
		border: solid 2px black;
		overflow: visible;
		font-size: 11px;
		border: solid 2px ${(p) => (p.selected ? 'rgb(0,192,255)' : 'black')};
	`;

	export const Title = styled.div`
		background: rgba(0, 0, 0, 0.3);
		display: flex;
		white-space: nowrap;
		justify-items: center;
	`;

	export const TitleName = styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`;

	export const Ports = styled.div`
		display: flex;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
	`;

	export const PortsContainer = styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&:first-of-type {
			margin-right: 10px;
		}

		&:only-child {
			margin-right: 0px;
		}
	`;
}

export interface IntegrationNodeProps {
	node: IntegrationNodeModel;
	engine: DiagramEngine;
}

/**
 * Integration node that models the IntegrationNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export class IntegrationNodeWidget extends React.Component<IntegrationNodeProps> {
	
	apps = [{id:62,name:"Coupa"},
			{id:61,name:"SAP"},
			{id:63,name:"AX"},
			{id:64,name:"Oracle EBusiness"},
			{id:57,name:"PIF"},
			{id:65,name:"Email"},
			{id:66,name:"SFTP"},
			{id:67,name:"AuroraPortal"},
		];
	
	entities = [{id:26,name:"Solicitud"},
			{id:15,name:"PO"},
			{id:16,name:"PO change"},
			{id:18,name:"Recepciones"},
			{id:19,name:"Devoluciones"},
			{id:17,name:"Factura"},
			{id:20,name:"FE"},
			{id:23,name:"Pagos"},
			{id:"GatosViaje",name:"Gastos de viaje"},
			{id:1,name:"Proveedor"},
			{id:2,name:"Artículos"},
			{id:5,name:"Cuenta"},
			{id:"Contrato",name:"Contrato"},
			{id:"TasaCambio",name:"Tasa de cambio"},
			{id:"Presupuesto",name:"Presupuesto"},
			{id:"TerminoPago",name:"Termino de pago"},
			{id:9,name:"Lookups"},
			{id:21,name:"Líneas presupuestales"},
			{id:14,name:"Compradores"},
			{id:22,name:"Contraseña de pagos"},
			{id:24,name:"Certificados de factura"},
			{id:25,name:"Anticipos"}
		];
	
	generatePort = (port:any) => {
		return <DefaultPortLabel engine={this.props.engine} port={port} key={port.getID()} />;
	};

	selectedApp = (event:any) => {
		let opt = this.props.node.getOptions();
		opt.data[`id${opt.name}`] = event.target.value;
	};

	selectedEntity = (event:any) => {
		let opt = this.props.node.getOptions();
		opt.data[`id${opt.name}IntegrationType`] = event.target.value;
	};

	getValue = (field:string)=>{
		if(!this.props.node.getOptions().data)
			this.props.node.getOptions().data = {};
		
		return this.props.node.getOptions().data[field];
	};

	getApps = ()=>{
		return this.props.node.getOptions().apps ?? this.apps;
	};

	getEntities = ()=>{
		let ents = Object.assign([], this.props.node.getOptions().entities ?? this.entities);
		ents.push({id:0,name:"Transformación"});
		return ents;
	};

	render() {
		return (
			<S.Node
				data-default-node-name={this.props.node.getOptions().name}
				selected={this.props.node.isSelected()}
				background={this.props.node.getOptions().color}
				onClick={(event)=>{this.props.node.onClick(event, this.props.node)}}
			>
				<S.Title>
					<Select 
						style={{color:"white", fontSize:"12px", paddingLeft:"10px"}} 
						defaultValue={this.getValue(`id${this.props.node.getOptions().name}`)}
						onChange={this.selectedApp}
						disabled={this.getValue(`id${this.props.node.getOptions().name}`) === 57}
						>
						{this.getApps().map((option:any) => (
							<MenuItem
								key={option.id}
								value={option.id}
							>
								{option.name}
							</MenuItem>
						))}
					</Select>
					<Select 
						style={{color:"white", fontSize:"12px", paddingLeft:"10px"}} 
						defaultValue={this.getValue(`id${this.props.node.getOptions().name}IntegrationType`)}
						onChange={this.selectedEntity}
						disabled={this.getValue(`id${this.props.node.getOptions().name}IntegrationType`) === 0}
						>
						{this.getEntities().map((option:any) => (
							<MenuItem
								key={option.id}
								value={option.id}
							>
								{option.name}
							</MenuItem>
						))}	
					</Select>
				</S.Title>
				<S.Ports>
					<S.PortsContainer>{_.map(this.props.node.getInPorts(), this.generatePort)}</S.PortsContainer>
					<S.PortsContainer>{_.map(this.props.node.getOutPorts(), this.generatePort)}</S.PortsContainer>
				</S.Ports>
			</S.Node>
		);
	}
}
