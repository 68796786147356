import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Loading from '../../components/Sign/Loading';
import SignatureSection from './SignatureSection';
import { Grid } from '@material-ui/core';
import "./signature.css";

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	body: {
		backgroundImage: 'url(../styles/StylesBody.css)',
		backgroundPosition: '0 -50%  0 -50% !important',
		backgroundSize: '100% 400px !important',
	},
	paper: {
		padding: '35px',
		marginTop: '-25px',
		borderRadius: '10px',
		width: '100%',
	},
	gridItem: {
		margin: '15px 5px 0',
		marginLeft: '20px',
		justifyItems: 'right;'
	},
	buttonContainer: {
		marginTop: '20px',
		marginBottom: '20px',
	},
}));

const Signatures = (props) => {
	let params = new URLSearchParams(window.location.search);

	const { loading } = props;
	const classes = useStyles();
	const [transactionId] = useState(params.get("transactionId") ?? "");

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	});

	if (loading) return <Loading />;

	return (
		<>
			<Grid container spacing={0} direction="row">
				{/* <Container component="main" maxWidth="lg">
					<h1 className={'title-background'}>Firmar documentos</h1>
				</Container> */}
				<Container component="main" >
					<Grid
						container
						spacing={0}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className="zone-upload-container"
						maxWidth="md"
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<SignatureSection isDashboard={false} transactionId={transactionId}/>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</>
	);
};

export default (Signatures);
