import {
  GET_CUSTOMFORM,
  GET_CUSTOMFORMS,
  SET_CUSTOMFORM_ERROR,
  GET_CUSTOMFORM_CHANGED,
  GET_CUSTOMFORM_DELETED
} from "../actions/types";

const initialState = {
  getCustomFormResponse: {},
  getCustomFormsResponse: {count:0,records:[]},
  setCustomFormErrorResponse: [],
  getCustomFormChangedResponse: [],
  getCustomFormDeletedResponse: []
};

/**
 * customFormsReducer (set customForm or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, customForm}
 */
export default function customFormsReducer (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMFORMS:
      return {
        ...state,
        getCustomFormsResponse: action.payload,
      };
    case GET_CUSTOMFORM:
      return {
        ...state,
        getCustomFormResponse: action.payload,
      };
    case GET_CUSTOMFORM_CHANGED:
      return {
        ...state,
        getCustomFormChangedResponse: action.payload,
      };
    case GET_CUSTOMFORM_DELETED:
      return {
        ...state,
        getCustomFormDeletedResponse: action.payload,
      };
    case SET_CUSTOMFORM_ERROR:
      return {
        ...state,
        setCustomFormErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
