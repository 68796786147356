import {
  GET_INTEGRATIONTYPE,
  GET_INTEGRATIONTYPES,
  SET_INTEGRATIONTYPE_ERROR,
  GET_INTEGRATIONTYPE_CHANGED,
} from "../actions/types";

const initialState = {
  getIntegrationTypeResponse: [],
  getIntegrationTypesResponse: [],
  setIntegrationTypeErrorResponse: [],
  getIntegrationTypeChangedResponse: []
};

/**
 * integrationTypesReducer (set integrationType or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, integrationType}
 */
export default function integrationTypesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_INTEGRATIONTYPES:
      return {
        ...state,
        getIntegrationTypesResponse: action.payload,
      };
    case GET_INTEGRATIONTYPE:
      return {
        ...state,
        getIntegrationTypeResponse: action.payload,
      };
    case GET_INTEGRATIONTYPE_CHANGED:
      return {
        ...state,
        getIntegrationTypeChangedResponse: action.payload,
      };
    case SET_INTEGRATIONTYPE_ERROR:
      return {
        ...state,
        setIntegrationTypeErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
