import { createTheme } from "@material-ui/core/styles";
import { themeColors } from "./themeColors";

export const apps = [
  {
    name: "coupa",
    title: "Monithor-coupa",
    backgroundImage: "",
    logo: "logoPif.png",
    icon: "logoPif.ico",
  },
  {
    name: "PIF",
    title: "PIF",
    backgroundImage: "",
    logo: "logoPif.png",
    icon: "logoPif.ico",
  },
];

export const muiTheme = (appName) => {
  const { backgroundImage, logo, icon } = apps.filter(
    (item) => item.name === appName
  )[0];
  var favicon = document.getElementById("favicon");
  favicon.href = `/${icon}`;
  return createTheme({
    palette: {
      primary: {
        light: `${themeColors[appName]["primary"]["light"]}`,
        main: `${themeColors[appName]["primary"]["main"]}`,
        dark: `${themeColors[appName]["primary"]["dark"]}`,
        iconSend: `${themeColors[appName]["primary"]["iconSend"]}`,
      },
      secondary: {
        light: `${themeColors[appName]["secondary"]["light"]}`,
        main: `${themeColors[appName]["secondary"]["main"]}`,
        dark: `${themeColors[appName]["secondary"]["dark"]}`, //claro
      },
      info: {
        light: `${themeColors[appName]["info"]["light"]}`,
        main: `${themeColors[appName]["info"]["main"]}`,
        dark: `${themeColors[appName]["info"]["dark"]}`,
      },
      error: {
        light: `${themeColors[appName]["error"]["light"]}`,
        main: `${themeColors[appName]["error"]["main"]}`,
        dark: `${themeColors[appName]["error"]["dark"]}`,
      },
      text: {
        primary: "#575756",
        secondary: "#FFFFFF",
        disabled: "#B2B2B2",
      },
      common: {
        grey: "#A8A8A8",
        black: "#575756",
        white: "#FFFFFF",
      },
    },

    logo: {
      backgroundImage: `url('${logo}')`,
    },
    pageBackground: {
      backgroundImage: `url('${backgroundImage}')`,
      backgroundSize: "cover",
    },
    typography: {
      fontFamily: ["Muli", "Arial", "sans-serif"].join(","),
    },
  });
};
