import { CHANGE_CLIENT_MESSAGE, ERROR_GET_ALL_CLIENTS, GET_ALL_CLIENTS, GET_ALL_SCOPES, GET_ERROR_CHANGE_CLIENT } from "../actions/types";

const initialState = {
  getAllClients : {},
  errorGetAllClient: "",
  getChangeClient:"",
  getErrorClient: "",
  getAllScopes: {}
}

export default function clientReducer (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CLIENTS:
      return {
        ...state,
        getAllClients: action.payload
      };
    case ERROR_GET_ALL_CLIENTS:
      return {
        ...state,
        errorGetAllClient: action.payload
      };
    case CHANGE_CLIENT_MESSAGE:
      return {
        ...state,
        getChangeClient: action.payload
      };
    case GET_ALL_SCOPES:
      return {
        ...state,
        getAllScopes: action.payload
      };
    case GET_ERROR_CHANGE_CLIENT:
      return {
        ...state,
        getErrorClient: action.payload
      };

    default:
      return state;
  }
}