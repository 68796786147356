import {
  GET_HOMOLOGATIONVALUE,
  GET_HOMOLOGATIONVALUES,
  SET_HOMOLOGATIONVALUE_ERROR,
  GET_HOMOLOGATIONVALUE_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * HomologationValues Action HomologationValues
 *
 * @method GET
 */
  const getHomologationValues = () => async (dispatch) => {
    dispatch(getRecords("homologationvalues", GET_HOMOLOGATIONVALUES, SET_HOMOLOGATIONVALUE_ERROR));
  };

export /**
 * HomologationValues Action getHomologationValues
 *
 * @method GET
 * @param customerid
 */
  const getHomologationValuesByCustomer = (customerid, parentId) => async (dispatch) => {
    dispatch(getRecordsByCustomer("homologationvalues", customerid, GET_HOMOLOGATIONVALUES, SET_HOMOLOGATIONVALUE_ERROR, 1, "", "Homologation.Integration.IdCustomer"
      , parentId ? [{fieldName: "IdHomologation", fieldValue: parentId}] : []
      )
    );
  };

export /**
 * HomologationValue Action getHomologationValueById
 *
 * @method GET
 * @param {*} id
 */
  const getHomologationValueById = (id) => async (dispatch) => {
    dispatch(getRecordById("homologationvalues", id, GET_HOMOLOGATIONVALUE, SET_HOMOLOGATIONVALUE_ERROR));
  };

export /**
  * HomologationValue Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createHomologationValue = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord("homologationvalues", recordToCreate, updateChecked, GET_HOMOLOGATIONVALUE_CHANGED, SET_HOMOLOGATIONVALUE_ERROR));
 };
 

export /**
 * HomologationValue Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyHomologationValue = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("homologationvalues", recordToUpdate, updateChecked, isPrincipalPage, GET_HOMOLOGATIONVALUE_CHANGED, SET_HOMOLOGATIONVALUE_ERROR));  
};
