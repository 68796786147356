import * as React from 'react';
import { IntegrationNodeModel } from './IntegrationNodeModel';
import { IntegrationNodeWidget } from './IntegrationNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';

export class IntegrationNodeFactory extends AbstractReactFactory<IntegrationNodeModel, DiagramEngine> {
	constructor() {
		super('default');
	}

	generateReactWidget(event:any): JSX.Element {
		return <IntegrationNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event:any): IntegrationNodeModel {
		return new IntegrationNodeModel();
	}
}
