import {
  GET_DOCUMENTTYPE,
  GET_DOCUMENTTYPES,
  SET_DOCUMENTTYPE_ERROR,
  GET_DOCUMENTTYPE_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * DocumentTypes Action DocumentTypes
 *
 * @method GET
 */
  const getDocumentTypes = () => async (dispatch) => {
    dispatch(getRecords("documenttype", GET_DOCUMENTTYPES, SET_DOCUMENTTYPE_ERROR));
  };

export /**
 * DocumentTypes Action getDocumentTypes
 *
 * @method GET
 * @param customerid
 */
  const getDocumentTypesByCustomer = (customerid) => async (dispatch) => {
    dispatch(getRecordsByCustomer("documenttype", customerid, GET_DOCUMENTTYPES, SET_DOCUMENTTYPE_ERROR));
  };

export /**
 * DocumentType Action getDocumentTypeById
 *
 * @method GET
 * @param {*} id
 */
  const getDocumentTypeById = (id) => async (dispatch) => {
    dispatch(getRecordById("documenttype", id, GET_DOCUMENTTYPE, SET_DOCUMENTTYPE_ERROR));
  };

export /**
  * DocumentType Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createDocumentType = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord("documenttype", recordToCreate, updateChecked, GET_DOCUMENTTYPE_CHANGED, SET_DOCUMENTTYPE_ERROR));
 };
 

export /**
 * DocumentType Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyDocumentType = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("documenttype", recordToUpdate, updateChecked, isPrincipalPage, GET_DOCUMENTTYPE_CHANGED, SET_DOCUMENTTYPE_ERROR));  
};
