import {
  GET_RESPONSECODE,
  GET_RESPONSECODES,
  SET_RESPONSECODE_ERROR,
  GET_RESPONSECODE_CHANGED,
} from "../actions/types";

const initialState = {
  getResponseCodeResponse: [],
  getResponseCodesResponse: [],
  setResponseCodeErrorResponse: [],
  getResponseCodeChangedResponse: []
};

/**
 * responsecodesReducer (set responsecode or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, responsecode}
 */
export default function responseCodesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_RESPONSECODES:
      return {
        ...state,
        getResponseCodesResponse: action.payload,
      };
    case GET_RESPONSECODE:
      return {
        ...state,
        getResponseCodeResponse: action.payload,
      };
    case GET_RESPONSECODE_CHANGED:
      return {
        ...state,
        getResponseCodeChangedResponse: action.payload,
      };
    case SET_RESPONSECODE_ERROR:
      return {
        ...state,
        setResponseCodeErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
