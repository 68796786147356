import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import FormLayout from "../../components/layouts/FormLayout";
import { isEmpty } from "../../utils/proprietaryHooks";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TabComponent from "../../components/TabsComponent/TabComponent";
import {Grid} from "@material-ui/core";

//#region Entity's dependencies

//#endregion

/**
 * TracingForm Component ( full view for form to create/modify integration )
 *
 * @export Class Component
 * @extends {Component}
 * @returns Redux connect
 */
const TracingForm = (props) => {
  /* #region  Props */
  const {
    userPrivileges,
    recordId,
    record,
  } = props;
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(0);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  const enabledTabsForm = !isEmpty(record) ? false : true;
  /* #endregion */

  /* #region  useRef */
  const validators = useRef({
    isNewRecord: recordId === 0 ? true : false,
  });
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().max(200).required(requiredMessage),
  });
  const {
//    errors,
//    control,
    setValue,
  } = useForm({
    defaultValues: { ...record },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  //#endregion

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    setTab(newTab);
  };

  const backButton = () => {
    
  };

  useEffect(() => {
    if(record){
      for (const key in record) {
        setValue(key, record[key]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const handleCancel = () => {
    
  };

  const handleAccept = (e) => {
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleChangeEdit = (response) => {
    
  };

  const defineSubHeader = () => {
    let subheader = "";
    if (!validators.current.isNewRecord) {
      switch (tab) {
        case 0:
          subheader = enabledTabsForm
            ? "Recursos"
            : "Recursos";
          break;
        case 1:
          subheader = enabledTabsForm
            ? "Logs"
            : "Logs";
          break;
        case 2:
          subheader = enabledTabsForm
            ? "Colas de información"
            : "Colas de información";
          break;
        case 3:
          subheader = enabledTabsForm
            ? "Transacciones"
            : "Transacciones";
          break;
        default:
          subheader = "Nuevo rastreo";
          break;
      }
    } else {
      subheader = "Nuevo rastreo";
    }
    return subheader;
  };

  const childrenForm = [];

  if(record.resourceService && record.resourceService !== "" && userPrivileges.resource.read){
    childrenForm.push({
      label: "Recursos",
      tabContent: (
        <Grid container style={{
            height: "1400px",
            position: "relative",
            overflow: "hidden"
          }}>
          <div 
            style={{
              width: "400px",
              height: "40px",
              backgroundColor: "white",
              zIndex: "1",
              position: "absolute"
            }}
          ></div>
          <iframe title="Servicio de gestión de recursos" src={record.resourceService} width="100%" height="1400" frameborder="0"
            style={{
                position: "absolute",
                top: "-40px",
            }}
          ></iframe>
        </Grid>
      ),
    });
  }
  if(record.logService && record.logService !== "" && userPrivileges.log.read){
    childrenForm.push({
      label: "Logs",
      tabContent: (
        <Grid container style={{
          height: "600px",
          position: "relative",
          overflow: "auto"
        }}>
          <div 
            style={{
              width: "40%",
              height: "80px",
              backgroundColor: "#F1F1F1",
              zIndex: "1",
              left: "50%",
              position: "absolute"
            }}
          ></div>
          {!userPrivileges.log.modify &&
          (<div 
            style={{
              width: "99%",
              height: "220px",
              backgroundColor: "transparent",
              zIndex: "1",
              left: "1%",
              position: "absolute"
            }}
          ></div>
          )}
          {!userPrivileges.log.modify &&
          (<div 
            style={{
              width: "100%",
              height: "20px",
              backgroundColor: "transparent",
              zIndex: "1",
              left: "80px",
              top: "10px",
              position: "absolute"
            }}
          ></div>
          )}
          <iframe id="logService" title="Servicio de gestión de logs" src={record.logService} width="100%" height="1200" frameBorder="0"
            style={{
                position: "absolute",
                left: "-90px",
            }}
            onLoad={(event) => {
              //const { contentWindow } = event.target;
              //const main = contentWindow.document.body.querySelector('.actionBar').style.display="none";
            }}
          ></iframe>
        </Grid>
      ),
    });
  }
  if(record.quequeService && record.quequeService !== "" && userPrivileges.queue.read){
    childrenForm.push({
      label: "Colas de información",
      tabContent: (
        <Grid container style={{
          height: "2350px",
          position: "relative",
          overflow: "hidden"
        }}>
          <div 
            style={{
              width: "420px",
              height: "40px",
              backgroundColor: "white",
              zIndex: "1",
              position: "absolute"
            }}
          ></div>
          <iframe title="Servicio de gestión de logs" src={record.quequeService} width="100%" height="2385" frameBorder="0" 
          style={{
              position: "absolute",
              top: "-40px",
          }}
          ></iframe>
        </Grid>
      ),
    });
  }
  if(record.tracingService && record.tracingService !== "" && userPrivileges.transaction.read){
    childrenForm.push({
      label: "Transacciones",
      tabContent: (
        <Grid container style={{
          height: "750px",
          position: "relative",
          overflow: "hidden"
        }}>
          {/* <Grid item lg={10} md={10} sm={10}>
            <ControlledInputRoundedForm
              id="tracingService"
              name="tracingService"
              inputProps={{ maxLength: 100 }}
              label="Servicio de rastreo de transacciones"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.name}
              helperText={errors.name?.message}
            />
          </Grid> */}
          <iframe title="Servicio de rastreo de transacciones" src={record.tracingService} width="100%" height="600" frameBorder="0"></iframe>
        </Grid>
      ),
    });
  }

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        handleIconClick={backButton}
        subheaderBase={record.name}
        subheader={defineSubHeader()}
        isEditing={false}
        enabledForm={enabledTabsForm}
        setEnabledForm={handleChangeEdit}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        handleCancel={handleCancel}
        modalMessage={`¿Desea modificar esta configuración?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"tracings tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={childrenForm}
        />
      </FormLayout>
    </div>
  );
};

//#region entities's declarations 
TracingForm.propTypes = {
  
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(TracingForm);
//#endregion