import {
  GET_INTEGRATION,
  GET_INTEGRATIONS,
  SET_INTEGRATION_ERROR,
  GET_INTEGRATION_CHANGED,
} from "../actions/types";

const initialState = {
  getIntegrationResponse: [],
  getIntegrationsResponse: [],
  setIntegrationErrorResponse: [],
  getIntegrationChangedResponse: []
};

/**
 * integrationsReducer (set integration or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, integration}
 */
export default function integrationsReducer (state = initialState, action) {
  switch (action.type) {
    case GET_INTEGRATIONS:
      return {
        ...state,
        getIntegrationsResponse: action.payload,
      };
    case GET_INTEGRATION:
      return {
        ...state,
        getIntegrationResponse: action.payload,
      };
    case GET_INTEGRATION_CHANGED:
      return {
        ...state,
        getIntegrationChangedResponse: action.payload,
      };
    case SET_INTEGRATION_ERROR:
      return {
        ...state,
        setIntegrationErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
