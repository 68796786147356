import * as React from 'react';
import styled from '@emotion/styled';

namespace S {
	export const Tray = styled.div`
		min-width: 100px;
		background: white;
		flex-grow: 0;
		flex-shrink: 0;
		border: 2px #ffe6e6 solid;
		padding-top: 5px;
	`;
}

export class TrayWidget extends React.Component<{children: any}> {
	  
	render() {
		return <S.Tray>{this.props.children}</S.Tray>;
	}
}
