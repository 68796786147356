import { useState } from "react";
import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { useStylesMenuSettings } from "../views/menuSettings/menusettings.styles";
import SwitchForm from "../components/Switch/switchForm.component";

const initialStateTree = {
  menu1: [],
  menu2: {},
  menu3: { application: [], user: [] },
};

export const useTree = (itemAction, initialState = initialStateTree) => {
  const classes = useStylesMenuSettings();
  const [values, setValues] = useState(initialState);

  const addInitialDataMenu = (data) => {
    setValues((state) => ({
      ...state,
      menu1: addPropertiesTitle(data),
    }));
  };

  const addItemMenu = (item) => {
    item.title = addPropertyTitle(item.name, item.key, undefined, item.isActivate, item);
    const temporalList = [...values.menu1, item];
    setValues((state) => ({ ...state, menu1: temporalList }));
  };

  const addInitialDataMenu3 = (data, key) => {
    if (key === undefined) {
      const application = addPropertiesTitle(data.application);
      const user = addPropertiesTitle(data.user);
      setValues((state) => ({ ...state, menu3: { application, user } }));
    } else {
      const newData = { ...values.menu3, [key]: addPropertiesTitle(data) };
      setValues((state) => ({ ...state, menu3: newData }));
    }
  };

  const addItemMenu3 = (item, key) => {
    item.title = addPropertyTitle(
      item.name,
      item.key,
      item.section,
      item.isActivate,
      item
    );
    const temporalList = [...values.menu3[key], item];
    const newData = { ...values.menu3, [key]: temporalList };
    setValues((state) => ({ ...state, menu3: newData }));
  };

  // const addByKeyMenu2 = (item) => {
  //   const key = Object.keys(values.menu2).length;
  //   const temporalList = { ...values.menu2, [key]: item };
  //   setValues((state) => ({ ...state, menu2: temporalList }));
  // };

  /* #region  Agrega los estilos a cada item, además agrega los botones de editar y eliminar */
  const addPropertiesTitle = (data) => {
    let dataResponse = [];
    for (const dat of data) {
      let dataChildrenResponse = [];
      if (dat.children !== undefined && dat.children.length > 0) {
        dataChildrenResponse = addPropertiesTitle(dat.children);
      }
      if (dataChildrenResponse.length !== 0) {
        dataResponse.push({
          ...dat,
          title: addPropertyTitle(
            dat.name,
            dat.key,
            dat.section,
            dat.isActivate,
            dat
          ),
          children: dataChildrenResponse,
        });
      } else {
        dataResponse.push({
          ...dat,
          title: addPropertyTitle(
            dat.name,
            dat.key,
            dat.section,
            dat.isActivate,
            dat
          ),
        });
      }
    }

    return dataResponse;
  };

  const addPropertyTitle = (name, key, section, isActivate, item) => {
    return (
      <div
        id={`container-${key}`}
        className={classes.titleTree}
        onMouseOver={(e) => {
          const iconEdit = document.getElementById(`edit_${key}`);
          const iconDelete = document.getElementById(`delete_${key}`);
          const divContainer = document.getElementById(`container-${key}`);
          const extra = document.getElementById(`extra_${key}`);
          if (!!iconEdit) {
            iconEdit.style.display = "initial";
            extra.style.display = "block";
            divContainer.style.paddingRight = "0px";
          }

          if (!!iconDelete) {
            iconDelete.style.display = "initial";
          }
        }}
        onMouseLeave={(e) => {
          const iconEdit = document.getElementById(`edit_${key}`);
          const iconDelete = document.getElementById(`delete_${key}`);
          const divContainer = document.getElementById(`container-${key}`);
          const extra = document.getElementById(`extra_${key}`);
          if (!!iconEdit) {
            iconEdit.style.display = "none";
            extra.style.display = "none";
            divContainer.style.paddingRight = "12px";
          }

          if (!!iconDelete) {
            iconDelete.style.display = "none";
          }
        }}
      >
        <Grid container spacing={2} justify="space-between">
          <Grid item lg={7} md={7} sm={6} xs={6} className={classes.containerTitleItem}>
            <span className={classes.titleItem}>{name}</span>
          </Grid>
          <Grid
            container
            item
            lg={5}
            md={5}
            sm={6}
            xs={6}
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
          >
            <Grid item lg={4} md={4}>
              <IconButton
                id={`edit_${key}`}
                style={{ display: "none" }}
                onClick={() => {
                  const values = typeof section === "object" ? section : item;
                  itemAction(key, "Edit", values);
                }}
              >
                <span
                  className={`material-icons ${classes.icon}`}
                  style={{ fontSize: "20px", color: "#575756" }}
                >
                  edit
                </span>
              </IconButton>
            </Grid>
            {
              (item.children !== undefined && item.children.length === 0) || item.children === undefined
                ? (
                  <Grid item lg={4} md={4}>
                    <IconButton
                      id={`delete_${key}`}
                      style={{ display: "none" }}
                      onClick={() => itemAction(key, "Delete", section)}
                    >
                      <span
                        className={`material-icons ${classes.icon}`}
                        style={{ fontSize: "20px", color: "#575756" }}
                      >
                        delete
                      </span>
                    </IconButton>
                  </Grid>
                )
                : <></>
            }

            <Grid container item lg={4} md={4} alignItems="center">
              <div id={`extra_${key}`} style={{ display: "none" }}>
                <SwitchForm
                  key={`extra_${key}switch`}
                  checked={isActivate || false}
                  value={isActivate || false}
                  onChange={(e) => {
                    itemAction(key, "State", item, e);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  // const createPersonalizedIcon = (name) => {
  //   const IconGet = getIconFromList(name)?.icon
  //   if (IconGet !== undefined) {
  //     return (
  //       <IconGet
  //         color="#575756"
  //         width='20px'
  //       />
  //     )
  //   } else {
  //     return <div></div>
  //   }
  // }
  /* #endregion */

  return {
    values,
    addItemMenu,
    addInitialDataMenu,
    addInitialDataMenu3,
    addItemMenu3,
  };
};
