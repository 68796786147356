import { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormLayout from "../../components/layouts/FormLayout";
import { isEmpty } from "../../utils/proprietaryHooks";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TabComponent from "../../components/TabsComponent/TabComponent";

//#region Entity's dependencies
import { getCustomViewsByEnterprise } from "../../actions/objecttype.action";
//#endregion

/**
 * CustomViews Component ( full view for form to create/modify integration )
 *
 * @export Class Component
 * @extends {Component}
 * @returns Redux connect
 */
const CustomViews = (props) => {
  /* #region  Props */
  const {
    record,
    getCustomViews,
    customViewsResponse,
    currentEnterprise,
    setLoading
  } = props;
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(0);
  const [customViews, setCustomViews] = useState();
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  const enabledTabsForm = !isEmpty(record) ? false : true;
  /* #endregion */

  /* #region  useRef */
  
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().max(200).required(requiredMessage),
  });
  const {
//    errors,
//    control,
    setValue,
  } = useForm({
    defaultValues: { ...record },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  //#endregion

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    setTab(newTab);
  };

  const backButton = () => {
    
  };

  useEffect(() => {
    if(record){
      for (const key in record) {
        setValue(key, record[key]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);
  
  useEffect(() => {
    if(currentEnterprise.id)
      getCustomViews(currentEnterprise.name);
  }, [getCustomViews, currentEnterprise]);

  useEffect(() => {
    if (customViewsResponse) {
      setCustomViews(customViewsResponse)
      setLoading(false);
    }
  }, [setLoading, customViewsResponse]);

  const handleCancel = () => {
    
  };

  const handleAccept = (e) => {
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleChangeEdit = (response) => {
    
  };

  const defineSubHeader = () => {
    return customViews?.length ? customViews[tab].name : "Nueva vista";
  };

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        handleIconClick={backButton}
        subheaderBase={record.name}
        subheader={defineSubHeader()}
        isEditing={false}
        enabledForm={enabledTabsForm}
        setEnabledForm={handleChangeEdit}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        handleCancel={handleCancel}
        modalMessage={`¿Desea modificar esta vista?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"Views tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={!customViews ? [] : customViews.map(rec=> {
            return {
              label: rec.name,
              tabContent: (
                <>
                  <iframe title={rec.name} src={JSON.parse(rec.description).src} width={JSON.parse(rec.description).width} height={JSON.parse(rec.description).height} frameborder="0"></iframe>
                </>
              )
            }
          })
        }
        />
      </FormLayout>
    </div>
  );
};

//#region entities's declarations 
CustomViews.propTypes = {
  
};

const mapStateToProps = (state) => ({
  customViewsResponse: state.objectTypesReducer.getCustomViewsResponse,
  currentEnterprise: state.enterpriseReducer.getEnterpriseByIDResponse
});

const mapDispatchToProps = {
  getCustomViews: getCustomViewsByEnterprise
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomViews);
//#endregion