import axios from "axios";
import { messagesResponseRecords } from "../utils/constants";
import { API_CRUD_DATA_ENDPOINT, defaultHeaders } from "../config/config";
import { DEFAULTRESPONSE } from "./types";

var ApiUrl = API_CRUD_DATA_ENDPOINT;

export /**
 * Actualizar ApiUrl del servicio
 */
const setUrl = (URL) => {
  ApiUrl = URL;
};

export /**
 * Base Action get records
 *
 * @method GET
 * @param {*}
 */
  const getRecords = (service, GET_RECORDS, SET_RECORD_ERROR, pDepht=1, pDephtRelation="", pPageOffset, pPageSize) => async (dispatch) => {
    
    let defaultHeader = defaultHeaders();

    if(pPageOffset !== undefined && pPageOffset !== null && pPageSize){
      Object.assign(defaultHeader.headers, {
        pFieldName: "Idstate",
        pFieldValue: "2",
        pComparision: "1",
        pDepht: pDepht,
        pDephtRelation: pDephtRelation,
        pRecent: true,
        pPageOffset: pPageOffset,
        pPageSize: pPageSize
      });
    } else {
      Object.assign(defaultHeader.headers, {
        pFieldName: "Idstate",
        pFieldValue: "2",
        pComparision: "1",
        pDepht: pDepht,
        pDephtRelation: pDephtRelation,
        pRecent: true
      });
    }
    
    try {
      const response = await axios.get(
        `${ApiUrl}/api/${service}/GetByFieldValue`,
        defaultHeader
      );
      dispatch({
        type: GET_RECORDS,
        payload: response.data.result,
      });
    } catch (error) {
      if(error.response.status === 401 || error.response.status === 403)
          dispatch({
            type: SET_RECORD_ERROR,
            payload: messagesResponseRecords.notPermission,
          });
      else if (error.response.status === 500)
        dispatch({
          type: SET_RECORD_ERROR,
          payload: messagesResponseRecords.errorNotControlled,
        });
      else{
        const { response } = error;
        dispatch({
          type: SET_RECORD_ERROR,
          payload: !!response.error.error ? response.error.error[0].message + " (cod:" + response.error.error[0].code + ")" 
                          : response.error.message + " (cod:" + response.error.code + ")",
        });
      }
    }
  };

  export /**
 * Base Action get records by customer
 *
 * @method GET
 * @param {*}
 */
  const getRecordsByCustomer = (service, customerid, GET_RECORDS, SET_RECORD_ERROR, pDepht=1, pDephtRelation="", pCustomerFieldName = "IdCustomer", pFilters = [], pPageOffset, pPageSize) => async (dispatch) => {
    dispatch(getRecordsByField(service, pCustomerFieldName, customerid, GET_RECORDS, SET_RECORD_ERROR, pDepht, pDephtRelation, pFilters, pPageOffset, pPageSize));
  };

  export /**
 * Base Action get records by field
 *
 * @method GET
 * @param {*}
 */
  const getRecordsByField = (service, pFieldName, pFieldValue, GET_RECORDS, SET_RECORD_ERROR, pDepht=1, pDephtRelation="", pFilters = [], pPageOffset, pPageSize) => async (dispatch) => {
    
    pFilters.push({
      fieldName: pFieldName,
      fieldValue: pFieldValue,
      comparision: "0",
      groupAction: "10"
    });

    dispatch(getRecordsByFilters(service, pFilters, GET_RECORDS, SET_RECORD_ERROR, pDepht, pDephtRelation, pPageOffset, pPageSize));
  }

export /**
 * Base Action get records by filters
 *
 * @method GET
 * @param {*}
 */
  const getRecordsByFilters = (service, pFilters, GET_RECORDS, SET_RECORD_ERROR, pDepht=1, pDephtRelation="", pPageOffset, pPageSize) => async (dispatch) => {
    
    let defaultHeader = defaultHeaders();

    let pFieldName = "";
    let pFieldValue = "";
    let pComparision = "";
    let pGroupAction = "";
    let pGroup = "";

    pFilters.forEach((filter)=>{
      pFieldName += (pFieldName ? "," : "") + filter.fieldName;
      pFieldValue += (pFieldValue ? "," : "") + filter.fieldValue;
      pComparision += (pComparision ? "," : "") + (!!filter.comparision ? filter.comparision : "0");
      pGroupAction += (pGroupAction ? "," : "") + (!!filter.groupAction ? filter.groupAction : "10");
      pGroup += (pGroup ? "," : "") + (!!filter.group ? filter.group : "Grupo1");
    });

    if (!pFieldValue) 
      return;

    if(pPageOffset !== undefined && pPageOffset !== null && pPageSize){
      Object.assign(defaultHeader.headers, {
        pFieldName: pFieldName,
        pFieldValues: pFieldValue,
        pComparision: pComparision,
        pGroupAction: pGroupAction,
        pGroup: pGroup,
        pDepht: pDepht,
        pDephtRelation: pDephtRelation,
        pRecent: true,
        pPageOffset: pPageOffset,
        pPageSize: pPageSize
      });
    } else {
      Object.assign(defaultHeader.headers, {
        pFieldName: pFieldName,
        pFieldValues: pFieldValue,
        pComparision: pComparision,
        pGroupAction: pGroupAction,
        pGroup: pGroup, //"Grupo1",
        pDepht: pDepht,
        pDephtRelation: pDephtRelation,
        pRecent: true
      });
    }
    
    try {
      const response = await axios.get(
        `${ApiUrl}/api/${service}/GetByFieldValuesAll`,
        defaultHeader
      );
      dispatch({
        type: GET_RECORDS,
        payload: response.data.result,
      });
    } catch (error) {
      if(error.response.status === 401 || error.response.status === 403)
          dispatch({
            type: SET_RECORD_ERROR,
            payload: messagesResponseRecords.notPermission,
          });
      else if (error.response.status === 500)
        dispatch({
          type: SET_RECORD_ERROR,
          payload: messagesResponseRecords.errorNotControlled,
        });
      else {
        let response = error.response.data;
        console.log(error);
        dispatch({
          type: SET_RECORD_ERROR,
          payload: !!response.error.error ? response.error.error[0].message + " (cod:" + response.error.error[0].code + ")" 
                          : response.error.message + " (cod:" + response.error.code + ")",
        });
      }
    }
  };

export /**
 * Base Action get record by id
 *
 * @method GET
 * @param {*} recordId
 */
  const getRecordById = (service, recordId, GET_RECORD, SET_RECORD_ERROR, pDepht=1, pDephtRelation="") => async (dispatch) => {
    if (recordId === -1) {
      dispatch({
        type: GET_RECORD,
        payload: [],
      });
    } else {
      let myConfig = defaultHeaders();
      Object.assign(myConfig.headers, {
        pId: recordId,
        pDepht: pDepht,
        pDephtRelation: pDephtRelation,
      });

      try {
        const response = await axios.get(
          `${ApiUrl}/api/${service}/GetById`,
          myConfig
        );
        if (response.data.result.count === 0) {
          return;
        }
        dispatch({
          type: GET_RECORD,
          payload: response.data.result.records[0],
        });
      } catch (error) {
        if(error.response.status === 401 || error.response.status === 403)
          dispatch({
            type: SET_RECORD_ERROR,
            payload: messagesResponseRecords.notPermission,
          });
        else if (error.response.status === 500)
          dispatch({
            type: SET_RECORD_ERROR,
            payload: messagesResponseRecords.errorNotControlled,
          });
        else{
          let response = error.response.data;
          console.error(error);
          dispatch({
            type: SET_RECORD_ERROR,
            payload: !!response.error.error ? response.error.error[0].message + " (cod:" + response.error.error[0].code + ")" 
                          : response.error.message + " (cod:" + response.error.code + ")",
          });
        }
      }
    }
  };

export /**
  * Base action create record
  *
  * @method POST
  * @param {*}
  */
 const createRecord = (service, recordToCreate, updateChecked, GET_RECORD_CHANGED, SET_RECORD_ERROR) => async (dispatch) => {
     try {
         //Update info of audit
         recordToCreate.modifiedon = new Date();
         recordToCreate.createdon = new Date();

         var response = await axios.post(`${ApiUrl}/api/${service}`, recordToCreate, defaultHeaders());
         if (response.status === 200) {
          if(!!updateChecked) 
            updateChecked(recordToCreate);
          dispatch({
              type: GET_RECORD_CHANGED,
              payload: messagesResponseRecords.createdRecord,
          });
         } else {
          dispatch({
              type: SET_RECORD_ERROR,
              payload: response.data.error.message + " (" + response.data.error.code + ")",
          });
         }
     } catch (error) {
        if(error.response.status === 401 || error.response.status === 403)
          dispatch({
            type: SET_RECORD_ERROR,
            payload: messagesResponseRecords.notPermission,
          });
        else if (error.response.status === 500)
          dispatch({
            type: SET_RECORD_ERROR,
            payload: messagesResponseRecords.errorNotControlled,
          });
        else {
          const response = !!error.response ? error.response.data : DEFAULTRESPONSE.noResponseFromApi;
          dispatch({
              type: SET_RECORD_ERROR,
              payload: !!response.error.error ? response.error.error[0].message + " (cod:" + response.error.error[0].code + ")" 
                        : response.error.message + " (cod:" + response.error.code + ")",
          });
        }
     }
 };

export /**
 * Base action modify record
 *
 * @method PUT
 * @param {*}
 */
const modifyRecord = (service, recordToUpdate, updateChecked, isPrincipalPage, GET_RECORD_CHANGED, SET_RECORD_ERROR) => async (dispatch) => {
    try {

        //Update info of audit
        recordToUpdate.modifiedon = new Date();

        var response = await axios.put(`${ApiUrl}/api/${service}/${recordToUpdate.id}`, recordToUpdate, defaultHeaders());
        if (response.status === 200) {
            dispatch({
                type: GET_RECORD_CHANGED,
                payload: isPrincipalPage
                    ? `El registro quedó ${recordToUpdate.idstate === 0 ? "activo" 
                                                : recordToUpdate.idstate === 1 ? "inactivo" 
                                                : recordToUpdate.idstate === 2 ? "eliminado"
                                                : recordToUpdate.idstate === 3 ? "bloqueado"
                                                : "en el estado solicitado"}`
                    : messagesResponseRecords.updatedRecordData
            });
            if(!!updateChecked) updateChecked(recordToUpdate);
        } else {
            dispatch({
                type: SET_RECORD_ERROR,
                payload: response.data.error.message + " (" + response.data.error.code + ")",
            });
        }
    } catch (error) {
      if(error.response.status === 401 || error.response.status === 403)
        dispatch({
          type: SET_RECORD_ERROR,
          payload: messagesResponseRecords.notPermission,
        });
      else if (error.response.status === 500)
        dispatch({
          type: SET_RECORD_ERROR,
          payload: messagesResponseRecords.errorNotControlled,
        });
      else{
        const response = !!error.response ? error.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: SET_RECORD_ERROR,
            payload: !!response.error.error ? response.error.error[0].message + " (cod:" + response.error.error[0].code + ")" 
                        : response.error.message + " (cod:" + response.error.code + ")",
        });
      }
    }
};

export /**
 * Base action modify record
 *
 * @method PUT
 * @param {*}
 */
const deleteRecord = (service, recordToDelete, updateChecked, GET_RECORD_DELETED, SET_RECORD_ERROR) => async (dispatch) => {
  let myConfig = defaultHeaders();
  Object.assign(myConfig.headers, {
    pId: recordToDelete.id
  });

  try {
    var response = await axios.delete(`${ApiUrl}/api/${service}/DeleteById`, myConfig);
    if (response.status === 200) {
        dispatch({
            type: GET_RECORD_DELETED,
            payload: messagesResponseRecords.deletedRecordData
        });
        if(!!updateChecked) updateChecked(recordToDelete);
    } else {
        dispatch({
            type: SET_RECORD_ERROR,
            payload: response.data.error.message + " (" + response.data.error.code + ")",
        });
    }
  } catch (error) {
    if(error.response.status === 401 || error.response.status === 403)
      dispatch({
        type: SET_RECORD_ERROR,
        payload: messagesResponseRecords.notPermission,
      });
    else if (error.response.status === 500)
      dispatch({
        type: SET_RECORD_ERROR,
        payload: messagesResponseRecords.errorNotControlled,
      });
    else{
      const response = !!error.response ? error.response.data : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
          type: SET_RECORD_ERROR,
          payload: !!response.error.error ? response.error.error[0].message + " (cod:" + response.error.error[0].code + ")" 
                      : response.error.message + " (cod:" + response.error.code + ")",
      });
    }
  }
};