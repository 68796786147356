import {
  GET_ALL_DATA_MONITHOR,
  GET_ERROR_ALL_DATA_MONITHOR,
  GET_COUNT_ALL_RECORDS,
  ERROR_GET_PAYLOAD,
  GET_PAYLOAD,
  GET_RESPONSE_RETRY,
  GET_ERROR_RESPONSE_RETRY,
  GET_TITLE,
  GET_OBJECT_TYPES,
  GET_RESPONSE_CHANGE_STATUS,
  GET_ERROR_RESPONSE_CHANGE_STATUS,
  EXPORT_ALL_DATA,
  ERROR_EXPORT_ALL_DATA,
  GET_MANDANTES
} from "../actions/types";

const initialState = {
  getAllData: { data: [], state: false },
  getErrorAllData: "",
  getPayload: "",
  errorGetPayload: "",
  getResponseRetry: "",
  getErrorResponseRetry: "",
  getCountAllRecords: 0,
  getTitle: "",
  getObjectTypes: { data: [], state: false },
  getResponseExecuteRecord: "",
  getErrorResponseExecuteRecord: "",
  errorExportAllData: "",
  exportAllData: "",
  getMandantes: { data: [], state: false }
};

export default function monithorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DATA_MONITHOR:
      return {
        ...state,
        getAllData: action.payload,
      };
    case GET_ERROR_ALL_DATA_MONITHOR:
      return {
        ...state,
        getErrorAllData: action.payload,
      };
    case GET_PAYLOAD:
      return {
        ...state,
        getPayload: action.payload,
      };
    case ERROR_GET_PAYLOAD:
      return {
        ...state,
        errorGetPayload: action.payload,
      };
    case GET_RESPONSE_RETRY:
      return {
        ...state,
        getResponseRetry: action.payload,
      };
    case GET_ERROR_RESPONSE_RETRY:
      return {
        ...state,
        getErrorResponseRetry: action.payload,
      };
    case GET_COUNT_ALL_RECORDS:
      return {
        ...state,
        getCountAllRecords: action.payload,
      };
    case GET_TITLE:
      return {
        ...state,
        getTitle: action.payload,
      };
    case GET_OBJECT_TYPES:
      return {
        ...state,
        getObjectTypes: action.payload,
      };
    case GET_RESPONSE_CHANGE_STATUS:
      return {
        ...state,
        getResponseExecuteRecord: action.payload,
      };
    case GET_ERROR_RESPONSE_CHANGE_STATUS:
      return {
        ...state,
        getErrorResponseExecuteRecord: action.payload,
      };
    case EXPORT_ALL_DATA:
      return {
        ...state,
        exportAllData: action.payload,
      };
    case ERROR_EXPORT_ALL_DATA:
      return {
        ...state,
        errorExportAllData: action.payload,
      };
    case GET_MANDANTES:
      return {
        ...state,
        getMandantes: action.payload,
      };
    default:
      return state;
  }
}
