import {
    GET_DATA_CUSTOM_VIEWS,
    GET_ERROR_DATA_CUSTOM_VIEWS,
    GET_COUNT_DATA_CUSTOM_VIEWS,
    SEARCH_SOCIETY,
    SEARCH_BUY_ORGANIZATION,
    GET_DATA_CUSTOM_VIEWS_MATERIALS,
    GET_ERROR_DATA_CUSTOM_VIEWS_MATERIALS,
    GET_COUNT_DATA_CUSTOM_VIEWS_MATERIALS,
    SEARCH_SOCIETY_MATERIALS,
    SEARCH_BUY_ORGANIZATION_MATERIALS,
  } from "../actions/types";
  
  const initialState = {
    getAllData: { data: [], state: false },
    getErrorAllData: {},
    getCountAllRecords: 0,
    searchSocieties: [],
    searchBuyOrganization: [],
    getAllDataMaterials: { data: [], state: false }, 
    getErrorAllDataMaterials: {}, 
    getCountAllRecordsMaterials: 0,
    searchSocietiesMaterials: [],
    searchBuyOrganizationMaterials: [],
  };
  
  export default function customViewsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_DATA_CUSTOM_VIEWS:
        return {
          ...state,
          getAllData: action.payload,
        };
      case GET_ERROR_DATA_CUSTOM_VIEWS:
        return {
          ...state,
          getErrorAllData: action.payload,
        };
      case GET_COUNT_DATA_CUSTOM_VIEWS:
        return {
          ...state,
          getCountAllRecords: action.payload,
        };
      case SEARCH_SOCIETY:
        return {
          ...state,
          searchSocieties: action.payload,
        };
      case SEARCH_BUY_ORGANIZATION:
        return {
          ...state,
          searchBuyOrganization: action.payload,
        };
        case GET_DATA_CUSTOM_VIEWS_MATERIALS:
        return {
          ...state,
          getAllDataMaterials: action.payload,
        };
      case GET_ERROR_DATA_CUSTOM_VIEWS_MATERIALS:
        return {
          ...state,
          getErrorAllDataMaterials: action.payload,
        };
      case GET_COUNT_DATA_CUSTOM_VIEWS_MATERIALS:
        return {
          ...state,
          getCountAllRecordsMaterials: action.payload,
        };
      case SEARCH_SOCIETY_MATERIALS:
        return {
          ...state,
          searchSocietiesMaterials: action.payload,
        };
      case SEARCH_BUY_ORGANIZATION_MATERIALS:
        return {
          ...state,
          searchBuyOrganizationMaterials: action.payload,
        };

  
      default:
        return state;
    }
  }
  