import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ViewLayout from "../../components/layouts/ViewLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import TabComponent from "../../components/TabsComponent/TabComponent";
import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import NewUserIcon from "../../components/IconsLibrary/NewUserIcon";
import { BodyWidget } from './components/BodyWidget';
import { IntegrationDesign } from './IntegrationDesign';

//#region Entity's dependencies
import { getApplications } from "../../actions/applications.action";
import { getIntegrationTypes } from "../../actions/integrationtype.action";
import { getIntegrationsByCustomer, createIntegration, modifyIntegration, getIntegrationById } from "../../actions/integrations.action";
import IntegrationForm from "../integrations/integrationsForm.view";
import { messagesResponseIntegrations } from "../../utils/constants";
import { SET_INTEGRATION_ERROR, GET_INTEGRATION_CHANGED } from "../../actions/types";
//#endregion

/**
 * Integrations Component ( full view for list of Integrations)
 *
 * @export Class Component
 * @class Integrations
 * @extends {Component}
 * @returns Redux connect
 */
const useStyles = makeStyles((theme) => ({
  newUserIcon: {
    stroke: theme.palette.secondary.main,
  },
  styleFab: {
    boxShadow: "none",
  },
}));

const Integrations = ({ component: Component, container, ...props }) => {
  const {
    privileges,
    records,
    getRecords,
    modifyRecord,
    createRecord,
    recordChanged,
    recordErrorResponse,
    enterpriseChanged,
    messagesResponse,
    integrationTypesResponse,
    applicationsResponse,
    getRecordResponse,
    GET_RECORD_CHANGED,
    SET_RECORD_ERROR,
    Loading,
    getIntegrationTypes,
    getApplications,
    getRecordById
  } = props;
  const classes = useStyles();

  //#region States
  const [showRecordAlert, setShowRecordAlert] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [recordInfo, setRecordInfo] = useState({});
  const [initialTab, setInitialTab] = useState(0);
  const dispatch = useDispatch();
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });
  const [isFormShowed, setFormShowed] = useState(false);
  const [option, setOption] = useState(0);
  let [isLoading, setLoading] = useState(false);
  if(Loading !== undefined){
    setLoading = Loading;
  }
  const [tab, setTab] = useState(0);
  const ServiceTypes = [
    {id:18, name:'Cron'},
    {id:19, name:'Call out'},
    {id:20, name:'End point'},
  ];
  const [IntegrationTypes, setIntegrationTypes] = useState(
    [
      {id:'1', name:'Proveedores'},
      {id:'2', name:'Artículos'},
      {id:'5', name:'Búsquedas'},
    ]
  );
  const [Applications, setApplications] = useState(
    [
      {id:'62', name:'Coupa'},
      {id:'61', name:'SAP'},
      {id:'63', name:'AX'},
      {id:'64', name:'Orcale EBusiness'},
      {id:'57', name:'PIF'},
    ]
  );
  const [userPrivileges] = useState({
      read: privileges.filter(p => p.includes("INTEGRATION_READ")).length > 0,
      create: privileges.filter(p => p.includes("INTEGRATION_CREATE")).length > 0,
      modify: privileges.filter(p => p.includes("INTEGRATION_MODIFY")).length > 0,
      delete: privileges.filter(p => p.includes("INTEGRATION_DELETE")).length > 0,
      changeState: privileges.filter(p => p.includes("INTEGRATION_CHANGESTATE")).length > 0
    }
  );
  const [integrationModel, setIntegrationModel] = useState(/*{
    integrations: records,
    integrationsOriginalData: JSON.stringify(records),
    apps: Applications,
    entities: IntegrationTypes
  }*/);
  //#endregion

  //#region Effects
  useEffect(() => {
    if (!Array.isArray(recordChanged)) {
      setConfirmInactivate({
        open: true,
        message: recordChanged,
        item: "inactivate",
      });
      dispatch({
        type: GET_RECORD_CHANGED,
        payload: [],
      });
    }
    if (!Array.isArray(recordErrorResponse) && recordErrorResponse !== messagesResponse.notPermission) {
      setConfirmInactivate({
        open: true,
        message: recordErrorResponse,
        showBtnAccept: true,
        textButtonAccept: "Cerrar",
        item: "inactivate",
      });
      dispatch({
        type: SET_RECORD_ERROR,
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordChanged, dispatch, recordErrorResponse]);

  useEffect(() => {    
    if(tab === 0 && JSON.stringify(integrationModel?.integrations) !== integrationModel?.integrationsOriginalData){
      const currentRecord = integrationModel?.integrations.sort((a, b) => a.id - b.id);
      const originalRecord = JSON.parse(integrationModel?.integrationsOriginalData).sort((a, b) => a.id - b.id);
      if(JSON.stringify(currentRecord) !== JSON.stringify(originalRecord)){
        showMessageSaveOrUpdate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if(enterpriseChanged.id && tab === 0)
      getRecords(enterpriseChanged.id);
  }, [getRecords, enterpriseChanged, isFormShowed, tab]);

  useEffect(() => {
    if (!!records && Applications && IntegrationTypes) {
      records.forEach((rec)=>{
        rec.triggerActionName = ServiceTypes.filter(st=> st.id === rec.services.filter(ser=>ser.idstate === 0 && ser.idServiceCategory === 488)[0]?.idServiceType)[0]?.name;
        rec.sourceName = rec.source.name;
        rec.destinationName = rec.destination.name;
        rec.integrationActionName = rec.integrationAction.name;
        rec.stateName = rec.idstateNavigation.name;
        rec.idTranformation = 57;
		    rec.idTranformationIntegrationType = 0;
      });
      setIntegrationModel({
        integrations: records,
        integrationsOriginalData: JSON.stringify(records),
        apps: Applications,
        entities: IntegrationTypes
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  useEffect(() => {
    getIntegrationTypes();
    getApplications();
  },[getIntegrationTypes, getApplications]);

  useEffect(() => {
    if (integrationTypesResponse) {
      setIntegrationTypes(integrationTypesResponse)
      setLoading(false);
    }
  }, [setLoading, integrationTypesResponse]);

  useEffect(() => {
    if (applicationsResponse) {
      setApplications(applicationsResponse)
      setLoading(false);
    }
  }, [setLoading, applicationsResponse]);

  useEffect(() => {
    if (getRecordResponse?.id) {
      setRecordInfo(getRecordResponse);
      setFormShowed(true);
      //setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*setLoading,*/ getRecordResponse]);
  //#endregion

  //#region custom
  const columns = [
    {
      id: "integrationActionName",
      label: "Acción",
      minWidth: 40,
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "name",
      label: "Nombre integración",
      minWidth: 40,
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "sourceName",
      label: "Origen",
      minWidth: 40,
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "destinationName",
      label: "Destino",
      minWidth: 40,
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "triggerActionName",
      label: "Desencadenador",
      minWidth: 40,
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "createdon",
      label: "Fecha de creación",
      minWidth: 140,
      haveDropDown: true,
      format: (value) => {
        if(value){
          let splited = value.toString().split("T")[0];
          let newDate = splited.split("-");
          return newDate.length > 2 ? `${newDate[2]}/${newDate[1]}/${newDate[0]}`:value.toString();
        }
          return "";
      },
    },
    {
      id: "stateName",
      label: "Estado",
      minWidth: 40,
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "idstate",
      label: "",
      minWidth: 70,
      haveDropDown: false,
      align: "center",
      component: {
        handleChange: (e, value, id) => handleStateClick(e, value, id),
      },
      tooltipMessage: "integración",
      withIcon: false,
      withStateIconByValue: (value) => (value < 5 || value === 9),
      withDeleteIconByValue: (value) => (value < 5 || value === 9),
      handleIconClick: (ev, id) => handleDeleteClick(ev, id),
      format: (value) => (value === 1 || value === false ? false : true),
    }
  ];

  const showMessageSaveOrUpdate = (props) => {
    setConfirmInactivate({
      ...confirmInactivate,
      ...props,
      open: true,
      message: (
        <div>
          Hiciste modificaciones
          <br />
          ¿Deseas actualizarlas?
        </div>
      ),
      showBtnAccept: true,
      showBtnCancel: true,
      handleAccept: () => {
        handleSaveModel();
        closeAlertDefault();
      }
    });
  };

  const closeAlertDefault = () => {
    setConfirmInactivate({
      open: false,
      checked: false,
      back: false,
      cancelEdit: false,
      changeTab: false,
      newTab: 0,
    });
  };

  /**
   * Manage the click event for of change state
   * @param {*} e
   * @param {*} id
   * @param {*} name
   */
  const handleStateClick = (e, id, name) => {
    setConfirmInactivate({
      open: true,
      item: id,
      name: name,
      showBtnAccept: true,
      showBtnCancel: true,
      checked: e.target.checked,
      message: `¿Está seguro que desea ${
        e.target.checked ? "activar" : "inactivar"
      }
      ${"esta integración"}?`,
    });
  };

  /**
   * Manage the click event of the delete icon
   * @param {*} ev
   * @param {*} id
   */
   const handleDeleteClick = (ev, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: true,
      message: '¿Deseas eliminar esta integración?',
    });
  };

  /**
   * Manage the click event of the confirmation or not of the popup
   */
  const handleConfirmChecked = () => {
    let result = records.filter(
      (item) => item.id === confirmInactivate.item
    )[0];

    if(result){
      let recordToBeModified = {
            id: confirmInactivate.item,
            name: result.name,
            description: result.description,
            concurrence: result.concurrence,
            hasVPN: result.hasVPN,
            hasIPsWhitelist: result.hasIPsWhitelist,
            rulespojo: result.rulespojo,
            responserulespojo: result.responserulespojo,
            fieldspojo: result.fieldspojo,
            idSource: result.idSource,
            idSourceIntegrationType: result.idSourceIntegrationType,
            idIntegrationAction: result.idIntegrationAction,
            idDestination: result.idDestination,
            idDestinationIntegrationType: result.idDestinationIntegrationType,
            idCustomer: result.idCustomer,
            idstate: confirmInactivate.checked ? 0 : 1,
            createdby: result.createdby,
            createdon: result.createdon,
            modifiedby: result.modifiedby,
            modifiedon: result.modifiedby,
            idbusinessunit: result.idbusinessunit,
            idowner: result.idowner
          };
      console.log(confirmInactivate);
      if (confirmInactivate.name === "idstate") {
        recordToBeModified.idstate = confirmInactivate.checked ? 0 : 1;
        modifyRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      } else if (confirmInactivate.delete) {
        recordToBeModified.idstate = 2;
        modifyRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      }
    }
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
      item: "",
      checked: false,
      message: "",
    });
  };

  /**
   * Get the buttons definations of the custom table
   */
  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          sm: 7,
          xs: 12,
        },
      },
      menuOrder: {
        options: [
          {
            label: "Activo",
            columns: [
              {
                prop: "idstate",
                direction: -1,
              },
            ],
          },
          {
            label: "Inactivo",
            columns: [
              {
                prop: "idstate",
              },
            ],
          },
          {
            label: "Más reciente",
            columns: [
              {
                prop: "createdon",
                direction: 1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "createdon",
                direction: -1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
        ],
        cellSize: {
          sm: 2,
          xs: 2,
        },
        justify: "start"
      },
      // exportButton: {
      //   cellSize: {
      //     lg: 2,
      //     md: 2,
      //     sm: 2
      //   }
      // },
      children: () => {
        return (
          <Grid container item lg={12} md={12} sm={2} xs={2} direction="row-reverse">
              {userPrivileges.create && (<Tooltip title={`Crear`} aria-label={`Nuevo`} >
                <Fab
                  size="small"
                  className={[classes.fab, classes.styleFab]}
                  onClick={() => {
                    setFormShowed(true);
                    setRecordId(0);
                    setRecordInfo({id:1});
                  }}
                >
                  <NewUserIcon />
                </Fab>
              </Tooltip>)}
          </Grid>
        );
      },
    };

    return button;
  };

  /**
   * if record is updated, this method get records again
   */
  const updateChecked = () => {
    if(enterpriseChanged.id)
      getRecords(enterpriseChanged.id);
  };

  /**
   * Manage the click event of the selected cell
   */
  const handleCellClick = (evt, row) => {
    setRecordId(row.id);
    getRecordById(row.id);
    setInitialTab(row.initialTab ?? 0);
    setLoading(true);
  };

  /**
   * Manage the savemodel event of the clicked save bottom
   */
  const handleSaveModel = () => {
    if(JSON.stringify(integrationModel.integrations) !== integrationModel.integrationsOriginalData){
      //New records
      integrationModel.integrations.filter(rec => rec.id === 0).forEach((newRecord) =>{
        if(newRecord.idSourceIntegrationType && newRecord.idDestinationIntegrationType && newRecord.idSource && newRecord.idDestination){
          newRecord.services.filter(service => service.idServiceCategory === 488 || service.idServiceCategory === 489).forEach((service)=>{
            const servName = Applications.filter(app=>app.id === (service.idServiceCategory === 488 ? newRecord.idSource:newRecord.idDestination))[0].name
                                + " - " + IntegrationTypes.filter(it=>it.id === (service.idServiceCategory === 488 ? newRecord.idSourceIntegrationType:newRecord.idDestinationIntegrationType))[0].name;
            if(service.name !== servName){
              service.name = servName;
              service.idApplication = parseInt(service.idServiceCategory === 488 ? newRecord.idSource:newRecord.idDestination);
              service.createdon = new Date();
              service.modifiedon = new Date();
              service.pojo = service.idServiceCategory === 488 ? "{\"Name\":\"\",\"ExternalRefNum\":\"\",\"ExternalRefCode\":\"\",\"Description\":\"\",\"Active\":\"\"}":"{\"Name\":\"\",\"InternalID\":\"\",\"isInactive\":\"\",\"ExternalID\":\"\"}";
            }
          });
          newRecord.name = IntegrationTypes.filter(it=>it.id === newRecord.idSourceIntegrationType)[0].name;
          newRecord.idCustomer = enterpriseChanged.id;
          newRecord.rulespojo = "[{\"id\":\"1.request\",\"Accion\":\"1\",\"Origen\":\"services.0.pojoObject.Name\",\"Destino\":\"services.1.pojoObject.Name\",\"focused\":false},{\"id\":\"2.request\",\"Accion\":\"1\",\"Origen\":\"services.0.pojoObject.ExternalRefNum\",\"Destino\":\"services.1.pojoObject.InternalID\",\"focused\":false},{\"id\":\"3.request\",\"Accion\":\"1\",\"Origen\":\"services.0.pojoObject.ExternalRefCode\",\"Destino\":\"services.1.pojoObject.ExternalID\",\"focused\":false}]";
          newRecord.fieldspojo = "[{\"name\":\"Coupa:Name\",\"id\":\"services.0.pojoObject.Name\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\"},{\"name\":\"Coupa:ExternalRefNum\",\"id\":\"services.0.pojoObject.ExternalRefNum\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\"},{\"name\":\"Coupa:ExternalRefCode\",\"id\":\"services.0.pojoObject.ExternalRefCode\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\"},{\"name\":\"Coupa:Description\",\"id\":\"services.0.pojoObject.Description\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\"},{\"name\":\"Coupa:Active\",\"id\":\"services.0.pojoObject.Active\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\"},{\"name\":\"NetSuite:Name\",\"id\":\"services.1.pojoObject.Name\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\",\"readOnly\":true},{\"name\":\"NetSuite:InternalID\",\"id\":\"services.1.pojoObject.InternalID\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\"},{\"name\":\"NetSuite:isInactive\",\"id\":\"services.1.pojoObject.isInactive\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\",\"readOnly\":true},{\"name\":\"NetSuite:ExternalID\",\"id\":\"services.1.pojoObject.ExternalID\",\"defaultValue\":\"\",\"type\":\"string\",\"size\":\"\",\"required\":false,\"homologationName\":\"\"}]";
          createRecord(newRecord, updateChecked);
        } else {
          const msg = `
                        ${!newRecord.idSourceIntegrationType ? "- Objecto origen ":""}
                        ${!newRecord.idDestinationIntegrationType ? "- Objecto destino ":""}
                        ${!newRecord.idSource ? "- Aplicación origen ":""}
                        ${!newRecord.idDestination ? "- Aplicación destino ":""}`
          setConfirmInactivate({
            ...confirmInactivate,
            open: true,
            message: `Por favor seleccionar: ${msg}`,
            showBtnAccept: true,
            textButtonAccept: "Cerrar",
            item: "inactivate",
          });
        }
      });
      //Update records
      const originalData = JSON.parse(integrationModel.integrationsOriginalData);
      integrationModel.integrations.filter(rec => rec.id !== 0).forEach((record) =>{
        if(JSON.stringify(record) !== JSON.stringify(originalData.filter(od => od.id === record.id)[0])){
          const recordSaveData = {
            id: record.id,
            name: record.name,
            description: record.description,
            concurrence: record.concurrence,
            hasVPN: record.hasVPN,
            hasIPsWhitelist: record.hasIPsWhitelist,
            rulespojo: record.rulespojo,
            responserulespojo: record.responserulespojo,
            fieldspojo: record.fieldspojo,
            idIntegration: record.idIntegration,
            idSource: record.idSource,
            idSourceIntegrationType: record.idSourceIntegrationType,
            idIntegrationAction: record.idIntegrationAction,
            idDestination: record.idDestination,
            idDestinationIntegrationType: record.idDestinationIntegrationType,
            idCustomer: record.idCustomer,
            idstate: record.idstate,
            createdby: record.createdby,
            createdon: record.createdon,
            modifiedby: record.modifiedby,
            modifiedon: record.modifiedby,
            idbusinessunit: record.idbusinessunit,
            idowner: record.idowner,
            services:record.services,
          };
        
          recordSaveData.services.filter(service => service.idServiceCategory === 488 || service.idServiceCategory === 489).forEach((service)=>{
            const servName = Applications.filter(app=>app.id === (service.idServiceCategory === 488 ? recordSaveData.idSource:recordSaveData.idDestination))[0].name
                                + " - " + IntegrationTypes.filter(it=>it.id === (service.idServiceCategory === 488 ? recordSaveData.idSourceIntegrationType:recordSaveData.idDestinationIntegrationType))[0].name;
            if(service.name !== servName){
              service.name = servName;
              service.idApplication = parseInt(service.idServiceCategory === 488 ? recordSaveData.idSource:recordSaveData.idDestination);
              service.modifiedon = new Date();
            }
          });
          modifyRecord(recordSaveData, updateChecked);
        }
      });
    } else {
      //There is no changes
      setConfirmInactivate({
        ...confirmInactivate,
        open: true,
        item: "",
        checked: false,
        message: `No hay cambios para guardar`,
      });
    }
  };

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    setTab(newTab);
  };

  return (
    <ViewLayout
      headerTitle={"Integraciones"}
      handleAccept={handleConfirmChecked}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      showAlert={showRecordAlert}
      setShowAlert={setShowRecordAlert}
      isLoading={isLoading}
    >
      {isFormShowed ? enterpriseChanged.id && recordInfo?.id && (
        <IntegrationForm
          recordId={recordId}
          record={recordInfo}
          initialTab={initialTab}
          customerId={enterpriseChanged.id}
          setRecordId={setRecordId}
          setCardState={setFormShowed}
          updateChecked={updateChecked}
          setLoading={setLoading}
          setShowRecordAlert={setShowRecordAlert}
          integrationTypesResponse={integrationTypesResponse}
          applicationsResponse={applicationsResponse}
          userPrivileges={userPrivileges}
          privileges={privileges}
        />
      ) : (
        <TabComponent
          aria={"integrations tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={[
            {
              label: "Listado de integraciones",
              tabContent: (
                <CustomTable
                  columns={columns}
                  data={records}
                  mainParam={"name"}
                  buttons={getButtons()}
                  havePagination={true}
                  handleCellClick={handleCellClick}
                  option={option}
                  setOption={setOption}
                  //paramToDisable={"idstate"}
                />
              )
            },
            {
              label: "Vista de Diseño",
              tabContent: (
                <>
                  {integrationModel &&
                  <BodyWidget 
                    app={new IntegrationDesign(integrationModel, handleCellClick, handleSaveModel)} 
                    handleCellClick={handleCellClick} 
                    userPrivileges={userPrivileges}
                    />
                  }
                </>
              )
            }
          ]}
        />
      )}
    </ViewLayout>
  );
};

//#endregion

//#region entities's declarations 
Integrations.propTypes = {
  getRecords: PropTypes.func.isRequired,
  modifyRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  records: state.integrationsReducer.getIntegrationsResponse,
  recordChanged: state.integrationsReducer.getIntegrationChangedResponse,
  recordErrorResponse: state.integrationsReducer.setIntegrationErrorResponse,
  enterpriseChanged: state.enterpriseReducer.getEnterpriseByIDResponse,
  messagesResponse: messagesResponseIntegrations,
  GET_RECORD_CHANGED: GET_INTEGRATION_CHANGED,
  SET_RECORD_ERROR: SET_INTEGRATION_ERROR,
  integrationTypesResponse: state.integrationTypesReducer.getIntegrationTypesResponse,
  applicationsResponse: state.applicationsReducer.getApplicationsResponse,
  getRecordResponse: state.integrationsReducer.getIntegrationResponse
});

const mapDispatchToProps = {
  getRecords: getIntegrationsByCustomer,
  modifyRecord: modifyIntegration,
  createRecord: createIntegration,
  getApplications,
  getIntegrationTypes,
  getRecordById: getIntegrationById
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
//#endregion