import { useState } from "react";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

export const CustomCheckbox = (props) => {
  const { id, isDisabled, name, width, height, onChangeHandler, opacity } = props;
  const [checked, setChecked] = useState(false);
  const checkHandler = (e) => {
    setChecked(e.currentTarget.checked);
    if(onChangeHandler)
      onChangeHandler(e, e.currentTarget.checked)
  };
  return (
    <label htmlFor="checkbox" style={{position: "relative", display: "flex", alignItems: "center"}}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={checked}
        disabled={isDisabled}
        onChange={checkHandler}
        style={{ opacity: opacity ?? 0, position: "absolute"}}
        {...props}
      />
      {!checked && <CheckBoxOutlineBlank style={{ width: width ?? "15px", height: height ?? "15px" }} />}
      {checked && <CheckBox style={{ width: width ?? "15px", height: height ?? "15px" }} />}
    </label>
    );
}
