import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFiles } from '../../../actions/SignAction';
import Modal from '@material-ui/core/Modal';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/Sign/Loading';
import Pdf from './Pdf'

const ModalAdvancedSignature = (props) => {

	const { isOpen, onClose, filesSize, style, selected, rows, getFiles, history, setFiles, signers, transactionId, imageSignature, setImageSignature } = props;
	const [loading, setLoading] = useState(true);
	const [newFiles, setNewFiles] = useState([]);
	const [size, setSize] = useState(0);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (style) {
			const getData = async () => {
				let selectFiles = [];
				selected.map(sel => {
					let file = rows.find(x => x.id === sel);
					selectFiles.push(file);
				});
				let response = await getFiles(selectFiles, enqueueSnackbar, history);
				if (response) {
					setNewFiles(response.files);
					setSize(response.filesSize);
					setLoading(false);
				}
			};
			getData();
		} else {
			setLoading(false);
		}
	}, []);

	const onExit = (type, finish) => {
		onClose(type, finish);
	}

	if (loading) return <Loading />;

	return (
		<Modal
			disableBackdropClick
			open={isOpen}
			onClose={onExit}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Pdf
				files={style ? newFiles : props.files}
				onClose={onExit}
				filesSize={style ? size : filesSize}
				style={style}
				setFiles={setFiles}
				signers={signers}
				transactionId={transactionId}
				imageSignature={imageSignature}
            	setImageSignature={setImageSignature}
			/>
		</Modal>
	)

}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
	getFiles: getFiles,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalAdvancedSignature));