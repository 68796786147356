import { useState, useEffect } from "react";
import {Grid} from "@material-ui/core";
import SvgIcon from "@mui/material/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/DeleteOutline";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import collapseIcon from "@mui/icons-material/ExpandLess";
import {ArrowIcon} from "../IconsLibrary/ArrowIcon";
import Tooltip from '@mui/material/Tooltip';

function BorderedSection({ id, icon, title, subTitle, borderStyle, isCollepsible, Collapsed, isCollepsibleHorizontal, collepsibledHorizontal, isNextstep, iconNextstep, nextstpeTitle, setNextstep, add, handleAddClick, remove, handleRemoveClick, customOption, customTitle, customIcon, customClick, children, style, childrenStyle, ...props }) {
  
    const [isHoverRemove, setIsHoverRemove] = useState(false);
    const [isHoverAdd, setIsHoverAdd] = useState(false);
    const [isHoverCustom, setIsHoverCustom] = useState(false);
    const [isCollapse, setIsCollapse] = useState(Collapsed);
    const [isCollapseHorinzontal, setIsCollapseHorinzontal] = useState(collepsibledHorizontal);
    const handleMouseEnterRemove = () => {
        setIsHoverRemove(true);
     };
     const handleMouseLeaveRemove = () => {
        setIsHoverRemove(false);
     };
     const handleMouseEnterAdd = () => {
        setIsHoverAdd(true);
     };
     const handleMouseLeaveAdd = () => {
        setIsHoverAdd(false);
     };
     const handleMouseEnterCustom = () => {
        setIsHoverCustom(true);
     };
     const handleMouseLeaveCustom = () => {
        setIsHoverCustom(false);
     };
     const handleCollapseClick = () => {
        setIsCollapse(false);
     };
     const handleExpandClick = () => {
        setIsCollapse(true);
     };
     const handleNextStepClick = () => {
        setIsCollapseHorinzontal(!isCollapseHorinzontal);
        if(setNextstep !== undefined)
            setNextstep(`${id}|${isCollapseHorinzontal}`);
     };
     useEffect(() => {
        setIsCollapseHorinzontal(collepsibledHorizontal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [collepsibledHorizontal]);

    const useStyles = makeStyles((theme) => ({
        mainContainer: {
          display: "flex",
          flexDirection: "column",
          maxWidth: isCollapseHorinzontal ? "40px" : "100%",
          borderLeft: "1px solid #b2b2b2",
          borderBottom: "1px solid #b2b2b2",
          borderRight: "1px solid #b2b2b2",
          borderRadius: "5px",
          margin: "1em 0.05em 1em 0em",
          height: isCollapse ? "30px" : "100%",
          minHeight: isCollapseHorinzontal ? "570px" : "",
          maxHeight: isCollapseHorinzontal ? "570px" : ""
        },
      
        childrenContainer: {
            padding: borderStyle==="borderStyle2" ? "1em 0em 1em 0em" : "1em",
            overflow: "auto",
            display: isCollapse || isCollapseHorinzontal ? "none" : "block"
        },
      
        header: {
            display: "flex",
            flexDirection: "row",
            width: "100% !important"
        },
      
        headerBorderBefore: {
            borderTop: "1px solid #b2b2b2",
            width: "1em",
            borderTopLeftRadius: "5px",
            zIndex: "1"
        },
      
        headerTitle: {
            display: isCollapseHorinzontal ? "none" : "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            gap: "0.25em",
            width: "fit-content",
            height: "2em",
            marginTop: "-1em",
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "1em",
            fontWeight: "600",
            backgroundColor: "white"
        },
      
        headerBorderAfter: {
            borderTop: "1px solid #b2b2b2",
            minWidth: remove && add ? "49px": "1em",
            flexGrow: "2",
            borderTopRightRadius: "5px",
            display: isCollapseHorinzontal ? "none" : ""
        },
      
        icon: {
          cursor:"pointer"
        },

        removeIcon: {
            cursor:"pointer",
            float: "right",
            color: isHoverRemove ? '#da163d' : '#575756',
        },

        addIcon: {
            cursor:"pointer",
            float: "right",
            color: isHoverAdd ? '#da163d' : '#575756',
        },

        customIcon: {
            cursor:"pointer",
            float: "right",
            color: isHoverCustom ? '#da163d' : '#575756',
        },

        nextStepIcon: {
            cursor:"pointer",
            float: "right",
            stroke: theme.palette.info.main,
            marginTop: "10px"
        },

        borderStyle1: {
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            border: "0px", 
            width: "100%"
        },

        borderStyle2: {
            borderLeft:"0px", 
            borderRight:"0px"
        },

        headerTitleRotate:{
            transform: "rotate(270deg)",
            marginLeft: "-159px",
            marginTop: "100px",
            minWidth: "300px",
            padding: "0px"
        },

        baseContainer:{
            padding:"6px 6px 6px 6px",
            maxHeight: isCollapseHorinzontal ? "570px" : "",
            height: isCollapseHorinzontal ? "100%" : "",
            maxWidth: isCollapseHorinzontal ? "40px" : "",
        },

        headerSubTitle:{
            borderLeft: "1px solid",
            borderBottom: "1px solid",
            borderBottomLeftRadius: "5px",
            float: "right",
            padding: "5px 5px 0px 2px"
        },

        subTitle:{
            fontSize: "1em",
            fontWeight: "600"
        }
      }));
    const styles = useStyles();

    return (
      <Grid className={styles.baseContainer} {...props} >
        <div 
            className={`${styles.mainContainer} ${(borderStyle==="borderStyle1"?styles.borderStyle1:borderStyle==="borderStyle2"?styles.borderStyle2:"")}`}
            style={style}>
          <div className={styles.header}>
                <div className={styles.headerBorderBefore}>
                    {(isCollepsibleHorizontal || isNextstep) && 
                        ( iconNextstep ? 
                          <Tooltip title={nextstpeTitle??"Fase"}>
                            <SvgIcon className={styles.removeIcon} 
                            onClick={handleNextStepClick}
                            component={iconNextstep}
                            />
                          </Tooltip >
                        : 
                            <ArrowIcon className={styles.nextStepIcon} 
                            onClick={handleNextStepClick}
                            />
                        )}
                </div>
                <div className={styles.headerTitle}>
                    {isCollepsible && isCollapse && <Tooltip title="Expandir"><SvgIcon className={styles.icon} component={ExpandIcon} onClick={handleCollapseClick}/></Tooltip>}
                    {isCollepsible && !isCollapse && <Tooltip title="Contraer"><SvgIcon className={styles.icon} component={collapseIcon} onClick={handleExpandClick}/></Tooltip>}
                </div>
                {(icon || title) && (
                  <div className={`${isCollapseHorinzontal?styles.headerTitleRotate:styles.headerTitle}`}>
                      {icon && <SvgIcon component={icon} />}
                      {title && <span className={styles.title}>{title}</span>}
                  </div>
                )}
                <div className={styles.headerBorderAfter}>
                    {remove && <Tooltip title="Quitar"><SvgIcon className={styles.removeIcon} 
                                onMouseEnter={handleMouseEnterRemove} 
                                onMouseLeave={handleMouseLeaveRemove}
                                onClick={handleRemoveClick}
                                component={RemoveIcon}/></Tooltip >}
                    {add && <Tooltip title="Agregar"><SvgIcon className={styles.addIcon} 
                                onMouseEnter={handleMouseEnterAdd} 
                                onMouseLeave={handleMouseLeaveAdd}
                                onClick={handleAddClick}
                                component={AddIcon}/></Tooltip >}
                    {customOption && <Tooltip title={customTitle}><SvgIcon className={styles.customIcon} 
                                onMouseEnter={handleMouseEnterCustom} 
                                onMouseLeave={handleMouseLeaveCustom}
                                onClick={customClick}
                                component={customIcon}/></Tooltip >}
                    {subTitle && 
                    <div className={styles.headerSubTitle}>
                        {title && <span className={styles.subTitle}>{subTitle}</span>}
                    </div>}
                </div>
          </div>
          <div className={styles.childrenContainer} style={childrenStyle}>{children}</div>
        </div>
      </Grid>
    );
}

export default BorderedSection;