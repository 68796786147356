import { alpha, FormControl, FormControlLabel, Grid, IconButton, makeStyles, RadioGroup, Typography } from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
import CustomModal from "../../components/Modal/modalLogin";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "../../utils/proprietaryHooks";
import { SelectIcon } from "./selectIcon";
import { dataIcons } from "./dataIcons";
import { getList } from '@pif-tr-components/components'
import { PrimaryButton } from "../../components/ButtonForm/PrimaryButton";
import StyledRadioButton from "../../components/ButtonForm/StyledRadioButton.component";
import { ControlledAutocompleteInputRoundedForm } from "../../components/InputForm/ControlledAutocompleteInputRoundedForm";
export const ModalForm = forwardRef((props, ref) => {
  const {
    open,
    setOpen,
    handleSave,
    initialValues,
    handleClose,
    privileges,
    section,
  } = props;
  const classes = useStyles();
  const iconsTotal = useRef([...getList(), ...dataIcons])
  const [selectedImageLogo, setSelectedImageLogo] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [header, setHeader] = useState({
    tab0: {
      header: "Nuevo ítem",
      subtitle: "Ingresa los datos del ítem",
    },
    tab1: {
      header: "Nuevo ítem",
      subtitle: "Selecciona un ícono para el ítem",
    },
  });

  const requiredMessage = "Campo obligatorio";
  const schema = yup.object().shape({
    name: yup.string().required(requiredMessage),
    privilege: yup.string().required(requiredMessage),
    section: yup.string(),
    isFunction: yup.boolean(),
    functionName: yup.lazy(() => watch("isFunction")
      ? yup.string().required(requiredMessage)
      : yup.string()),
    sizeIcon: yup.number(),
    url: yup.lazy(() => !watch("isFunction")
      ? yup.string()
        /*.matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Ingrese una url correcta!"
        )*/.required(requiredMessage)
      : yup.string()),
  });
  const form = useForm({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });
  const {
    control,
    errors,
    trigger,
    getValues,
    handleSubmit,
    formState,
    setValue,
    watch
  } = form;

  /**
   * set the values to inputs when view is triggered
   */
  useEffect(() => {
    if (initialValues.name !== "") {
      Object.keys(initialValues).forEach((key) => {
        if (key === "privilege") {
          setValue("privilege", initialValues[key].toString());
        } else {
          setValue(key, initialValues[key]);
        }
      });
      setHeader({
        tab0: {
          header: `Edición- ${initialValues["name"]}`,
          subtitle: "Modifica los datos del ítem",
        },
        tab1: {
          header: `Edición- ${initialValues["name"]}`,
          subtitle: "Selecciona un ícono para el ítem",
        },
      });
      setSelectedImageLogo({ icon: initialValues["iconName"] });
    } else {
      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, setValue]);

  // deprecated
  const handleCloseModal = (save, nameIcon) => {
    if (!save) {
      setSelectedImageLogo((state) => ({ ...state, icon: "" }));
    } else {
      setSelectedImageLogo((state) => ({ ...state, icon: nameIcon }));
      // clear errors
    }
    setOpen(false);
  };

  const handleClickModal = () => {
    const elements = document.getElementsByClassName("selectedIcon");
    let iconName = "";
    let isPersonalizedIcon = false;
    if (elements.length > 0) {
      for (const element of elements) {
        iconName = element.getAttribute("iconname");
        isPersonalizedIcon = element.getAttribute("isPersonalizedIcon");
      }
    }
    const isEdit = initialValues.name !== "" ? "Edit" : undefined;
    const values = getValues();
    handleSave(iconName, isPersonalizedIcon === "true", isEdit, values);
    //  handleCloseModal(true, iconName);
  };

  const handleChangeTab = async () => {
    await trigger();
    if (!isEmpty(formState.errors)) {
      return;
    } else {
      setCurrentTab(1);
    }
  };

  const handleChangeRadio = (evt) => {
    setValue(evt.target.name, evt.target.value === "false" ? false : true);
  };

  /**
   * make body of modal depending tab
   * @returns {JSX.Element} body
   */
  const getBody = () => {
    let modal = {};
    if (currentTab === 0) {
      modal = {
        header: (
          <Typography
            variant="h6"
            color="primary"
            className={classes.modalHeader}
          >
            {header.tab0.header}
          </Typography>
        ),
        body: (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.bodyTitle}
              >
                {header.tab0.subtitle}
              </Typography>
            </Grid>
            {section ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ControlledSelect
                  id="section"
                  label="Sección del menú"
                  name="section"
                  control={control}
                  options={section}
                  error={errors.section}
                  helperText={errors.section?.message}
                />
              </Grid>
            ) : null}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="name"
                fullWidth
                control={control}
                name="name"
                label="Nombre elemento"
                error={errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledAutocompleteInputRoundedForm
                data={privileges}
                watch={watch}
                setValue={setValue}
              />
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              <Typography margin="dense"  >
                Tipo de vinculo
              </Typography>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="Tipo de vinculo"
                    value={watch('isFunction')}
                    onChange={handleChangeRadio}>
                    <FormControlLabel
                      name="isFunction"
                      InputRef={control}
                      value={false}
                      control={<StyledRadioButton />}
                      label="URL"
                    />

                    <FormControlLabel
                      name="isFunction"
                      InputRef={control}
                      value={true}
                      control={<StyledRadioButton />}
                      label="Función"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {
              watch("isFunction") ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ControlledInputRoundedForm
                    id="functionName"
                    fullWidth
                    control={control}
                    name="functionName"
                    label="Nombre de la función"
                    error={errors.functionName}
                    helperText={errors.functionName?.message}
                  />
                </Grid>
              ) : (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ControlledInputRoundedForm
                    id="url"
                    fullWidth
                    control={control}
                    name="url"
                    label="Url"
                    error={errors.url}
                    helperText={errors.url?.message}
                  />
                </Grid>
              )
            }
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="enterprises"
                fullWidth
                control={control}
                name="enterprises"
                label="Empresas (opcional)"
                error={errors.enterprises}
                helperText={errors.enterprises?.message}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="description"
                fullWidth
                control={control}
                name="description"
                multiline
                rows={5}
                label="Descripción (opcional)"
                error={errors.description}
                helperText={errors.description?.message}
              />
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              <Grid item lg={2} md={2} xs={3} sm={3}>
                <Typography>Activo</Typography>
              </Grid>
              <Grid item lg={3} md={3} xs={3} sm={3}>
                <ControlledSwitchComponent
                  name="isActivate"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        ),
        actions: (
          <Grid container direction="row-reverse">
            <Grid container item lg={4} md={4} sm={6} xs={6}>
              <Grid
                container
                item
                lg={9}
                md={9}
                sm={6}
                xs={6}
                alignItems="center"
              >
                <Typography color="secondary">Siguiente</Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <IconButton
                  area-label="NextTab"
                  onClick={() => handleChangeTab()}
                >
                  <ArrowIcon className={classes.nextArrow} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ),
      };
    } else {
      modal = {
        header: (
          <Typography
            variant="h6"
            color="primary"
            className={classes.modalHeader}
          >
            {header.tab1.header}
          </Typography>
        ),
        body: (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div
                className={classes.paperSelectIcon}
                style={{ paddingTop: "10px", marginBottom: 18 }}
              >
                <SelectIcon
                  selectedIcon={selectedImageLogo}
                  dataIcons={iconsTotal.current}
                  handleCloseModal={handleCloseModal}
                  title={header.tab1.subtitle}
                  setSelectedIcon={setSelectedImageLogo}
                />
              </div>
            </Grid>
          </Grid>
        ),
        actions: (
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <IconButton
                area-label="PreviousTab"
                onClick={() => setCurrentTab(0)}
              >
                <ArrowIcon className={classes.previousArrow} />
              </IconButton>
            </Grid>
            <Grid container item lg={6} md={6} sm={6} xs={6} justify="flex-end">
              <PrimaryButton
                text={
                  initialValues.name !== "" ? "Actualizar ítem" : "Crear item"
                }
                onClick={handleClickModal}
              />
            </Grid>
          </Grid>
        ),
      };
    }
    return modal;
  };

  const restartModal = () => {
    setCurrentTab(0);
  };

  useImperativeHandle(
    ref,
    () => ({
      form: form,
      logo: selectedImageLogo,
      save: handleClickModal,
      restartModal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        handleClose();
        setOpen(false);
      }}
      //  header={}
      body={
        <div>
          {getBody().header}
          {getBody().body}
        </div>
      }
      actions={getBody().actions}
      width={"503px"}
      handleSubmit={handleSubmit(handleChangeTab)}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.secondary.main,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
  modalHeader: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bodyTitle: {
    color: theme.palette.primary.light,
  },
  paperSelectIcon: {
    height: "350px",
    marginBottom: 120,
  },
  closeIcon: {
    color: theme.palette.primary.light,
    fontSize: "15px",
  },
  inputHiddenLabel: {
    color: "#b2b2b2",
  },
  autoComplete: {
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "#FFFFFF",
      boxShadow: `0px 1px 1px #00000026`,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      "&$error": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiInputBase-input.Mui-error": {
        borderColor: theme.palette.error.main,
      },
    },
    inputHiddenLabel: {
      color: "#b2b2b2",
    },
    focused: {
      fontSize: 14,
    },
    error: {},
  }
}));
