import {
  GET_RESPONSECODE,
  GET_RESPONSECODES,
  SET_RESPONSECODE_ERROR,
  GET_RESPONSECODE_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * ResponseCodes Action ResponseCodes
 *
 * @method GET
 */
  const getResponseCodes = () => async (dispatch) => {
    dispatch(getRecords("responsecode", GET_RESPONSECODES, SET_RESPONSECODE_ERROR));
  };

export /**
 * ResponseCodes Action getResponseCodes
 *
 * @method GET
 * @param customerid
 */
  const getResponseCodesByCustomer = (customerid, parentId) => async (dispatch) => {
    dispatch(getRecordsByCustomer("responsecode", customerid, GET_RESPONSECODES, SET_RESPONSECODE_ERROR, 1, "", "Service.Integration.IdCustomer"
      , parentId ? [{fieldName: "IdService", fieldValue: parentId}] : []
      )
    );
  };

  export /**
  * ResponseCodes Action getResponseCodes
  *
  * @method GET
  * @param customerid
  */
   const getResponseCodesByCodeAndCustomer = (customerid, code) => async (dispatch) => {
     dispatch(getRecordsByCustomer("responsecode", customerid, GET_RESPONSECODES, SET_RESPONSECODE_ERROR, 1, "", "Service.Integration.IdCustomer"
       , code ? [{fieldName: "Code", fieldValue: code}] : []
       )
     );
   };

export /**
 * ResponseCode Action getResponseCodeById
 *
 * @method GET
 * @param {*} id
 */
  const getResponseCodeById = (id) => async (dispatch) => {
    dispatch(getRecordById("responsecode", id, GET_RESPONSECODE, SET_RESPONSECODE_ERROR));
  };

export /**
  * ResponseCode Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createResponseCode = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord("responsecode", recordToCreate, updateChecked, GET_RESPONSECODE_CHANGED, SET_RESPONSECODE_ERROR));
 };
 

export /**
 * ResponseCode Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyResponseCode = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("responsecode", recordToUpdate, updateChecked, isPrincipalPage, GET_RESPONSECODE_CHANGED, SET_RESPONSECODE_ERROR));  
};
