import React, { useEffect, useState } from 'react'
import ViewLayout from '../../components/layouts/ViewLayout'
import { parseJwt } from '../../utils/userInfo'
import { ClientsView } from './Client.view'

export const ContainerClient = () => {

  const [sequence, setSequence] = useState(0)

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token !== undefined && token !== null) {
      const { nameid } = parseJwt(token);
      setSequence(parseInt(nameid))
    }
  }, [])


  return (
    <ViewLayout
      headerTitle={'Clientes Outh 2.0'}
    >
      {
        sequence !== 0
          ? <ClientsView isRoute={true} sequence={sequence} />
          : <></>
      }

    </ViewLayout>
  )
}
