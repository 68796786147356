import {makeStyles, TextField } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({

    searchTextField: {
        width: "100%",
        padding: "10px 23px 10px 10px",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `10px`,
              //  backgroundColor: theme.palette.common.white,
                height: '40px',
                boxShadow: '0px 3px 2px #00000026',
                border: `1px solid ${theme.palette.info.light}`,
            },
        },

    },


}));
const InputSearch = (props) => {
    const classes = useStyles();
    return (
        <TextField
            className={classes.searchTextField}
            {...props}
            variant="outlined"
            margin="dense"
        />
    )
}

export default InputSearch
