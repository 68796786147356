import * as SRD from '@projectstorm/react-diagrams';
import { DiamondNodeFactory } from './components/diamondNode/DiamondNodeFactory';
import { SimplePortFactory } from './components/diamondNode/SimplePortFactory';
import { DiamondPortModel } from './components/diamondNode/DiamondPortModel';
import { IntegrationNodeModel } from './components/integrationNode/IntegrationNodeModel';
import { IntegrationNodeFactory } from './components/integrationNode/IntegrationNodeFactory';

/**
 * @author Dylan Vorster
 */
export class IntegrationDesign {
	protected activeModel: SRD.DiagramModel;
	protected diagramEngine: SRD.DiagramEngine;
	protected integrationModel: any;
	protected handleNodeClick: (event:any,data:any) => void;
	protected handleSaveModel: () => void;

	constructor(integrationModel: any
			, handleClick?: (event:any, data:any) => void
			, handleSaveModel?: () => void
			) {
		this.diagramEngine = SRD.default();
		this.integrationModel = integrationModel;
		this.handleNodeClick = handleClick;
		this.handleSaveModel = handleSaveModel;
		this.currentModel();
	}

	public currentModel() {

		// register some other factories as well
		this.diagramEngine
			.getPortFactories()
			.registerFactory(new SimplePortFactory('diamond', (config) => new DiamondPortModel(SRD.PortModelAlignment.LEFT)));
		this.diagramEngine.getNodeFactories().registerFactory(new DiamondNodeFactory());
		this.diagramEngine.getNodeFactories().registerFactory(new IntegrationNodeFactory());

		this.activeModel = new SRD.DiagramModel();
		this.diagramEngine.setModel(this.activeModel);
		var posY=20;

		for(const item in this.integrationModel.integrations.filter((rec: { idstate: number; }) => rec.idstate !== 1 && rec.idstate !== 2)){
			this.setRecordInModel(this.integrationModel.integrations[item], {y:posY});
			posY += 80;
		}
		// this.integrationModel.forEach((integration) => {
		// 	this.setRecordInModel(integration, {y:posY});
		// 	posY += 80;
		// });
	}

	public setRecordInModel(record:any, point:any){
		//3-A) create a default node
		var node1 = new IntegrationNodeModel({data:record, name:"Source", color:'rgb(0,192,255)', app:record.idSource, entity:record.idSourceIntegrationType, apps:this.integrationModel.apps, entities:this.integrationModel.entities, handleClick:this.handleNodeClick});
		let port1Out = node1.addOutPort('Out');
		node1.setPosition(point.x ? point.x - 250 : 100, point.y);

		//3-A) create a default node
		record.idTranformation = 57;
		record.idTranformationIntegrationType = 0;
		var node2 = new IntegrationNodeModel({data:record, name:"Tranformation", color:'#ff415a', app:"57", entity:record.idSourceIntegrationType, apps:this.integrationModel.apps, entities:this.integrationModel.entities, handleClick:this.handleNodeClick});
		let port2In = node2.addInPort('In');
		let port2Out = node2.addOutPort('Out');
		node2.setPosition(point.x ? point.x : 350, point.y);

		//3-B) create another default node
		var node3 = new IntegrationNodeModel({data:record, name:"Destination", color:'rgb(192,255,0)', app:record.idDestination, entity:record.idDestinationIntegrationType, apps:this.integrationModel.apps, entities:this.integrationModel.entities, handleClick:this.handleNodeClick});
		let port3In = node3.addInPort('In');
		node3.setPosition(point.x ? point.x + 250 : 600, point.y);

		// link the ports
		let link1 = port1Out.link(port2In);
		let link2 = port2Out.link(port3In);

		//3-B) create our new custom node
		// var node4 = new DiamondNodeModel();
		// node4.setPosition(250, 108);

		this.activeModel.addAll(node1, node2, node3, /*node4,*/ link1, link2);
	}

	public getActiveDiagram(): SRD.DiagramModel {
		return this.activeModel;
	}

	public getDiagramEngine(): SRD.DiagramEngine {
		return this.diagramEngine;
	}

	public getRecords(){
		return this.integrationModel.integrations;
	}

	public getApps(){
		return this.integrationModel.apps;
	}

	public getEntities(){
		return this.integrationModel.entities;
	}

	public getRecordNew(){
		return {
			id: 0,
			name: "Nueva integración",
			description: "",
			concurrence: 100,
			hasVPN: false,
			hasIPsWhitelist: false,
			rulesPojo: "[]",
			responseRulesPojo: "[]",
			fieldsPojo: "",
			idSource: 62,
			idSourceIntegrationType: 1,
			idIntegrationAction: 492,
			idDestination: 61,
			idDestinationIntegrationType: 1,
			idCustomer: 0,
			idstate: 0,
			createdby: 0,
			modifiedby: 0,
			idbusinessunit: 0,
			idowner: 0,
			/***************************** */
			services:[
				{idServiceCategory: 488, name:"Servicio origen", code: "PIF.IC", idFormatType: 7, idProtocolType: 13, idServiceType: 19, idMethodType: 21, idSecurityType: 27, idApplication: 62, idstate: 0, pojo: "{}", responsePojo:"{}"},
				{idServiceCategory: 489, name:"Servicio destino", code: "PIF.IE", idFormatType: 7, idProtocolType: 13, idServiceType: 20, idMethodType: 21, idSecurityType: 27, idApplication: 61, idstate: 0, pojo: "{}", responsePojo:"{}" }
			],
			idTranformation: 57,
			idTranformationIntegrationType: 0
		};
	}

	public saveModel(){
		if(this.handleSaveModel){
			this.handleSaveModel();
		}
	}
}
