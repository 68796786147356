import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState, useRef } from "react";
import "./dashboard.css";
import { MonitoringDashBoardTransactions } from "./monitoringDashboardTransactions";
import { MonitoringDashBoardResponseTimes } from "./monitoringDashboardResponseTimes";
import { Grid, MenuItem } from "@material-ui/core";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import CustomForms from "../customForms/customForms.view";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getDashboardTypesByEnterprise } from "../../actions/objecttype.action";
import { useDispatch, useSelector } from "react-redux";
import { MonitoringDashBoardEmbed } from "./monitoringDashboardEmbed";

export function MonitoringDashBoard(props) {
  const dispatch = useDispatch();
  const { enterpriseRecord } = props;
  const [dashboardTypeSelected, setDashboardTypeSelected] = useState({
    id: "dashboardtransactions",
    value: "dashboardtransactions",
    name: "Transacciones",
    selected: true,
    filter: "",
  });
  const [dashboardTypes, setDashboardTypes] = useState([dashboardTypeSelected]);
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    objectType: Yup.string().required(requiredMessage),
  });
  const { control, watch } = useForm({
    defaultValues: {
      dashboardType: "dashboardtransactions",
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
  });

  const { getDashboardTypesResponse } = useSelector(
    (store) => store.objectTypesReducer
  );

  useEffect(() => {
    if (enterpriseRecord.id)
      dispatch(getDashboardTypesByEnterprise(enterpriseRecord.name));
  }, [getDashboardTypesByEnterprise, enterpriseRecord]);

  useEffect(() => {
    if (getDashboardTypesResponse && getDashboardTypesResponse.length) {
      const dashboardTypes = [];
      getDashboardTypesResponse
        .sort((a, b) => {
          return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
        })
        .forEach((dashboardType) => {
          const objdashboardType = jsonParse(dashboardType.description);
          if (objdashboardType.id) {
            dashboardTypes.push({
              id: objdashboardType.id,
              value: objdashboardType.value,
              name: objdashboardType.name,
              selected: objdashboardType.selected,
              filter: objdashboardType.filter,
            });
          }
        });
      setDashboardTypes(dashboardTypes);
    }
  }, [getDashboardTypesResponse]);

  const jsonParse = (value, initialValue = {}, callback) => {
    let obj = initialValue;
    try {
      obj = JSON.parse(value);
    } catch (ex) {
      if (callback) callback(ex);
    }
    return obj;
  };

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
        }}
      >
        <Grid item sm={4} xs={12} style={{ marginRight: "10px" }}>
          <ControlledInputRoundedForm
            id="dashboardType"
            name="dashboardType"
            label="Panel de control"
            control={control}
            fullWidth
            select
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            {dashboardTypes.map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                selected={option.selected}
                onClick={() => setDashboardTypeSelected(option)}
              >
                {option.name}
              </MenuItem>
            ))}
          </ControlledInputRoundedForm>
        </Grid>
      </Grid>
      {dashboardTypeSelected.id === "dashboardtransactions" && (
        <>
          <MonitoringDashBoardTransactions />
        </>
      )}

      {dashboardTypeSelected.id === "dashboardresponsetime" && (
        <>
          <MonitoringDashBoardResponseTimes />
        </>
      )}

      {dashboardTypeSelected.filter === "embed" && (
        <MonitoringDashBoardEmbed source={dashboardTypeSelected.value} />
      )}
    </>
  );
}
