export const themeColors = {
  PIF: {
    primary: {
      light: "#ff415a",
      main: "#da163d",
      dark: "#b70120",
      iconSend: "#b70120",
    },
    secondary: {
      dark: "#ff415a",
      main: "#ff415a",
      light: "#FA5A0026",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    text: {
      primary: "#009BDD",
    },
    theme: "light"
  },
  coupa: {
    primary: {
      light: "#9b9b9b",
      main: "#333333",
      dark: "#333333",
      iconSend: "#333333",
    },
    secondary: {
      dark: "#545759",
      main: "#9b9b9b",
      light: "#FA5A0026",
    },
    info: {
      light: "#D5D5D5",
      main: "#00000080",
      dark: "#C1C1C1",
    },
    error: {
      light: "#FA5A00",
      main: "#D80000",
      dark: "#D80000",
    },
    text: {
      primary: "#575756",
      secondary: "#FFFFFF",
      disabled: "#B2B2B2",
    },
    common: {
      grey: "#A8A8A8",
      black: "#575756",
      white: "#FFFFFF",
    },
    theme: "dark"
  }
};

/*
  primary -> light: #ff415a / main: #da163d  / dark: #b70120
  secondary -> light: #FA5A00  / main: #FA5A00  / dark:  #E45501
  error -> light:  / main:  / dark:
  warning -> light:  / main:  / dark:
  info -> light:  #00000026 / main: #00000080 / dark:  #00000099
  success -> light:  / main:  / dark:
 */
