import {
  GET_DATATYPE,
  GET_DATATYPES,
  SET_DATATYPE_ERROR,
  GET_DATATYPE_CHANGED,
} from "../actions/types";

const initialState = {
  getDataTypeResponse: [],
  getDataTypesResponse: [],
  setDataTypeErrorResponse: [],
  getDataTypeChangedResponse: []
};

/**
 * datatypesReducer (set datatype or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, datatype}
 */
export default function dataTypesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_DATATYPES:
      return {
        ...state,
        getDataTypesResponse: action.payload,
      };
    case GET_DATATYPE:
      return {
        ...state,
        getDataTypeResponse: action.payload,
      };
    case GET_DATATYPE_CHANGED:
      return {
        ...state,
        getDataTypeChangedResponse: action.payload,
      };
    case SET_DATATYPE_ERROR:
      return {
        ...state,
        setDataTypeErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
