import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import Proptypes from "prop-types";
export const PrimaryButton = ({ text, onClick, disabled }, props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      classes={{
        root: classes.root,
        label: classes.text,
      }}
      disabled={disabled}
      variant="contained"
      color="primary"
      {...props}
    >
      {text}
    </Button>
  );
};

PrimaryButton.prototypes = {
  text: Proptypes.string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px !important",
    height: 37,
    width: 168,
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    textAlign: "center",
    color: theme.palette.common.white,
  },
}));
