import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { CHANGE_CLIENT_MESSAGE, DEFAULTRESPONSE, ERROR_GET_ALL_CLIENTS, GET_ALL_CLIENTS, GET_ALL_SCOPES, GET_ERROR_CHANGE_CLIENT } from "./types";

export /**
 * User Action GetClients
 *
 * @method GET
 * @param {*}
 */
  const GetClients = (data) => async (dispatch) => {
    try {

      let myConfig = defaultHeaders();
      Object.assign(myConfig.headers, {
        ...data
      });

      const response = await axios.get(
        API_SECURITY_ENDPOINT + "/api/client/list",
        myConfig
      );
      dispatch({
        type: GET_ALL_CLIENTS,
        payload: response.data.result,
      });
      //console.log(response.data.result.records);
    } catch (error) {
      console.error(error);
      const { response } = error;
      dispatch({
        type: ERROR_GET_ALL_CLIENTS,
        payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
      });
    }
  };

export /**
 * User Action getUsers
 *
 * @method GET
 * @param {*}
 */
  const GetScopes = (data) => async (dispatch) => {
    try {
      let myConfig = defaultHeaders();
      Object.assign(myConfig.headers, {
        ...data
      });

      const response = await axios.get(
        API_SECURITY_ENDPOINT + "/api/client/scopes",
        myConfig
      );
      dispatch({
        type: GET_ALL_SCOPES,
        payload: response.data.result,
      });
      //console.log(response.data.result.records);
    } catch (error) {
      console.error(error);
      const { response } = error;
      dispatch({
        type: ERROR_GET_ALL_CLIENTS,
        payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
      });
    }
  };

export /**
 * User Action getUsers
 *
 * @method POST
 * @param {*}
 */
  const CrudClient = (data, action) => async (dispatch) => {
    try {

      const response = await axios.post(
        API_SECURITY_ENDPOINT + `/api/client/${action}`,
        data,
        defaultHeaders()
      );

      dispatch({
        type: CHANGE_CLIENT_MESSAGE,
        payload: response.data.result,
      });
      //console.log(response.data.result.records);
    } catch (error) {
      console.error(error);
      const { response } = error;
      dispatch({
        type: GET_ERROR_CHANGE_CLIENT,
        payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
      });
    }
  };