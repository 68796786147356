import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {IconButton, Grid, Tooltip, Typography, makeStyles, FormControlLabel } from "@material-ui/core";
import FormLayout from "../../components/layouts/FormLayout";
import { validateEquals, isEmpty } from "../../utils/proprietaryHooks";
import { ButtonSaveChanges } from "../../components/ButtonForm/ButtonSaveChanges";
import TabComponent from "../../components/TabsComponent/TabComponent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import BorderedSection from "../../components/CardBody/borderedSection.component";

//#region Entity's dependencies
import { getFormatTypes, getProtocolTypes, getServiceTypes, getServiceCategories, getMethodTypes, getSecurityTypes } from "../../actions/objecttype.action";
import { getIntegrationsByCustomer } from "../../actions/integrations.action";
import { getApplications } from "../../actions/applications.action";
import { createService, getServiceById, modifyService } from "../../actions/service.action";
import { GET_SERVICE, GET_SERVICE_CHANGED } from "../../actions/types";
import { messagesResponseServices } from "../../utils/constants";
import ResponseCodes from "../responseCodes/responseCodes.view";
//#endregion

/**
 * ServiceForm Component ( full view for form to create/modify service )
 *
 * @export Class Component
 * @class ServiceForm
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
    borderBottom: `2px solid ${theme.palette.common.grey}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary.main.main,
      fontWeight: "bold",
    },
  },
  selected: {
    color: theme.palette.secondary.main.main,
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    margin: "0px 15px",
  },
  tabsItem: {
    textTransform: "none",
  },
  tabs: {
    borderBottom: "1px solid #e8e8e8",
  },
  btnApplications: {
    marginBottom: "9px",
    boxShadow: "0px 3px 3px #0000001A",
    borderRadius: "5px",
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  buttonSave: {
    margin: "3px 0px",
    flex: 1,
    float: "right",
  },
  errorText: {
    padding: "10px 15px",
  },
  categoryButtonFalse: { background: "#FFFFFF 0% 0% no-repeat padding-box" },
  categoryButtonTrue: {
    background: "#6D6E71 0% 0% no-repeat padding-box",
    color: "white !important",
  },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.text.disabled,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
}));

const ServiceForm = (props) => {
  const classes = useStyles();
  /* #region  Props */
  const {
    privileges,
    userPrivileges,
    recordId,
    record,
    setRecordId,
    setCardState,
    updateChecked,
    setShowRecordAlert,
    setLoading,
    getRecordResponse,
    recordChanged,
    recordErrorResponse,
    messagesResponse,
    GET_RECORD_CHANGED,
    GET_RECORD,
    formatTypesResponse,
    protocolTypesResponse,
    serviceTypesResponse,
    serviceCategoriesResponse,
    methodTypesResponse,
    securityTypesResponse,
    integrationsResponse,
    applicationsResponse,
    enterpriseChanged,
    modifyRecord,
    createRecord,
    getRecordById,
    getFormatTypes,
    getProtocolTypes,
    getServiceTypes,
    getServiceCategories,
    getMethodTypes,
    getSecurityTypes,
    getIntegrations,
    getApplications
  } = props;
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(0);
  const [charactersToEnd] = useState(
    !!record.description ? 500 - record.description.length : 500
  );
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  const [enabledTabsForm, setEnabledTabsForm] = useState(
    !isEmpty(record) ? false : true
  );
  const initialStateForm = {
    id: 0,
    code: "",
    componentVersion: "",
    repositoryName: "",
    name: "",
    description:"",
    idIntegration: 0,
    idApplication: 0,
    idServiceType: 0,
    idProtocolType: 0,
    idFormatType: 0,
    idMethodType: 0,
    idSecurityType: 0,
    idServiceCategory: 0,
    metadataServiceURL: "",
    filter: "",
    pojo: "",
    responsePojo: "",
    serviceURL: "",
    headers: "",
    cronKey: "",
    cronPeriodicity: "",
    callOutName: "",
    schedulledRestart: "",
    oauthURL: "",
    payload: "",
    async: false,
    asyncCallbackURL: "",
    clientId: "",
    clienteSecret: "",
    scope: "",
    user: "",
    pass: "",
    jwtURL: "",
    idstate: 0,
    createdby: 0,
    modifiedby: 0,
    idbusinessunit: 0,
    idowner: 0
  };
  const setRecord = (rec) => {
    return !isEmpty(rec) && recordId
      ? {
        id: rec.id,
        code: rec.code,
        componentVersion: rec.componentVersion,
        repositoryName: rec.repositoryName,
        name: rec.name,
        description: rec.description,
        idIntegration: rec.idIntegration,
        idApplication: rec.idApplication,
        idServiceType: rec.idServiceType,
        idProtocolType: rec.idProtocolType,
        idFormatType: rec.idFormatType,
        idMethodType: rec.idMethodType,
        idSecurityType: rec.idSecurityType,
        idServiceCategory: rec.idServiceCategory,
        metadataServiceURL: rec.metadataServiceURL,
        filter: rec.filter,
        cronKey: rec.cronKey,
        cronPeriodicity: rec.cronPeriodicity,
        callOutName: rec.callOutName,
        schedulledRestart: rec.schedulledRestart,
        oauthURL: rec.oauthURL,
        pojo: rec.pojo,
        responsePojo: rec.responsePojo,
        serviceURL: rec.serviceURL,
        headers: rec.headers,
        payload: rec.payload,
        async: rec.async,
        asyncCallbackURL: rec.asyncCallbackURL,
        clientId: rec.clientId,
        clienteSecret: rec.clienteSecret,
        scope: rec.scope,
        user: rec.user,
        pass: rec.pass,
        jwtURL: rec.jwtURL,
        idstate: rec.idstate,
        createdby: rec.createdby,
        createdon: rec.createdon,
        modifiedby: rec.modifiedby,
        idbusinessunit: rec.idbusinessunit,
        idowner: rec.idowner
      }
      : initialStateForm
  };
  const [formRecordData, setFormRecordData] = useState(
    setRecord(record)
  );
  const [FormatTypes, setFormatTypes] = useState([{id: 1, name:'Json'},{id: 9, name:'Xml'},]);
  const [ProtocolTypes, setProtocolTypes] = useState([{id: 1, name:'Api rest'},{id: 9, name:'Soap'},]);
  const [ServiceTypes, setServiceTypes] = useState([{id: 1, name:'Cron'},{id: 9, name:'Call out'},]);
  const [ServiceCategories, setServiceCategories] = useState([{id: 1, name:'Origen'},{id: 2, name:'Destino'},{id: 3, name:'Transformación'}]);
  const [MethodTypes, setMethodTypes] = useState([{id: 1, name:'Post'},{id: 9, name:'Get'},]);
  const [SecurityTypes, setSecurityTypes] = useState([{id: 1, name:'Jwt'},{id: 9, name:'Oauth2.0'},]);
  const [Integrations, setIntegrations] = useState([{id: 1, name:'Integration 1'},{id: 9, name:'Integration 2'},]);
  const [Applications, setApplications] = useState([{id: 1, name:'Coupa'},{id: 9, name:'SAP'},]);
  /* #endregion */

  /* #region  useRef */
  const validators = useRef({
    isNewRecord: recordId === 0 ? true : false,
  });
  const originalInfo = useRef({
    originalData: formRecordData,
  });
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    documenttypeid: Yup.number().required(requiredMessage),
    name: Yup.string().max(200).required(requiredMessage),
  });
  const {
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: { ...formRecordData },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  const lastTab = 3; //Tab donde se comienza a colocar los hijos (1:N) del actual registro, si no tiene colocar último tab + 1
  //#endregion

  const dispatch = useDispatch();

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    if (validators.current.isNewRecord) {
      await trigger();
      if (!isEmpty(formState.errors)) {
        return;
      }
      setTab(newTab);
    } else if (enabledTabsForm && tab < lastTab) {
      if (!compareObjects(tab)) {
        setTab(newTab);
        showMessageSaveOrUpdate({
          changeTab: false,
          cancelEdit: false,
          back: false,
        });
      } else {
        setTab(newTab);
      }
    } else {
      setTab(newTab);
    }
  };

  const saveRecord = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validators.current.isNewRecord) {
      await trigger();
      if (isEmpty(formState.errors)) {
        const [recordSaveData] = createObjectSave(true);
        confirmSaveChanges(recordSaveData);
      }
    } else {
      const [recordSaveData] = createObjectSave(true);
      confirmSaveChanges(recordSaveData);
    }
  };

  const confirmSaveChanges = (recordSaveData) => {
    if (validators.current.isNewRecord) {
      createRecord(recordSaveData, updateChecked);
    } else {
      modifyRecord(recordSaveData, updateChecked, false, tab);
    }
  };

  const NotChange = () => {
    dispatch({
      type: GET_RECORD_CHANGED,
      payload: messagesResponse.noChanges,
    });
  };

  const createObjectSave = (includeForm) => {
    let recordSaveData = JSON.parse(
      JSON.stringify(includeForm ? { ...getValues() } : { id: getValues().id })
    );

    return [recordSaveData];
  };

  const compareObjects = (tab) => {
    setLoading(true);
    let response = validateEquals(
      { ...getValues() },
      originalInfo.current.originalData
    );
    setLoading(false);
    return response;
  };

  const backButton = () => {
    if (validators.current.isNewRecord) {
      resetFieldComplete();
    } else if (enabledTabsForm) {
      if (!compareObjects(0)) {
        showMessageSaveOrUpdate({
          back: true,
          newTab: 0,
          changeTab: false,
          cancelEdit: false,
        });
      } else {
        resetFieldComplete();
      }
    } else {
      resetFieldComplete();
    }
  };

  useEffect(() => {
    return () => {
      resetFieldComplete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Array.isArray(recordErrorResponse)) {
      setLoading(false);
    }
  }, [setLoading, recordErrorResponse]);

  useEffect(() => {
    if (!Array.isArray(recordChanged)) {
      setCardState(true);
      setShowRecordAlert(true);
      setConfirmInactivate({
        ...confirmInactivate,
        open: false,
        item: "inactivate",
      });
    }

    if (!!recordChanged) {
      if (recordChanged === messagesResponse.createdRecord) {
        resetFieldComplete();
      }

      if (recordChanged === messagesResponse.updatedRecordData) {
        originalInfo.current.originalData = JSON.parse(
          JSON.stringify({ ...getValues() })
        );
        activeValidations();
      }

      if (recordChanged === messagesResponse.updatedRecordConfig) {
        if (!confirmInactivate.changeTab && !confirmInactivate.back && !confirmInactivate.cancelEdit) {
          resetFieldComplete()
        } else {
          activeValidations();
        }
      }

      if (recordChanged === messagesResponse.noChanges) {
        // Cuando no hay cambios
        activeValidations();
      }

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordChanged]);

  const resetFieldComplete = () => {
    setRecordId(0);
    setTab(0);
    setConfirmInactivate((state) => ({ ...state, newTab: -1 }));
    setCardState(false);
    setFormRecordData(initialStateForm);
    originalInfo.current = {};
    setLoading(false);
    setConfirmInactivate({
      open: false,
    });
    dispatch({
      type: GET_RECORD,
      payload: [],
    });
    dispatch({
      type: GET_RECORD_CHANGED,
      payload: [],
    });
  };

  const showMessageSaveOrUpdate = (props) => {
    setConfirmInactivate({
      ...confirmInactivate,
      ...props,
      open: true,
      message: (
        <div>
          Hiciste modificaciones
          <br />
          ¿Deseas actualizarlas?
        </div>
      ),
      showBtnAccept: true,
      showBtnCancel: true,
    });
  };

  const closeAlertDefault = () => {
    setConfirmInactivate({
      open: false,
      checked: false,
      back: false,
      cancelEdit: false,
      changeTab: false,
      newTab: 0,
    });
  };

  const activeValidations = () => {
    if (confirmInactivate.changeTab) {
      setTab(confirmInactivate.newTab);
    }

    if (confirmInactivate.back) {
      if (tab === 0) {
        resetFieldComplete();
      } else {
        setTab(0);
      }
    }

    if (confirmInactivate.cancelEdit) {
      setEnabledTabsForm(false);
    }
  };

  const handleCancel = () => {
    for (const key in formRecordData) {
      setValue(key, originalInfo.current.originalData[key]);
    }
    activeValidations();
    closeAlertDefault();
  };

  const handleAccept = (e) => {
    saveRecord(e);
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleChangeEdit = (response) => {
    if (!response) {
      if (!compareObjects(tab)) {
        showMessageSaveOrUpdate({
          cancelEdit: true,
          newTab: 0,
          changeTab: false,
          back: false,
        });
      } else {
        setEnabledTabsForm(response);
      }
    } else {
      setEnabledTabsForm(response);
    }
  };

  const defineSubHeader = () => {
    let subheader = "";
    if (!validators.current.isNewRecord) {
      subheader = (enabledTabsForm && tab < lastTab ? "Editar " + items[tab].label.toLowerCase() : items[tab].label) ;
    } else {
      subheader = "Nuevo servicio | " + items[tab].label;
    }
    return subheader;
  };

  useEffect(() => {
    if(recordId && enabledTabsForm){
      setLoading(true);
      getRecordById(recordId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecordById, enabledTabsForm]);

  useEffect(() => {
    if (getRecordResponse?.id) {
      let recordRefresh = setRecord(getRecordResponse);
      if(!validateEquals(recordRefresh, originalInfo.current.originalData)){
        setFormRecordData(recordRefresh);
        for (const key in recordRefresh) {
          setValue(key, recordRefresh[key]);
        }
        originalInfo.current.originalData = JSON.parse(
          JSON.stringify({ ...getValues() })
        );
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, getRecordResponse]);  

  useEffect(() => {
    getFormatTypes();
    getProtocolTypes();
    getServiceTypes();
    getServiceCategories();
    getMethodTypes();
    getSecurityTypes();
    getApplications();
  }, [getFormatTypes, getProtocolTypes, getServiceTypes, getServiceCategories, getMethodTypes, getSecurityTypes, getApplications]);

  useEffect(() => {
    if(enterpriseChanged.id)
      getIntegrations(enterpriseChanged.id);
  }, [getIntegrations, enterpriseChanged]);

  useEffect(() => {
    if (formatTypesResponse) {
      setFormatTypes(formatTypesResponse)
      setLoading(false);
    }
  }, [setLoading, formatTypesResponse]);
  useEffect(() => {
    if (protocolTypesResponse) {
      setProtocolTypes(protocolTypesResponse)
      setLoading(false);
    }
  }, [setLoading, protocolTypesResponse]);
  useEffect(() => {
    if (serviceTypesResponse) {
      setServiceTypes(serviceTypesResponse)
      setLoading(false);
    }
  }, [setLoading, serviceTypesResponse]);
  useEffect(() => {
    if (serviceCategoriesResponse) {
      setServiceCategories(serviceCategoriesResponse)
      setLoading(false);
    }
  }, [setLoading, serviceCategoriesResponse]);
  useEffect(() => {
    if (methodTypesResponse) {
      setMethodTypes(methodTypesResponse)
      setLoading(false);
    }
  }, [setLoading, methodTypesResponse]);
  useEffect(() => {
    if (securityTypesResponse) {
      setSecurityTypes(securityTypesResponse)
      setLoading(false);
    }
  }, [setLoading, securityTypesResponse]);
  useEffect(() => {
    if (applicationsResponse) {
      setApplications(applicationsResponse)
      setLoading(false);
    }
  }, [setLoading, applicationsResponse]);
  useEffect(() => {
    if (integrationsResponse) {
      setIntegrations(integrationsResponse)
      setLoading(false);
    }
  }, [setLoading, integrationsResponse]);

  const items = [
    {
      label: "Datos básicos",
      tabContent: (
        <>
          <Grid item lg={5} md={5} sm={10} xs={12}>
            <ControlledSelect
              id="idIntegration"
              name="idIntegration"
              label="Integración"
              options={Integrations}
              error={errors.idIntegration}
              helperText= {errors.idIntegration?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={5} md={5} sm={10} xs={12}>
            <ControlledSelect
              id="idApplication"
              name="idApplication"
              label="Aplicación"
              options={Applications}
              error={errors.idApplication}
              helperText= {errors.idApplication?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={5} md={5} sm={10} xs={12}>
            <ControlledInputRoundedForm
              id="code"
              name="code"
              inputProps={{ maxLength: 200 }}
              label="Componente"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú._\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.code}
              helperText={errors.code?.message}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={10} xs={12} style={{paddingLeft:"3px"}}>
            <ControlledInputRoundedForm
              id="repositoryName"
              name="repositoryName"
              inputProps={{ maxLength: 200 }}
              label="Repositorio"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú._\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.repositoryName}
              helperText={errors.repositoryName?.message}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={10} xs={12} style={{paddingLeft:"3px"}}>
            <ControlledInputRoundedForm
              id="componentVersion"
              name="componentVersion"
              inputProps={{ maxLength: 200 }}
              label="Versión"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú._\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.cocomponentVersionde}
              helperText={errors.componentVersion?.message}
            />
          </Grid>
          <Grid item lg={5} md={5} sm={10} xs={12}>
            <ControlledInputRoundedForm
                id="name"
                name="name"
                inputProps={{ maxLength: 200 }}
                label="Nombre servicio"
                fullWidth
                control={control}
                pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                disabled={!enabledTabsForm}
                error={errors.name}
                helperText={errors.name?.message}
              />
          </Grid>
          <Grid item lg={5} md={5} sm={10} xs={12}>
            <ControlledSelect
              id="idServiceCategory"
              name="idServiceCategory"
              label="Categoría"
              options={ServiceCategories}
              error={errors.idServiceCategory}
              helperText= {errors.idServiceCategory?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <ControlledInputRoundedForm
              id="description"
              name="description"
              label="Descripción"
              multiline
              rows={5}
              inputProps={{ maxLength: 500 }}
              helperText={charactersToEnd}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
            />
          </Grid>
        </>
      ),
    },
    {
      label: "Configuración",
      tabContent: (
        <>
          <Grid item lg={2} md={2} sm={10} xs={12}>
            <ControlledSelect
              id="idServiceType"
              name="idServiceType"
              label="Servicio"
              options={ServiceTypes}
              error={errors.idServiceType}
              helperText= {errors.idServiceType?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={10} xs={12}>
            <ControlledSelect
              id="idProtocolType"
              name="idProtocolType"
              label="Protocolo"
              options={ProtocolTypes}
              error={errors.idProtocolType}
              helperText= {errors.idProtocolType?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={10} xs={12}>
            <ControlledSelect
              id="idFormatType"
              name="idFormatType"
              label="Formato"
              options={FormatTypes}
              error={errors.idFormatType}
              helperText= {errors.idFormatType?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={10} xs={12}>
            <ControlledSelect
              id="idMethodType"
              name="idMethodType"
              label="Metodo"
              options={MethodTypes}
              error={errors.idMethodType}
              helperText= {errors.idMethodType?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={10} xs={12}>
            <ControlledSelect
              id="idSecurityType"
              name="idSecurityType"
              label="Tipo de seguridad"
              options={SecurityTypes}
              error={errors.idSecurityType}
              helperText= {errors.idSecurityType?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <BorderedSection 
            // icon={RuleIcon} 
            title="Metadata"
            item lg={5} md={10} sm={10} xs={12}
            >
            <ControlledInputRoundedForm
              id="metadataServiceURL"
              name="metadataServiceURL"
              inputProps={{ maxLength: 200 }}
              label="URL de metadata del servicio"
              fullWidth
              control={control}
              //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.metadataServiceURL}
              helperText={errors.metadataServiceURL?.message}
            />
            <ControlledInputRoundedForm
              id="filter"
              name="filter"
              inputProps={{ maxLength: 200 }}
              label="Filtro"
              fullWidth
              control={control}
              //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.filter}
              helperText={errors.filter?.message}
            />
            <ControlledInputRoundedForm
              id="pojo"
              name="pojo"
              label="Pojo (json)"
              multiline
              rows={5}
              inputProps={{ maxLength: 500 }}
              helperText={charactersToEnd}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
            />
            <ControlledInputRoundedForm
              id="responsePojo"
              name="responsePojo"
              label="Pojo (json)"
              multiline
              rows={5}
              inputProps={{ maxLength: 500 }}
              helperText={charactersToEnd}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
            />
            </BorderedSection>
          <BorderedSection 
            // icon={RuleIcon} 
            title="End-Point"
            item lg={5} md={10} sm={10} xs={12}
            >
              <ControlledInputRoundedForm
                id="serviceURL"
                name="serviceURL"
                inputProps={{ maxLength: 200 }}
                label="URL del servicio"
                fullWidth
                control={control}
                //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                disabled={!enabledTabsForm}
                error={errors.serviceURL}
                helperText={errors.serviceURL?.message}
              />
              <ControlledInputRoundedForm
                id="headers"
                name="headers"
                inputProps={{ maxLength: 200 }}
                label="Headers"
                fullWidth
                control={control}
                pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                disabled={!enabledTabsForm}
                error={errors.headers}
                helperText={errors.headers?.message}
              />
              <ControlledInputRoundedForm
                id="payload"
                name="payload"
                label="Payload"
                multiline
                rows={5}
                inputProps={{ maxLength: 500 }}
                helperText={charactersToEnd}
                fullWidth
                disabled={!enabledTabsForm}
                control={control}
              />
              <FormControlLabel
                className={classes.textField}
                label="Asíncrono"
                variant="outlined"
                margin="dense"
                labelPlacement="end"
                control={
                  <ControlledSwitchComponent
                    id="async"
                    name="async"
                    color="primary"
                    isDisabled={!enabledTabsForm}
                    control={control}
                  />
                }
              />
              <ControlledInputRoundedForm
                id="asyncCallbackURL"
                name="asyncCallbackURL"
                inputProps={{ maxLength: 200 }}
                label="URL de retorno"
                fullWidth
                control={control}
                //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                disabled={!enabledTabsForm}
                error={errors.asyncCallbackURL}
                helperText={errors.asyncCallbackURL?.message}
              />
            </BorderedSection>
            <BorderedSection 
              title="Adicional"
              item lg={10} md={10} sm={10} xs={12}
            >
              <Grid container>
                <Grid md={6} sm={6} xs={12} style={{paddingRight:"10px"}}>
                  <ControlledInputRoundedForm
                    id="cronKey"
                    name="cronKey"
                    inputProps={{ maxLength: 200 }}
                    label="CRON ID"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledTabsForm}
                    error={errors.cronKey}
                    helperText={errors.cronKey?.message}
                  />
                </Grid>
                <Grid md={6} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="cronPeriodicity"
                    name="cronPeriodicity"
                    inputProps={{ maxLength: 200 }}
                    label="Periodicidad CRON"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledTabsForm}
                    error={errors.cronPeriodicity}
                    helperText={errors.cronPeriodicity?.message}
                  />
                </Grid>
                <Grid md={6} sm={6} xs={12} style={{paddingRight:"10px"}}>
                  <ControlledInputRoundedForm
                    id="callOutName"
                    name="callOutName"
                    inputProps={{ maxLength: 200 }}
                    label="Nombre call out"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledTabsForm}
                    error={errors.callOutName}
                    helperText={errors.callOutName?.message}
                  />
                </Grid>
                <Grid md={6} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="schedulledRestart"
                    name="schedulledRestart"
                    inputProps={{ maxLength: 200 }}
                    label="Reinicio programado"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledTabsForm}
                    error={errors.schedulledRestart}
                    helperText={errors.schedulledRestart?.message}
                  />
                </Grid>
              </Grid>
            </BorderedSection>
        </>
      ),
    },
    {
      label: "Seguridad",
      tabContent: (
        <Grid container item lg={10} md={10} sm={10} xs={12}>
          <BorderedSection 
            // icon={RuleIcon} 
            title="Oauth 2.0"
            xs={12}
            >
            <ControlledInputRoundedForm
              id="oauthURL"
              name="oauthURL"
              inputProps={{ maxLength: 200 }}
              label="URL de Oauth 2.0"
              fullWidth
              control={control}
              //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.oauthURL}
              helperText={errors.oauthURL?.message}
            />
            <ControlledInputRoundedForm
              id="clientId"
              name="clientId"
              inputProps={{ maxLength: 200 }}
              label="ID del cliente"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.clientId}
              helperText={errors.clientId?.message}
            />
            <ControlledInputRoundedForm
              id="clienteSecret"
              name="clienteSecret"
              inputProps={{ maxLength: 200 }}
              label="Secreto del cliente"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.clienteSecret}
              helperText={errors.clienteSecret?.message}
            />
            <ControlledInputRoundedForm
              id="scope"
              name="scope"
              label="Scopes"
              multiline
              rows={5}
              inputProps={{ maxLength: 500 }}
              helperText={charactersToEnd}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
            />
          </BorderedSection>
          <BorderedSection 
            // icon={RuleIcon} 
            title="Basic / JWT"
            xs={12}
            >
            <ControlledInputRoundedForm
              id="user"
              name="user"
              inputProps={{ maxLength: 200 }}
              label="Usuario"
              fullWidth
              control={control}
              //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.name}
              helperText={errors.name?.message}
            />
            <ControlledInputRoundedForm
              id="pass"
              name="pass"
              inputProps={{ maxLength: 200 }}
              label="Password"
              fullWidth
              control={control}
              //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.pass}
              helperText={errors.pass?.message}
            />
            <ControlledInputRoundedForm
              id="jwtURL"
              name="jwtURL"
              inputProps={{ maxLength: 200 }}
              label="URL Jwt"
              fullWidth
              control={control}
              //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.jwtURL}
              helperText={errors.jwtURL?.message}
            />
          </BorderedSection>
        </Grid>
      ),
    }
  ];

  if(!validators.current.isNewRecord){
    items.push(
      {
        label: "Códigos de respuesta",
        tabContent: (
          <>
            <ResponseCodes
              Loading={setLoading}
              parentId={recordId}
              recordId={recordId}
              ConfirmInactivate={setConfirmInactivate}
              privileges={privileges}
            />
          </>
        ),
      }
    );
  }

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        handleIconClick={backButton}
        subheaderBase={formRecordData.name}
        subheader={defineSubHeader()}
        isEditing={tab<lastTab && !validators.current.isNewRecord && userPrivileges.modify}
        enabledForm={enabledTabsForm}
        setEnabledForm={handleChangeEdit}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        handleCancel={handleCancel}
        modalMessage={`¿Desea modificar este servicio?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"services tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={items}
        />
        {!recordId && tab < lastTab - 1 && (
        <Grid container item lg={12} md={12} sm={12} alignItems="center" style={{ paddingLeft: 20 }} direction="row-reverse">
          <Tooltip id="button-next" title={tab===0?"Configuración":tab===1?"Seguridad":""} style={{ float: "right", marginTop: "17px 2px 17px 17px" }}>
            <IconButton onClick={(e) => {
              const newTab = tab + 1;
              handleChangeTab(e, newTab);
            }}>
              <ArrowIcon className={classes.nextArrow} />
            </IconButton>
          </Tooltip>
          <Typography
              color="primary"
              className={classes.subtitle}
              variant="h6"
            >
              Siguiente
          </Typography> 
        </Grid>
        )}  { ((!recordId && !(tab < lastTab - 1)) || (recordId && enabledTabsForm && tab < lastTab)) && (
        <Grid container item direction="row" lg={12} justify="center">
          <Grid container item style={{ marginTop: "10px", marginBottom: "20px" }} lg={4}>
            <ButtonSaveChanges
              title={!recordId ? "Crear" :"Actualizar Datos"}
              isDisabled={!enabledTabsForm}
              handleClick={(e) => {
                if (validators.current.isNewRecord) {
                  handleSubmit(saveRecord(e));
                } else {
                  setConfirmInactivate({
                    cancelEdit: true
                  });
                  if (!compareObjects(0)) {
                    handleSubmit(saveRecord(e));
                  } else {
                    NotChange();
                  }
                }
              }}
            />
          </Grid>
        </Grid>
        )}
      </FormLayout>
    </div>
  );
};

//#region entities's declarations 
ServiceForm.propTypes = {
  createRecord: PropTypes.func.isRequired,
  modifyRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getRecordResponse: state.servicesReducer.getServiceResponse,
  recordChanged: state.servicesReducer.getServiceChangedResponse,
  recordErrorResponse: state.servicesReducer.setServiceErrorResponse,
  messagesResponse: messagesResponseServices,
  GET_RECORD_CHANGED: GET_SERVICE_CHANGED,
  GET_RECORD: GET_SERVICE,
  formatTypesResponse: state.objectTypesReducer.getFormatTypesResponse,
  protocolTypesResponse: state.objectTypesReducer.getProtocolTypesResponse,
  serviceTypesResponse: state.objectTypesReducer.getServiceTypesResponse,
  methodTypesResponse: state.objectTypesReducer.getMethodTypesResponse,
  securityTypesResponse: state.objectTypesReducer.getSecurityTypesResponse,
  serviceCategoriesResponse: state.objectTypesReducer.getServiceCategoriesResponse,
  integrationsResponse: state.integrationsReducer.getIntegrationsResponse,
  applicationsResponse: state.applicationsReducer.getApplicationsResponse,
  enterpriseChanged: state.enterpriseReducer.getEnterpriseByIDResponse
});

const mapDispatchToProps = {
  createRecord: createService,
  modifyRecord: modifyService,
  getRecordById: getServiceById,
  getFormatTypes,
  getProtocolTypes,
  getServiceTypes,
  getServiceCategories,
  getMethodTypes,
  getSecurityTypes,
  getIntegrations: getIntegrationsByCustomer,
  getApplications
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm);
//#endregion