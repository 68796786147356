import { useState } from "react";

const initialStateDrag = [];

export const useDrag = (initialState = initialStateDrag) => {
    const [values, setValues] = useState(initialState);

    const addInitialData = (data) => {
        setValues(data);
    }

    const addItem = (item) => {
        const temporalList = [...values, item];
        setValues(temporalList);
    }

    const dragStart = (e) => {
        const html = e.target;
        html.setAttribute("isdrag", true);
        e.dataTransfer.setData("idDragItem", e.target.id);
    };

    const drop = (e) => {
        e.preventDefault();
        const elementDrop = e.target;
        const posDrop = parseInt(elementDrop.getAttribute("pos"));

        const idElementStart = e.dataTransfer.getData("idDragItem");
        const elementStart = document.getElementById(idElementStart);
        const posStart = parseInt(elementStart.getAttribute("pos"));
        elementStart.removeAttribute("isdrag");

        let data = JSON.parse(JSON.stringify(values));
        const dataComfirm = JSON.parse(JSON.stringify(values));

        if (posStart < posDrop) {
            data[posDrop] = dataComfirm[posStart];
            for (let i = posStart; i < posDrop; i++) {
                data[i] = dataComfirm[i + 1];
            }
        } else {
            data[posDrop] = dataComfirm[posStart];
            for (let i = posDrop+1; i <= posStart; i++) {
                data[i] = dataComfirm[i - 1];
            }
            
        }

        setValues(data);
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    return {
        values,
        addInitialData,
        addItem,
        dragStart,
        dragOver,
        drop,
    };
};
