import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * PrimaryButton constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "Center",
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    display: "block",
    color: theme.palette.common.white,
    fontSize: "20px",
    textTransform: "none",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
const PrimaryButton = ({ ...props }) => {
  const { txtBtn, onClick, type, loading, name } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        name={name}
        type={type === "" || type === undefined ? "submit" : type}
        className={classes.button}
        onClick={onClick}
        disabled={loading}
      >
        {loading && <CircularProgress size={14} color="inherit" />}
        {!loading && txtBtn}
      </Button>
    </div>
  );
};

export default PrimaryButton;
