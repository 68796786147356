import {
  GET_ROLE,
  GET_ROLES,
  GET_PRIVILEGES,
  SET_ROLE_ERROR,
  GET_ROLE_CHANGED,
  GET_APPLICATIONS,
} from "../actions/types";

const initialState = {
  getRoleResponse: [],
  getRolesResponse: [],
  setRoleErrorResponse: [],
  getPrivilegesResponse: [],
  getRoleChangedResponse: [],
  getApplicationsResponse: [],
};

/**
 * rolesReducer (set role or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, role}
 */
export default function rolesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_ROLE:
      return {
        ...state,
        getRoleResponse: action.payload,
      };
    case GET_ROLES:
      return {
        ...state,
        getRolesResponse: action.payload,
      };
    case GET_ROLE_CHANGED:
      return {
        ...state,
        getRoleChangedResponse: action.payload,
      };
    case SET_ROLE_ERROR:
      return {
        ...state,
        setRoleErrorResponse: action.payload,
      };
    case GET_APPLICATIONS:
      return {
        ...state,
        getApplicationsResponse: action.payload,
      };
    case GET_PRIVILEGES:
      return {
        ...state,
        getPrivilegesResponse: action.payload,
      };
    default:
      return state;
  }
}
