import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import React, { forwardRef, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAppsForMenu } from '../../../actions/applications.action';
import { GetMenuByApp } from '../../../actions/menu.action';
import { useDrag } from '../../../hooks/useDrag';
import { GridDrag } from '../../../views/menuSettings/GridDrag';
import ControlledSelect from '../../InputForm/ControlledSelect';
import FullLoader from '../../Loader/FullLoader.component';

export const SuiteMenuSetting = forwardRef((props, ref) => {

  const classes = useStyles();
  const [loading, 
    // setLoading
  ] = React.useState(false);
  const { menuLocation, menu } = props;
  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      application: "",
      state: false,
    },
  });
  const { control, watch } = form;
  const [applications, setApplications] = React.useState([])
  const applicationsResponse = useSelector(({ applicationsReducer }) => {
    return applicationsReducer.getApplicationsMenuResponse;
  });
  const [currentApp, setCurrentApp] = React.useState({
    name: "",
    id: 0,
    items: [],
  });
  const {
    values: suiteApplications,
    dragStart,
    dragOver,
    drop,
    addInitialData,
    // addItem,
  } = useDrag();
  const dispatch = useDispatch();

  useEffect(() => {
    if (menu.currentMenu.length > 0) {
      addInitialData(menu.currentMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu.currentMenu]);

  useEffect(() => {
    if (!applicationsResponse.length) {
      dispatch(getAppsForMenu());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!applications.length && applicationsResponse.length) {
      setApplications(applicationsResponse);
    }
  }, [applications.length, applicationsResponse, setApplications]);

  useEffect(() => {
    const app = watch("application");
    if (app !== "" && app !== undefined) {
      dispatch(GetMenuByApp(app, menuLocation));
      setCurrentApp((oldState) => ({
        ...oldState,
        name: applications.find((item) => item.id === app).name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("application")]);

  const itemAction = () => { };

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <FullLoader open={loading} />
      ) : (
        <div>
          <Grid container>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <ControlledSelect
                control={control}
                id="application"
                name="application"
                options={applications}
                label='Aplicación'
              />
            </Grid>
          </Grid>
          <div>
            <Grid container>
              {currentApp.name !== "" ? (
                <Grid container spacing={2}>
                  <Grid container item lg={8} md={12} sm={12} xs={12}>
                    <Paper className={classes.paperTable} style={{ padding: "20px" }}>
                      <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={11}>
                          <Typography className={classes.titleSelectTypeMenu}>
                            {currentApp.name}
                          </Typography>
                        </Grid>
                        {/* <Grid item lg={1} md={1} sm={1} xs={1}>
                          <NewUserIcon
                            className={classes.plusIcon}
                            onClick={() => {
                              // setOpen(true);
                            }}
                          />
                        </Grid> */}
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                          <Typography className={classes.subtitleSelectTypeMenu}>
                          Recuerda que puedes ordenar el menú arrastrando y soltando cada aplicación a la posición que prefieras.
                          </Typography>
                        </Grid>
                      </Grid>
                      <div>
                        <GridDrag
                          values={suiteApplications}
                          dragStart={dragStart}
                          dragOver={dragOver}
                          drop={drop}
                          itemAction={itemAction}
                        />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
              {/* <Grid container style={{ marginBottom: 20 }}>
                <Grid container item justify="center" lg={8} md={12} sm={12} xs={12}>
                  <PrimaryButton
                    text="Guardar menú"
                    disabled={currentApp.name === "" ? true : false}
                    onClick={() => { }}
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </div>
          {/* <MenuSettingsContainer
            setInitialFormValues={setInitialFormValues}
            ref={menuSettingRef}
            saveMenu={saveItem}
            applications={applications}
            setApplications={setApplications}
            currentApp={currentApp}
            setCurrentApp={setCurrentApp}
            form={form}
            setOpen={setOpen}
            menu={{ currentMenu: appMenu, setCurrentMenu: setAppMenu }}
            children={appsSection()}
            valuesTitle={"Seccion aplicacion"}
          />
          <ModalForm
            initialValues={initialFormValues}
            ref={modalRef}
            open={open}
            setOpen={setOpen}
            handleSave={saveItem}
            handleClose={handleClose}
            appName={currentApp}
            privileges={privilegesList}
            section={[
              { id: 0, name: "Sección usuario", value: 0 },
              { id: 1, name: "Sección Aplicación", value: 1 },
            ]}
          />
          <Dialog />
          <ItemEditedModal
            open={editedModalOpen}
            setOpen={setEditedModalOpen}
          /> */}
        </div>
      )}
    </div>
  )
});

const useStyles = makeStyles((theme) => ({
  titleSelectTypeMenu: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    marginTop: "10px",
    marginBottom: "10px",
  },
  subtitleSelectTypeMenu: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.pxToRem(12),
    marginTop: "10px",
    marginBottom: "10px",
  },
  paperTable: {
    width: "100%",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.light}`,
    marginBottom: 20,
  },
  plusIcon: {
    height: "32px !important",
    width: "32px !important",
    stroke: theme.palette.secondary.main,
  },
  itemThree: {
    height: 40,
    minWidth: 350,
  },
}));
