import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ZoneUpload from '../../components/Sign/ZoneUpload';
import { Box, Grid } from '@material-ui/core';
import ButtonsSign from '../../components/Sign/ButtonsSign';
import { useDispatch, useSelector } from "react-redux";
import { getDocumentToSign, setUrlService } from "../../actions/sign.action";
import { useSnackbar } from 'notistack';

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BorderedSection from "../../components/CardBody/borderedSection.component";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
import SignersIcon from "@mui/icons-material/Groups2Outlined";
import SignerIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useForm, useFieldArray } from "react-hook-form";

const FirmarSection = (props) => {

	const { getDocumentToSignResponse, getDocumentToSignResponseError } = useSelector((store) => store.sign);
	const { getEnterpriseByIDResponse } = useSelector((store) => store.enterpriseReducer);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [filesInit, setFilesInit] = useState([]);
	const [isP7, setP7] = useState(0);
	const [sizeFiles, setSizeFiles] = useState(0);

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	});

	useEffect(() => {
		if(props.transactionId)
			setLoading(true);

		if(getEnterpriseByIDResponse.monitoringService){
			setUrlService(getEnterpriseByIDResponse.monitoringService);
			setLoading(false);
			if(props.transactionId){
				setLoading(true);
				dispatch(getDocumentToSign(props.transactionId, enqueueSnackbar));
			}
			
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [getEnterpriseByIDResponse]);

	useEffect(() => {
		if(getDocumentToSignResponse){
			if(getDocumentToSignResponse.size){
				let validFiles = [];
				validFiles.push(new File([getDocumentToSignResponse]
					,`Contrato - ${props.transactionId}.pdf`
					,{type:'application/pdf'}
				));
				setFilesInit([...filesInit, ...validFiles]);
			} else {
				enqueueSnackbar(`El documento "${props.transactionId}" se encuentra vacío y no es posible utilizarlo para el flujo de firmas`, {
					variant: 'warning',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
			setLoading(false);
		}
		if(getDocumentToSignResponseError){
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getDocumentToSignResponse, getDocumentToSignResponseError]);

	useEffect(() => {
		validateP7();
		validateSize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	const validateP7 = () => {
		let p7 = 0;
		files.map(file => {
			const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
			if (fileExtension != 'pdf') {
				p7++;
			}
		});
		setP7(p7);
	}

	const validateSize = () => {
		let dropedSize = 0;
		for (let i = 0; i < files.length; i++) {
			let sizekiloByte = Math.round(files[i].sizeBytes / 1024);
			dropedSize += sizekiloByte;
		}
		console.log('Peso documentos: ' + dropedSize, files);
		setSizeFiles(dropedSize);
	}

	const init = () =>{
		return {
			transactionId: props.transactionId,
			signers: [
				{ id: `F1`, name: "", email: "", title: `Firmante 1`, order: "1" }
			]
		};
	}
	const validationSchema = Yup.object().shape(
		{
			signers: Yup.array().of(
				Yup.object().shape(
					{
						name: Yup.string().required("Campo obligatorio."),
						email: Yup.string().email("El correo no posee el formato correcto.").required("Campo obligatorio.").max(100)
				  	}
			  )
			)
	  	}
	);
	const formOptions = { resolver: yupResolver(validationSchema), defaultValues: { ...init() }, mode: "all"};
	const {
		handleSubmit,
		formState,
		control,
		trigger
	  } = useForm(formOptions);
	const { errors } = formState;
	const { fields, append, remove } = useFieldArray({ name: 'signers', control });

	return (
		<>
			<Paper elevation={0} className="paper-upload" >
				<ZoneUpload
					key={1}
					setFiles={setFiles}
					files={files}
					isP7={isP7}
					isDashboard={props.isDashboard}
					sizeFiles={sizeFiles}
					className={'contentZoneUpload'}
					isResponsive={props.isResponsive}
					filesInit={filesInit}
					setFilesInit={setFilesInit}
					loadingParent={isLoading}
				/>
				<BorderedSection 
					id="signers"
					icon={SignersIcon} 
					title="Circuito de firmas" 
					add={true}
					handleAddClick={()=>{
						const index = fields.length;
						append({
							id: `F${index + 1}`,
							name: "",
							email: "",
							title: `Firmante ${index + 1}`,
							order: `${index + 1}`
						});
					}}
					borderStyle="borderStyle1"
					xs={12}
					>
					<Grid container>
						{fields ? fields.map((signer, index)=>(
							<BorderedSection 
								icon={SignerIcon}
								title={signer.title}
								remove={fields.length - index === 1}
								handleRemoveClick={()=>{
									remove(index);
								}}
								borderStyle="borderStyle2"
								item md={4} sm={6} xs={12}
								>
								<ControlledInputRoundedForm
									id={`signers.${index}.name`}
									name={`signers.${index}.name`} 
									inputProps={{ maxLength: 100 }}
									label="Nombre"
									fullWidth
									control={control}
									pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
									error={errors.signers?.[index]?.name}
									helperText={errors.signers?.[index]?.name?.message}
								/>
								<ControlledInputRoundedForm
									id={`signers.${index}.email`}
									name={`signers.${index}.email`}
									label="Correo"
									control={control}
									fullWidth
									pattern={/[^a-zA-Z0-9._\-@+]/g}
									error={errors.signers?.[index]?.email}
									shrink={true}
									helperText={errors.signers?.[index]?.email?.message}
								/>
								<ControlledSelect
									id={`signers.${index}.signatureType`}
									name={`signers.${index}.signatureType`}
									label="Tipo de firma"
									control={control}
									error={errors.signers?.[index]?.signatureType}
									helperText= {errors.signers?.[index]?.signatureType}
									isSearchable={true}
									options={[
										{id:1, name:'Manuscrita'},
										{id:2, name:'Imagen firma'},
										{id:3, name:'Iniciales'},
										{id:4, name:'Nombres'}
									  ]}
								/>
							</BorderedSection>
						)) : null}
					</Grid>
				</BorderedSection>
				<Box id="box-botonera" style={{marginTop: props.isDashboard ? '12px ' : '12px' }}>
					<ButtonsSign
						files={files}
						setFiles={setFiles}
						isP7={isP7}
						sizeFiles={sizeFiles}
						isResponsive={props.isResponsive}
						signers={fields}
						control={control}
						handleSubmit={handleSubmit}
						formState={formState}
						trigger={trigger}
						transactionId={props.transactionId}
					/>
				</Box>
			</Paper>
		</>
	);
};

export default withRouter(FirmarSection);
