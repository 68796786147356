import {
  GET_INTEGRATIONTYPE,
  GET_INTEGRATIONTYPES,
  SET_INTEGRATIONTYPE_ERROR,
  GET_INTEGRATIONTYPE_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * IntegrationTypes Action IntegrationTypes
 *
 * @method GET
 */
  const getIntegrationTypes = () => async (dispatch) => {
    dispatch(getRecords("integrationtype", GET_INTEGRATIONTYPES, SET_INTEGRATIONTYPE_ERROR));
  };

export /**
 * IntegrationTypes Action getIntegrationTypes
 *
 * @method GET
 * @param customerid
 */
  const getIntegrationTypesByCustomer = (customerid) => async (dispatch) => {
    dispatch(getRecordsByCustomer("integrationtype", customerid, GET_INTEGRATIONTYPES, SET_INTEGRATIONTYPE_ERROR));
  };

export /**
 * IntegrationType Action getIntegrationTypeById
 *
 * @method GET
 * @param {*} id
 */
  const getIntegrationTypeById = (id) => async (dispatch) => {
    dispatch(getRecordById("integrationtype", id, GET_INTEGRATIONTYPE, SET_INTEGRATIONTYPE_ERROR));
  };

export /**
  * IntegrationType Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createIntegrationType = (recordToCreate, updateChecked) => async (dispatch) => {
    //recordToCreate.idSource = parseInt(recordToCreate.idSource);
    dispatch(createRecord("integrationtype", recordToCreate, updateChecked, GET_INTEGRATIONTYPE_CHANGED, SET_INTEGRATIONTYPE_ERROR));
 };
 

export /**
 * IntegrationType Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyIntegrationType = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    //recordToUpdate.idSource = parseInt(recordToUpdate.idSource);
    dispatch(modifyRecord("integrationtype", recordToUpdate, updateChecked, isPrincipalPage, GET_INTEGRATIONTYPE_CHANGED, SET_INTEGRATIONTYPE_ERROR));  
};
