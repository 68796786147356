import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from "@material-ui/core/IconButton";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  menuContent:{
    width:220,
  },
  menuSearch:{
  },
  divider: {
    backgroundColor: "#C1C1C1",
    marginLeft: "13px",
    marginRight: "12px",
    height: "1px",
  },
  textItem:{
    fontSize:15,
  },
  listContent:{
    height:200,
  }
}));

const StyledMenu = withStyles({
    paper: {
      border: '0px solid red',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));


  const listHelp = [
    "firmar archivos",
    "Uso de estampas",
    "Firmas multiples de documentos"
  ]

const HelpBar = () => {
  const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [listSearch] = React.useState(listHelp)

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const search = (e) =>{
      console.log(e.target.value)
    }
  
    return (
      <div>
        <IconButton
          color="default"
          onClick={handleClick}
          edge="end"
        >
        <HelpIcon></HelpIcon>
        </IconButton>
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
        <div className={classes.menuContent}>
          <div className={classes.menuSearch}>
            <TextField 
              id="input-with-icon-textfield"
              onChange={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            
            ></TextField>
          </div>
          <div>
          <Paper style={{ maxHeight: 400, overflow: 'auto' }} elevation={0}>
            <List component="nav" aria-label="secondary mailbox folders" className={classes.listContent} >
              {
                listSearch.map(
                  (item,key) => (
                      <ListItem key={key}>
                        <ListItemIcon>
                          <LibraryBooksIcon />
                        </ListItemIcon>
                        <ListItemText><Typography className={classes.textItem}>{item}</Typography></ListItemText>
                      </ListItem>
                  ))
              }
            </List>
          </Paper>
          </div>
        </div>
        </StyledMenu>
      </div>
    );
}
 
export default HelpBar;