import React, { Component } from "react";
import { connect } from "react-redux";
import CardBody from "../../components/CardBody/cardBody.component";

/**
 * Home Component ( full view for loggedIn users homepage )
 *
 * @export Class Component
 * @class Home
 * @extends {Component}
 * @returns Redux connect
 */
export class Home extends Component {
  
  render() {
    return (
      <CardBody>
        <div className={this.props.className}></div>
      </CardBody>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
