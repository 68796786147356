import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPrivilegeByApp } from "../../actions/roles.action";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel } from "@material-ui/core";
import SwitchForm from "../../components/Switch/switchForm.component";
import CustomTable from "../../components/Table/CustomTable.component";
import { useState } from "react";
// import { useDispatch } from "react-redux";

/**
 * PrivilegesApp Component ( view privileges from application )
 *
 * @export Class Component
 * @class PrivilegesApp
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  card: {
    margin: "0px 0px 0px 15px",
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: "10px",
  },
  paper: {
    width: "100%",
    padding: "23px 20px",
  },
  searchTextField: {
    width: "368px",
    maxHeight: "25px",
    paddingLeft: "23px",
  },
  tableCell: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  logo: {
    width: "87%",
    height: "37px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    marginTop: "10px",
    marginLeft: "22px",
    marginRight: "1px",
    marginBottom: "10px",
  },
}));

const PrivilegesApp = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const {
    appId,
    roleId,
    privilegeData,
    setPrivilegeData,
    getPrivilegeByApp,
    getPrivilegesResponse,
    allRoles,
    checkRoles,
    handleAllRolesChecked,
    handleChangeChecked,
    setLoading,
    globalDisabled,
    originalInfo,
    setPrivilegeCounter,
    privilegeCounter,
  } = props;

  const [roleOption, setRoleOption] = useState(null);
  // const dispatch = useDispatch();
  const validateInitial = useRef(false);
  const columns = [
    {
      id: "name",
      label: "Privilegio",
      align: "left",
    },
    {
      id: "assigned",
      label: "Asignado",
      minWidth: 20,
      haveDropDown: true,
      format: (value) => value,
      children: (value, row) => {
        return (
          <Grid
            container
            direction="row"
            alignItems={"center"}
            justify={"center"}
          >
            <div>
              <SwitchForm
                checked={value}
                name={"assigned"}
                disabled={globalDisabled}
                onChange={(e) => {
                  handleChangeChecked(e, "assigned", row.name);
                }}
              />
            </div>
          </Grid>
        );
      },
    },
  ];
  const [currentApp, setCurrentApp] = useState([]);

  const finishGetRolesList = (data) => {
    if (!!appId) {
      setPrivilegeData((prevState) => ({
        ...prevState,
        [appId]: JSON.parse(JSON.stringify(data)),
      }));
      originalInfo.current.originalPrivileges = {
        ...originalInfo.current.originalPrivileges,
        [appId]: JSON.parse(JSON.stringify(data)),
      };
      const rolCount = data.filter((item) => item.assigned).length;
      setPrivilegeCounter(
        privilegeCounter.map((item) =>
          item.name === appId ? { counter: rolCount, name: item.name, title: item.title } : item
        )
      );
    }
  };

  useEffect(() => {
    if (validateInitial.current) {
      finishGetRolesList(getPrivilegesResponse);
    }
    validateInitial.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPrivilegesResponse]);

  useEffect(() => {
    checkRoles(appId);
    if (privilegeData[appId] !== undefined) {
      const orderList = privilegeData[appId].sort((a, b) => {
        return a.assigned ? -1 : 1;
      });
      originalInfo.current.originalPrivileges[appId].sort((a, b) => {
        return a.assigned ? -1 : 1;
      });
      setCurrentApp(orderList);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, privilegeData]);

  useEffect(
    () => {
      if (appId !== "") {
        //si no existe el state appId se llama hacia la BD
        if (!!!privilegeData[appId]) {
          getPrivilegeByApp(appId, roleId);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appId, roleId, getPrivilegeByApp]
  );

  const buttons = {
    justify: "space-between",
    searchBar: {
      cellSize: {
        lg: 7,
        md: 7,
        sm: 7,
      },
    },
    children: () => {
      const config = {
        cellSize: {
          lg: 4,
          md: 5,
          sm: 2,
        },
      };

      const component = () => (
        <>
          <Grid container item lg={8} md={8} sm={6} justify="flex-start">
            <Box flexWrap="wrap">
              <Typography variant="subtitle2" align="right">
                Asignar todos los privilegios
              </Typography>
            </Box>
          </Grid>
          <Grid container item lg={4} md={4} sm={12} justify="flex-end">
            <Tooltip title="Seleccionar todas los privilegios">
              <FormControlLabel
                control={
                  <SwitchForm
                    name="allRoles"
                    onChange={handleAllRolesChecked}
                    checked={allRoles}
                    disabled={globalDisabled}
                  />
                }
              />
            </Tooltip>
          </Grid>
        </>
      );
      return { config, component };
    },
  };

  return (
    <Card className={`${classes.card} 'RolePriv-Card'`}>
      <Grid container justify="flex-start" alignItems="center" spacing={1}>
        <Grid item lg={3} md={3} sm={12}>
          <div
            className={classes.logo}
            style={{ backgroundImage: `url('${appId}Logo.png')` }}
            alt="Logo"
          ></div>
        </Grid>
      </Grid>
      <CustomTable
        columns={columns}
        data={currentApp}
        buttons={buttons}
        mainParam={"name"}
        handleChange={handleChangeChecked}
        option={roleOption}
        setOption={setRoleOption}
        //globalDisabled={globalDisabled}
      />
    </Card>
  );
};

PrivilegesApp.propTypes = {
  getPrivilegeByApp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getPrivilegesResponse: state.rolesReducer.getPrivilegesResponse,
});

const mapDispatchToProps = {
  getPrivilegeByApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivilegesApp);
