import { Fab, Grid, makeStyles, Modal, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CustomTable from '../../components/Table/CustomTable.component'
import NewUserIcon from '../../components/IconsLibrary/NewUserIcon';
import { useDispatch, useSelector } from 'react-redux';
import { CrudClient, GetClients } from '../../actions/client.action';
import { ClientFormView } from './ClientForm.view';
import { FullLoader, useDialog } from '@pif-tr-components/components';
import { CHANGE_CLIENT_MESSAGE, ERROR_GET_ALL_CLIENTS } from '../../actions/types';

const useStyles = makeStyles((theme) => ({
  styleFab: {
    boxShadow: 'none'
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "relative",
    width: "70%",
    minHeight: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperSelectIcon: {
    minHeight: "80%",
  },
}))

export const ClientsView = (props) => {

  /* #region  Props */
  const {
    isRoute,
    userId,
    sequence,
    disabled
  } = props;
  /* #endregion */

  /* #region  States */
  const [dataAlertConfirm, setDataAlertConfirm] = useState({
    message: "",
  });
  const [messageDialogData, setMessageDialogData] = useState("")
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState(null);
  const [dataComplete, setDataComplete] = useState([])
  const [isFormShowed, setFormShowed] = useState(false);
  const [clientData, setClientData] = useState({ id: 0 })
  const [openModal, setOpenModal] = useState(false);
  /* #endregion */

  /* #region  Hooks */
  const classes = useStyles();

  const { Dialog, onOpen, onClose } = useDialog({
    bodyText: dataAlertConfirm.message,
    cancelButtonText: 'No',
    confirmationButtonText: 'Si',
    onConfirmClick: () => handleAcceptAlert(),
    onCancelClick: () => {
      setDataAlertConfirm({ message: "", })
      onClose()
      setLoading(false);
    }
  })

  const { Dialog: MessageDialog, onOpen: messageDialogOpen } = useDialog({
    bodyText: messageDialogData,
  })

  const { getAllClients, getChangeClient, errorGetAllClient } = useSelector(store => store.clientReducer);

  const dispatch = useDispatch();
  /* #endregion */

  const handleAcceptAlert = () => {

    if (dataAlertConfirm.action === "INACTIVATE_CLIENT") {
      dispatch(
        CrudClient(dataAlertConfirm.data, "inactivate")
      )
    }

    if (dataAlertConfirm.action === "DELETE_CLIENT") {
      dispatch(
        CrudClient(dataAlertConfirm.data, "delete")
      )
    }
    setDataAlertConfirm({ message: "", })
    onClose();
  }

  useEffect(() => {
    dispatch(
      GetClients(userId !== undefined ? { userId } : { sequence })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Array.isArray(getAllClients)) {
      setLoading(false);
      setDataComplete(getAllClients)
    }
  }, [getAllClients])

  useEffect(() => {
    if (dataAlertConfirm.message !== "") {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAlertConfirm])

  useEffect(() => {
    if (errorGetAllClient !== "") {
      setLoading(false)
      setMessageDialogData(errorGetAllClient);
      messageDialogOpen()
      dispatch(
        {
          type: ERROR_GET_ALL_CLIENTS,
          payload: "",
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetAllClient])

  useEffect(() => {
    if (getChangeClient !== "") {
      setMessageDialogData(getChangeClient);
      messageDialogOpen()
      dispatch({
        type: CHANGE_CLIENT_MESSAGE,
        payload: "",
      })
      dispatch(
        GetClients(userId !== undefined ? { userId } : { sequence })
      )
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChangeClient])


  const columns = [
    {
      id: 'clientName', label: 'Nombre Cliente',
      haveDropDown: true
    },
    {
      id: 'clientId', label: 'Id Cliente',
      haveDropDown: true
    },
    {
      id: "createdon",
      label: "Fecha de creación",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
      format: (value) => {
        let splited = value.split("T")[0];
        let newDate = splited.split("-");
        return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      },
    },
    {
      id: "idstate",
      label: "Estado",
      minWidth: 40,
      align: "center",
      haveDropDown: true,
      disabled: disabled,
      component: {
        handleChange: (e, value, id) => handleConfirmClick(e, value, id)
      },
      handleIconClick: (evt, id) => handleDeleteClick(evt, id),
      withIcon: disabled ? false : true,
      tooltipMessage: "Cliente",
      format: (value) => (value === 1 ? false : true),
    }
  ]

  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          sm: 7,
          xs: 12,
        }
      },
      menuOrder: {
        options: [
          {
            label: 'Activo',
            columns: [{
              prop: 'idState',
              direction: -1
            }]
          },
          {
            label: 'Inactivo', columns: [{
              prop: 'idState',
            },
            ]
          },
          {
            label: 'Más reciente',
            columns: [{ prop: 'modifiedOn', direction: 1, format: (date) => { return new Date(date).getTime() } }]
          },
          { label: 'Más antiguo', columns: [{ prop: 'modifiedOn', direction: -1, format: (date) => { return new Date(date).getTime() } }] }
        ],
        cellSize: {
          sm: 2,
          xs: 2,
        }
      },
      // exportButton: {
      //     cellSize: {
      //         lg: 6,
      //         md: 2,
      //         sm: 2
      //     }
      // },
      children: () => {
        return !disabled && (
          <Grid container item lg={12} md={12} sm={2} xs={2} direction="row-reverse" >
            <Tooltip title={`Crear cliente`} aria-label={`Nuevo cliente`}>
              <Fab
                className={classes.styleFab}
                size="small"
                disabled={disabled}
                onClick={() => {
                  setClientData({ id: 0 });
                  if (isFormShowed === true) {
                    setFormShowed(false);
                  }
                  if (isRoute) {
                    setFormShowed(true);
                  } else {
                    setOpenModal(true)
                  }
                }}
              >
                <NewUserIcon />
              </Fab>
            </Tooltip>
          </Grid>
        )
      }
    }
    return button;
  }

  const handleCellClick = (evt, row) => {
    setClientData(row)
    if (isRoute) {
      setFormShowed(true);
    } else {
      setOpenModal(true)
    }
  }

  const handleConfirmClick = (evt, value, id) => {
    evt.preventDefault();
    setLoading(true);
    // deseas activar esta aplicacion
    setDataAlertConfirm({
      message: `¿Deseas ${evt.target.checked ? 'activar' : 'desactivar'} este cliente?`,
      action: "INACTIVATE_CLIENT",
      data: {
        id: value
      }
    });
  }

  const handleDeleteClick = (evt, id) => {
    evt.preventDefault();
    setLoading(true);
    // deseas activar esta aplicacion
    setDataAlertConfirm({
      message: `¿Deseas eliminar este cliente?`,
      action: "DELETE_CLIENT",
      data: {
        id: id
      }
    });
  }

  const handleCloseModal = (save, nameIcon) => {
    setOpenModal(false);
  };

  return (
    <>
      {
        isFormShowed ? (
          <ClientFormView
            userId={userId}
            sequence={sequence}
            clientData={clientData}
            setClientData={setClientData}
            setFormShowed={setFormShowed}
            isRoute={isRoute}
            onCloseModal={handleCloseModal}
          />

        ) : (
          <CustomTable
            columns={columns}
            mainParam={'name'}
            data={dataComplete}
            buttons={getButtons()}
            handleCellClick={handleCellClick}
            option={option}
            setOption={setOption}
            paramToDisable={'idstate'}
            havePagination={dataComplete.length > 10 ? true : false}
            //globalDisabled={disabled} 
          />
        )
      }
      <FullLoader open={loading} viewLoader={true} />
      <Dialog />
      <MessageDialog />
      <Modal
        className={classes.modal}
        open={openModal}
        disableBackdropClick={true}
        onClose={handleCloseModal}
      >
        <div className={classes.paperModal}>
          
            <ClientFormView
              userId={userId}
              sequence={sequence}
              clientData={clientData}
              setClientData={setClientData}
              setFormShowed={setFormShowed}
              isRoute={isRoute}
              onCloseModal={handleCloseModal}
            />
        </div>
      </Modal>
    </>
  )
}
