import { Grid, IconButton, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  styleImages: {
    border: "1px solid #C1C1C1",
    borderRadius: "5px",
    padding: "5px",
    margin: "5px",
    width: "80px",
    height: "80px",
    objectFit: "contain",
    display: "block"
  },
  styleContainer: {
    border: "1px solid #EFEFEF",
    borderRadius: "10px",
    background: "#00000005 0% 0% no-repeat padding-box",
    padding: "10px",
    overflowY: "scroll",
    width: "387px",
  },
  styleContainerImage: {
    position: "relative",
    display: "inline-block",
    margin: "15px"
  },
  styleIconButton: {
    fontSize: "15px",
    color: "#009BDD",
  },
  containerButtonActions: {
    position: "absolute",
    right: "0",
    top: "0",
    marginRight: "5px",
    marginTop: "5px",
    display: "none"
  },
  iconButtonStyle: {
    width: "20px",
    height: "20px"
  }
});

export const GridDrag = ({
  numberRows = 3,
  values,
  dragStart,
  dragOver,
  drop,
  itemAction,
}) => {
  const classes = useStyles();
  const maxWidth = 130 * numberRows;

  return values.length > 0 ? (
    <Grid
      id="gridContainerSuite"
      style={{
        height: `${maxWidth}px`,
      }}
      className={classes.styleContainer}
    >
      {values.map((app, index) => {
        return (
          <span className={classes.styleContainerImage}
            onMouseOver={() => {
              const p = document.getElementById(`buttons-${app.name}-${index}`);
              const img = document.getElementById(`images-${app.id}`);
              if (!!p && !!img) {
                p.style.display = "block";
                img.style.border = "1px solid #009BDD";
                if (app.isActivate) {
                  img.style.opacity = 0.5;
                }
              }
            }}
            onMouseLeave={() => {
              const p = document.getElementById(`buttons-${app.name}-${index}`);
              const img = document.getElementById(`images-${app.id}`);
              if (!!p && !!img) {
                p.style.display = "none";
                img.style.border = "1px solid #C1C1C1";
                if (app.isActivate) {
                  img.style.opacity = 1
                }
              }
            }}>
            <div style={{ width: "90px", position: "relative" }}>
              <img
                className={[classes.styleImages]}
                id={`images-${app.id}`}
                alt={app.name}
                pos={`${index}`}
                src={app.iconName}
                style={{ opacity: `${app.isActivate ? '1.0' : '0.5'}` }}
                title={app.name}
                onDragStart={dragStart}
                onDragOver={dragOver}
                onDrop={drop}
              />
            </div>
            <div id={`buttons-${app.name}-${index}`} className={classes.containerButtonActions}>
              <IconButton
                onClick={() => itemAction(app.id, "Edit")}
                className={classes.iconButtonStyle}>
                <span className={`material-icons ${classes.styleIconButton}`}>
                  edit
                </span>
              </IconButton>
              <IconButton
                onClick={() => itemAction(app.id, "Delete")}
                className={classes.iconButtonStyle}>
                <span className={`material-icons ${classes.styleIconButton}`}>
                  delete
                </span>
              </IconButton>
            </div>
          </span>
        );
      })}
    </Grid>
  ) : (
    <span>No hay datos para mostrar</span>
  );
};
