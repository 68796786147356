import * as React from 'react';
import * as _ from 'lodash';
import { TrayWidget } from './TrayWidget';
import { IntegrationDesign } from '../IntegrationDesign';
import { DiamondNodeModel } from './diamondNode/DiamondNodeModel';
import { TrayItemWidget } from './TrayItemWidget';
import { NodeModel, LinkModel } from '@projectstorm/react-diagrams';
import { IntegrationNodeModel } from './integrationNode/IntegrationNodeModel';
import { BaseModel, CanvasWidget } from '@projectstorm/react-canvas-core';
import { DemoCanvasWidget } from '../helpers/DemoCanvasWidget';
import styled from '@emotion/styled';

export interface BodyWidgetProps {
	app: IntegrationDesign;
	handleNodeClick: (event:any,id:number) => void;
	userPrivileges: any;
}

namespace S {
	export const Body = styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		min-height: 500px;
	`;

	export const Header = styled.div`
		display: flex;
		background: #ffe6e6;
		flex-grow: 0;
		flex-shrink: 0;
		font-family: Helvetica, Arial, sans-serif;
		color:#ff415a;
		padding: 10px;
		align-items: center;
	`;

	export const Content = styled.div`
		display: flex;
		flex-grow: 1;
	`;

	export const Layer = styled.div`
		position: relative;
		flex-grow: 1;
		height: 2500px;
	`;

	export const Sector = styled.div`
		margin-top: 20px;
		border-top-width: 2px !important;
		border-top-color: #ffe6e6 !important;
		border-top: solid;
		padding: 5px;
		color: grey;
	`;

	export const Toolbar = styled.div`
		padding: 5px;
		display: flex;
		flex-shrink: 0;
		direction: rtl;
		border-top-width: 2px !important;
		border-top-color: #ffe6e6 !important;
		border-top: solid;
		border-right-color: #ffe6e6 !important;
		border-right-width: 2px !important;
		border-right: solid;
	`;
	export const DemoButton = styled.button`
		background: #ffe6e6;
		height: 25px;
		font-size: 14px;
		padding: 5px 10px;
		border: none;
		color: #ff415a;
		outline: none;
		cursor: pointer;
		margin: 2px;
		border-radius: 3px;

		&:hover {
			background: #ff415a;
			color: white;
		}
	`;
}

export class BodyWidget extends React.Component<BodyWidgetProps> {

	dataTypeBefore = "";

	constructor(props: any) {
		super(props);
		this.cloneSelected = this.cloneSelected.bind(this);
	}

	addInPorts = () => {
		this.addPorts("in");
	}
	addOutPorts = () =>{
		this.addPorts("out");
	}
	addPorts = (action:string) => {
		_.forEach(this.props.app.getActiveDiagram().getSelectedEntities(), (node: BaseModel<any>) => {
				let nodeUpd = (node as IntegrationNodeModel);
				if (action === 'in') {
					nodeUpd.addInPort(`in-${nodeUpd.getInPorts().length + 1}`, false);
				} else if(action === 'out') {
					nodeUpd.addOutPort(`out-${nodeUpd.getOutPorts().length + 1}`, false);
				}
		});
		this.props.app.getDiagramEngine().repaintCanvas();
	};

	cloneSelected = ()=>{
		let offset = { x: 100, y: 100 };
		let model = this.props.app.getActiveDiagram();
		let itemMap = {};
		_.forEach(model.getSelectedEntities(), (item: BaseModel<any>) => {
			let newItem = item.clone(itemMap);

			// offset the nodes slightly
			if (newItem instanceof NodeModel) {
				newItem.setPosition(newItem.getX() + offset.x, newItem.getY() + offset.y);
				model.addNode(newItem);
			} else if (newItem instanceof LinkModel) {
				// offset the link points
				newItem.getPoints().forEach((p) => {
					p.setPosition(p.getX() + offset.x, p.getY() + offset.y);
				});
				model.addLink(newItem);
			}
			(newItem as BaseModel).setSelected(false);
		});

		this.forceUpdate();
	}

	setRecord = (integrationType:number, point:any)=>{
		var data = this.props.app.getRecordNew();
		data.idSourceIntegrationType = integrationType;
		data.idDestinationIntegrationType = integrationType;
		this.props.app.getRecords().push(data);
		this.props.app.setRecordInModel(data,point);
		this.forceUpdate();
	}

	isSameDataType = (current:string)=>{
		if(this.dataTypeBefore !== current){
			this.dataTypeBefore = current;
			return false;
		} else 
			return true;
	}

	saveModel = ()=>{
		this.props.app.saveModel();
	}

	s2p = ()=>{
		
	}

	p2p = ()=>{
		
	}

	p2o = ()=>{
		
	}

	ok2pay = ()=>{
		
	}
	
	render() {
		return (
			<S.Body>
				<S.Header>
					<div className="title">Diagrama de integraciones</div>
				</S.Header>
				<S.Content>
					{this.props.userPrivileges.modify && 
					(<TrayWidget>
						{/* <TrayItemWidget model={{ type: 'source' }} name="Origen" color="rgb(0,192,255)" />
						<TrayItemWidget model={{ type: 'middle' }} name="Transformación" color="#ff415a" />
						<TrayItemWidget model={{ type: 'destination' }} name="Destino" color="rgb(192,255,0)" />
						<TrayItemWidget model={{ type: 'gate' }} name="Gate" color="#9896f1" /> */}
						<TrayItemWidget model={{ type: '' }} name="Integración" color="rgb(192,255,0)" />
						{this.props.app.getEntities().sort((a:any,b:any)=>{return a.idDataType===b.idDataType?0:a.idDataType<b.idDataType?-1:1;}).map((entity:any) => (
							<>
								{this.isSameDataType(entity.dataType?.name) ? null : (<S.Sector>{entity.dataType?.name}</S.Sector>)}
								<TrayItemWidget model={{ type: entity.id }} name={entity.name} color="#9896f1" />
							</>
						))}

					</TrayWidget>)}
					<S.Layer
						onDrop={(event) => {
							var data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'));
							//var nodesCount = _.keys(this.props.app.getDiagramEngine().getModel().getNodes()).length;

							var node: IntegrationNodeModel = null;
							var nodeDiamond: DiamondNodeModel = null;
							if (data.type === 'destination') {
								node = new IntegrationNodeModel('Destination', 'rgb(192,255,0)');
								node.addInPort('In');
							} else if (data.type === 'middle') {
								node = new IntegrationNodeModel('Transformation', '#ff415a');
								node.addInPort('In');
								node.addOutPort('Out');
							} else if (data.type === 'source') {
								node = new IntegrationNodeModel('Source', 'rgb(0,192,255)');
								node.addOutPort('Out');
							}else if (data.type === 'gate') {
								nodeDiamond = new DiamondNodeModel();
							} else {
								this.setRecord(data.type,this.props.app.getDiagramEngine().getRelativeMousePoint(event));
								return;
							}
							
							var point = this.props.app.getDiagramEngine().getRelativeMousePoint(event);
							if(node)
								node.setPosition(point);
							else
								nodeDiamond.setPosition(point);
							this.props.app.getDiagramEngine().getModel().addNode(node?node:nodeDiamond);
							this.forceUpdate();
						}}
						onDragOver={(event) => {
							event.preventDefault();
						}}
					>
						{this.props.userPrivileges.modify && 
						(<S.Toolbar>
							{/* <S.DemoButton onClick={this.cloneSelected}>Clone Selected</S.DemoButton> */}
							{/* <S.DemoButton onClick={this.addInPorts}>Add in</S.DemoButton>
							<S.DemoButton onClick={this.addOutPorts}>Add out</S.DemoButton> */}
							<S.DemoButton onClick={this.saveModel}>Guardar</S.DemoButton>
							<S.DemoButton onClick={this.ok2pay}>Ok2Pay</S.DemoButton>
							<S.DemoButton onClick={this.p2o}>P2O</S.DemoButton>
							<S.DemoButton onClick={this.p2p}>P2P</S.DemoButton>
							<S.DemoButton onClick={this.s2p}>S2P</S.DemoButton>
						</S.Toolbar>)}
						<DemoCanvasWidget>
							<CanvasWidget engine={this.props.app.getDiagramEngine()} />
						</DemoCanvasWidget>						
					</S.Layer>
				</S.Content>
			</S.Body>
		);
	}
}
