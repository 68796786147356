import {
  GET_HOMOLOGATION,
  GET_HOMOLOGATIONS,
  SET_HOMOLOGATION_ERROR,
  GET_HOMOLOGATION_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * Homologations Action Homologations
 *
 * @method GET
 */
  const getHomologations = () => async (dispatch) => {
    dispatch(getRecords("homologation", GET_HOMOLOGATIONS, SET_HOMOLOGATION_ERROR));
  };

export /**
 * Homologations Action getHomologations
 *
 * @method GET
 * @param customerid
 */
  const getHomologationsByCustomer = (customerid, parentId) => async (dispatch) => {
    dispatch(getRecordsByCustomer("homologation", customerid, GET_HOMOLOGATIONS, SET_HOMOLOGATION_ERROR, 1, "", "Integration.IdCustomer"
      , parentId ? [{fieldName: "IdIntegration", fieldValue: parentId}] : []
      )
    );
  };

export /**
 * Homologation Action getHomologationById
 *
 * @method GET
 * @param {*} id
 */
  const getHomologationById = (id) => async (dispatch) => {
    dispatch(getRecordById("homologation", id, GET_HOMOLOGATION, SET_HOMOLOGATION_ERROR));
  };

export /**
  * Homologation Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createHomologation = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord("homologation", recordToCreate, updateChecked, GET_HOMOLOGATION_CHANGED, SET_HOMOLOGATION_ERROR));
 };
 

export /**
 * Homologation Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyHomologation = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("homologation", recordToUpdate, updateChecked, isPrincipalPage, GET_HOMOLOGATION_CHANGED, SET_HOMOLOGATION_ERROR));  
};
