import { Box, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ButtonSaveChanges from '../../components/ButtonForm/ButtonSaveChanges';
import ControlledInputRoundedForm from '../../components/InputForm/ControlledInputRoundedForm';
import FormLayout from '../../components/layouts/FormLayout'
import { FormControlLabel, Paper } from '@material-ui/core'
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import CustomTable from "../../components/Table/CustomTable.component";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import SwitchForm from '../../components/Switch/switchForm.component';
import { HelpOutline } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CrudClient, GetScopes } from '../../actions/client.action';
import { FullLoader, useDialog } from '@pif-tr-components/components';
import { isEmpty, validateEquals } from '../../utils/proprietaryHooks';
import update from "react-addons-update";
import { CHANGE_CLIENT_MESSAGE, GET_ERROR_CHANGE_CLIENT } from '../../actions/types';

const Constants = {
  BACK_BUTTON: "BackButon",
  CANCEL_EDIT: "CancelEdit",
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: "10px 15px",
  },
  paperTable: {
    width: "100%",
    border: `2px solid ${theme.palette.primary.light}`,
  },
  container: {
    maxHeight: 440,
  },
  searchTextField: {
    maxHeight: "25px",
    padding: "10px 23px",
    marginBottom: "12px",
  },
  buttonSave: {
    margin: "15px 3px 20px 3px",
    flex: 1,
    float: "right",
    background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: 10,
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main + "D4",
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  errorText: {
    padding: "10px 15px",
  },
  helpIcon: {
    color: theme.palette.secondary.main,
  },
  checksMargin: {
    marginLeft: "0px"
  }
}));

export const ClientFormView = (props) => {

  /* #region  Props */
  const {
    userId,
    sequence,
    clientData,
    setClientData,
    setFormShowed,
    isRoute,
    onCloseModal,
  } = props;
  /* #endregion */

  /* #region  States */
  const [privilegeOption, setPrivilegeOption] = useState(null);
  const [scopesComplete, setScopesComplete] = useState([])
  const [enabledForm, setEnabledForm] = useState(clientData.id === 0);
  const [viewErrors, setErrors] = useState("");
  const [allScopes, setAllScopes] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [dataAlertConfirm, setDataAlertConfirm] = useState({
    message: "",
  });
  const [messageDialogData, setMessageDialogData] = useState("")
  const [formData, setFormData] = useState(
    clientData.id === 0
      ? {
        id: 0,
        clientId: "",
        clientName: "",
        clientSecret: "",
        grantType: "",
        requireClientSecret: false,
        requirePkce: false,
        requireConsent: false,
        clientClaimsPrefix: "",
        accessTokenLifeTime: 0,
        redirectUris: "https://",
        scopes: []
      }
      : {
        id: clientData.id,
        clientId: clientData.clientId,
        clientName: clientData.clientName,
        clientSecret: clientData.clientSecret,
        grantType: clientData.grantType,
        requireClientSecret: clientData.requireClientSecret,
        requirePkce: clientData.requirePkce,
        requireConsent: clientData.requireConsent,
        clientClaimsPrefix: clientData.clientClaimsPrefix,
        accessTokenLifeTime: clientData.accessTokenLifeTime,
        redirectUris: clientData.redirectUris ,
        scopes: []
      }
  )
  /* #endregion */

  /* #region  Refs */
  const originalData = useRef({ originalData: formData, originalScopes: {} });
  /* #endregion */

  /* #region  Hooks */
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAllScopes, getChangeClient, getErrorClient } = useSelector(store => store.clientReducer);
  const { Dialog, onOpen, onClose } = useDialog({
    bodyText: dataAlertConfirm.message,
    cancelButtonText: 'No',
    confirmationButtonText: 'Si',
    onConfirmClick: () => handleAcceptAlert(),
    onCancelClick: () => handleCloseAlert()
  })

  const { Dialog: MessageDialog, onOpen: messageDialogOpen } = useDialog({
    bodyText: messageDialogData,
  })
  /* #endregion */

  /* #region  React hook form */
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    clientId: Yup.string().max(50).required(requiredMessage),
    clientName: Yup.string().max(50).required(requiredMessage),
    clientSecret: Yup.string().max(50).required(requiredMessage),
    grantType: Yup.string().max(50).required(requiredMessage),
    requireClientSecret: Yup.boolean(),
    requirePkce: Yup.boolean(),
    requireConsent: Yup.boolean(),
    clientClaimsPrefix: Yup.string().max(50),
    accessTokenLifeTime: Yup.number().test("isNotZero", "Campo obligatorio", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
  });
  const {
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    setValue,
    control
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
  });
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    dispatch(
      GetScopes(
        userId !== undefined
          ? {
            userId, clientId: clientData.id
          }
          : {
            sequence, clientId: clientData.id
          })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(getAllScopes)) {
      const orderList = getAllScopes.sort((a, b) => {
        return a.assigned ? -1 : 1;
      });
      if (getAllScopes !== undefined && getAllScopes !== null && getAllScopes.length > 0) {
        originalData.current = {
          originalData: { ...originalData.current.originalData, scopes: getScopes(orderList) },
          originalScopes: orderList,
        };
      }
      setisLoading(false);
      setScopesComplete(orderList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllScopes])

  useEffect(() => {
    if (getChangeClient !== "") {
      setMessageDialogData(getChangeClient);
      messageDialogOpen()
      dispatch({
        type: CHANGE_CLIENT_MESSAGE,
        payload: "",
      })
      if (clientData.id === 0) {
        setTimeout(() => {
          restartFields()
        }, 1500);
      } else {
        if (dataAlertConfirm.origin === Constants.BACK_BUTTON) {
          restartFields()
        }else if (dataAlertConfirm.origin === Constants.CANCEL_EDIT) {
          originalData.current = {
            originalData: {
              ...originalData.current.originalData,
              ...getValues(), scopes: getScopes()
            },
            originalScopes: scopesComplete
          }
          setEnabledForm(false)
          setisLoading(false)
        }else{
          setTimeout(() => {
            restartFields()
          }, 1500);
        }

      }
      setDataAlertConfirm({ message: "", })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChangeClient])

  useEffect(() => {
    if (getErrorClient !== "") {
      setErrors(getErrorClient.result);
      setisLoading(false)
      dispatch({
        type: GET_ERROR_CHANGE_CLIENT,
        payload: "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getErrorClient])

  useEffect(() => {
    return () => {
      restartFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  /* #endregion */


  /* #region  Config Table and functions */
  const columns = [
    {
      id: "name",
      label: "Scope",
      minWidth: 140,
      haveDropDown: true,
      align: "left",
      internalRow: {
        align: "left",
        marginLeft: "20px",
      },
    },
    {
      id: "assigned",
      label: "Asignado",
      haveDropDown: true,
      minWidth: 90,
      align: "center",
      disabled: !enabledForm,
      component: {
        handleChange: (e, value, id, row) => handleConfirmScopes(e, row),
      },
      tooltipMessage: "privilegio",
      format: (value) => value,
    },
  ];

  const buttons = {
    justify: "space-between",
    searchBar: {
      cellSize: {
        lg: 7,
        md: 7,
        sm: 7,
      },
    },
    children: () => {
      const config = {
        cellSize: {
          lg: 4,
          md: 5,
          sm: 2,
        },
      };
      const component = () => (
        <>
          <Grid container item lg={8} md={8} sm={6} justify="flex-start">
            <Box flexWrap="wrap">
              <Typography variant="subtitle2" align="right">
                Asignar todos los scopes
              </Typography>
            </Box>
          </Grid>
          <Grid container item lg={4} md={4} sm={12} justify="flex-end">
            <Tooltip title="Seleccionar todas las aplicaciones">
              <FormControlLabel
                control={
                  <SwitchForm
                    name="allRoles"
                    disabled={!enabledForm}
                    onChange={handleAllScopesCheched}
                    checked={allScopes}
                  />
                }
              />
            </Tooltip>
          </Grid>
        </>
      );

      return { config, component };
    },
  };

  const handleConfirmScopes = (event, row) => {
    var index = scopesComplete.findIndex((element) => element.name === row.name);
    setScopesComplete(
      update(scopesComplete, {
        [index]: {
          $merge: { assigned: event.target.checked },
        },
      })
    );
    if (event.target.checked) {
      let selected = scopesComplete.filter((item) => item.assigned === true);
      if (selected.length === scopesComplete.length - 1) {
        setAllScopes(true);
      }
    } else {
      setAllScopes(false);
    }
  };

  const handleAllScopesCheched = (event) => {
    if (event === undefined) {
      const checked = scopesComplete.filter((item) => item.assigned === true);
      if (checked.length === scopesComplete.length && scopesComplete.length !== 0) {
        setAllScopes(true);
        //  setEnabledForm(true)
      } else {
        setAllScopes(false);
      }
    } else {
      const checked = event.target.checked;
      scopesComplete.map((item) => (item.assigned = checked));
      setScopesComplete([...scopesComplete]);
      setAllScopes(checked);
    }
  };
  /* #endregion */

  const backButton = () => {
    if (clientData.id === 0) {
      restartFields();
    } else if (enabledForm) {
      let scopeList = getScopes()
      const values = getValues();
      const clientFormData = {
        ...values,
        id: clientData.id,
        scopes: scopeList,
        accessTokenLifeTime: parseInt(values.accessTokenLifeTime)
      };
      if (!validateEquals(originalData.current.originalData, clientFormData)) {
        setDataAlertConfirm({
          message: (
            <div>
              Hiciste modificaciones
              <br />
              ¿Deseas actualizarlas?
            </div>
          ),
          origin: Constants.BACK_BUTTON
        })
        onOpen()
      } else {
        restartFields()
      }
    } else {
      restartFields();
    }
  }

  const restartFields = () => {
    setClientData({ id: 0 })
    setFormShowed(false)
    if (!isRoute) {
      onCloseModal()
    }
    setFormData({})
  }

  const handleChangeEdit = (response) => {
    if (!response) {
      let scopeList = getScopes()
      const values = getValues();
      const clientFormData = {
        ...values,
        id: clientData.id,
        scopes: scopeList,
        accessTokenLifeTime: parseInt(values.accessTokenLifeTime)
      };
      if (!validateEquals(originalData.current.originalData, clientFormData)) {
        setDataAlertConfirm({
          message: (
            <div>
              Hiciste modificaciones
              <br />
              ¿Deseas actualizarlas?
            </div>
          ),
          origin: Constants.CANCEL_EDIT
        })
        onOpen()
      } else {
        setEnabledForm(response);
      }
    } else {
      setEnabledForm(response);
    }

  }

  const handleAcceptAlert = () => {
    saveClient()
    onClose();
  }

  const handleCloseAlert = () => {
    setDataAlertConfirm({ message: "", })
    onClose()
    setisLoading(false);

    if (dataAlertConfirm.origin === Constants.BACK_BUTTON) {
      restartFields()
    }

    if (dataAlertConfirm.origin === Constants.CANCEL_EDIT) {
      setClientValue(originalData.current.originalData)
      setScopesComplete(originalData.current.originalScopes)
      setEnabledForm(false);
    }
  }

  const setClientValue = useCallback(
    (data) => {
      if (isEmpty(data)) {
        data = formData;
      }
      for (const key in data) {
        setValue(key, formData[key]);
      }
    },
    [setValue, formData]
  );

  const saveClient = async (e) => {
    if (!!e) {
      e.preventDefault();
    }
    setErrors("");
    await trigger();
    let selectedScopes = scopesComplete.filter((app) => app.assigned === true).length;
    if (isEmpty(formState.errors) && selectedScopes > 0) {
      setisLoading(true)
      let clientFormData = {}
      let scopeList = getScopes()
      if (clientData.id === 0) {
        const values = getValues();
        const info = userId !== undefined ? { userId } : { sequence }
        clientFormData = {
          ...values,
          ...info,
          scopes: scopeList,
          accessTokenLifeTime: parseInt(values.accessTokenLifeTime)
        };
        dispatch(
          CrudClient(clientFormData, "create")
        )
      } else {
        const values = getValues();

        clientFormData = {
          ...values,
          id: clientData.id,
          scopes: scopeList,
          accessTokenLifeTime: parseInt(values.accessTokenLifeTime)
        };
        dispatch(
          CrudClient(clientFormData, "update")
        )
      }
    } else if (selectedScopes === 0) {
      setErrors("Se requiere minimo un scope");
    }
  }

  const getScopes = (data) => {
    let appList = [];
    if (data !== undefined) {
      data.forEach(function (scope) {
        if (scope.assigned === true) {
          appList.push(scope.name);
        }
      });
    } else {
      scopesComplete.forEach(function (scope) {
        if (scope.assigned === true) {
          appList.push(scope.name);
        }
      });
    }
    return appList;
  };

  return (
    <>
      <FormLayout
        subheader={clientData.id === 0 ? "Nuevo cliente" : "Editar cliente"}
        handleIconClick={() => {
          backButton();
        }}
        isEditing={clientData.id !== 0}
        isPrivilege={true}
        // handleCancel={handleCancel}
        // confirmInactivate={confirmInactivate}
        // setConfirmInactivate={setConfirmInactivate}
        // handleAccept={savePrivilege}
        enabledForm={enabledForm}
        setEnabledForm={handleChangeEdit}
      >
        <Grid container justify="space-evenly" alignItems="stretch" spacing={1}>
          <Grid item lg={5} sm={12}>
            <div className={classes.paper}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <ControlledInputRoundedForm
                    id="clientId"
                    name="clientId"
                    inputProps={{ maxLength: 100 }}
                    label="ID"
                    fullWidth
                    control={control}
                    pattern={/[^a-zA-Z0-9\s_.]/g}
                    disabled={!enabledForm}
                    error={errors.clientId}
                    helperText={errors.clientId?.message}
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Identificador del cliente"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <ControlledInputRoundedForm
                    id="clientName"
                    name="clientName"
                    inputProps={{ maxLength: 100 }}
                    label="Nombre"
                    fullWidth
                    control={control}
                    pattern={/[^a-zA-Z0-9\s]/g}
                    disabled={!enabledForm}
                    error={errors.clientName}
                    helperText={errors.clientName?.message}
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Nombre del cliente"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <ControlledInputRoundedForm
                    id="clientSecret"
                    name="clientSecret"
                    inputProps={{ maxLength: 100 }}
                    label="Secreto"
                    fullWidth
                    control={control}
                    pattern={/[^a-zA-Z0-9\s]/g}
                    disabled={!enabledForm}
                    error={errors.clientSecret}
                    helperText={errors.clientSecret?.message}
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Secreto del cliente"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <ControlledInputRoundedForm
                    id="grantType"
                    name="grantType"
                    inputProps={{ maxLength: 100 }}
                    label="Grant type"
                    fullWidth
                    control={control}
                    pattern={/[^a-zA-Z0-9_]/g}
                    disabled={!enabledForm}
                    error={errors.grantType}
                    helperText={errors.grantType?.message}
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Tipo de granttype"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <FormControlLabel
                    className={classes.checksMargin}
                    label="Requiere secreto"
                    variant="outlined"
                    margin="dense"
                    labelPlacement="end"
                    control={
                      <Tooltip title="Activar/Inactivar" aria-label="Activar/Inactivar">
                        <ControlledSwitchComponent
                          id="requireClientSecret"
                          name="requireClientSecret"
                          color="primary"
                          isDisabled={!enabledForm}
                          control={control}
                          checked={getValues.requireClientSecret}
                        />
                      </Tooltip>
                    }
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Indica si es necesario el secreto"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <FormControlLabel
                    className={classes.checksMargin}
                    label="Requiere PKCE"
                    variant="outlined"
                    margin="dense"
                    labelPlacement="end"
                    control={
                      <Tooltip title="Activar/Inactivar" aria-label="Activar/Inactivar">
                        <ControlledSwitchComponent
                          id="requirePkce"
                          name="requirePkce"
                          color="primary"
                          isDisabled={!enabledForm}
                          control={control}
                          checked={getValues.requirePkce}
                        />
                      </Tooltip>
                    }
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Indica si requiere el PKCE"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <FormControlLabel
                    className={classes.checksMargin}
                    label="Requiere consentimiento"
                    variant="outlined"
                    margin="dense"
                    labelPlacement="end"
                    control={
                      <Tooltip title="Activar/Inactivar" aria-label="Activar/Inactivar">
                        <ControlledSwitchComponent
                          id="requireConsent"
                          name="requireConsent"
                          color="primary"
                          isDisabled={!enabledForm}
                          control={control}
                          checked={getValues.requireConsent}
                        />
                      </Tooltip>
                    }
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Indica si requiere el consentimiento"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <ControlledInputRoundedForm
                    id="clientClaimsPrefix"
                    name="clientClaimsPrefix"
                    inputProps={{ maxLength: 100 }}
                    label="Prefijo de los claims"
                    fullWidth
                    control={control}
                    pattern={/[^a-zA-Z]/g}
                    disabled={!enabledForm}
                    error={errors.clientClaimsPrefix}
                    helperText={errors.clientClaimsPrefix?.message}
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"Prefijo que va a aparecer en los claims"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <ControlledInputRoundedForm
                    id="accessTokenLifeTime"
                    name="accessTokenLifeTime"
                    inputProps={{ maxLength: 100 }}
                    label="Duracion del token"
                    fullWidth
                    control={control}
                    pattern={/[^0-9]/g}
                    disabled={!enabledForm}
                    error={errors.accessTokenLifeTime}
                    helperText={errors.accessTokenLifeTime?.message}
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={
                    <div>
                      Duración del token generado
                      <br />
                      El valor ingresado debe estar en segundos
                    </div>}
                  >
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <ControlledInputRoundedForm
                    id="redirectUris"
                    name="redirectUris"
                    inputProps={{ maxLength: 500 }}
                    label="Redirect Uris"
                    fullWidth
                    control={control}
                    pattern={/[^a-zA-Z0-9_/:,.-]/g}
                    disabled={!enabledForm}
                    error={errors.redirectUris}
                    helperText={errors.redirectUris?.message}
                  />
                </Grid>
                <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justify="center">
                  <Tooltip title={"URLs para redirección permitidas"}>
                    <HelpOutline className={classes.helpIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                {viewErrors !== "" && (
                  <div className={classes.errorText}>
                    <Typography color="error" variant="subtitle2">
                      {viewErrors}
                    </Typography>
                  </div>
                )}
              </Grid>
            </div>
          </Grid>

          <Grid container lg={7} item sm={12} justify="space-evenly" alignItems="flex-start" spacing={1}>
            <Paper className={classes.paperTable}>
              <CustomTable
                columns={columns}
                data={scopesComplete}
                buttons={buttons}
                mainParam={"name"}
                havePagination={false}
                handleChange={handleConfirmScopes}
                option={privilegeOption}
                setOption={setPrivilegeOption}
                //globalDisabled={!enabledForm}
              />
            </Paper>
          </Grid>
          {enabledForm && (
          <Grid item lg={12}>
            <ButtonSaveChanges
              type="submit"
              id="buttonSave"
              style={classes.buttonSave}
              margin="dense"
              title={clientData.id === 0 ? "Guardar" : "Actualizar"}
              isDisabled={!enabledForm}
              handleClick={(e) => {
                e.preventDefault();
                handleSubmit(saveClient(e));
              }}
            />
          </Grid>)}
        </Grid>
      </FormLayout>
      <FullLoader open={isLoading} viewLoader={true} />
      <Dialog />
      <MessageDialog />
    </>

  )
}
