import { Button, FormControlLabel, Grid, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import React, { useState } from 'react'
import SwitchForm from '../Switch/switchForm.component';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: "9px",
    boxShadow: "1px 3px 3px 2px #00000026",
    borderRadius: "5px",
    border: '1px solid #D5D5D5',
    textAlign: 'left',
  },
  buttonActive: {
    background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
    color: 'white !important',
    '&&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.main,
    }
  },
  buttonInactive: {
    background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    color: "white !important",
    '&&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.common.white
    }
  },
  typographyTitle: {
    font: 'normal normal bold 16px/14px Muli',
    fontWeight: 600,
    active: {
      color: 'white'
    },
    inactive: {
      color: theme.palette.primary.main
    }
  },
  typographyTitleActive: {
    color: 'white'
  },
  typographyTitleInactive: {
    color: theme.palette.primary.light,
  },
  typographyCounter: {
    font: 'normal normal normal 12px/14px Muli',
    color: theme.palette.common.white,
    opacity: 0.6
  },
  typographyCounterInactive: {
    font: 'normal normal normal 12px/14px Muli',
    color: theme.palette.common.grey
  },
  arrowActive: theme.palette.common.white,
  arrowInactive: theme.palette.primary.light
}));


const ButtonApps = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { title, isActive, appCounter, appsIsVisible, checked, globlaDisabled,viewSwitch, ...rest } = props;

  const [isVisible, setIsVisible] = useState(checked !== undefined ? checked : false);

  const handleChangeIsVisible = (e) => {
    const title = e.target.name;
    const checked = e.target.checked;
    setIsVisible(checked)

    appsIsVisible.current = appsIsVisible.current.map((item) => {
      if (item.title === title) {
        item.isVisible = checked;
      }
      return item
    })
  }

  return (
    <Button
      className={`${classes.button} ${isActive
        ? classes.buttonActive
        : classes.buttonInactive
        }`}

      variant="contained"
      endIcon={<ArrowRight style={{
        color: isActive === true
          ? theme.palette.common.white
          : theme.palette.primary.light
      }} />}
      {...rest}
    >
      <Grid container direction="row" justifyContent='space-between' style={{ paddingBottom: 2, paddingTop: 2 }} >
        <Grid item direction="column" >
          <Grid item>
            <Typography
              className={`${classes.typographyTitle} ${isActive
                ? classes.typographyTitleActive
                : classes.typographyTitleInactive
                }`}
              align="left">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="left"
              className={`${isActive
                ? classes.typographyCounter
                : classes.typographyCounterInactive
                }`}
            >
              {appCounter}
            </Typography>
          </Grid>
        </Grid>
        {(appCounter !== '0 privilegios' && viewSwitch !== undefined && viewSwitch) && (
          <Grid item>
            <Tooltip title="Será visible en esta aplicación">
              <FormControlLabel
                control={<SwitchForm name={title} disabled={globlaDisabled} onChange={handleChangeIsVisible} checked={isVisible} />}
              />
            </Tooltip>
          </Grid>
        )}

      </Grid>
    </Button>
  )
}

export default ButtonApps

