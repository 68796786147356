import { Fab, Grid, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getListNotifications, DeleteNotification } from '../../actions/notifications.action';
import { CHANGE_NOTIFICATION } from '../../actions/types';
import NewUserIcon from '../../components/IconsLibrary/NewUserIcon';
import TrashIcon from '../../components/IconsLibrary/TrashIcon';
import ViewLayout from '../../components/layouts/ViewLayout';
import CustomTable from '../../components/Table/CustomTable.component';
import { states } from '../../utils/constants';
import { isEmpty, toPascalCase } from '../../utils/proprietaryHooks';
import { NotificationsFormController } from './notificationForm.controller';

const useStyles = makeStyles((theme) => ({
  btnNewUser: props => ({
    flex: 1,
    textAlign: "right",
    paddingRight: "8px",
    width: "32px",
    height: "32px",
    marginRight: 40,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 1px #0000003D",
    border: `1px solid ${props.background}`,
    opacity: "1",
  }),
  newUserIcon: {
    stroke: theme.palette.secondary.main,
  },
  styleFab: {
    boxShadow: 'none'
  }
}));

export const NotificationView = ({ component: Component, container, ...props }) => {
  const { Loading } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [isFormShowed, setFormShowed] = useState(false);
  const [option, setOption] = useState(0);
  const dispatch = useDispatch();
  const { getAllNotifications, changeNotification } = useSelector(state => state.notificationReducer)
  const [showNotificationsAlert, setShowNotificationsAlert] = useState(false);
  const [currentNotification, setCurrentNotification] = useState({})
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: ""
  });
  let [isLoading, setLoading] = useState(true);
  if(Loading !== undefined){
    setLoading = Loading;
  }

  const columns = [
    {
      id: "reason", label: "Motivo notificación", minWidth: 40,
      haveDropDown: true,
      // key: '_',
      wordLength: 100
    },
    {
      id: "subjectName", label: "Asunto del correo", minWidth: 40,
      haveDropDown: true,
      // key: '_',
      wordLength: 100
    },
    {
      id: "sharedApps", label: "Aplicación", minWidth: 140, 
      haveDropDown: true,
      children: (value) => {
        let result = value.split(',');
        result = result.map((item) => toPascalCase(item))
        const typographyStyle = {
          textDecoration: (result.length > 2) ? 'underline' : '',
          color: theme.palette.primary.main,
          font: 'normal normal normal 14px/19px Muli',
          letterSpacing: '0px',
          cursor: 'pointer'
        }
        return (
          <>
            {result.length > 2 ?
              <Tooltip title={result.join(', ')}>
                <Typography className={classes.labels} align={'center'} style={typographyStyle}>
                  {(value.includes(',')) ? `${result[0]}, ${result[1]} ...` : result[0]}
                </Typography>
              </Tooltip>
              :
              <Typography className={classes.labels} align={'center'} style={typographyStyle}>
                {(value.includes(',')) ? `${result[0]}, ${result[1]}` : result[0]}
              </Typography>
            }
          </>
        )
      }
    },
    {
      id: "executionDate", label: "Fecha ejecución", minWidth: 140, haveDropDown: true,
      format: (value) => {
        const dateParse = new Date(value);
        const date = dateParse.toLocaleDateString();
        return `${date}`
      }
    },
    {
      id: "stateName", label: "Estado", minWidth: 40,
      haveDropDown: true,
      children: (value, row) => {
        return (
          <Grid
            style={{ display: 'inline-flex' }}
            onMouseOver={() => {
              const trashButton = document.getElementById(row.id);
              if (!!trashButton) {
                trashButton.style.visibility = "visible";
                trashButton.style.cursor = "pointer";
              }
              //}
            }}
            onMouseLeave={() => {
              const trashButton = document.getElementById(row.id);
              if (!!trashButton) {
                trashButton.style.visibility = "hidden";
              }
            }}>
            <Typography>
              {toPascalCase(value)}
            </Typography>
            {
              value.toUpperCase() === "PENDIENTE" ?
                <Tooltip
                  title='Eliminar'
                  aria-label="Eliminar"
                  style={{ marginTop: 4, marginLeft: 2 }}
                >

                  <TrashIcon
                    id={row.id}
                    style={{ visibility: "hidden", marginLeft: 4, justifyContent: 'center' }}
                    color="primary"
                    width={'20px'}
                    active={true}
                    onClick={(evt) => {
                      handleIconClick(evt, row.id);
                    }}
                  />
                </Tooltip>
                :
                <Tooltip
                  title='Eliminar'
                  aria-label="Eliminar"
                  style={{ marginTop: 4, marginLeft: 2 }}
                >

                  <TrashIcon
                    style={{ visibility: "hidden", marginLeft: 4, justifyContent: 'center' }}
                    color="primary"
                    width={'20px'}
                    active={true}
                  />
                </Tooltip>
            }
          </Grid>
        );
      },
      format: (value) => (value === "PENDIENTE" ? true : false)
    },
  ];

  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          sm: 7,
          xs: 12,
        }
      },
      menuOrder: {
        options: [
          {
            label: 'Pendiente',
            columns: [{
              prop: 'idstate',
              direction: -1
            }]
          },
          {
            label: 'Ejecutada', columns: [{
              prop: 'idstate',
            },
            ]
          },
          {
            label: 'Más reciente',
            columns: [{ prop: 'createdon', direction: 1, format: (date) => { return new Date(date).getTime() } }]
          },
          { label: 'Más antiguo', columns: [{ prop: 'createdon', direction: -1, format: (date) => { return new Date(date).getTime() } }] }
        ],
        cellSize: {
          sm: 2,
          xs: 2,
        }
      },
      // exportButton: {
      //   cellSize: {
      //     lg: 6,
      //     md: 2,
      //     sm: 2
      //   }
      // },
      children: () => {
        return (
          <Grid container item lg={12} md={12} sm={2} xs={2} direction="row-reverse" >
            <p className={classes.buttonNewUser}>
              <Tooltip title={`Crear notificación`} aria-label={`Nueva notificación`}>
                <Fab
                  size="small"
                  className={[classes.fab, classes.styleFab]}
                  onClick={() => {
                    setFormShowed(true);
                    // setLoading(true);
                  }}
                >
                  <NewUserIcon className={classes.newUserIcon} />
                </Fab>
              </Tooltip>
            </p>
          </Grid>
        )
      }

    }

    return button;
  }

  useEffect(() => {

    dispatch(getListNotifications())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getAllNotifications.loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllNotifications])

  useEffect(() => {
    if (!isEmpty(changeNotification)) {
      dispatch(getListNotifications())
      setConfirmInactivate({
        open: true,
        message: changeNotification,
        item: "inactivate",
      });
      dispatch({
        type: CHANGE_NOTIFICATION,
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeNotification]);

  const handleConfirmChecked = () => {
    if (confirmInactivate.item !== "inactivate") {
      dispatch(DeleteNotification({id: confirmInactivate.item}));
    }
    dispatch({
      type: CHANGE_NOTIFICATION,
      payload: {},
    });
    setConfirmInactivate({
      item: "",
      open: false,
      checked: false,
      message: "",
    });
  };

  const handleIconClick = (event, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      checked: event.target.checked,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: true,
      message: `¿Desea eliminar esta notificación?`,
    });
  }

  const handleCellClick = (evt, row) => {
    if (row.idstate === states.Pendiente) {
      setCurrentNotification(row)
      setFormShowed(true);
    } else {
      setConfirmInactivate(state => ({
        ...state, open: true, message: "La notificación ya fue ejecutada, no se puede modificar"
      }))
    }
  }

  // const handleDeleteClick = () => {

  // }

  return (
    <ViewLayout
      headerTitle={'Notificaciones de correo'}
      handleAccept={handleConfirmChecked}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      showAlert={showNotificationsAlert}
      setShowAlert={setShowNotificationsAlert}
      isLoading={isLoading}
    >
      {isFormShowed ?
        <NotificationsFormController
          setCardState={setFormShowed}
          setLoading={setLoading}
          currentNotification={currentNotification}
          setCurrentNotification={setCurrentNotification} /> :
        <CustomTable
          columns={columns}
          data={getAllNotifications.notifications}
          mainParam={'reason'}
          buttons={getButtons()}
          havePagination={true}
          handleCellClick={handleCellClick}
          option={option}
          setOption={setOption}
          paramToDisable={'stateName'}
          validateToDisable={"EJECUTADA"}
          isBoolTheValidationToDisable={false}
        />
      }
    </ViewLayout>
  )
}
