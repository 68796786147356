import {
  GET_HOMOLOGATION,
  GET_HOMOLOGATIONS,
  SET_HOMOLOGATION_ERROR,
  GET_HOMOLOGATION_CHANGED,
} from "../actions/types";

const initialState = {
  getHomologationResponse: [],
  getHomologationsResponse: [],
  setHomologationErrorResponse: [],
  getHomologationChangedResponse: []
};

/**
 * homologationsReducer (set homologation or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, homologation}
 */
export default function homologationsReducer (state = initialState, action) {
  switch (action.type) {
    case GET_HOMOLOGATIONS:
      return {
        ...state,
        getHomologationsResponse: action.payload,
      };
    case GET_HOMOLOGATION:
      return {
        ...state,
        getHomologationResponse: action.payload,
      };
    case GET_HOMOLOGATION_CHANGED:
      return {
        ...state,
        getHomologationChangedResponse: action.payload,
      };
    case SET_HOMOLOGATION_ERROR:
      return {
        ...state,
        setHomologationErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
