import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import IconEspecialButton from "../../components/ButtonForm/IconEspecialButton.component";
import { Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  getDashboardData,
  setUrlMonithorService,
} from "../../actions/monithor.action";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Scatter } from "react-chartjs-2";

ChartJS.plugins.register({
  id: "responsetimes",
  ArcElement,
  Tooltip,
  Legend,
  plugins,
});

export function MonitoringDashBoardResponseTimes() {
  const [isData, setIsData] = useState(false);
  const chartRef = useRef(null);
  let params = new URLSearchParams(window.location.search);
  const [dashboardData, setDashboardData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const [environmentURL, setEnvironmentURL] = useState(
    params.get("env") ? `https://${params.get("env")}.parameta.co` : ""
  );

  const [version, setVersion] = useState(parseInt(params.get("ver") ?? "1"));
  const [fullURL] = useState(
    params.get("fullurl") ? params.get("fullurl") : ""
  );

  const { getEnterpriseByIDResponse } = useSelector(
    (store) => store.enterpriseReducer
  );

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  async function fetchData(startDate, endDate) {
    try {
      const fetchedData = await getDashboardData(startDate, endDate);

      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;
      const result = labels.map((label, index) => ({
        OBJECT_TYPE: label,
        N: data[index].toString(),
      }));

      setDashboardData(fetchedData);
    } catch (error) {
      console.error("Error al fetchData: ", error);
    }
  }

  useEffect(() => {
    if (getEnterpriseByIDResponse.monitoringService) {
      setEnvironmentURL(getEnterpriseByIDResponse.monitoringService);
      setVersion(getEnterpriseByIDResponse.monitoringVersion);
    }

    if (version && (environmentURL || fullURL)) {
      setUrlMonithorService(fullURL ? fullURL : environmentURL);
      fetchData(
        dateControl.getValues().dateStart,
        dateControl.getValues().dateEnd
      );
    }
  }, [getEnterpriseByIDResponse, version, environmentURL, fullURL]);

  useEffect(() => {
    try {
      if (dashboardData.labels.length == 0) {
        setIsData(false);
      } else {
        setIsData(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dashboardData]);

  const {
    control: dateControl,
    handleSubmit: handleDateSubmit,
    errors: dateErrors,
  } = useForm({
    defaultValues: {
      dateStart: "",
      dateEnd: getCurrentDate(),
    },
    shouldUnregister: false,
    mode: "onChange",
  });

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    plugins: {},
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: "Integraciones",
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        console.log(dashboardData);
      }
    },
  };

  const monthLabels = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const dummyData = {
    datasets: [
      {
        label: "Dummy Scatter Data",
        data: [
          { x: 0, y: 2 },
          { x: 1, y: 5 },
          { x: 2, y: 3 },
          { x: 3, y: 8 },
          { x: 4, y: 4 },
          { x: 5, y: 7 },
          { x: 6, y: 2 },
          { x: 7, y: 6 },
          { x: 8, y: 4 },
          { x: 9, y: 5 },
          { x: 10, y: 3 },
          { x: 11, y: 7 },
        ],
        backgroundColor: "rgba(255, 99, 132, 1)",
        pointRadius: 5,
      },
    ],
  };

  const scatterChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: "Test",
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Mes",
          },
          ticks: {
            display: true,
          },
          type: "category",
          labels: monthLabels,
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Value",
          },
          ticks: {
            display: true,
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 5,
        hitRadius: 10,
      },
    },
  };

  return (
    <>
      <Grid item container spacing={1} sm={6} xs={12}>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateStart"
            name="dateStart"
            label="Fecha inicio"
            control={dateControl}
            fullWidth
            type="date"
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={dateErrors.dateStart}
            shrink={true}
            helperText={dateErrors.dateStart?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateEnd"
            name="dateEnd"
            label="Fecha fin"
            control={dateControl}
            fullWidth
            type="date"
            shrink={true}
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={dateErrors.dateEnd}
            helperText={dateErrors.dateEnd?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          sm={4}
          xs={12}
          style={{
            paddingLeft: "22px",
            paddingTop: "10px",
          }}
        ></Grid>
      </Grid>

      {isData && (
        <>
          <Grid
            item
            container
            sm={3}
            xs={12}
            style={{
              paddingLeft: "20px",
              paddingTop: "15px",
            }}
          >
            <IconEspecialButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              size={"large"}
              startIcon={<Search />}
              /*onClick={() => {
                handleDataWithDate();
              }}*/
            >
              Buscar
            </IconEspecialButton>
          </Grid>
        </>
      )}

      {isData && (
        <>
          <Grid item container spacing={1} sm={12} xs={6}>
            <Grid item md={6} sm={3} xs={6}>
              <Bar
                data={dashboardData}
                options={{ ...barChartOptions }}
                width={300}
                height={300}
                ref={chartRef}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={0} sm={6} xs={12}>
            <Grid item md={6} sm={6} xs={12}>
              <Scatter
                data={dummyData}
                options={{ ...scatterChartOptions }}
                width={300}
                height={300}
                ref={chartRef}
              />
            </Grid>
            {isData && (
              <Grid item md={6} sm={6} xs={12}>
                <Scatter
                  data={dummyData}
                  options={{ ...scatterChartOptions }}
                  width={300}
                  height={300}
                  ref={chartRef}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={0} sm={6} xs={12}>
            {isData && (
              <Grid item md={6} sm={6} xs={12}>
                <Scatter
                  data={dummyData}
                  options={{ ...scatterChartOptions }}
                  width={300}
                  height={300}
                  ref={chartRef}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
