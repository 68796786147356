import {
  GET_DATATYPE,
  GET_DATATYPES,
  SET_DATATYPE_ERROR,
  GET_DATATYPE_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * DataTypes Action DataTypes
 *
 * @method GET
 */
  const getDataTypes = () => async (dispatch) => {
    dispatch(getRecords("datatype", GET_DATATYPES, SET_DATATYPE_ERROR));
  };

export /**
 * DataTypes Action getDataTypes
 *
 * @method GET
 * @param customerid
 */
  const getDataTypesByCustomer = (customerid) => async (dispatch) => {
    dispatch(getRecordsByCustomer("datatype", customerid, GET_DATATYPES, SET_DATATYPE_ERROR));
  };

export /**
 * DataType Action getDataTypeById
 *
 * @method GET
 * @param {*} id
 */
  const getDataTypeById = (id) => async (dispatch) => {
    dispatch(getRecordById("datatype", id, GET_DATATYPE, SET_DATATYPE_ERROR));
  };

export /**
  * DataType Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createDataType = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord("datatype", recordToCreate, updateChecked, GET_DATATYPE_CHANGED, SET_DATATYPE_ERROR));
 };
 

export /**
 * DataType Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyDataType = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("datatype", recordToUpdate, updateChecked, isPrincipalPage, GET_DATATYPE_CHANGED, SET_DATATYPE_ERROR));  
};
