import { useState, useEffect } from "react";
import { connect } from "react-redux";
import ViewLayout from "../../components/layouts/ViewLayout";

//#region Entity's dependencies
import TracingForm from "./customViewsForm.view";
//#endregion

const CustomViews = ({ component: Component, container, ...props }) => {
  const {
    record,
    Loading
  } = props;

  //#region States
  const [showRecordAlert, setShowRecordAlert] = useState(false);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });
  const [recordInfo, setRecordInfo] = useState({});
  const [isFormShowed, setFormShowed] = useState(false);
  let [isLoading, setLoading] = useState(false);
  if(Loading !== undefined){
    setLoading = Loading;
  }
  //#endregion

  //#region Effects
  useEffect(() => {
    if(record.id){
      setRecordInfo(record);
      setFormShowed(true);
    }
  }, [record]);
  //#endregion

  //#region custom

  return (
    <ViewLayout
      headerTitle={"Vistas personalizadas"}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      showAlert={showRecordAlert}
      setShowAlert={setShowRecordAlert}
      isLoading={isLoading}
    >
      {isFormShowed && (
        <TracingForm
          recordId={recordInfo.id}
          record={recordInfo}
          setCardState={setFormShowed}
          setLoading={setLoading}
        />
      )}
    </ViewLayout>
  );
};

//#endregion

//#region entities's declarations 
CustomViews.propTypes = {
  
};

const mapStateToProps = (state) => ({
  record: state.enterpriseReducer.getEnterpriseByIDResponse,
});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomViews);
//#endregion