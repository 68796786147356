//import { makeStyles } from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
import BorderedSection from "../../components/CardBody/borderedSection.component";
import RulesIcon from "@mui/icons-material/DesignServices";
import SourceServiceIcon from "@mui/icons-material/Settings";
import DestinationServiceIcon from "@mui/icons-material/SettingsSuggest";
import EntityIcon from "@mui/icons-material/DescriptionOutlined";
import HeaderIcon from "@mui/icons-material/InfoOutlined";
import ParentIcon from "@mui/icons-material/DataObject";
import LineIcon from "@mui/icons-material/FormatOverline";
import LinesIcon from "@mui/icons-material/FormatListBulleted";
import RuleIcon from "@mui/icons-material/Straighten";
//import HomologationIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import Nextstep1Icon from "@mui/icons-material/LooksOne";
import Nextstep2Icon from "@mui/icons-material/LooksTwo";
import { useState, useEffect, useRef } from "react";
import { ModalForm } from "./ModalForm";

/*const useStyles = makeStyles((theme) => ({
  itemContainer: {
    boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    border: "0px", 
    marginRight: "6px", 
    marginLeft: "6px"
  },

  rule: {
    marginRight: "2px", 
    marginLeft: "2px", 
    borderLeft:"0px", 
    borderRight:"0px"
  },

  nextStep: {
    paddingTop:"21px"
  },

  nextAction: {
    stroke: theme.palette.info.main,
  }
}));*/

function ConfigurationForm({ record, control, errors, Applications, IntegrationTypes, enabledTabsForm, ServiceTypes, ...props }) {
  //const styles = useStyles();

  const MapperActions = [
    {id:'1', name:'Mapper - texto'},
    {id:'2', name:'Mapper - fecha'},
    {id:'3', name:'Mapper - número'},
    {id:'4', name:'Mapper - concatenar'},
    {id:'5', name:'Mapper - formula'},
    {id:'6', name:'Mapper - Groovy'},
  ];

  ServiceTypes = ServiceTypes ?? [
    {id:18, name:'Cron'},
    {id:19, name:'Call out'},
    {id:20, name:'End point'},
  ];

  const ControlledPojo = (pojo) => {
    let headerItems = [];
    let lines = [];
    let parents = [];
    let fields = [];

    for (const header in pojo.pojoObject) {
      if(typeof pojo.pojoObject[header] === "object" && Array.isArray(pojo.pojoObject[header])){
        let line = [];
        for (const lineObject in pojo.pojoObject[header]){ 
          let lineItems = [];
          for (const line in pojo.pojoObject[header][lineObject]){
            lineItems.push(
              <ControlledInputRoundedForm
                id={`${pojo.pojoPath}.${header}.${lineObject}.${line}`}
                name={`${pojo.pojoPath}.${header}.${lineObject}.${line}`}
                inputProps={{ maxLength: 200 }}
                label={line}
                fullWidth
                control={control}
                pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                disabled={!enabledTabsForm}
                isCustomizable={true}
                detailOption={!enabledTabsForm}
                editOption={enabledTabsForm}
                deleteOption={enabledTabsForm}
                handleDetailClick={()=>{detailObjectPojo(`${pojo.pojoPath}.${header}.${lineObject}.${line}`)}}
                handleEditClick={()=>{editObjectPojo(`${pojo.pojoPath}.${header}.${lineObject}.${line}`,pojo.id)}}
                handleRemoveClick={()=>{removeControlledPojo(pojo,`${header}.${lineObject}.${line}`)}}
                focused={affectedFields.includes(`,${pojo.pojoPath}.${header}.${lineObject}.${line},`)}
                style={affectedFields.includes(`,${pojo.pojoPath}.${header}.${lineObject}.${line},`)?{boxShadow: `rgb(218 22 61 / 25%) 0 0 0 2px`, borderRadius: 10}:null}
              />
            );
            pushField(fields, {
              id: `${pojo.pojoPath}.${header}.${lineObject}.${line}`,
              name: `${pojo.app}:${header}.${parseInt(lineObject) + 1}.${line}`, 
              defaultValue: pojo.pojoObject[header][lineObject][line]
            });
          }
          line.push(
            <BorderedSection 
              icon={LineIcon} 
              title={`${header} ${parseInt(lineObject) + 1}`} 
              isCollepsible={true}
              remove={enabledTabsForm}
              add={enabledTabsForm}
              handleAddClick={()=>{addObjectPojo(`${pojo.pojoPath}.${header}.${lineObject}`, pojo.id)}}
              handleRemoveClick={()=>{removeControlledPojo(pojo,`${header}.${lineObject}`)}}
              borderStyle="borderStyle2"
              >
                {lineItems}
            </BorderedSection>
          );
        }
        lines.push(
          <BorderedSection 
            icon={LinesIcon} 
            title={header} 
            isCollepsible={true}
            remove={enabledTabsForm}
            add={enabledTabsForm}
            handleAddClick={()=>{addObjectPojo(`${pojo.pojoPath}.${header}`, pojo.id, true)}}
            handleRemoveClick={()=>{removeControlledPojo(pojo,`${header}`)}}
            >
              {line}
          </BorderedSection>
        );
      }
      else if(typeof pojo.pojoObject[header] === "object" && pojo.pojoObject[header] !== null) {
        let parentItems = [];
        for (const parent in pojo.pojoObject[header]){
          parentItems.push(
            <ControlledInputRoundedForm
                    id={`${pojo.pojoPath}.${header}.${parent}`}
                    name={`${pojo.pojoPath}.${header}.${parent}`}
                    inputProps={{ maxLength: 200 }}
                    label={parent}
                    fullWidth
                    control={control}
                    pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledTabsForm}
                    isCustomizable={true}
                    detailOption={!enabledTabsForm}
                    editOption={enabledTabsForm}
                    deleteOption={enabledTabsForm}
                    handleDetailClick={()=>{detailObjectPojo(`${pojo.pojoPath}.${header}.${parent}`)}}
                    handleEditClick={()=>{editObjectPojo(`${pojo.pojoPath}.${header}.${parent}`,pojo.id)}}
                    handleRemoveClick={()=>{removeControlledPojo(pojo,`${header}.${parent}`)}}
                    focused={affectedFields.includes(`,${pojo.pojoPath}.${header}.${parent},`)}
                    style={affectedFields.includes(`,${pojo.pojoPath}.${header}.${parent},`)?{boxShadow: `rgb(218 22 61 / 25%) 0 0 0 2px`, borderRadius: 10}:null}
                  />
          );
          pushField(fields, {
            id:`${pojo.pojoPath}.${header}.${parent}`,
            name: pojo.app + ":" + header + "." + parent, 
            defaultValue: pojo.pojoObject[header][parent]
          });
        }
        parents.push(
          <BorderedSection 
            icon={ParentIcon} 
            title={header} 
            isCollepsible={true}
            remove={enabledTabsForm}
            add={enabledTabsForm}
            handleAddClick={()=>{addObjectPojo(`${pojo.pojoPath}.${header}`, pojo.id)}}
            handleRemoveClick={()=>{removeControlledPojo(pojo,`${header}`)}}
            >
              {parentItems}
          </BorderedSection>
        );
      }
      else {
        headerItems.push(
          <ControlledInputRoundedForm
            id={`${pojo.pojoPath}.${header}`}
            name={`${pojo.pojoPath}.${header}`}
            inputProps={{ maxLength: 200 }}
            label={header}
            fullWidth
            control={control}
            pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
            disabled={!enabledTabsForm}
            isCustomizable={true}
            detailOption={!enabledTabsForm}
            editOption={enabledTabsForm}
            deleteOption={enabledTabsForm}
            handleDetailClick={()=>{detailObjectPojo(`${pojo.pojoPath}.${header}`)}}
            handleEditClick={()=>{editObjectPojo(`${pojo.pojoPath}.${header}`,pojo.id)}}
            handleRemoveClick={()=>{removeControlledPojo(pojo,`${header}`)}}
            focused={affectedFields.includes(`,${pojo.pojoPath}.${header},`)}
            style={affectedFields.includes(`,${pojo.pojoPath}.${header},`)?{boxShadow: `rgb(218 22 61 / 25%) 0 0 0 2px`, borderRadius: 10}:null}
          />
        );
        pushField(fields, {
          name:pojo.app + ":" + header, 
          id:`${pojo.pojoPath}.${header}`,
          defaultValue: pojo.pojoObject[header]
        });
      }
    }

    let header = (
      <BorderedSection 
        icon={HeaderIcon} 
        title="Cabecera" 
        isCollepsible={true} 
        add={enabledTabsForm}
        handleAddClick={()=>{addObjectPojo(`${pojo.pojoPath}`, pojo.id)}}
        >
          {headerItems}
      </BorderedSection>
    );

    let pojoForm = []
    pojoForm.push(header);
    pojoForm.push(parents);
    pojoForm.push(lines);

    return {
      pojoForm: (
        <BorderedSection 
          icon={EntityIcon} 
          title={pojo.entityName} 
          isCollepsible={true}
          borderStyle="borderStyle2"
          style={{
              marginTop: "30px",
            }}
          >
          {pojoForm}
        </BorderedSection>
      ), 
      pojoFields: fields};
  }

  const pushField = (fields, field)=>{
    fields.push(field);
    if(fieldsDetails.filter(f=>f.id === field.id).length === 0){
      fieldsDetails.push(Object.assign(field, {
        type: "string",
        size: "",
        required: false,
        homologationName: ""
      }));
    }
  }

  const ControlledRules = (pojo) => {
    const rules = pojo.pojoObject;
    let lines = [];

    if(typeof rules === "object" && Array.isArray(rules)){
      let item = 0;
      for (const lineObject in rules){ 
        let lineItems = [];
        for (const line in rules[lineObject]){
          if(line !== "id" && line !== "focused"){ 
            let fieldsOptions = line === "Accion" ? MapperActions : pojoForms.filter(form=>form.pojoType === line && form.group === pojo.group)[0]?.pojoForm.pojoFields.sort((a,b)=>{return a.id===b.id?0:a.id<b.id?-1:1;});
            let focused = false;
            if((rules[lineObject].focused || affectedFields.includes(`,${rules[lineObject].id},`)) && line==="Origen"){
              focused = true;
            }
            lineItems.push(
              <ControlledSelect
                id={`${pojo.pojoPath}.${item}.${line}`}
                name={`${pojo.pojoPath}.${item}.${line}`}
                label={line}
                options={fieldsOptions}
                control={control}
                disabled={!enabledTabsForm}
                isSearchable={true}
                autoFocus={focused && line==="Origen"}
              />
            );
          }
        }
        rules[lineObject].focused = false;
        item++;
      
        lines.push(
          <BorderedSection 
            icon={RuleIcon} 
            title={"Regla " + rules[lineObject].id.split(".")[0]}
            isCollepsible={true}
            remove={enabledTabsForm}
            handleRemoveClick={()=>{removeControlledPojo(pojo,`${lineObject}`)}}
            borderStyle="borderStyle2"
            customOption={true}
            customTitle="Aplicar"
            customIcon={SyncIcon}
            style={affectedFields.includes(`,${rules[lineObject].id},`)?{boxShadow: `rgb(218 22 61 / 25%) 0 0 0 2px`, borderRadius: 10}:null}
            customClick={(event)=>{
              applyRules(pojo.id,"",rules[lineObject].id);
            }}
            >
              {lineItems}
          </BorderedSection>
        );
      }
    }

    let rulesForm = []
    rulesForm.push(lines);

    return {
      pojoForm: rulesForm, 
      pojoFields: []
    };
  }

  const initializePojos = ()=>{
    const pojos = {
      pojos:[
        {
          id:"source",
          pojoType: "Origen",
          basePath:"services.0",
          pojoPath:"services.0.pojoObject",
          fieldPath:"services.0.pojo",
          originalData: control.getValues().services[0].pojo,
          controlled: ControlledPojo,
          app: Applications.filter(f=>f.id === control.getValues().idSource)[0]?.name,
          entityName: IntegrationTypes.filter(f=>f.id === control.getValues().idSourceIntegrationType)[0]?.name,
          openedForm: true,
          group: "request",
        },
        {
          id:"destination",
          pojoType: "Destino",
          basePath:"services.1",
          pojoPath:"services.1.pojoObject",
          fieldPath:"services.1.pojo",
          originalData: control.getValues().services[1].pojo,
          controlled: ControlledPojo,
          app: Applications.filter(f=>f.id === control.getValues().idDestination)[0]?.name,
          entityName: IntegrationTypes.filter(f=>f.id === control.getValues().idDestinationIntegrationType)[0]?.name,
          openedForm: true,
          group: "request"
        },
        {
          id:"rules",
          pojoType: "rule",
          basePath:"",
          pojoPath:"rulesObject",
          fieldPath:"rulesPojo",
          originalData: control.getValues().rulesPojo,
          controlled: ControlledRules,
          openedForm: true,
          group: "request"
        },
        {
          id:"resp:source",
          pojoType: "Destino",
          basePath:"services.0",
          pojoPath:"services.0.responsePojoObject",
          fieldPath:"services.0.responsePojo",
          originalData: control.getValues().services[0].responsePojo,
          controlled: ControlledPojo,
          app: Applications.filter(f=>f.id === control.getValues().idSource)[0]?.name,
          entityName: IntegrationTypes.filter(f=>f.id === control.getValues().idSourceIntegrationType)[0]?.name,
          openedForm: true,
          group: "response"
        },
        {
          id:"resp:destination",
          pojoType: "Origen",
          basePath:"services.1",
          pojoPath:"services.1.responsePojoObject",
          fieldPath:"services.1.responsePojo",
          originalData: control.getValues().services[1].responsePojo,
          controlled: ControlledPojo,
          app: Applications.filter(f=>f.id === control.getValues().idDestination)[0]?.name,
          entityName: IntegrationTypes.filter(f=>f.id === control.getValues().idDestinationIntegrationType)[0]?.name,
          openedForm: true,
          group: "response"
        },
        {
          id:"resp:rules",
          pojoType: "rule",
          basePath:"",
          pojoPath:"responseRulesObject",
          fieldPath:"responseRulesPojo",
          originalData: control.getValues().responseRulesPojo,
          controlled: ControlledRules,
          openedForm: true,
          group: "response"
        }
      ],
      getPojo: (id)=>{
        let pojo;
        pojos.pojos.filter(pojo => pojo.id === id).forEach((item)=>{
          pojo = item;
        });
        return pojo;
      }
    };

    let forms = [];
    pojos.pojos.forEach((pojo)=>{
      let pojoValue = control.getValues();
      pojo.fieldPath.split(".").forEach((field)=>{pojoValue = pojoValue[field];});
      Object.assign(pojo,{
        pojo:pojoValue,
        pojoObject:jsonParse(pojoValue),
      });
      forms.push({
        id:pojo.id,
        pojoType:pojo.pojoType,
        group:pojo.group,
        pojoForm: {
          pojoForm: (
            <BorderedSection 
              icon={EntityIcon} 
              title="Objecto" 
              style={{marginTop: "30px"}}
              >
            </BorderedSection>
          ), 
          pojoFields: []
        }
      });
    });

    setPojoForms(forms);

    setOpenedServiceForms(JSON.stringify(pojos.pojos.map(opened=>{
      return {
        id: opened.id,
        openedForm: opened.openedForm,
        group: opened.group,
        lg: 4
      }
    })));

    return pojos;
  }

  const setPojoForm = (pojoForm, id)=>{
    let forms = pojoForms?pojoForms:[{id:id}];
    forms.filter(form=> form.id === id).forEach((form)=>{
      form.pojoForm = pojoForm;
    });
    setPojoForms(forms);
  }

  const initialModalPojoFieldValues = {
    name:"", 
    id:"",
    type: "string",
    size: "",
    required: false,
    defaultValue: "",
    homologationName: "",
    parentFieldId: "",
    readOnly: false
  };

  const jsonParse = (value, initialValue = {}, callback)=>{
    let obj = initialValue;
    try{
      obj = JSON.parse(value);
    } catch (ex){
      if(callback)
        callback(ex);
    }
    return obj ?? initialValue;
  }

  const [fieldsDetails,setFieldsDetails] = useState(jsonParse(control.getValues().fieldsPojo, []));
  const [affectedFields,setAffectedFields] = useState("");
  const [pojoForms,setPojoForms] = useState();
  const [pojoFormsChanged,setPojoFormsChanged] = useState();
  const [pojos, setPojos] = useState();
  const [nextstep, setNextstep] = useState("request");
  const [recentlyOpenedOrClosedServiceForm, setRecentlyOpenedOrClosedServiceForm] = useState("");
  const [openedServiceForms, setOpenedServiceForms] = useState();
  const [open, setOpen] = useState(false);
  const [initialModalFormValues, setInitialModalFormValues] = useState(
    initialModalPojoFieldValues
  );
  const modalRef = useRef(null);

  useEffect(() => {
    if(recentlyOpenedOrClosedServiceForm){
      let openedForms = jsonParse(openedServiceForms);
      openedForms.filter(form=>form.id === recentlyOpenedOrClosedServiceForm.split("|")[0]).forEach((item)=>{
        item.openedForm = !item.openedForm;
        let openedlengthGroup = openedForms.filter(form=>form.group === item.group && form.openedForm).length;
        let lengthGroup = openedForms.filter(form=>form.group === item.group).length;
        openedForms.filter(form=>form.group === item.group).forEach((groupItem)=>{
          groupItem.lg = Math.floor((openedlengthGroup === lengthGroup ? 12 : openedlengthGroup === lengthGroup - (lengthGroup < 2 ? 0 : 2) ? 10 : 11) / openedlengthGroup);
        });
      });
      setOpenedServiceForms(JSON.stringify(openedForms));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentlyOpenedOrClosedServiceForm]);

  useEffect(() => {
    if(Applications && IntegrationTypes /*&& !pojos*/)
        setPojos(initializePojos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Applications, IntegrationTypes, record]);
  
  useEffect(() => {
    if(Applications && IntegrationTypes && pojos ){
      pojos.pojos.filter(p => !p.id.includes("rules")).forEach((pojo)=>{
        pojo.controlled = ControlledPojo;
        if(!pojo.setPojoForm)
          pojo.setPojoForm = setPojoForm;
        pojo.setPojoForm(pojo.controlled(pojo), pojo.id);
      });
      setPojoFormsChanged("refresh pojos");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Applications, enabledTabsForm, IntegrationTypes, pojos, affectedFields]);

  useEffect(() => {
    if(pojoForms && pojoFormsChanged && !pojoFormsChanged.includes("rules")){
      const updateFields = [];
      fieldsDetails.forEach((field)=>{
        if(pojoForms.filter(form=>!form.id.includes("rules") && form.pojoForm?.pojoFields.filter(f=>f.id === field.id).length).length){
          updateFields.push(field);
        }
      });
      if(updateFields.length !== fieldsDetails.length){
        setFieldsDetails(updateFields);
      }
      control.setValue("fieldsPojo",JSON.stringify(updateFields));      

      let forms = pojoForms.filter(form=>!form.id.includes("rules") && form.pojoForm?.pojoFields && form.pojoType === "Origen");
      if(forms.length){
        forms.forEach((form)=>{
          form.pojoForm.pojoFields.forEach((field)=>{
            let fieldControl = document.getElementById(field.id);
            if(fieldControl){
              if(!fieldControl.onclick){
                fieldControl.onclick = enabledTabsForm ? (event) => {
                  if(event.detail === 2){
                    addControlledRule(form.id.includes("resp:") ? "resp:rules":"rules",field.id);
                  }
                } : null;
              }
              if(!fieldControl.onblur){
                //fieldControl.onkeyup = enabledTabsForm ? () => {
                fieldControl.onblur = enabledTabsForm ? () => {
                  applyRules(form.id.includes("resp:") ? "resp:rules":"rules",field.id)
                } : null;
              }
            }
          });
        });
        pojos.pojos.forEach((pojo)=>{
          const elem = document.getElementById(pojo.fieldPath);
          if(!elem.onblur)
            elem.onblur = enabledTabsForm ? (event) => {renderPojoForm(event,pojo.id);} : null;
        });
        
        pojos.pojos.filter(p => p.id.includes("rules")).forEach((pojo)=>{
          pojo.controlled = ControlledRules;
          if(!pojo.setPojoForm)
            pojo.setPojoForm = setPojoForm;
          pojo.setPojoForm(pojo.controlled(pojo), pojo.id);
        });
        setPojoFormsChanged("Refresh rules");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pojoFormsChanged, enabledTabsForm]);

  const renderPojoForm = (event, pojoId) =>{
    pojos.pojos.filter(p=>p.id===pojoId).forEach((pojo)=>{
      if(event.currentTarget.value !== pojo.originalData){
        let pojoObjectOriginal = jsonParse(pojo.originalData);
        pojo.pojoObject = jsonParse(event.currentTarget.value);
        pojo.pojo = event.currentTarget.value;
        setObjectAsUndefined(pojo.pojoPath,pojoObjectOriginal);
        control.setValue(pojo.pojoPath, pojo.pojoObject);
        pojo.setPojoForm(pojo.controlled(pojo), pojo.id);
        pojo.originalData = event.currentTarget.value;
        setPojoFormsChanged("Render pojo " + pojoId);
      }
    });
  }

  const setObjectAsUndefined = (pojoName,pojoObjectOriginal) =>{
    if(typeof pojoObjectOriginal === "object" && Array.isArray(pojoObjectOriginal)){
      for(const item in pojoObjectOriginal){
        for(const field in pojoObjectOriginal[item]){
          if(typeof pojoObjectOriginal[item][field] === "object"){
            //setObjectAsUndefined(`${pojoName}.${item}.${field}`,pojoObjectOriginal[item][field])
            control.setValue(`${pojoName}.${item}.${field}`, undefined);
            control.unregister(`${pojoName}.${item}.${field}`);
          } else {
            control.setValue(`${pojoName}.${item}.${field}`, undefined);
            control.unregister(`${pojoName}.${item}.${field}`);
          }
        }
      }
    } else if(typeof pojoObjectOriginal === "object" && pojoObjectOriginal !== null){
      for(const field in pojoObjectOriginal){
        if(typeof pojoObjectOriginal[field] === "object"){
          //setObjectAsUndefined(`${pojoName}.${field}`, pojoObjectOriginal[field])
          control.setValue(`${pojoName}.${field}`, undefined);
          control.unregister(`${pojoName}.${field}`);
        } else {
          control.setValue(`${pojoName}.${field}`, undefined);
          control.unregister(`${pojoName}.${field}`);
        }
      }
    } else{
      control.setValue(`${pojoName}`, undefined);
      control.unregister(`${pojoName}`);
    }
  }

  const addControlledRule = (ruleId, sourceName="") =>{
    const pojo = pojos.getPojo(ruleId);
    let pojoObject = control.getValues();
    pojo.pojoPath.split(".").forEach((obj)=>{ pojoObject = pojoObject[obj]; });
    if(!Array.isArray(pojoObject))
      pojoObject = [];
    const pojoNew = {
      id:`${Math.max(...pojoObject.map((p) => p.id.split(".")[0]), 0) + 1}.${pojo.group}`,
      Accion:"1",
      Origen:sourceName,
      Destino:"",
      focused:true
    };
    pojoObject.push(pojoNew);
    control.setValue(pojo.pojoPath, pojoObject);
    pojo.pojoObject = pojoObject;
    pojo.setPojoForm(pojo.controlled(pojo), pojo.id);
    control.setValue(pojo.fieldPath, JSON.stringify(pojoObject));
    pojo.pojo = JSON.stringify(pojoObject);
    pojo.originalData = JSON.stringify(pojoObject);
    setPojoFormsChanged("Add rules: " + pojoNew.id);
  }

  const addObjectPojo = (parentFieldId, pojoId, isArray) =>{
    if(!isArray){
      const field = Object.assign({}, initialModalPojoFieldValues);
      field.pojoId = pojoId;
      field.parentFieldId = parentFieldId;
      setInitialModalFormValues(field);
      setOpen(true);
    } else {
      let pojoObjectField = control.getValues();
      parentFieldId.split(".").forEach((obj)=>{ pojoObjectField = pojoObjectField[obj]; });
      if(pojoObjectField.length === 0){
        pojoObjectField.push({
          campo1:""
        });
      } else {
        let newObjectField = {};
        for(const prop in pojoObjectField[0])
          newObjectField[prop] = "";
          pojoObjectField.push(newObjectField);
      }
      control.setValue(parentFieldId,pojoObjectField);
  
      const pojo = pojos.getPojo(pojoId);
      let pojoObject = control.getValues();
      pojo.pojoPath.split(".").forEach((obj)=>{ pojoObject = pojoObject[obj]; });
  
      if(pojo.setPojoForm && pojo.controlled){
        pojo.pojoObject = pojoObject;
        pojo.pojo = JSON.stringify(pojoObject);
        pojo.setPojoForm(pojo.controlled(pojo), pojo.id);
      }
      control.setValue(pojo.fieldPath, JSON.stringify(pojoObject));
      pojo.originalData = JSON.stringify(pojoObject);
      setPojoFormsChanged(`Edit pojoObject: ${pojo.id} field-> ${JSON.stringify(pojoObjectField)}`);
    }
  }

  const detailObjectPojo = (fieldId) =>{
    fieldsDetails.filter(f=>f.id === fieldId).forEach((field)=>{
        field.readOnly = true;
        setInitialModalFormValues(field);
        setOpen(true);
    });
  }

  const editObjectPojo = (fieldId, pojoId) =>{
    fieldsDetails.filter(f=>f.id === fieldId).forEach((field)=>{
        field.pojoId = pojoId;
        field.readOnly = false;
        setInitialModalFormValues(field);
        setOpen(true);
    });
  }

  const savePojo = (isEdit, values) =>{
    const pojo = pojos.getPojo(values.pojoId);
    if(isEdit){
      fieldsDetails.filter(f=>f.id === values.id).forEach((field)=>{
        for(const prop in field){
          field[prop] = values[prop];
        }
      });
      let fieldObject = control.getValues();
      let fieldName = "";
      let fieldPathToEdit = values.id;
      let fieldPathToEditValues = fieldPathToEdit.split(".");
      fieldPathToEditValues.forEach((obj)=>{
        fieldObject = fieldName === "" ? fieldObject : fieldObject[fieldName];
        fieldName = obj;
        });
      let fieldNameToUpdate = "";
      values.name.split(":")[1].split(".").forEach((obj)=>{ 
        fieldNameToUpdate = obj;
        });
      if(fieldName !== fieldNameToUpdate){
        setObjectAsUndefined(fieldPathToEdit,fieldObject[fieldName]);
        delete fieldObject[fieldName];
      }
      fieldObject[fieldNameToUpdate] = values.defaultValue;
      fieldPathToEditValues.pop();
      let parentfieldPath = fieldPathToEditValues.join(".");
      control.setValue(parentfieldPath,fieldObject);
    } else {
      fieldsDetails.push({
        id: `${values.parentFieldId}.${values.name}`,
        name: `${pojo.app}:${`${values.parentFieldId}.`.replace(`${pojo.pojoPath}.`,"")}${values.name}`,
        type: values.type,
        size: values.size,
        required: values.required,
        defaultValue: values.defaultValue,
        homologationName: values.homologationName
      });
      let fieldObject = control.getValues();
      let parentFieldPath = values.parentFieldId;
      parentFieldPath.split(".").forEach((obj)=>{
        fieldObject = fieldObject[obj];
      });
      fieldObject[values.name] = values.type === "list" ? [] : values.defaultValue;
      control.setValue(parentFieldPath,fieldObject);
    }

    let pojoObject = control.getValues();
    pojo.pojoPath.split(".").forEach((obj)=>{ pojoObject = pojoObject[obj]; });

    if(pojo.setPojoForm && pojo.controlled){
      pojo.pojoObject = pojoObject;
      pojo.pojo = JSON.stringify(pojoObject);
      pojo.setPojoForm(pojo.controlled(pojo), pojo.id);
    }
    control.setValue(pojo.fieldPath, JSON.stringify(pojoObject));
    pojo.originalData = JSON.stringify(pojoObject);
    setPojoFormsChanged(`Edit pojoObject: ${pojo.id} field-> ${JSON.stringify(values)}`);

    setInitialModalFormValues(initialModalPojoFieldValues);
    modalRef.current.restartModal();
    setOpen(false);
  }

  const handleClose = () => {
    setInitialModalFormValues(initialModalPojoFieldValues);
    modalRef.current.restartModal();
  };

  const syncPojoURL = (event) =>{
    
  }

  const removeControlledPojo = (pojo, fieldPathToTemove) =>{

    fieldPathToTemove = `${pojo.pojoPath}.${fieldPathToTemove}`;
    let value = control.getValues();
    let parentValue = control.getValues();
    let parentObj = "";
    let fields = fieldPathToTemove.split(".");
    fields.forEach((obj)=>{ 
      parentValue = parentObj === "" ? parentValue : parentValue[parentObj];
      value = value[obj];
      parentObj = obj;
    });
    fields.pop();
    let parentfieldNamePath = fields.join(".");

    if(Array.isArray(parentValue)){
      setObjectAsUndefined(parentfieldNamePath,parentValue);
      parentValue.splice(parentObj,1);
    } else {
      setObjectAsUndefined(fieldPathToTemove,value);
      delete parentValue[parentObj];
    }
    control.setValue(parentfieldNamePath,parentValue);

    let pojoObject = control.getValues();
    pojo.pojoPath.split(".").forEach((obj)=>{ pojoObject = pojoObject[obj]; });

    if(pojo.setPojoForm && pojo.controlled){
      pojo.pojoObject = pojoObject;
      pojo.pojo = JSON.stringify(pojoObject);
      pojo.setPojoForm(pojo.controlled(pojo), pojo.id);
    }
    control.setValue(pojo.fieldPath, JSON.stringify(pojoObject));
    pojo.originalData = JSON.stringify(pojoObject);
    setPojoFormsChanged(`Remove pojo: ${pojo.id} ${fieldPathToTemove}`);
  }

  const applyRules = (rulePojoId,sourceName,ruleId) =>{
    let rulesObject = control.getValues();
    pojos.getPojo(rulePojoId).pojoPath.split(".").forEach((obj)=>{ rulesObject = rulesObject[obj]; });
    let affected = "";
    rulesObject.filter(rule => ((rule.Origen === sourceName && sourceName) || rule.id === ruleId) && rule.Origen && rule.Destino).forEach((rule)=>{
      let value = control.getValues();
      rule.Origen.split(".").forEach((obj)=>{ value = value[obj]; });
      switch (rule.Accion) {
        case "1": //text
          control.setValue(rule.Destino, value);
          affected += `,${rule.Origen},${rule.Destino},${rule.id},`;
          break;
        case "2": //number
          
            break;
        case "3": //date
          
          break;
        default:
          break;
      }
    });
    setAffectedFields(affected);
  }

  return (
  <>
    <BorderedSection 
      id="request"
      icon={SourceServiceIcon} 
      title="Petición"
      isCollepsibleHorizontal={true}
      collepsibledHorizontal={!nextstep.includes("request")}
      isNextstep={true}
      iconNextstep={Nextstep1Icon}
      nextstpeTitle={"Paso 1"}
      setNextstep={setNextstep}
      borderStyle="borderStyle1"
      item xs={nextstep.includes("request")? 11 : 1}
      style={{maxHeight:"670px", minHeight:"670px"}}
      >
      <Grid container>  
        <BorderedSection 
          id="source"
          icon={SourceServiceIcon} 
          title="Origen" 
          subTitle={ServiceTypes.filter(st=>st.id === control.getValues().services[0]?.idServiceType)[0]?.name}
          isCollepsible={true}
          isCollepsibleHorizontal={true}
          setNextstep={setRecentlyOpenedOrClosedServiceForm}
          borderStyle="borderStyle1"
          style={{maxHeight: "570px",height:"100%"}}
          item lg={!openedServiceForms?4:jsonParse(openedServiceForms,[]).filter(opened=>opened.id === "source")[0].lg} md={10} sm={10} xs={12}
        >
          <ControlledSelect
            id="idSource"
            name="idSource"
            label="Aplicación"
            options={Applications}
            error={errors.idSource}
            helperText= {errors.idSource?.message}
            control={control}
            disabled={!enabledTabsForm}
            isSearchable={true}
          />
          <ControlledSelect
            id="idSourceIntegrationType"
            name="idSourceIntegrationType"
            label="Objecto"
            options={IntegrationTypes}
            error={errors.idIntegrationType}
            helperText= {errors.name?.message}
            control={control}
            disabled={!enabledTabsForm}
            isSearchable={true}
          />
          <ControlledInputRoundedForm
            id="services.0.name"
            name="services.0.name"
            inputProps={{ maxLength: 200 }}
            label="Servicio"
            fullWidth
            control={control}
            disabled={true}
          />
          {pojoFormsChanged ? (pojoForms?.filter(form=>form.id==="source")[0]?.pojoForm?.pojoForm):null}
        </BorderedSection>
        <BorderedSection 
          id="rules"
          icon={RulesIcon} 
          title="Reglas" 
          add={enabledTabsForm}
          isCollepsible={true}
          isCollepsibleHorizontal={true}
          setNextstep={setRecentlyOpenedOrClosedServiceForm}
          handleAddClick={()=>{addControlledRule("rules")}}
          borderStyle="borderStyle1"
          style={{maxHeight: "570px",height:"100%"}}
          item lg={!openedServiceForms?4:jsonParse(openedServiceForms,[]).filter(opened=>opened.id === "rules")[0].lg} md={10} sm={10} xs={12}
          >
            {pojoFormsChanged ? (pojoForms?.filter(form=>form.id==="rules")[0]?.pojoForm?.pojoForm):null}
        </BorderedSection>
        <BorderedSection 
          id="destination"
          icon={DestinationServiceIcon} 
          title="Destino"
          subTitle={ServiceTypes.filter(st=>st.id === control.getValues().services[1]?.idServiceType)[0]?.name}
          isCollepsible={true}
          isCollepsibleHorizontal={true}
          setNextstep={setRecentlyOpenedOrClosedServiceForm}
          borderStyle="borderStyle1"
          style={{maxHeight: "570px",height:"100%"}}
          item lg={!openedServiceForms?4:jsonParse(openedServiceForms,[]).filter(opened=>opened.id === "destination")[0].lg} md={10} sm={10} xs={12}
          >
          <ControlledSelect
            id="idDestination"
            name="idDestination"
            label="Aplicación"
            options={Applications}
            error={errors.idDestination}
            helperText= {errors.idDestination?.message}
            control={control}
            disabled={!enabledTabsForm}
            isSearchable={true}
          />
          <ControlledSelect
            id="idDestinationIntegrationType"
            name="idDestinationIntegrationType"
            label="Objecto"
            options={IntegrationTypes}
            error={errors.idDestinationIntegrationType}
            helperText= {errors.idDestinationIntegrationType?.message}
            control={control}
            disabled={!enabledTabsForm}
            isSearchable={true}
          />
          <ControlledInputRoundedForm
            id="services.1.name"
            name="services.1.name"
            inputProps={{ maxLength: 200 }}
            label="Servicio"
            fullWidth
            control={control}
            disabled={true}
          />
          {pojoFormsChanged ? (pojoForms?.filter(form=>form.id==="destination")[0]?.pojoForm?.pojoForm):null}
        </BorderedSection>
      </Grid>
    </BorderedSection>
    <BorderedSection 
      id="response"
      icon={DestinationServiceIcon} 
      title="Respuesta"
      isCollepsibleHorizontal={true}
      collepsibledHorizontal={!nextstep.includes("response")}
      isNextstep={true}
      iconNextstep={Nextstep2Icon}
      nextstpeTitle={"Paso 2"}
      setNextstep={setNextstep}
      borderStyle="borderStyle1"
      item xs={nextstep.includes("response")? 11 : 1}
      style={{maxHeight:"670px", minHeight:"670px"}}
      >
      <Grid container>
        <BorderedSection 
          id="resp:destination"
          icon={DestinationServiceIcon} 
          title="Resp:destino"
          subTitle={ServiceTypes.filter(st=>st.id === control.getValues().services[1]?.idServiceType)[0]?.name}
          isCollepsible={true}
          isCollepsibleHorizontal={true}
          setNextstep={setRecentlyOpenedOrClosedServiceForm}
          borderStyle="borderStyle1"
          style={{maxHeight: "570px",height:"100%"}}
          item lg={!openedServiceForms?4:jsonParse(openedServiceForms,[]).filter(opened=>opened.id === "resp:destination")[0].lg} md={10} sm={10} xs={12}
          >
          <ControlledInputRoundedForm
            id="destinationName"
            name="destinationName"
            inputProps={{ maxLength: 200 }}
            label="Aplicación"
            fullWidth
            control={control}
            disabled={true}
          />
          <ControlledInputRoundedForm
            id="destinationIntegrationTypeName"
            name="destinationIntegrationTypeName"
            inputProps={{ maxLength: 200 }}
            label="Objecto"
            fullWidth
            control={control}
            disabled={true}
          />
          <ControlledInputRoundedForm
            id="services.1.name"
            name="services.1.name"
            inputProps={{ maxLength: 200 }}
            label="Servicio"
            fullWidth
            control={control}
            disabled={true}
          />
          {pojoFormsChanged ? (pojoForms?.filter(form=>form.id==="resp:destination")[0]?.pojoForm?.pojoForm):null}
        </BorderedSection>
        <BorderedSection 
          id="resp:rules"
          icon={RulesIcon} 
          title="Resp:reglas"
          add={enabledTabsForm}
          isCollepsible={true}
          isCollepsibleHorizontal={true}
          setNextstep={setRecentlyOpenedOrClosedServiceForm}
          handleAddClick={()=>{addControlledRule("resp:rules")}}
          borderStyle="borderStyle1"
          style={{maxHeight: "570px",height:"100%"}}
          item lg={!openedServiceForms?4:jsonParse(openedServiceForms,[]).filter(opened=>opened.id === "resp:rules")[0].lg} md={10} sm={10} xs={12}
          >
            {pojoFormsChanged ? (pojoForms?.filter(form=>form.id==="resp:rules")[0]?.pojoForm?.pojoForm):null}
        </BorderedSection>
        <BorderedSection 
          id="resp:source"
          icon={SourceServiceIcon} 
          title="Resp:origen"
          subTitle={ServiceTypes.filter(st=>st.id === control.getValues().services[0]?.idServiceType)[0]?.name}
          isCollepsible={true}
          isCollepsibleHorizontal={true}
          setNextstep={setRecentlyOpenedOrClosedServiceForm}
          borderStyle="borderStyle1"
          style={{maxHeight: "570px",height:"100%"}}
          item lg={!openedServiceForms?4:jsonParse(openedServiceForms,[]).filter(opened=>opened.id === "resp:source")[0].lg} md={10} sm={10} xs={12}
        >
          <ControlledInputRoundedForm
            id="sourceName"
            name="sourceName"
            inputProps={{ maxLength: 200 }}
            label="Aplicación"
            fullWidth
            control={control}
            disabled={true}
          />
          <ControlledInputRoundedForm
            id="sourceIntegrationTypeName"
            name="sourceIntegrationTypeName"
            inputProps={{ maxLength: 200 }}
            label="Objecto"
            fullWidth
            control={control}
            disabled={true}
          />
          <ControlledInputRoundedForm
            id="services.0.name"
            name="services.0.name"
            inputProps={{ maxLength: 200 }}
            label="Servicio"
            fullWidth
            control={control}
            disabled={true}
          />
          {pojoFormsChanged ? (pojoForms?.filter(form=>form.id==="resp:source")[0]?.pojoForm?.pojoForm):null}
        </BorderedSection>
      </Grid>
    </BorderedSection>
    <BorderedSection 
      icon={EntityIcon} 
      title="Metadata" 
      isCollepsible={true}
      Collapsed={true}
      borderStyle="borderStyle1"
      item xs={11}
      >
      <Grid container>
        <BorderedSection 
          icon={SourceServiceIcon} 
          title="Origen"
          isCollepsible={true}
          borderStyle="borderStyle2"
          item lg={6} xs={12}
          >
          <ControlledInputRoundedForm
            id="services.0.metadataServiceURL"
            name="services.0.metadataServiceURL"
            inputProps={{ maxLength: 200 }}
            label="URL de metadata del servicio"
            fullWidth
            control={control}
            //pattern={/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/g}
            disabled={!enabledTabsForm}
            error={errors.metadataServiceURL}
            helperText={errors.metadataServiceURL?.message}
            isCustomizable={enabledTabsForm}
            customOption={enabledTabsForm}
            customTitle="Sincronizar"
            customIcon={SyncIcon}
          />
          <ControlledInputRoundedForm
            id="services.0.filter"
            name="services.0.filter"
            inputProps={{ maxLength: 200 }}
            label="Filtro"
            fullWidth
            control={control}
            pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
            disabled={!enabledTabsForm}
            error={errors.filter}
            helperText={errors.filter?.message}
          />
          <ControlledInputRoundedForm
              id="services.0.pojo"
              name="services.0.pojo"
              label="Pojo (json)"
              multiline
              rows={5}
              inputProps={{ maxLength: 20000 }}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
            />
          <ControlledInputRoundedForm
              id="services.0.responsePojo"
              name="services.0.responsePojo"
              label="Pojo respuesta (json)"
              multiline
              rows={5}
              inputProps={{ maxLength: 20000 }}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
          />
        </BorderedSection>
        <BorderedSection 
          icon={DestinationServiceIcon} 
          title="Destino" 
          isCollepsible={true}
          borderStyle="borderStyle2"
          item lg={6} xs={12}
          >
          <ControlledInputRoundedForm
            id="services.1.metadataServiceURL"
            name="services.1.metadataServiceURL"
            inputProps={{ maxLength: 200 }}
            label="URL de metadata del servicio"
            fullWidth
            control={control}
            //pattern={/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/g}
            disabled={!enabledTabsForm}
            error={errors.metadataServiceURL}
            helperText={errors.metadataServiceURL?.message}
            isCustomizable={enabledTabsForm}
            customOption={enabledTabsForm}
            customTitle="Sincronizar"
            customIcon={SyncIcon}
            handleCustomClick={syncPojoURL}
          />
          <ControlledInputRoundedForm
            id="services.1.filter"
            name="services.1.filter"
            inputProps={{ maxLength: 200 }}
            label="Filtro"
            fullWidth
            control={control}
            pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
            disabled={!enabledTabsForm}
            error={errors.filter}
            helperText={errors.filter?.message}
          />
          <ControlledInputRoundedForm
              id="services.1.pojo"
              name="services.1.pojo"
              label="Pojo (json)"
              multiline
              rows={5}
              inputProps={{ maxLength: 20000 }}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
          />
          <ControlledInputRoundedForm
              id="services.1.responsePojo"
              name="services.1.responsePojo"
              label="Pojo respuesta (json)"
              multiline
              rows={5}
              inputProps={{ maxLength: 20000 }}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
          />
        </BorderedSection>
        <ControlledInputRoundedForm
              id="rulesPojo"
              name="rulesPojo"
              label="Estructura de reglas"
              multiline
              rows={5}
              inputProps={{ maxLength: 20000 }}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
              //style={{display:"none"}}
            />
        <ControlledInputRoundedForm
              id="responseRulesPojo"
              name="responseRulesPojo"
              label="Estructura de reglas de la respuesta"
              multiline
              rows={5}
              inputProps={{ maxLength: 20000 }}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
              //style={{display:"none"}}
            />
      </Grid>
    </BorderedSection>
    <ModalForm
      initialValues={initialModalFormValues}
      ref={modalRef}
      open={open}
      setOpen={setOpen}
      handleSave={savePojo}
      handleClose={handleClose}
      homologation={control.getValues().homologations}
    />
  </>
  );
}

export default ConfigurationForm;