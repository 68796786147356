import React from "react";

export const reactValidatorOptions = {
  element: (message) => (
    <span className="font-validator-red float-left">{message}</span>
  ),
  validators: {
    password: {
      // name the rule
      message: `La contraseña no cumple con el nivel mínimo de seguridad requerido.`,
      rule: (val, params, validator) => {
        //regex params (1 number)(1 a-z)(1 A-Z)(1 special char)(not contain special chars(space))
        return validator.helpers.testRegex(
          val,
          /^((?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?!.*?[ ])).+$/i
        );
      },
      required: true,
    },
    username: {
      message: "Campo obligatorio",
      rule: (val) => {
        return val !== "";
      },
      required: true,
    },
    privilege: {
      // name the rule
      message: `El campo no puede contener espacios ni caracteres especiales, en caso de necesitarlos use guion bajo _`,
      rule: (val, params, validator) => {
        return (
          validator.helpers.testRegex(val, /^([A-Ñ0-9\\S]*)$/i) &&
          params.indexOf(val) === -1
        );
      },
      required: true, // optional
    },
    role: {
      //name the rule
      message: "Campo obligatorio",
      rule: (val, params, validator) => {
        return val !== "";
      },
      required: true,
    },
  },
  messages: {
    required: "Campo obligatorio.",
    email: "El correo no posee el formato correcto.",
    min: "Debe tener al menos :min caracteres.",
    max: "Debe tener máximo :max caracteres.",
    numeric: ":attribute debe ser un número.",
    privilege:
      "El campo no puede contener espacios ni caracteres especiales, en caso de necesitarlos use guion bajo _",
    role: "Campo obligatorio.",
    username: "Campo obligatorio.",
  },
  locale: "en",
};
