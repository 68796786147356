import React from 'react';

export const textToSpan = (text, classes) => {
    return (
        <span className={classes.titleTree}>{text}</span>
    )
}

export const jsonParse = (value, initialValue = {}, callback)=>{
    let obj = initialValue;
    try{
        obj = JSON.parse(value);
    } catch (ex){
        if(callback)
        callback(ex);
    }
    return obj ?? initialValue;
}

export const jsonStringify = (value, replacer, space, initialValue = "", callback)=>{
    let obj = initialValue;
    try{
        obj = JSON.stringify(value, replacer, space);
    } catch (ex){
        if(callback)
        callback(ex);
    }
    return obj ?? initialValue;
}

export const generateString = (length, onlyNumber) => {
    let characters = "";
    if (onlyNumber) {
      characters = "0123456789";
    } else {
      characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    }
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
  };