
import {
    GET_ENTERPRISE,
    GET_ENTERPRISEBYID,
    GET_ENTERPRISES,
    GET_ENTERPRISE_CHANGED,
    SET_ENTERPRISE_ERROR,
  } from "../actions/types";

//constantes
const initialState = {
    getEnterpriseResponse : [],
    getEnterpriseByIDResponse : {},
    getEnterprisesResponse: [],
    setEnterpriseErrorResponse: [],
    getEnterpriseChangedResponse: []
}

export default function enterpriseReducer (state = initialState, action){
    switch(action.type){
        case GET_ENTERPRISE:
            return {
                ...state,
                getEnterpriseResponse: action.payload
            }
        case GET_ENTERPRISEBYID:
            return {
                ...state,
                getEnterpriseByIDResponse: action.payload
            }
        case GET_ENTERPRISES:
        return {
            ...state,
            getEnterprisesResponse: action.payload,
        };
        case GET_ENTERPRISE_CHANGED:
        return {
            ...state,
            getEnterpriseChangedResponse: action.payload,
        };
        case SET_ENTERPRISE_ERROR:
        return {
            ...state,
            setEnterpriseErrorResponse: action.payload,
        };
        default:
            return state

    }
}