import React, { useEffect, useState } from "react";
import imgDownloadIcon from "../../assets/img/descargarIcon.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Menu,
  MenuItem,
  Grid,
  useTheme,
  Dialog,
  withStyles,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useSnackbar } from "notistack";
import Modal from "../../views/sign/QuickSignature/Modal";
import ModalAdvancedSignature from "../../views/sign/AdvancedSignature/ModalAdvancedSignature";
//import ModalGetFIleCloud from "../../views/ConsultSignedDocument/ModalGetFIleCloud";
import {
  estamparAction,
  estamparActionS3,
  reQuickStamp,
} from "../../actions/SignAction";
import Loading from "./Loading";
import { isEmpty } from "../../utils/proprietaryHooks";

const useToolbarStyles = makeStyles((theme) => ({
  iconButtonDownload: {
    borderRadius: "5px",
    backgroundImage: `url(${imgDownloadIcon}) `,
    backgroundPosition: "center",
    width: "28px !important",
    height: "28px !important",
  },
}));

const ButtonsSign = (props) => {
  const {
    style,
    responsiveMode,
    handleClickDownload,
    files,
    setFiles,
    isP7,
    filesSize,
    history,
    estampar,
    estamparS3,
    getUrldocuments,
    rows,
    selected,
    loading,
    refresh,
    isUniqueElectronic,
    sizeFiles,
    signers,
    control,
    handleSubmit,
    formState,
    trigger,
    transactionId
  } = props;
  const classes = useToolbarStyles();
  const [statusModalAdvanced, setStatusModalAdvanced] = useState(false);
  const [statusModalCloudSelect, setStatusModalCloudSelect] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(window.innerWidth <= 1200 ? "220px" : "180px");
  const [statusModal, setStatusModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [infoModal, setInfoModal] = useState({});
  const [imageSignature, setImageSignature] = useState([]);
  const [finishedProcess, setFinishedProcess] = useState(false);
  const [s3Limit] = useState(4);
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  window.addEventListener('resize', function (event) {
    const size = document.getElementById("buttonSign");
    if (size != null) setButtonWidth(document.getElementById("buttonSign").getBoundingClientRect().width);
  }, true);

  const handleClickItem = (ltv, estampa) => {
    handleClose();
    setInfoModal({
      ltv: Boolean(ltv),
      estampa: Boolean(estampa),
      files,
    });
    setStatusModal(true);
  };

  useEffect(() => {
    if(!files.length){
      setFinishedProcess(false);
    }
  },[files]);

  const onClose = (type, finish) => {
    setStatusModalAdvanced(false);
    setStatusModal(false);
    signers.forEach((signer)=>{
      signer.checkSign = false;
    });
    if (refresh != undefined) refresh(type);
    if(finish){
      const sign = signers.filter(s=>s.order === "1")[0];
      setFinishedProcess(finish);
      enqueueSnackbar(`Se ha enviado un correo al primer firmante "${sign.name} - ${sign.email}", el cual debe dar inicio al cilo de firmas.`, {
				variant: 'info',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
    }
  };

  const handleEstampar = () => {
    const data = {
      documentos: files,
    };
    if (!style) {
      if (filesSize > s3Limit * 1024) {
        estamparS3(data, enqueueSnackbar, history);
      } else {
        estampar(data, enqueueSnackbar, history);
      }
    } else {
      let selectFiles = [];
      selected.map((sel) => {
        let file = rows.find((x) => x.id === sel);
        selectFiles.push(file);
      });
      getUrldocuments(selectFiles, enqueueSnackbar, history, onClose);
    }

    handleClose();
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1.25px solid #9D9D9C",
      boxShadow: "0 3px 3px 1px #00000033",
      width: style ? "" : buttonWidth,
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ));

  const handleSetSigners = async(data)=> {
    await trigger();
    if(isEmpty(formState.errors)){
      const signersForm = control.getValues().signers;
      for (let index = 0; index < signers.length; index++) {
        signers[index].name = signersForm[index].name;
        signers[index].email = signersForm[index].email;
        signers[index].signatureType = signersForm[index].signatureType;
      }
      setStatusModalAdvanced(true);
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <div>
        <Grid
          container
          justify="flex-start"
          direction="row"
          spacing={!style ? 2 : 1}
          className="gridButtons"
        >
          {/* <Grid item>
            <Button
              disabled={!style && !files.length}
              id="buttonSign"
              className={!style ? "btnGeneralSignIcon" : "btnQuick"}
              onClick={handleClick}
            >
              {" "}
              Firma rápida{" "}
              <ArrowDropDownIcon
                style={{ marginLeft: style ? "0px" : "10px" }}
              />
            </Button>
          </Grid> */}
          <Grid item>
            <Button
              disabled={(!style && (!files.length || isP7 > 0)) || isP7 > 0 || finishedProcess}
              className={!style ? "btnGeneralSign" : "btnDocuments"}
              onClick={(ev) => {
                if(handleSubmit){
                  handleSubmit(handleSetSigners(ev));
                } else {
                  handleSetSigners(ev);
                }
              }}
            >
              {" "}
              Configurar firmas{" "}
            </Button>
          </Grid>
          {/* {responsiveMode && (
            <Grid item>
              <Button
                className={"btnDocuments"}
                onClick={handleClickDownload}
                startIcon={
                  <IconButton
                    className={classes.iconButtonDownload}
                    data-title="Descargar"
                  />
                }
              >
                Descargar
              </Button>
            </Grid>
          )} */}
        </Grid>
        {/* <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={"menu-signs-type"}
        >
          <MenuItem onClick={() => handleClickItem(false, false)}>
            {" "}
            Firmar{" "}
          </MenuItem>
          <MenuItem disabled={isP7 > 0 || isUniqueElectronic} onClick={() => handleEstampar()}>
            {" "}
            Estampar{" "}
          </MenuItem>
          <MenuItem
            disabled={isP7 > 0 || isUniqueElectronic}
            onClick={() => handleClickItem(false, true)}
          >
            {" "}
            Firmar y estampar{" "}
          </MenuItem>
        </StyledMenu> */}
        <Dialog
          open={statusModalAdvanced}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ModalAdvancedSignature
            isOpen={statusModalAdvanced}
            onClose={onClose}
            files={files}
            filesSize={sizeFiles}
            style={style}
            rows={rows}
            selected={selected}
            setFiles={setFiles}
            imageSignature={imageSignature}
            setImageSignature={setImageSignature}
            signers={signers}
            transactionId={transactionId}
          />
        </Dialog>
        {statusModal == true && (
          <Modal
            isOpen={statusModal}
            onClose={onClose}
            info={infoModal}
            filesSize={sizeFiles}
            isP7={isP7}
            style={style}
            rows={rows}
            selected={selected}
            setStatusModal={setStatusModal}
          />
        )}
        {/*statusModalCloudSelect && (
          <ModalGetFIleCloud
            isOpen={statusModalCloudSelect}
            onClose={() => setStatusModalCloudSelect(false)}
            setStatusModalCloudSelect={setStatusModalCloudSelect}
            statusModalCloudSelect={statusModalCloudSelect}
            files={files}
            setFiles={setFiles}
            sizeFiles={sizeFiles}
          />
        )*/}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.sign.loading,
  isUniqueElectronic: state.sign.isUniqueElectronic,
});

const mapDispatchToProps = {
  estampar: estamparAction,
  estamparS3: estamparActionS3,
  getUrldocuments: reQuickStamp,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ButtonsSign)
);
