import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginUser } from "../../actions/auth.action";
import FullLoader from "../../components/Loader/FullLoader.component";
import PageBackground from "../../components/pageBackground.component";
import "./login.css";
// import vinkel from "../../assets/icons/ico_vinkel.png";
// import colfactura from "../../assets/icons/ico_colfactura.png";
// import firmaEmail from "../../assets/icons/ico_firmaemail.png";
// import firmaYa from "../../assets/icons/ico_firmaya.png";
// import cronoFirma from "../../assets/icons/ico_cronofirma.png";
// import firmaCentral from "../../assets/icons/ico_firmacentral.png";

const useStyles = makeStyles((theme) => ({
    root: {
        // width:'500px',
        maxWidth: "700px",
        // height: '350px',
        maxHeight: "350px",
        padding: "70px",
        borderRadius: "10px",
        backgroundColor: "#F7F7F7",
    },
    title: {
        fontSize: "18px",
    },
    titleExtra: {
        color: "#E45501",
        marginBottom: "28px",
    },
    cardApp: {
        width: "92px",
        height: "92px",
        cursor: "pointer",
        borderRadius: "10px",
        shadows: "0px 3px 6px #00000029",
        marginBottom: "10px",
        // marginLeft: '5px',
        // marginRight: '5px',
        border: "1px solid transparent",
        "&:hover": {
            border: "1px solid #009BDD",
        },
    },
    image: {
        width: "80px",
        height: "80px",
        padding: "5px",
        objectFit: "contain",
    },
}));

export const AvailableApps = ({ ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectorAuth = useSelector(state => state.authReducer.getAuthenticationResponse)
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!!selectorAuth) {
            if (!!selectorAuth.url && selectorAuth.url !== "") {
                window.open(selectorAuth.url, "_blank");
                setIsLoading(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectorAuth]);

    const handleClick = (app) => {
        if (app.mechanismAfterSetPass === 1) {
            window.open(app.landing, "_blank");
        } else {
            setIsLoading(true);
            dispatch(
                loginUser({ ...props.location.state.userData, appname: app.name }, history)
            )
        }
    };
    return (
        <div className="login">
            <PageBackground></PageBackground>
            {
                <Card className={classes.root}>
                    {isLoading ? <FullLoader
                        open={isLoading}
                        handleClose={() => { }}
                    /> : null}
                    <Grid container lg={12} justify="center">
                        <Typography className={[classes.title, classes.titleExtra]}>{props.location.state.message}</Typography>
                        <Typography className={classes.title}>Recuerda que con tu usuario y contraseña, puedes</Typography>
                        <Typography className={classes.title}>ingresar a las siguientes aplicaciones, haz clic en la que desees:</Typography>
                        <Grid lg={10} container justify="space-evenly" alignItems="stretch" style={{ marginTop: "40px" }}>
                            {props.location.state.apps.map((app) => {
                                return (
                                    <Card onClick={() => handleClick(app)} className={classes.cardApp} key={app.id}>
                                        <img className={classes.image} src={`icons/ico_${app.name.toLowerCase()}.png`} alt={app.title} title={app.title}></img>
                                    </Card>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Card>
            }
        </div>
    );
};
