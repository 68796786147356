import {
  GET_SERVICE,
  GET_SERVICES,
  SET_SERVICE_ERROR,
  GET_SERVICE_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * Services Action Services
 *
 * @method GET
 */
  const getServices = () => async (dispatch) => {
    dispatch(getRecords("service", GET_SERVICES, SET_SERVICE_ERROR));
  };

export /**
 * Services Action getServices
 *
 * @method GET
 * @param customerid
 */
  const getServicesByCustomer = (customerid, parentId) => async (dispatch) => {
    dispatch(getRecordsByCustomer("service", customerid, GET_SERVICES, SET_SERVICE_ERROR, 1, "", "Integration.IdCustomer"
      , parentId ? [{fieldName: "IdIntegration", fieldValue: parentId}] : []
      )
    );
  };

export /**
 * Service Action getServiceById
 *
 * @method GET
 * @param {*} id
 */
  const getServiceById = (id) => async (dispatch) => {
    dispatch(getRecordById("service", id, GET_SERVICE, SET_SERVICE_ERROR));
  };

export /**
  * Service Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createService = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord("service", recordToCreate, updateChecked, GET_SERVICE_CHANGED, SET_SERVICE_ERROR));
 };
 

export /**
 * Service Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyService = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("service", recordToUpdate, updateChecked, isPrincipalPage, GET_SERVICE_CHANGED, SET_SERVICE_ERROR));  
};
