import { UseDialog } from "@pif-tr-components/components";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppsForMenu } from "../../actions/applications.action";
import { useTree } from "../../hooks/useTree";
import { isEmpty } from "../../utils/proprietaryHooks";
import { MenuSettingsComponent } from "./MenuSettingsComponent";

export const MenuSettingsContainer = forwardRef((props, ref) => {
  const {
    saveMenu,
    applications,
    setApplications,
    currentApp,
    setCurrentApp,
    setOpen,
    setInitialFormValues,
    menu,
    children,
    valuesTitle,
  } = props;
  const {
    currentMenu,
    // setCurrentMenu 
  } = menu;
  const dispatch = useDispatch();

  //#region selectorsRegion
  const applicationsResponse = useSelector(({ applicationsReducer }) => {
    return applicationsReducer.getApplicationsMenuResponse;
  });

  const customAction = {
    delete: "Delete",
    edit: "Edit",
    state: "State",
  };
  //#endregion

  const [dialog, setDialog] = React.useState({
    bodyText: "",
    cancelText: "",
    acceptText: "",
    action: "",
  });

  /**
   * handle action when one button is clicked on item
   * @param {string} key
   * @param {string} action
   * @param {object} section
   * @param {MouseEvent} event
   */
  const itemAction = (key, action, section, event) => {
    if (action === customAction.delete) {
      setDialog({
        bodyText: "¿Deseas eliminar este ítem?",
        cancelText: "No",
        acceptText: "Si",
        action: key,
      });
      onAcceptOpen();
    } else if (action === customAction.edit) {
      setInitialFormValues(section);
      setOpen(true);
    } else if (action === customAction.state) {

      const dataComplete = values.menu1.length === 0 ? currentMenu : values.menu1

      const menuComplete = updateState(dataComplete, key, event.target.checked)

      saveMenu(true, menuComplete)
    }
  };

  const handleConfirmClick = () => {
    onAcceptClose();
    const newData = searchItemUpdateAndDelete(dialog.action, values.menu1, "Delete");
    saveMenu(true, newData, "El ítem ha sido eliminado");
  };

  const {
    Dialog: AcceptDialog,
    onOpen: onAcceptOpen,
    onClose: onAcceptClose,
  } = UseDialog({
    bodyText: dialog.bodyText,
    cancelButtonText: dialog.cancelText,
    confirmationButtonText: dialog.acceptText,
    onConfirmClick: handleConfirmClick,
  });

  const { values, addItemMenu, addInitialDataMenu } = useTree(itemAction);

  //#region
  useEffect(() => {
    if (!applicationsResponse.length) {
      dispatch(getAppsForMenu());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!applications.length && applicationsResponse.length) {
      setApplications(applicationsResponse);
    }
  }, [applications.length, applicationsResponse, setApplications]);

  useEffect(() => {
    if (!isEmpty(currentMenu) && !values.menu1.length) {
      addInitialDataMenu(currentMenu);
    }
  }, [addInitialDataMenu, currentMenu, values.menu1.length]);

  //#endregion

  useImperativeHandle(
    ref,
    () => ({
      addItemMenu,
      addInitialDataMenu,
      values,
      searchItemUpdateAndDelete,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  /**
   *
   * @param {*} key  clave del item a actualizar
   * @param {*} initialData  arbol completo
   * @param {*} action   editar o eliminar
   * @param {*} newData  item que se está actualizando
   * @returns
   */
  const searchItemUpdateAndDelete = (key, initialData, action, newData) => {
    let dataResponse = [];
    for (const dat of initialData) {
      let dataChildrenResponse = [];
      if (dat.children !== undefined && dat.children.length > 0) {
        // busco item y lo remuevo de la lista
        if (action === "Delete") {
          const haveItem = dat.children.find((item) => item.key === key);
          if (haveItem) {
            dat.children = dat.children.filter((item) => item.key !== key);
          }
        }
        dataChildrenResponse = searchItemUpdateAndDelete(
          key,
          dat.children,
          action,
          newData
        );
      }
      if (action === "Delete") {
        if (dat.key !== key) {
          if (dataChildrenResponse.length > 0) {
            dataResponse.push({ ...dat, children: dataChildrenResponse });
          } else {
            dataResponse.push(dat);
          }
        }
      } else {
        if (dat.key === key) {
          if (dataChildrenResponse.length > 0) {
            dataResponse.push({ ...newData, children: dataChildrenResponse });
          } else {
            dataResponse.push({ ...newData });
          }
        } else {
          if (dataChildrenResponse.length > 0) {
            dataResponse.push({ ...dat, children: dataChildrenResponse });
          } else {
            dataResponse.push(dat);
          }
        }
      }
    }
    return dataResponse;
  };

  const updateState = (data, key, value) => {
    let dataResponse = [];
    for (const item of data) {
      let dataChildrenResponse = []

      if (item.children !== undefined && item.children.length > 0) {
        dataChildrenResponse = updateState(item.children, key, value);
      }

      if (item.key === key) {
        item.isActivate = value
      }

      if (dataChildrenResponse.length > 0) {
        dataResponse.push({ ...item, children: dataChildrenResponse });
      } else {
        dataResponse.push({ ...item });
      }
    }

    return dataResponse;
  }

  return (
    <div>
      <MenuSettingsComponent
        applications={applications}
        currentApp={currentApp}
        values={values}
        addInitialDataMenu={addInitialDataMenu}
        saveMenu={saveMenu}
        setCurrentApp={setCurrentApp}
        setOpen={setOpen}
        children={children}
        valuesTitle={valuesTitle}
      />
      <AcceptDialog />
    </div>
  );
});
