import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { Box, Button, Grid, Paper } from '@material-ui/core';
import svgTrash from '../../assets/img/trash.svg';
import { connect } from 'react-redux';
import { getManuscritaByUser, deleteManuscritaAction } from '../../actions/SignAction';
import { useSnackbar } from 'notistack';
import imgLoader from '../../assets/img/loader.gif';
import addSign from '../../assets/img/add-button.png';

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'relative',
		background: '#F3F3F3 0% 0% no-repeat padding-box',
		boxShadow: '0px 2px 4px #00000040',
		borderRadius: '5px',
		width: '220px',
		height: '123px',
		margin: 'auto',
	},
	icon: {
		width: 20,
		height: 20,
	},
	messageContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		textAlign: 'center',
	},
	selected: {
		position: 'relative',
		top: '0',
		backgroundColor: "#00000099",
		width: '220px',
		height: '123px',
		borderRadius: '5px',
		opacity: 1,
	},
}));

const Sign = (props) => {

	const { getSignManuscrita, showComplete, listManuscrita, deleteManuscrita, selectSign, actionAdd, clear, setBase64 } = props;
	const [loading, setLoading] = useState(true);
	const [checked, setChecked] = useState(true);
	const [selected, setSelected] = useState(null);
	const [base64Selected, setBase64Selected] = useState(null);
	const [isAgregar, setAgregar] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		(async () => {
			if (isAgregar == false) {
				await getSignManuscrita(enqueueSnackbar);
				setLoading(false);
			}
		})()
	}, [isAgregar]);

	const handleChecked = async (base64, id) => {
		setSelected(selected == null ? id : null);
		setBase64Selected(selected == null ? base64 : null);
		if (window.innerWidth && window.innerWidth < 991) {
			listManuscrita.map(sign => {
				if (sign.id != id) {
					if (document.getElementById('sel-' + sign.id).style.visibility == 'hidden') {
						document.getElementById('sel-' + sign.id).style.visibility = 'visible';
					} else {
						document.getElementById('sel-' + sign.id).style.visibility = 'hidden';
					}
				}
			});
		} else {
			setTimeout(() => {
				listManuscrita.map(sign => {
					if (sign.id != id) {
						document.getElementById('sel-' + sign.id).style.visibility = 'hidden';
						document.getElementById('check-' + sign.id).style.visibility = 'hidden';
					}
				})
			}, 10);
			setTimeout(() => {
				checked ? setChecked(false) : setChecked(true);
				if (checked) {
					document.getElementById('seleccionada-' + id).style.backgroundColor = "#00000099";
					document.getElementById('check-' + id).style.visibility = 'hidden';
					document.getElementById('label-' + id).style.visibility = 'visible';
					document.getElementById("imageSign-" + id).style.opacity = "0.3";
				} else {
					document.getElementById('seleccionada-' + id).style.backgroundColor = "transparent";
					document.getElementById('check-' + id).style.visibility = 'visible';
					document.getElementById('label-' + id).style.visibility = 'hidden';
				}
			}, 100);
			setTimeout(() => {
				selectSign('data:image/png;base64,' + base64, id);
				//setBase64('data:image/png;base64,' + base64);
			}, 3000);
		}
	}

	const handleAplicar = async () => {
		setTimeout(() => {
			listManuscrita.map(sign => {
				if (sign.id != selected) {
					document.getElementById('sel-' + sign.id).style.visibility = 'hidden';
					document.getElementById('check-' + sign.id).style.visibility = 'hidden';
				}
			})
		}, 10);
		setTimeout(() => {
			checked ? setChecked(false) : setChecked(true);
			if (checked) {
				document.getElementById('seleccionada-' + selected).style.backgroundColor = "#00000099";
				document.getElementById('check-' + selected).style.visibility = 'hidden';
				document.getElementById('label-' + selected).style.visibility = 'visible';
				document.getElementById("imageSign-" + selected).style.opacity = "0.3";
			} else {
				document.getElementById('seleccionada-' + selected).style.backgroundColor = "transparent";
				document.getElementById('check-' + selected).style.visibility = 'visible';
				document.getElementById('label-' + selected).style.visibility = 'hidden';
			}
		}, 100);
		setTimeout(() => {
			selectSign('data:image/png;base64,' + base64Selected, selected);
		}, 3000);
	}

	const deleteSign = async (id) => {
		setAgregar(true);
		setSelected(null);
		setTimeout(() => {
			document.getElementById('sel-' + id).style.visibility = 'hidden';
			document.getElementById('seleccionada-' + id).style.backgroundColor = "#00000099";
			document.getElementById('check-' + id).style.visibility = 'hidden';
			document.getElementById('eliminada-' + id).style.visibility = 'visible';
		}, 10);
		const deleteimage = {
			"id": parseInt(id),
			"base64": "",
			"predeterminado": 1
		}
		setTimeout(() => {
			setLoading(true);
			setAgregar(false);
		}, 1000);
		await deleteManuscrita(deleteimage, enqueueSnackbar);
		await getSignManuscrita(enqueueSnackbar);
		clear(id);
	}

	const classes = useStyles();

	return (
		<>
			{loading ? <img src={imgLoader} style={{ marginTop:"-24px", width: "100%" }} /> :
				<div>
					<Box className="box-list-signs">
						<Grid container justify="center" alignItems="center" spacing={3}>
							{listManuscrita.length ?
								listManuscrita.map((firma, index) =>
									<Grid item xs={listManuscrita.length == 1 ? null : 6} key={index} className="grid-sign">
										<Paper name={"sign-" + firma.id} className="paper-sign">
											<div id={"seleccionada-" + firma.id} className="seleccionada" >
												<label id={'label-' + firma.id} className="label-sign">Firma seleccionada</label>
												<label id={'eliminada-' + firma.id} className="label-sign">Firma eliminada</label>
												<Grid container justify="flex-end" alignItems="flex-end" >
													<label className="checkbox-label" id={'sel-' + firma.id}>
														<input
															className="checkbox-input"
															type="checkbox"
															name={firma.id}
															onChange={(e) => handleChecked(firma.base64, firma.id)}
														/>
														<span className="checkbox-custom"></span>
													</label>
												</Grid>
												<Grid container justify="center" alignItems="center" className="sign-container">
													<img id={'imageSign-' + firma.id} src={'data:image/png;base64,' + firma.base64} />
												</Grid>
												<Grid id={"check-" + firma.id} style={{ visibility: firma.id == selected || window.innerWidth > 991 ? 'visible' : 'hidden' }} container justify="flex-end" alignItems="flex-start">
													<IconButton
														id="icon-delete"
														name={firma.id}
														onClick={(e) => deleteSign(firma.id)}
														className={classes.icon}
													>
														<img src={svgTrash} />
													</IconButton>
												</Grid>
											</div>
										</Paper>
									</Grid>
								)
								:
								<Grid container justify="center" className={classes.messageContainer} >
									<Grid item xs={12} className={'message'}>
										<p>No tienes firmas añadidas, para añadir una firma debes crearla en la opción firmar.</p>
										<IconButton
											style={{ padding: 0, margin: 0 }}
											aria-label="Agregar firma"
											onClick={actionAdd}
										>
											<img src={addSign} />
										</IconButton>
									</Grid>
								</Grid>
							}
						</Grid>
					</Box>
					<Box display="flex" justifyContent="center" style={{ visibility: listManuscrita.length && showComplete ? 'visible' : 'hidden' }}>
						<Button
							className={'btnPopup'}
							disabled={selected == null}
							disableElevation
							onClick={handleAplicar}
						>Aplicar Firma
						</Button>
					</Box>
				</div>
			}
		</>
	);
};

const mapStateToProps = ({ sign }) => ({
	listManuscrita: sign.listManuscrita,
	responseSign: sign.responseSign,
});

const mapDispatchToProps = {
	getSignManuscrita: getManuscritaByUser,
	deleteManuscrita: deleteManuscritaAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Sign);