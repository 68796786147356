import {
  GET_HOMOLOGATIONVALUE,
  GET_HOMOLOGATIONVALUES,
  SET_HOMOLOGATIONVALUE_ERROR,
  GET_HOMOLOGATIONVALUE_CHANGED,
} from "../actions/types";

const initialState = {
  getHomologationValueResponse: [],
  getHomologationValuesResponse: [],
  setHomologationValueErrorResponse: [],
  getHomologationValueChangedResponse: []
};

/**
 * homologationValuesReducer (set homologationValue or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, homologationValue}
 */
export default function homologationValuesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_HOMOLOGATIONVALUES:
      return {
        ...state,
        getHomologationValuesResponse: action.payload,
      };
    case GET_HOMOLOGATIONVALUE:
      return {
        ...state,
        getHomologationValueResponse: action.payload,
      };
    case GET_HOMOLOGATIONVALUE_CHANGED:
      return {
        ...state,
        getHomologationValueChangedResponse: action.payload,
      };
    case SET_HOMOLOGATIONVALUE_ERROR:
      return {
        ...state,
        setHomologationValueErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
