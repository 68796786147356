import { CircularProgress, Dialog, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'transparent 0% 0% no-repeat padding-box !important',
        opacity: 1,

        '& .MuiDialog-paper': {
            overflowY: 'hidden',

            width: 'auto'
        },
        '& .MuiDialog-paperWidthSm': {
            background: 'transparent  !important',
            opacity: 1,
            width: '50px !important'
        },


    }
}))


const FullLoader = ({ handleClose, open }) => {
    const classes = useStyles();
    return (
        <Dialog onClose={handleClose} className={classes.root}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
            aria-labelledby="simple-dialog-title" open={open}>
            <CircularProgress />
        </Dialog>
    )
}

export default FullLoader
