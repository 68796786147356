import axios from "axios";
import { API_AUTHENTICATION, defaultHeaders } from "../config/config";
import {
    DEFAULTRESPONSE,
    GET_ROLE,
    GET_USER_CHANGED,
    SET_USER_ERROR,
} from "./types";

export /**
 * Otp Action validate ( validate OTP )
 *
 * @method POST
 * @param {history} history
 */
    const validateOtp = (userData, history) => async (dispatch) => {
        try {
            var response = await axios.post(
                API_AUTHENTICATION + "/api/login/ValidateOtpMail",
                userData,
                defaultHeaders()
            );
            dispatch({
                type: GET_USER_CHANGED,
                payload: response.data.statusDetails[0].description,
            });
            dispatch({
                type: GET_ROLE,
                payload: response.data.statusDetails[0].code,
            });
        } catch (err) {
            console.error(err);
            const response = !!err.response
                ? err.response.data
                : DEFAULTRESPONSE.noResponseFromApi;
            dispatch({
                type: SET_USER_ERROR,
                payload: response,
            });
        }
    };
