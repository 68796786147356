import axios from "axios";
import { assignAuthTokenAndRefreshToken } from "./assignAuthToken";
import { API_AUTHENTICATION } from "../config/config";
import { revokeTokenUser } from "../utils/services/auth";
import { GET_MESSAGE_REFRESH_TOKEN, IS_REFRESH_TOKEN } from "../actions/types";

export const invokeInterceptor = (appName, history, dispatch) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url === API_AUTHENTICATION + "/api/Login/RefreshToken") {
        if (error.response.data.result.informationCode === "R101") {
          dispatch({
            type: GET_MESSAGE_REFRESH_TOKEN,
            payload: error.response.data.result.information,
          });
        } else {
          await revokeTokenUser();
          return Promise.reject(error);
        }
      } else {
        if (error.response.status === 403) {
          dispatch({
            type: "GET_PRIVILEGES_AUTH",
            payload:
              "La acción que está intentando realizar no es posible ejecutarla debido a que su usuario no tiene los privilegios suficientes.",
          });
        } else if (error.response.status === 401 && !originalRequest._retry) {
          try {
            originalRequest._retry = true;
            const refreshTokenEncrypted = localStorage.getItem("refreshToken");
            const userName = JSON.parse(localStorage.getItem("userTokenData"))
              .nameid;
            let refreshTokenData = {
              RefreshToken: refreshTokenEncrypted,
              UserName: userName,
              Appname: appName,
            };
            return axios
              .post(
                API_AUTHENTICATION + "/api/Login/RefreshToken",
                refreshTokenData
              )
              .then((res) => {
                if (res.status === 200) {
                  assignAuthTokenAndRefreshToken(res.data.result);
                  dispatch({
                    type: IS_REFRESH_TOKEN,
                    payload: true,
                  });
                  originalRequest.headers.Authorization =
                    "Bearer " + res.data.result.token;
                  return axios(originalRequest);
                }
              })
              .catch((error) => {
                // revokeTokenUser();
              });
          } catch (error) {
            history.push({
              pathname: "/Login",
            });
          }
        }
        return Promise.reject(error);
      }
    }
  );
};

export default invokeInterceptor;
