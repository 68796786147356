import {
  GET_DOCUMENTTYPE,
  GET_DOCUMENTTYPES,
  SET_DOCUMENTTYPE_ERROR,
  GET_DOCUMENTTYPE_CHANGED,
} from "../actions/types";

const initialState = {
  getDocumentTypeResponse: [],
  getDocumentTypesResponse: [],
  setDocumentTypeErrorResponse: [],
  getDocumentTypeChangedResponse: []
};

/**
 * documenttypesReducer (set documenttype or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, documenttype}
 */
export default function documentTypesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTTYPES:
      return {
        ...state,
        getDocumentTypesResponse: action.payload,
      };
    case GET_DOCUMENTTYPE:
      return {
        ...state,
        getDocumentTypeResponse: action.payload,
      };
    case GET_DOCUMENTTYPE_CHANGED:
      return {
        ...state,
        getDocumentTypeChangedResponse: action.payload,
      };
    case SET_DOCUMENTTYPE_ERROR:
      return {
        ...state,
        setDocumentTypeErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
