import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../ButtonForm/PrimaryButton";
import CustomModal from "./modalLogin";

export const ItemEditedModal = ({ open, setOpen, handleClose }) => {
  const classes = useStyles();
  const [isMessage, setIsMessage] = useState(true);
  const [checkMessage, setCheckMessage] = useState(false);

  useEffect(() => {
    const isMessage = localStorage.getItem("remember");
    if (isMessage) {
      setIsMessage(false);
    }
  }, []);

  const handleChange = () => {
    setCheckMessage(!checkMessage);
  };

  const handleAccept = () => {
    if (checkMessage) {
      localStorage.setItem("remember", true);
    }
    setOpen(false);
  };

  return (
    <CustomModal
      open={open}
      disableClose={true}
      handleClose={handleClose}
      header={
        <Grid container justify="center">
          <Typography variant="h6" color="secondary">
            {"¡Ítem actualizado con éxito!"}
          </Typography>
        </Grid>
      }
      body={
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="subtitle1">
              Debes dar clic en el botón <b>Guardar Menú</b> para aplicar los
              cambios
            </Typography>
          </Grid>
          {isMessage ? (
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              spacing={2}
              justify="center"
              alignItems="center"
            >
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Checkbox
                  className={classes.root}
                  checked={checkMessage}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant="subtitle2">
                  No volver a mostrar este mensaje
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      }
      actions={
        <Grid container item justify="center">
          <PrimaryButton text="Aceptar" onClick={handleAccept} />
        </Grid>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "grey",
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}));
