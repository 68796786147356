import {
  GET_CUSTOMFORM,
  GET_CUSTOMFORMS,
  SET_CUSTOMFORM_ERROR,
  GET_CUSTOMFORM_CHANGED,
  GET_CUSTOMFORM_DELETED
} from "./types";
import { setUrl, getRecords, getRecordsByFilters, getRecordsByCustomer, getRecordById, createRecord, modifyRecord, deleteRecord } from "./crud.base.action";

var Entity = "ExceptionMotivos";

export /**
 * Actualizar entity
 */
const setEntity = (entity) => {
  Entity = entity;
};

export /**
 * Actualizar ApiUrl del servicio of CustomForm
 */
const setCustomFormUrl = (URL) => {
  setUrl(URL);
};

export /**
 * CustomForms Action CustomForms
 *
 * @method GET
 */
  const getCustomForms = (filters, pPageOffset, pPageSize) => async (dispatch) => {
    if(filters){
      dispatch(getRecordsByFilters(Entity, filters, GET_CUSTOMFORMS, SET_CUSTOMFORM_ERROR, 1, "", pPageOffset, pPageSize));
    } else {
      dispatch(getRecords(Entity, GET_CUSTOMFORMS, SET_CUSTOMFORM_ERROR, 1, "", pPageOffset, pPageSize));
    }
  };

export /**
 * CustomForms Action getCustomForms
 *
 * @method GET
 * @param customerid
 */
  const getCustomFormsByCustomer = (customerid, parentId, pPageOffset, pPageSize) => async (dispatch) => {
    dispatch(getRecordsByCustomer(Entity, customerid, GET_CUSTOMFORMS, SET_CUSTOMFORM_ERROR, 1, "", "Integration.IdCustomer"
      , parentId ? [{fieldName: "IdIntegration", fieldValue: parentId}] : [], pPageOffset, pPageSize
      )
    );
  };

export /**
 * CustomForm Action getCustomFormById
 *
 * @method GET
 * @param {*} id
 */
  const getCustomFormById = (id) => async (dispatch) => {
    dispatch(getRecordById(Entity, id, GET_CUSTOMFORM, SET_CUSTOMFORM_ERROR));
  };

export /**
  * CustomForm Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createCustomForm = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord(Entity, recordToCreate, updateChecked, GET_CUSTOMFORM_CHANGED, SET_CUSTOMFORM_ERROR));
 };
 

export /**
 * CustomForm Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyCustomForm = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord(Entity, recordToUpdate, updateChecked, isPrincipalPage, GET_CUSTOMFORM_CHANGED, SET_CUSTOMFORM_ERROR));  
};

export /**
 * CustomForm Action delete ( delete )
 *
 * @method DELETE
 * @param {history} history
 */
const deleteCustomForm = (recordToDelete, updateChecked) => async (dispatch) => {
    dispatch(deleteRecord(Entity, recordToDelete, updateChecked, GET_CUSTOMFORM_DELETED, SET_CUSTOMFORM_ERROR));  
};