/*import axios from "axios";
import { messagesResponseIntegrations } from "../utils/constants";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";*/
import {
  GET_INTEGRATION,
  GET_INTEGRATIONS,
  //DEFAULTRESPONSE,
  SET_INTEGRATION_ERROR,
  GET_INTEGRATION_CHANGED,
} from "./types";
import { getRecordsByCustomer, getRecordById, createRecord, modifyRecord } from "./base.action";

export /**
 * Integrations Action getIntegrations
 *
 * @method GET
 * @param customerid
 */
  const getIntegrationsByCustomer = (customerid) => async (dispatch) => {
    dispatch(getRecordsByCustomer("integration", customerid, GET_INTEGRATIONS, SET_INTEGRATION_ERROR, "0", "IntegrationAction;Destination;Source;IdstateNavigation;Services"));
  };

export /**
 * Integration Action getIntegrationById
 *
 * @method GET
 * @param {*} id
 */
  const getIntegrationById = (id) => async (dispatch) => {
    dispatch(getRecordById("integration", id, GET_INTEGRATION, SET_INTEGRATION_ERROR, "1", "Destination;Source;Homologations"));
  };

export /**
  * Integration Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createIntegration = (recordToCreate, updateChecked) => async (dispatch) => {
    recordToCreate.concurrence = parseInt(recordToCreate.concurrence);
    recordToCreate.idDestination = parseInt(recordToCreate.idDestination);
    recordToCreate.idSource = parseInt(recordToCreate.idSource);
    recordToCreate.idstate = parseInt(recordToCreate.idstate);
    dispatch(createRecord("integration", recordToCreate, updateChecked, GET_INTEGRATION_CHANGED, SET_INTEGRATION_ERROR));
 };
 

export /**
 * Integration Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyIntegration = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    recordToUpdate.concurrence = parseInt(recordToUpdate.concurrence);
    recordToUpdate.idDestination = parseInt(recordToUpdate.idDestination);
    recordToUpdate.idSource = parseInt(recordToUpdate.idSource);
    recordToUpdate.idstate = parseInt(recordToUpdate.idstate);
    dispatch(modifyRecord("integration", recordToUpdate, updateChecked, isPrincipalPage, GET_INTEGRATION_CHANGED, SET_INTEGRATION_ERROR));  
};
