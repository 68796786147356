import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { PrimaryButton } from "../ButtonForm/PrimaryButton";
import NewUserIcon from "../IconsLibrary/NewUserIcon";
import MenuTree from "../../views/menuSettings/menutree";

export const MenuSettingsComponent = ({
  select,
  currentApp,
  values,
  valuesTitle,
  children,
  addInitialDataMenu,
  saveMenu,
  setOpen,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        {currentApp.name !== "" ? (
          <Grid container spacing={2}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              {select}
            </Grid>
            <Grid container item lg={8} md={12} sm={12} xs={12}>
              <Paper className={classes.paperTable} style={{ padding: "20px" }}>
                <Grid container>
                  <Grid item lg={11} md={11} sm={11} xs={11}>
                    <Typography className={classes.titleSelectTypeMenu}>
                      {currentApp.name}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    <NewUserIcon
                      className={classes.plusIcon}
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  </Grid>
                  <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Typography className={classes.subtitleSelectTypeMenu}>
                      Recuerda que puedes ordenar el menú arrastrando y soltando
                      cada ítem a la posición que prefieras.
                    </Typography>
                  </Grid>
                </Grid>
                {valuesTitle ? (
                  <Typography variant="h6">{valuesTitle}</Typography>
                ) : null}
                {values.menu1.length !== 0 ? (
                  <MenuTree
                    treeData={values.menu1}
                    setTreeData={addInitialDataMenu}
                  />
                ) : (
                  <span>No hay datos para mostrar</span>
                )}
                {children}
              </Paper>
            </Grid>
          </Grid>
        ) : null}
        <Grid container style={{ marginBottom: 20 }}>
          <Grid container item justify="center" lg={8} md={12} sm={12} xs={12}>
            <PrimaryButton
              text="Guardar menú"
              disabled={currentApp.name === "" ? true : false}
              onClick={saveMenu}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  titleSelectTypeMenu: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    marginTop: "10px",
    marginBottom: "10px",
  },
  subtitleSelectTypeMenu: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.pxToRem(12),
    marginTop: "10px",
    marginBottom: "10px",
  },
  paperTable: {
    width: "100%",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.light}`,
    marginBottom: 20,
  },
  plusIcon: {
    height: "32px !important",
    width: "32px !important",
    stroke: theme.palette.secondary.main,
  },
  itemThree: {
    height: 40,
    minWidth: 350,
  },
}));
