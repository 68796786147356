// import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import ViewLayout from "../../components/layouts/ViewLayout";
import { getMenuList } from "../../actions/menu.action";
import FormLayout from "../../components/layouts/FormLayout";
import TabComponent from "../../components/TabsComponent/TabComponent";
import { CustomMenuSettings } from "../../components/MenuSettings/MenuTabs/CustomMenuSetting";
import { isEmpty } from "../../utils/proprietaryHooks";
import { UserMenuSettings } from "../../components/MenuSettings/MenuTabs/UserMenuSetting";
import { SuiteMenuSetting } from "../../components/MenuSettings/MenuTabs/SuiteMenuSetting";
const MenuSettings = ({ component: Component, container, ...props }) => {
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });

  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading,] = useState(false);
  const [leftMenu, setLeftMenu] = useState([]);
  const [topMenu, setTopMenu] = useState([]);
  const [userMenu, setUserMenu] = useState([]);
  const [suiteMenu, setSuiteMenu] = useState([])
  const leftMenuRef = useRef(null);
  const topMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const suiteMenuRef = useRef(null);

  const idMenu = useRef(0)

  const menuLocations = {
    LEFT_MENU: 1,
    TOP_MENU: 2,
    USER_MENU: 3,
    SUITE_MENU: 4
  };

  //#region  selectors
  const menuLeftResponse = useSelector(
    ({ menuReducer }) => menuReducer.getLeftMenuResponse
  );
  const menuTopResponse = useSelector(
    ({ menuReducer }) => menuReducer.getTopMenuResponse
  );

  const menuUserResponse = useSelector(
    ({ menuReducer }) => menuReducer.getUserMenuResponse
  );

  const menuSuiteResponse = useSelector(
    ({ menuReducer }) => menuReducer.getSuiteMenuResponse
  );


  //#endregion

  //#region  effects section

  useEffect(() => {
    if (!isEmpty(menuLeftResponse)) {
      const value = menuLeftResponse.records[0] !== undefined ? JSON.parse(menuLeftResponse.records[0].menuJson) : []
      idMenu.current = menuLeftResponse.records[0] !== undefined ? menuLeftResponse.records[0].id : 0
      setLeftMenu(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuLeftResponse]);

  useEffect(() => {
    if (!isEmpty(menuTopResponse)) {
      const value = menuTopResponse.records[0] !== undefined ? JSON.parse(menuTopResponse.records[0].menuJson) : []
      idMenu.current = menuTopResponse.records[0] !== undefined ? menuTopResponse.records[0].id : 0
      setTopMenu(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuTopResponse]);

  useEffect(() => {
    if (!isEmpty(menuUserResponse)) {
      setUserMenu(JSON.parse(menuUserResponse.records[0].menuJson));
    }
  }, [menuUserResponse]);

  useEffect(() => {
    if (!isEmpty(menuSuiteResponse)) {
      setSuiteMenu(JSON.parse(menuSuiteResponse.records[0].menuJson));
    }
  }, [menuSuiteResponse]);

  //#endregion

  const handleChangeTab = (evt, key) => {
    if (currentTab === 0) {
      setLeftMenu([]);
    }
    if (currentTab === 1) {
      setTopMenu([]);
    }
    if (currentTab === 2) {
      setUserMenu([]);
    }
    if (currentTab === 3) {
      setSuiteMenu([]);
    }
    setCurrentTab(key);
  };

  return (
    <ViewLayout
      headerTitle={"Ajustes de Menús"}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      isLoading={isLoading}
    >
      <FormLayout>
        <TabComponent
          tab={currentTab}
          handleChangeTab={handleChangeTab}
          children={[
            {
              label: "Menú lateral",
              tabContent: (
                <CustomMenuSettings
                  ref={leftMenuRef}
                  menuLocation={menuLocations.LEFT_MENU}
                  menu={{
                    currentMenu: leftMenu,
                    setCurrentMenu: setLeftMenu,
                    idMenu: idMenu.current
                  }}
                />
              ),
            },
            {
              label: "Menú superior",
              tabContent: (
                <CustomMenuSettings
                  ref={topMenuRef}
                  menuLocation={menuLocations.TOP_MENU}
                  menu={{
                    currentMenu: topMenu,
                    setCurrentMenu: setTopMenu,
                    idMenu: idMenu.current
                  }}
                />
              ),
            },
            {
              label: "Menú de usuario",
              tabContent: (
                <UserMenuSettings
                  ref={userMenuRef}
                  menuLocation={menuLocations.USER_MENU}
                  menu={{ currentMenu: userMenu, setCurrentMenu: setUserMenu }}
                />
              ),
            },
            {
              label: "Menú suite aplicaciones",
              tabContent: (
                <SuiteMenuSetting
                  ref={suiteMenuRef}
                  menuLocation={menuLocations.SUITE_MENU}
                  menu={{ currentMenu: suiteMenu, setCurrentMenu: setSuiteMenu }}
                />
              ),
            },
          ]}
        />
      </FormLayout>
    </ViewLayout>
  );
};

MenuSettings.propTypes = {
  getMenuList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getMenuListResponse: state.menuReducer.getMenuListResponse,
});

const mapDispatchToProps = {
  getMenuList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSettings);
