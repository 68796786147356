import { alpha, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import CustomModal from "../../components/Modal/modalLogin";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "../../utils/proprietaryHooks";
import { PrimaryButton } from "../../components/ButtonForm/PrimaryButton";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";

export const ModalForm = forwardRef((props, ref) => {
  const {
    open,
    setOpen,
    handleSave,
    initialValues,
    handleClose,
    homologation
  } = props;
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [header, setHeader] = useState({
    tab0: {
      header: initialValues.name === "" ? "Nuevo campo" : "Actualizar campo",
      subtitle: initialValues.name === "" ? "Ingresa los datos" : "Modifica los datos",
    }
  });
  const types = [
    {id:'string', name:'String'},
    {id:'number', name:'Number'},
    {id:'date', name:'Date'},
    {id:'boolean', name:'Boolean'},
    {id:'list', name:'List'},
    {id:'homologation', name:'Homologation'}
  ];

  const requiredMessage = "Campo obligatorio";
  const schema = yup.object().shape({
    name: yup.string().required(requiredMessage),
  });
  const form = useForm({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });
  const {
    control,
    errors,
    trigger,
    getValues,
    handleSubmit,
    formState,
    setValue
  } = form;
  /**
   * set the values to inputs when view is triggered
   */
  useEffect(() => {
    if (initialValues.name !== "") {
      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
      setHeader({
        tab0: {
          header: `${initialValues.readOnly?"Campo":"Edición"} - ${initialValues["name"]}`,
          subtitle: initialValues.readOnly?"Detalle":"Modifica los datos",
        }
      });
    } else {
      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, setValue]);

  const handleClickModal = () => {
    const isEdit = initialValues.name !== "";
    const values = getValues();
    handleSave(isEdit, values);
  };

  const handleChangeTab = async () => {
    await trigger();
    if (!isEmpty(formState.errors)) {
      return;
    } else {
      setCurrentTab(1);
    }
  };

  /**
   * make body of modal depending tab
   * @returns {JSX.Element} body
   */
  const getBody = () => {
    let modal = {};

    switch (currentTab) {
      case 0:
        modal = {
          header: (
            <Typography
              variant="h6"
              color="primary"
              className={classes.modalHeader}
            >
              {header.tab0.header}
            </Typography>
          ),
          body: (
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.bodyTitle}
                >
                  {header.tab0.subtitle}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ControlledInputRoundedForm
                  id="name"
                  fullWidth
                  control={control}
                  name="name"
                  label="Nombre"
                  error={errors.name}
                  helperText={errors.name?.message}
                  disabled={initialValues.readOnly}
                />
                <ControlledSelect
                  id="type"
                  name="type"
                  label="Tipo"
                  options={types}
                  error={errors.type}
                  helperText= {errors.type?.message}
                  control={control}
                  disabled={initialValues.readOnly}
                />
                <ControlledInputRoundedForm
                  id="size"
                  fullWidth
                  control={control}
                  name="size"
                  label="Tamaño"
                  error={errors.size}
                  helperText={errors.size?.message}
                  disabled={initialValues.readOnly}
                />
                <ControlledSelect
                  id="homologationName"
                  name="homologationName"
                  label="Homologación"
                  options={homologation}
                  error={errors.homologationName}
                  helperText= {errors.homologationName?.message}
                  control={control}
                  disabled={initialValues.readOnly}
                />
                <ControlledInputRoundedForm
                  id="defaultValue"
                  fullWidth
                  control={control}
                  name="defaultValue"
                  label="Nuevo valor"
                  error={errors.defaultValue}
                  helperText={errors.defaultValue?.message}
                  disabled={initialValues.readOnly}
                />
                <Grid
                  container
                  item
                  lg={12} md={12} sm={12} xs={12}
                  alignItems="center"
                >
                  <Grid item lg={3} md={3} xs={3} sm={3}>
                    <Typography>Obligatorio</Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={3} sm={3}>
                    <ControlledSwitchComponent
                      name="required"
                      control={control}
                      disabled={initialValues.readOnly}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ),
          actions: (
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid container item lg={6} md={6} sm={6} xs={6} justify="flex-end">
                {!initialValues.readOnly &&
                <PrimaryButton
                  text={
                    initialValues.name !== "" ? "Actualizar" : "Crear"
                  }
                  onClick={handleClickModal}
                />
                }
              </Grid>
            </Grid>
          ),
        };
        break;
    
      default:
        break;
    }

    return modal;
  };

  const restartModal = () => {
    setCurrentTab(0);
  };

  useImperativeHandle(
    ref,
    () => ({
      form: form,
      save: handleClickModal,
      restartModal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        handleClose();
        setOpen(false);
      }}
      body={
        <div>
          {getBody().header}
          {getBody().body}
        </div>
      }
      actions={getBody().actions}
      width={"503px"}
      handleSubmit={handleSubmit(handleChangeTab)}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bodyTitle: {
    color: theme.palette.primary.light,
  },
  paperSelectIcon: {
    height: "350px",
    marginBottom: 120,
  },
  closeIcon: {
    color: theme.palette.primary.light,
    fontSize: "15px",
  },
  inputHiddenLabel: {
    color: "#b2b2b2",
  },
  autoComplete: {
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "#FFFFFF",
      boxShadow: `0px 1px 1px #00000026`,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      "&$error": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiInputBase-input.Mui-error": {
        borderColor: theme.palette.error.main,
      },
    },
    inputHiddenLabel: {
      color: "#b2b2b2",
    },
    focused: {
      fontSize: 14,
    },
    error: {},
  }
}));
