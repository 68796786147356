import axios from "axios";
import {
  API_HOSTNAME,
  API_HOSTNAME_SIGNS,
  API_HOSTNAME_AWS
} from "../types/ApiConstants";
import {
  GET_CERTIFICATES,
  LOADING,
  LIST_SIGNS,
  LIST_MANUSCRITA,
  POP
} from "../types/SignTypes";
import { toBase64, uuidGenerate } from "../helpers/FilesFunction";
import _crypto from "crypto-js";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("JWT");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getCerificatesByUser =
  (enqueueSnackbar, close, onNoCertificates) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_HOSTNAME}/api/certificado/certificados`
      );
      if (response.status === 200) {
        if(response.data.filter(ce => ce.codigoestadocertificado == "EMC" || ce.codigoestadocertificado == "FIN").length < 1) {
          onNoCertificates();
          return(false);
        }
        dispatch({
          type: GET_CERTIFICATES,
          payload: response.data.filter(
            (ce) =>
              ce.codigoestadocertificado == "EMC" ||
              ce.codigoestadocertificado == "FIN"
          ),
        });
      }
      return true;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          enqueueSnackbar(error.response.data, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status === 401) {
          enqueueSnackbar("Acceso no permitido", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else {
        enqueueSnackbar(
          "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      close();
      return false;
    }
  };

export const firmarAction =
  (data, enqueueSnackbar, close, history) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const files = [];
      await Promise.all(
        data.documentos.map(async (f) => {
          files.push({
            uuid: uuidGenerate(),
            base64: (await toBase64(f)).split(",").pop(),
            nombre: f.name,
          });
        })
      );
      const toSend = { ...data, documentos: files };
      const response = await axios
        .post(`${API_HOSTNAME_SIGNS}api/sign/quicksignature`, toSend)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.codigoRespuesta === "RS1") {
              enqueueSnackbar("Firma completa", {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else {
              response.data.detalles.forEach((element) => {
                const file = toSend.documentos.find(
                  (x) => x.uuid === element.uuid
                );
                enqueueSnackbar(
                  `${response.data.mensaje} con el documento ${file.nombre}`,
                  {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              });
            }
            dispatch({ type: LOADING, payload: false });
            dispatch({ type: POP, payload: files.length });
            close();
            history.push("/home/ConsultSignedDocument");
          }
        })
        .catch((error) => {
          try {
            if (error.response.status == 401) {
              enqueueSnackbar(
                "No tiene disponibilidad para el " +
                  error.response.data.mensaje,
                {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            } else if (error.response.status == 400) {
              enqueueSnackbar(error.response.data.mensaje, {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          } catch (error) {
            enqueueSnackbar(
              "Ocurrió un error inesperado al procesar la transacción",
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          }
          dispatch({ type: LOADING, payload: false });
          close();
        });
    } catch (error) {
      enqueueSnackbar(
        "Ocurrió un error inesperado al procesar la transacción",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      dispatch({ type: LOADING, payload: false });
    }
  };

export const firmarS3Action =
  (data, enqueueSnackbar, close, history) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const filesPut = [];
      await Promise.all(
        data.documentos.map((f, index) => {
          filesPut.push({ uuid: index, nombre: f.name });
        })
      );
      var dataS3 = {
        idClienteFirmante: data.idClienteFirmante,
        documentos: filesPut,
      };
      const response = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/generateS3Url`,
        dataS3
      );
      if (response.status === 200) {
        const listFiles = [];
        const listSign = [];
        await Promise.all(
          data.documentos.map(async (f, index) => {
            listFiles.push({
              uuid: index,
              base64: (await toBase64(f)).split(",").pop(),
              nombre: f.name,
            });
          })
        );
        await Promise.all(
          response.data.documentos.map(async (file) => {
            var bytes = null;
            listFiles.map((f) => {
              if (file.uuid == f.uuid && file.nombreReal == f.nombre) {
                bytes = _base64ToArrayBuffer(f.base64);
              }
            });
            if (bytes != null) {
              let putDocument = await putDocumentsS3(file.urlPut, bytes);
              if (putDocument == 200) {
                listSign.push(file);
              } else {
                enqueueSnackbar(
                  "Error al cargar el archivo " + file.nombreReal + " en S3",
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              }
            }
          })
        );
        const toSend = { ...data, documentos: listSign };
        const responsePut = await axios
          .post(`${API_HOSTNAME_SIGNS}api/sign/quicksignatures3`, toSend)
          .then((responsePut) => {
            dispatch({ type: LOADING, payload: false });
            if (responsePut.status == 200) {
              if (responsePut.data.codigoRespuesta === "RS1") {
                enqueueSnackbar("Firma completa", {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
                history.push("/home/ConsultSignedDocument");
                dispatch({ type: POP, payload: filesPut.length });
              } else {
                responsePut.data.documentos.forEach((element) => {
                  enqueueSnackbar(element.mensaje, {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                });
              }
              close();
            }
          })
          .catch((error) => {
            try {
              if (error.response.status == 401) {
                enqueueSnackbar(
                  "No tiene disponibilidad para el " +
                    error.response.data.descripcionRespuesta,
                  {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              } else if (error.response.status == 400) {
                enqueueSnackbar(error.response.data.descripcionRespuesta, {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              } else {
                enqueueSnackbar(
                  "Ocurrió un error inesperado al procesar la transacción",
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              }
            } catch (error) {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
            dispatch({ type: LOADING, payload: false });
            close();
          });
      } else {
        enqueueSnackbar(response.data.descripcionRespuesta, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(
        "Ocurrió un error inesperado al procesar la transacción",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      dispatch({ type: LOADING, payload: false });
      close();
    }
  };

export const reQuickSign =
  (files, signInfo, enqueueSnackbar, history, close) => async (dispatch) => {
    try {
      let nombreFinal = "";
      let listDocuments = [];
      dispatch({ type: LOADING, payload: true });
      for (let i = 0; i < files.length; i++) {
        const response = await axios.get(
          `${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
          {
            headers: {
              key: files[i].ruta + "/" + files[i].nombrerepositorio,
              pRepository: files[i].repositorio,
            },
          }
        );
        const { data } = response;
        if (data.httpStatus == 201) {
          let resp = await getDocumentsS3(data.url, files[i].nombrereal);
          let blob = resp.response;
          let file = new File([blob], files[i].nombrereal);
          listDocuments.push(file);
        }
      }
      let data = {
        idClienteFirmante: signInfo.idClienteFirmante,
        firmaelectronica: signInfo.firmaelectronica,
        clave: signInfo.clave,
        ltv: signInfo.ltv,
        estampa: signInfo.estampa,
        documentos: listDocuments,
        codeId: signInfo.codeId,
      };
      const filesPut = [];
      for (let i = 0; i < data.documentos.length; i++) {
        let nombre = data.documentos[i].name;
        let text = nombre;
        if (text.match(/\(([\w\s]*)\)/g)) {
          text.match(/\(([\w\s]*)\)/g).forEach((item) => {
            nombreFinal = nombre.replace(item, "");
          });
        } else {
          nombreFinal = nombre;
        }
        nombreFinal = nombreFinal.replace(".p7z", "");
        filesPut.push({ uuid: i, nombre: nombreFinal });
      }
      var dataS3 = {
        idClienteFirmante: data.idClienteFirmante,
        documentos: filesPut,
      };
      const response = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/generateS3Url`,
        dataS3
      );
      if (response.status === 200) {
        let listFiles = await validateNames(data.documentos);
        let listSign = [];
        for (let i = 0; i < response.data.documentos.length; i++) {
          const document = response.data.documentos[i];
          let findDoc = listFiles.find(
            (doc) => doc.nombre == document.nombreReal
          );
          if (findDoc) {
            let bytes = await _base64ToArrayBuffer(findDoc.base64);
            if (bytes != null) {
              let putDocument = await putDocumentsS3(document.urlPut, bytes);
              if (putDocument == 200) {
                listSign.push(document);
              } else {
                enqueueSnackbar(
                  "Error al cargar el archivo " +
                    document.nombreReal +
                    " en S3",
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              }
            }
          }
        }
        const toSend = { ...data, documentos: listSign };
        const responsePut = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/quicksignatures3`,
          toSend
        );
        if (responsePut.status == 200) {
          if (responsePut.data.codigoRespuesta === "RS1") {
            enqueueSnackbar("Firma completa", {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          } else {
            responsePut.data.documentos.forEach((element) => {
              enqueueSnackbar(element.mensaje, {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            });
          }
          close(true);
          history.push("/home/ConsultSignedDocument");
          dispatch({ type: POP, payload: listDocuments.length });
        } else {
          enqueueSnackbar(response.data.descripcionRespuesta, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      }
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 500) {
          if (error.response.status == 401) {
            enqueueSnackbar(
              "No tiene disponibilidad para el " +
                error.response.data.codigoRespuesta,
              {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          } else {
            enqueueSnackbar(error.response.data.descripcionRespuesta, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
        } else if (error.response.status === 500) {
          enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else {
        enqueueSnackbar(
          "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
    }
  };

function validateNames(documentos) {
  return new Promise(async function (resolve) {
    let listFiles = [];
    let nombreFinal = "";
    for (let i = 0; i < documentos.length; i++) {
      let nombre = documentos[i].name;
      let text = nombre;
      if (text.match(/\(([\w\s]*)\)/g)) {
        text.match(/\(([\w\s]*)\)/g).forEach((item) => {
          nombreFinal = nombre.replace(item, "");
        });
      } else {
        nombreFinal = nombre;
      }
      nombreFinal = nombreFinal.replace(".p7z", "");
      listFiles.push({
        uuid: i,
        base64: (await toBase64(documentos[i])).split(",").pop(),
        nombre: nombreFinal,
      });
    }
    resolve(listFiles);
  });
}

export const estamparAction =
  (data, enqueueSnackbar, history) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const files = [];
      await Promise.all(
        data.documentos.map(async (f) => {
          files.push({
            uuid: uuidGenerate(),
            base64: (await toBase64(f)).split(",").pop(),
            nombre: f.name,
          });
        })
      );
      const toSend = { ...data, documentos: files };
      await axios
        .post(`${API_HOSTNAME_SIGNS}api/sign/quickstamp`, toSend)
        .then((response) => {
          dispatch({ type: LOADING, payload: false });
          if (response.status === 200) {
            if (response.data.codigoRespuesta === "RS1") {
              enqueueSnackbar("Firma completa", {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
              dispatch({ type: POP, payload: files.length });
              history.push("/home/ConsultSignedDocument");
            } else {
              response.data.detalles.forEach((element) => {
                const file = toSend.documentos.find(
                  (x) => x.uuid === element.uuid
                );
                enqueueSnackbar(
                  `${response.data.mensaje} con el documento ${file.nombre}`,
                  {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              });
            }
          }
        })
        .catch((error) => {
          dispatch({ type: LOADING, payload: false });
          try {
            if (error.response.status == 401) {
              enqueueSnackbar(
                "No tiene disponibilidad para el " +
                  error.response.data.mensaje,
                {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            } else if (error.response.status == 400) {
              enqueueSnackbar(error.response.data.mensaje, {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          } catch (error) {
            enqueueSnackbar(
              "Ocurrió un error inesperado al procesar la transacción",
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
            return;
          }
        });
    } catch (error) {
      enqueueSnackbar(
        "Ocurrió un error inesperado al procesar la transacción",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      dispatch({ type: LOADING, payload: false });
    }
  };

export const estamparActionS3 =
  (data, enqueueSnackbar, history) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const filesPut = [];
      await Promise.all(
        data.documentos.map((f, index) => {
          filesPut.push({ uuid: index, nombre: f.name });
        })
      );
      var dataS3 = {
        documentos: filesPut,
      };
      const response = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/generateS3Url`,
        dataS3
      );
      if (response.status === 200) {
        const listFiles = [];
        const listSign = [];
        await Promise.all(
          data.documentos.map(async (f, index) => {
            listFiles.push({
              uuid: index,
              base64: (await toBase64(f)).split(",").pop(),
              nombre: f.name,
            });
          })
        );
        await Promise.all(
          response.data.documentos.map(async (file) => {
            var bytes = null;
            listFiles.map((f) => {
              if (file.uuid == f.uuid && file.nombreReal == f.nombre) {
                bytes = _base64ToArrayBuffer(f.base64);
              }
            });
            if (bytes != null) {
              let putDocument = await putDocumentsS3(file.urlPut, bytes);
              if (putDocument == 200) {
                listSign.push(file);
              } else {
                enqueueSnackbar(
                  "Error al cargar el archivo " + file.nombreReal + " en S3",
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              }
            }
          })
        );
        const toSend = { documentos: listSign };
        const responsePut = await axios
          .post(`${API_HOSTNAME_SIGNS}api/sign/quickstamps3`, toSend)
          .then((responsePut) => {
            dispatch({ type: LOADING, payload: false });
            if (responsePut.status == 200) {
              if (responsePut.data.codigoRespuesta === "RS1") {
                enqueueSnackbar("Firma completa", {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
                dispatch({ type: POP, payload: filesPut.length });
                history.push("/home/ConsultSignedDocument");
              } else {
                responsePut.data.documentos.forEach((element) => {
                  enqueueSnackbar(element.mensaje, {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                });
              }
            }
          })
          .catch((error) => {
            dispatch({ type: LOADING, payload: false });
            try {
              if (error.response.status == 401) {
                enqueueSnackbar(
                  "No tiene disponibilidad para el " +
                    error.response.data.descripcionRespuesta,
                  {
                    variant: "warning",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              } else if (error.response.status == 400) {
                enqueueSnackbar(error.response.data.descripcionRespuesta, {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              } else {
                enqueueSnackbar(
                  "Ocurrió un error inesperado al procesar la transacción",
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              }
            } catch (error) {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          });
      } else {
        enqueueSnackbar(response.data.descripcionRespuesta, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(
        "Ocurrió un error inesperado al procesar la transacción",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      dispatch({ type: LOADING, payload: false });
    }
  };

export const reQuickStamp =
  (files, enqueueSnackbar, history, close) => async (dispatch) => {
    try {
      let nombreFinal = "";
      let listDocuments = [];
      dispatch({ type: LOADING, payload: true });
      for (let i = 0; i < files.length; i++) {
        const response = await axios.get(
          `${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
          {
            headers: {
              key: files[i].ruta + "/" + files[i].nombrerepositorio,
              pRepository: files[i].repositorio,
            },
          }
        );
        const { data } = response;
        if (data.httpStatus == 201) {
          let resp = await getDocumentsS3(data.url, files[i].nombrereal);
          let blob = resp.response;
          let file = new File([blob], files[i].nombrereal);
          listDocuments.push(file);
        }
      }
      let data = {
        documentos: listDocuments,
      };
      const filesPut = [];
      for (let i = 0; i < data.documentos.length; i++) {
        let nombre = data.documentos[i].name;
        let text = nombre;
        if (text.match(/\(([\w\s]*)\)/g)) {
          text.match(/\(([\w\s]*)\)/g).forEach((item) => {
            nombreFinal = nombre.replace(item, "");
          });
        } else {
          nombreFinal = nombre;
        }
        nombreFinal = nombreFinal.replace(".p7z", "");
        filesPut.push({ uuid: i, nombre: nombreFinal });
      }
      var dataS3 = {
        documentos: filesPut,
      };
      const response = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/generateS3Url`,
        dataS3
      );
      if (response.status === 200) {
        let listFiles = await validateNames(data.documentos);
        let listSign = [];
        for (let i = 0; i < response.data.documentos.length; i++) {
          const document = response.data.documentos[i];
          let findDoc = listFiles.find(
            (doc) => doc.nombre == document.nombreReal
          );
          if (findDoc) {
            let bytes = await _base64ToArrayBuffer(findDoc.base64);
            if (bytes != null) {
              let putDocument = await putDocumentsS3(document.urlPut, bytes);
              if (putDocument == 200) {
                listSign.push(document);
              } else {
                enqueueSnackbar(
                  "Error al cargar el archivo " +
                    document.nombreReal +
                    " en S3",
                  {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }
                );
              }
            }
          }
        }
        const toSend = { documentos: listSign };
        const responsePut = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/quickstamps3`,
          toSend
        );
        if (responsePut.status == 200) {
          if (responsePut.data.codigoRespuesta === "RS1") {
            enqueueSnackbar("Firma completa", {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          } else {
            responsePut.data.documentos.forEach((element) => {
              enqueueSnackbar(element.mensaje, {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            });
          }
          close(true);
          dispatch({ type: POP, payload: filesPut.length });
          history.push("/home/ConsultSignedDocument");
        } else {
          enqueueSnackbar(response.data.descripcionRespuesta, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      }
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401) {
          enqueueSnackbar(
            "No tiene disponibilidad para el " +
              error.response.data.descripcionRespuesta,
            {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          dispatch({ type: LOADING, payload: false });
          enqueueSnackbar(error.response.data.mensaje, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch({ type: LOADING, payload: false });
        }
      } else {
        enqueueSnackbar(
          "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
        dispatch({ type: LOADING, payload: false });
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const verificarFirmasAction =
  (data, enqueueSnackbar) => async (dispatch) => {
    try {
      const files = [];
      const signs = [];
      await Promise.all(
        data.map(async (f) => {
          files.push({ uuid: f.uuid, base64: f.base64 });
        })
      );
      const response = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/getsignature`,
        files
      );
      if (response.status === 200) {
        response.data.documentos.map((doc) => {
          signs.push({
            docs: doc,
          });
        });
        dispatch({ type: LIST_SIGNS, payload: signs });
        dispatch({ type: LOADING, payload: false });
      } else {
        response.data.detalles.forEach((element) => {
          const file = files.documentos.find((x) => x.uuid === element.uuid);
          enqueueSnackbar(
            `${response.data.mensaje} con el documento ${file.nombre}`,
            {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        });
        dispatch({ type: LIST_SIGNS, payload: [] });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 500) {
          enqueueSnackbar(error.response.data.mensaje, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else {
        enqueueSnackbar(
          "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      dispatch({ type: LIST_SIGNS, payload: [] });
    }
  };

export const getManuscritaByUser = (enqueueSnackbar) => async (dispatch) => {
  try {
    const response = await axios.get(`${API_HOSTNAME}/api/Manuscrita/get/`);
    if (response.status === 200) {
      dispatch({
        type: LIST_MANUSCRITA,
        payload: response.data,
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        dispatch({ type: LOADING, payload: false });
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (error.response.status === 401) {
        dispatch({ type: LOADING, payload: false });
        enqueueSnackbar("Acceso no permitido", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (error.response.status === 500) {
        dispatch({ type: LOADING, payload: false });
        enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      enqueueSnackbar(
        "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const saveManuscritaAction =
  (data, enqueueSnackbar) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_HOSTNAME}/api/Manuscrita/add/`,
        data
      );
      if (response.status === 200) {
        enqueueSnackbar("Firma añadida a mis firmas", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 500) {
          enqueueSnackbar(error.response.data.mensaje, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else {
        enqueueSnackbar(
          "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      dispatch({ type: LIST_SIGNS, payload: [] });
    }
  };

export const deleteManuscritaAction =
  (data, enqueueSnackbar) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_HOSTNAME}/api/Manuscrita/delete/`,
        data
      );
      if (response.status === 200) {
        enqueueSnackbar("Firma eliminada correctamente", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 500) {
          enqueueSnackbar(error.response.data.mensaje, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else {
        enqueueSnackbar(
          "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      dispatch({ type: LIST_SIGNS, payload: [] });
    }
  };

export const getFiles = (files, enqueueSnackbar) => async () => {
  try {
    let nombreFinal = "";
    let listDocuments = [];
    let returnFiles = [];
    for (let i = 0; i < files.length; i++) {
      const response = await axios.get(
        `${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
        {
          headers: {
            key: files[i].ruta + "/" + files[i].nombrerepositorio,
            pRepository: files[i].repositorio,
          },
        }
      );
      const { data } = response;
      if (data.httpStatus == 201) {
        let resp = await getDocumentsS3(data.url, files[i].nombrereal);
        let blob = new Blob([resp.response], { type: "application/pdf" });
        let nombre = files[i].nombrereal;
        let text = nombre;
        if (text.match(/\(([\w\s]*)\)/g)) {
          text.match(/\(([\w\s]*)\)/g).forEach((item) => {
            nombreFinal = nombre.replace(item, "");
          });
        } else {
          nombreFinal = nombre;
        }
        nombreFinal = nombreFinal.replace(".p7z", "");
        let file = new File([blob], nombreFinal, { type: blob.type });
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = function () {
          var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
          file.numPages = count;
        };
        listDocuments.push(file);
        let totalSize = 0;
        listDocuments.map((file) => {
          var size = file.size;
          totalSize += size;
        });
        returnFiles = {
          files: listDocuments,
          filesSize: totalSize,
        };
      } else {
        enqueueSnackbar("No fue posible generar la url del documento", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }
    return returnFiles;
  } catch (error) {
    if (error.response) {
      if (error.response.status >= 400 && error.response.status < 500) {
        enqueueSnackbar(error.response.data.mensaje, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      enqueueSnackbar(
        "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }
  }
};

export const firmarToken =
  (data, enqueueSnackbar, close, history) => async (dispatch) => {
    let deleteFiles = [];
    try {
      dispatch({ type: LOADING, payload: true });
      var socketConnection = new WebSocket("ws://127.0.0.1:28080/Connection");
      socketConnection.onopen = function () {
        socketConnection.send(0);
      };
      socketConnection.onmessage = async function (e) {
        socketConnection.close();
        let filesPut = [];
        await Promise.all(
          data.documentos.map((f, index) => {
            filesPut.push({ uuid: index, nombre: f.name });
          })
        );
        var dataS3 = {
          documentos: filesPut,
          estampa: data.estampa,
          ltv: data.ltv,
        };
        try {
          const response = await axios.post(
            `${API_HOSTNAME_SIGNS}api/sign/generateS3UrlToken`,
            dataS3
          );
          if (response.status === 200) {
            let listFiles = [];
            let listSign = [];
            let signDocuments = [];
            await Promise.all(
              data.documentos.map(async (f, index) => {
                listFiles.push({
                  uuid: index,
                  base64: (await toBase64(f)).split(",").pop(),
                  nombre: f.name,
                });
              })
            );
            await Promise.all(
              response.data.documentos.map(async (file) => {
                deleteFiles.push(file.nombreBucket);
                deleteFiles.push("temporal_" + file.nombreBucket);
                deleteFiles.push(
                  "propiedades_" +
                    file.nombreBucket.replace(
                      "." + file.nombreBucket.split(".").pop(),
                      ""
                    )
                );
                var bytes = null;
                listFiles.map((f) => {
                  if (file.uuid == f.uuid && file.nombreReal == f.nombre) {
                    bytes = _base64ToArrayBuffer(f.base64);
                  }
                });
                if (bytes != null) {
                  let putDocument = await putDocumentsS3(
                    file.urlPutOriginal,
                    bytes
                  );
                  if (putDocument == 200) {
                    listSign.push(file);
                  } else {
                    enqueueSnackbar(
                      "Error al cargar el archivo " +
                        file.nombreReal +
                        " en S3 Token",
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      }
                    );
                  }
                }
              })
            );
            const toSend = {
              estampa: data.estampa,
              ltv: data.ltv,
              documentos: listSign,
            };
            const responseHash = await axios.post(
              `${API_HOSTNAME_SIGNS}api/sign/generateHash`,
              toSend
            );
            if (responseHash.status === 200) {
              let listSocket = [];
              await Promise.all(
                responseHash.data.documentos.map(async (document) => {
                  let dataSocket = {
                    Uuid: document.uuid,
                    Data: document.hash,
                  };
                  listSocket.push(dataSocket);
                })
              );
              let payload = {
                token: responseHash.data.token,
                data: listSocket,
              };
              var sock = new WebSocket("ws://127.0.0.1:28080/Signer");
              sock.onopen = function () {
                sock.send(JSON.stringify(payload));
              };
              sock.onmessage = async function (e) {
                let responseSocket = JSON.parse(e.data);
                if (responseSocket.Certificate != null) {
                  await Promise.all(
                    responseHash.data.documentos.map(async (document) => {
                      responseSocket.Signature.map((signature) => {
                        if (signature.Uuid == document.uuid) {
                          document.certificado = responseSocket.Certificate;
                          document.hashFirmado = signature.Data;
                          signDocuments.push(document);
                        }
                      });
                    })
                  );
                  const toSign = {
                    estampa: data.estampa,
                    ltv: data.ltv,
                    documentos: signDocuments,
                  };
                  const responseSign = await axios.post(
                    `${API_HOSTNAME_SIGNS}api/sign/generateSign`,
                    toSign
                  );
                  if (responseSign.status === 200) {
                    enqueueSnackbar("Firma completa", {
                      variant: "success",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    });
                    close();
                    dispatch({ type: POP, payload: filesPut.length });
                    history.push("/home/ConsultSignedDocument");
                  } else {
                    enqueueSnackbar(responseSign.data.descripcionRespuesta, {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    });
                  }
                  sock.close();
                } else {
                  if (deleteFiles.length > 0) {
                    let toSend = {
                      folderName: null,
                      repository: null,
                      documentsName: deleteFiles,
                    };
                    const responseClean = await axios.post(
                      `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
                      toSend
                    );
                    console.log(responseClean);
                  }
                  sock.close();
                }
              };
              sock.onclose = async function () {
                dispatch({ type: LOADING, payload: false });
              };
            } else {
              enqueueSnackbar(responseHash.data.descripcionRespuesta, {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
              dispatch({ type: LOADING, payload: false });
            }
          } else {
            dispatch({ type: LOADING, payload: false });
            enqueueSnackbar(response.data.descripcionRespuesta, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
        } catch (e) {
          dispatch({ type: LOADING, payload: false });
          if (e.response) {
            if (e.response.status == 400) {
              enqueueSnackbar("Los datos enviados son incorrectos", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 401) {
              enqueueSnackbar(
                "No tiene disponibilidad para el " +
                  e.response.data.descripcionRespuesta,
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            } else if (e.response.status == 404) {
              enqueueSnackbar("El servicio solicitado no se encontró", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 500) {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        }
      };
      if (deleteFiles.length > 0) {
        let toSend = {
          folderName: null,
          repository: null,
          documentsName: deleteFiles,
        };
        const responseClean = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
          toSend
        );
        console.log(responseClean);
      }
      socketConnection.onerror = function () {
        dispatch({ type: LOADING, payload: false });
        enqueueSnackbar(
          "El asistente de firma no se está ejecutando. Ejecútelo e intente de nuevo.",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      };
    } catch (error) {
      if (deleteFiles.length > 0) {
        let toSend = {
          folderName: null,
          repository: null,
          documentsName: deleteFiles,
        };
        const responseClean = await axios.post(
          `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
          toSend
        );
        console.log(responseClean);
      }
      if (error.response) {
        if (error.response.status == 400) {
          enqueueSnackbar("Los datos enviados son incorrectos", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status == 401) {
          enqueueSnackbar(
            "No tiene autorización para procesar la transacción",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        } else if (error.response.status == 404) {
          enqueueSnackbar("El servicio solicitado no se encontró", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status == 500) {
          enqueueSnackbar(
            "Ocurrió un error inesperado al procesar la transacción",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const getUrldocumentsToken =
  (selected, fileInfo, enqueueSnackbar, history, close) => async (dispatch) => {
    let deleteFiles = [];
    try {
      dispatch({ type: LOADING, payload: true });
      var socketConnection = new WebSocket("ws://127.0.0.1:28080/Connection");
      socketConnection.onopen = function () {
        socketConnection.send(0);
      };
      socketConnection.onmessage = async function (e) {
        socketConnection.close();
        let filesPut = [];
        let urlList = [];
        for (let i = 0; i < selected.length; i++) {
          const response = await axios.get(
            `${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
            {
              headers: {
                key: selected[i].ruta + "/" + selected[i].nombrerepositorio,
                pRepository: selected[i].repositorio,
              },
            }
          );
          const { data } = response;
          urlList.push({
            uuid: i,
            nombre: selected[i].nombrereal,
            url: data.url,
          });
        }
        await Promise.all(
          urlList.map((f) => {
            let nombreFinal = "";
            let nombre = f.nombre;
            let text = nombre;
            if (text.match(/\(([\w\s]*)\)/g)) {
              text.match(/\(([\w\s]*)\)/g).forEach((item) => {
                nombreFinal = nombre.replace(item, "");
              });
            } else {
              nombreFinal = nombre;
            }
            nombreFinal = nombreFinal.replace(".p7z", "");
            filesPut.push({ uuid: f.uuid, nombre: nombreFinal, url: f.url });
          })
        );
        var dataS3 = {
          documentos: urlList,
          estampa: fileInfo.estampa,
          ltv: fileInfo.ltv,
        };
        try {
          const response = await axios.post(
            `${API_HOSTNAME_SIGNS}api/sign/generateS3UrlToken`,
            dataS3
          );
          if (response.status === 200) {
            let listSign = [];
            let signDocuments = [];
            let documentos = response.data.documentos;
            documentos.forEach((document) => {
              deleteFiles.push("temporal_" + document.nombreBucket);
              deleteFiles.push(
                "propiedades_" +
                  document.nombreBucket.replace(
                    "." + document.nombreBucket.split(".").pop(),
                    ""
                  )
              );
              filesPut.forEach((file) => {
                if (document.uuid == file.uuid) {
                  document.nombreReal = file.nombre;
                  document.urlGetOriginal = file.url;
                  listSign.push(document);
                }
              });
            });
            const toSend = {
              estampa: fileInfo.estampa,
              ltv: fileInfo.ltv,
              documentos: listSign,
            };
            const responseHash = await axios.post(
              `${API_HOSTNAME_SIGNS}api/sign/generateHash`,
              toSend
            );
            if (responseHash.status === 200) {
              let listSocket = [];
              await Promise.all(
                responseHash.data.documentos.map(async (document) => {
                  let dataSocket = {
                    Uuid: document.uuid,
                    Data: document.hash,
                  };
                  listSocket.push(dataSocket);
                })
              );
              let payload = {
                token: responseHash.data.token,
                data: listSocket,
              };
              var sock = new WebSocket("ws://127.0.0.1:28080/Signer");
              sock.onopen = function () {
                sock.send(JSON.stringify(payload));
              };
              sock.onmessage = async function (e) {
                let responseSocket = JSON.parse(e.data);
                if (responseSocket.Certificate != null) {
                  await Promise.all(
                    responseHash.data.documentos.map(async (document) => {
                      responseSocket.Signature.map((signature) => {
                        if (signature.Uuid == document.uuid) {
                          document.certificado = responseSocket.Certificate;
                          document.hashFirmado = signature.Data;
                          signDocuments.push(document);
                        }
                      });
                    })
                  );
                  const toSign = {
                    estampa: fileInfo.estampa,
                    ltv: fileInfo.ltv,
                    documentos: signDocuments,
                  };
                  const responseSign = await axios.post(
                    `${API_HOSTNAME_SIGNS}api/sign/generateSign`,
                    toSign
                  );
                  if (responseSign.status === 200) {
                    enqueueSnackbar("Firma completa", {
                      variant: "success",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    });
                    close();
                    dispatch({ type: POP, payload: urlList.length });
                    history.push("/home/ConsultSignedDocument");
                  } else {
                    let toSend = {
                      folderName: null,
                      documentsName: deleteFiles,
                    };
                    const responseClean = await axios.post(
                      `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
                      toSend
                    );
                    if (responseClean.status === 200) {
                      enqueueSnackbar(responseSign.data.descripcionRespuesta, {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      });
                    } else {
                      enqueueSnackbar(responseClean.data.descripcionRespuesta, {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      });
                    }
                  }
                  sock.close();
                } else {
                  let toSend = {
                    folderName: null,
                    documentsName: deleteFiles,
                  };
                  const responseClean = await axios.post(
                    `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
                    toSend
                  );
                  if (responseClean.status === 200) {
                  } else {
                    enqueueSnackbar(responseClean.data.descripcionRespuesta, {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    });
                  }
                  sock.close();
                }
              };
              sock.onclose = async function () {
                dispatch({ type: LOADING, payload: false });
              };
            } else {
              let toSend = {
                folderName: null,
                documentsName: deleteFiles,
              };
              const response = await axios.post(
                `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
                toSend
              );
              console.log(response.data);
              if (response.status === 200) {
                enqueueSnackbar(responseHash.data.descripcionRespuesta, {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              } else {
                enqueueSnackbar(responseHash.data.descripcionRespuesta, {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              }
              dispatch({ type: LOADING, payload: false });
            }
          } else {
            enqueueSnackbar(response.data.descripcionRespuesta, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            dispatch({ type: LOADING, payload: false });
          }
        } catch (e) {
          dispatch({ type: LOADING, payload: false });
          if (e.response) {
            if (e.response.status == 400) {
              enqueueSnackbar("Los datos enviados son incorrectos", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 401) {
              enqueueSnackbar(
                "No tiene disponibilidad para el " +
                  e.response.data.descripcionRespuesta,
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            } else if (e.response.status == 404) {
              enqueueSnackbar("El servicio solicitado no se encontró", {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            } else if (e.response.status == 500) {
              enqueueSnackbar(
                "Ocurrió un error inesperado al procesar la transacción",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        }
      };
      socketConnection.onerror = function (event) {
        dispatch({ type: LOADING, payload: false });
        enqueueSnackbar(
          "El asistente de firma no se está ejecutando. Ejecútelo e intente de nuevo.",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      };
    } catch (error) {
      let toSend = {
        folderName: null,
        documentsName: deleteFiles,
      };
      const responseClean = await axios.post(
        `${API_HOSTNAME_SIGNS}api/sign/cleanBucket`,
        toSend
      );
      if (responseClean.status === 200) {
        if (error.response) {
          if (error.response.status == 400) {
            enqueueSnackbar("Los datos enviados son incorrectos", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          } else if (error.response.status == 401) {
            enqueueSnackbar(
              "No tiene autorización para procesar la transacción",
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          } else if (error.response.status == 404) {
            enqueueSnackbar("El servicio solicitado no se encontró", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          } else if (error.response.status == 500) {
            enqueueSnackbar(
              "Ocurrió un error inesperado al procesar la transacción",
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          }
        }
      } else {
        enqueueSnackbar(responseClean.data.descripcionRespuesta, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const mailOtpSendClient = (enqueueSnackbar) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${API_HOSTNAME}/api/SendEmail/SendMailOtp`
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status >= 400 && error.response.status < 500) {
        enqueueSnackbar(error.response.data.results.information, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      enqueueSnackbar(
        "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }
    return false;
  }
};

async function text(url) {
  return fetch(url).then(res => res.text());
}

export const generateCertificadoAction =
  (enqueueSnackbar, codeClie) => async (dispatch) => {
    var ip = "127.0.0.1";
    await text('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
      let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
      ip = data.match(ipRegex)[0];
    });
    try {
      const param = {
        ip:ip,
        code: codeClie,
      };
      const response = await axios.post(
        `${API_HOSTNAME}/api/SendEmail/ValidateCodeOtp`,
        param
      );
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 500) {
          enqueueSnackbar(error.response.data.result.information, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else if (error.response.status === 500) {
          enqueueSnackbar("Ocurrio un error procesando su solicitud.", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else {
        enqueueSnackbar(
          "Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      return null;
    }
  };

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function putDocumentsS3(url, data) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onload = function () {
      if (xhr.status != 200) {
        reject(xhr.status);
      } else {
        resolve(xhr.status);
      }
    };
    xhr.send(data);
  });
}

function getDocumentsS3(url) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (xhr.status != 200) {
        reject(xhr);
      } else {
        resolve(xhr);
      }
    };
    xhr.send();
  });
}