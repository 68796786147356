import React, { useState } from 'react'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ImgQR from '../../../assets/img/qr_img.png';
import { TextField, Checkbox, FormControl, FormGroup, FormControlLabel, Box, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        color: '#E55200',
        opacity: 1,
        fontFamily: 'Muli-Bold',
        fontSize: 28
    },
    text: {
        borderRadius: 10,
        opacity: 1,
        '& .MuiInputBase-input': {
            background: 'white !important'
        }
    },
    button: {
        background: '#F5AE19 0% 0% no-repeat padding-box',
        marginTop: '10px',
        boxShadow: '0px 3px 0px #00000033',
        borderColor: '#F5AE19 !important',
        borderRadius: 10,
        opacity: 1,
        textAlign: 'center',
        color: '#FFFFFF',
        textShadow: '0px 2px 0px #00000040',
        width: 180,
        fontWeight:'bolder',
        fontSize:"18px",
        height: 45
    },
    min: {
        width: 50,
        height: 50,
        position: 'fixed',
        marginTop: -80,
        marginLeft: 106
    },
}))
const ModalQR = (props) => {

    const classes = useStyles();
    const [checkedURL, setCheckedURL] = useState(false);
    const [checkedTPaginas, setCheckedTPaginas] = useState(false);
    const [textTitle, setTextTitle] = useState('');

    const handleChange = (event) => {
        setCheckedURL(event.target.checked);
    }

    const handleChangeTPaginas = (event) => {
        setCheckedTPaginas(event.target.checked);
    }

    const handleChangeTitle = (event) => {
        const {value} = event.target
        if(value.length <= 40){
            setTextTitle(value);
        }
    }

    const handleClick = (event) =>{
        props.setTypeImg(props.qr)
        props.setConfigQR({
            title: textTitle,
            url: checkedURL,
            tPaginas: checkedTPaginas
        })
        props.setSignImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIQAAACEAQMAAABrihHkAAAABlBMVEX///8AAABVwtN+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAyElEQVRIie2Uyw3DMAxDtQH335IbsKKdBO2lRcQeIxiC/A6M9UvVYz9MEu2PYEzak6DIFc+JYzTbMCb1ByJCMfFBqT5yv09c8GUfvbhPbND5ujmxqD/A6qoFRHCGLv6V6YisidISVUZQ6OOEKyD9stZtR4AB6YIBHigPZ0B8bc2WPjOdka0rgVdPR6Tv3P8RVUK2evmJYEC8IHDVmZE1kNouIl5c35UT/7B7fY/U58Tt49q7gLjwlM61mxJXvmMn+taL++SxL/YC+rrZ93htqY0AAAAASUVORK5CYII=');
        props.setVerQR(false)
        props.setQR(true);
    }

    return (
        <>
            <Paper tabIndex={-1} className="sign-paper">
                <Grid container spacing={0}>
                    <Grid item lg={12} xs={12} >
                        <Box fontWeight="fontWeightBold" m={1} className={classes.title}>
                            QR
                        </Box>
                        <IconButton
                            edge="start"
                            aria-label="upload file"
                            onClick={e => { props.setQR(false);props.setVerQR(false) }}
                            className={classes.min}
                        >
                            <HighlightOffIcon
                                color="primary"
                                fontSize="large"
                            />
                        </IconButton>
                    </Grid>
                    <Grid item lg={12} xs={12} >
                        <form noValidate autoComplete="off" >
                            <TextField id="outlined-basic" placeholder="Encabezado QR" variant="outlined" className={'selectQR'} value={textTitle} onChange={handleChangeTitle}/>
                        </form>
                    </Grid>
                    <Grid style={{ marginBottom: '-2px' ,paddingTop: '10px' }} item lg={12} xs={12} >
                        <img src={ImgQR}></img>
                    </Grid>
                    <Grid style={{ paddingLeft: '18px' }} item lg={6} xs={6} >
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={checkedURL} onChange={handleChange} />}
                                    label="URL QR"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid  style={{ paddingLeft: '-4px' }}  item lg={6} xs={6} >
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={checkedTPaginas} onChange={handleChangeTPaginas} />}
                                    label="Todas las páginas"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} xs={12} >
                        <button
                            className={classes.button}
                            onClick={handleClick}
                        >
                            Aceptar
                        </button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default ModalQR