import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getPrivileges,
  modifyPrivilege,
  getApplicationById,
} from "../../actions/privileges.action";
import PrivilegeForm from "../privileges/privilegesForm.view";
import ViewLayout from "../../components/layouts/ViewLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import {
  Fab,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import NewUserIcon from "../../components/IconsLibrary/NewUserIcon";
import { useDispatch } from "react-redux";

/**
 * Privileges Component ( full view for list of Privileges)
 *
 * @export Class Component
 * @class Privileges
 * @extends {Component}
 * @returns Redux connect
 */
const useStyles = makeStyles((theme) => ({
  btnNewUser: (props) => ({
    flex: 1,
    textAlign: "right",
    paddingRight: "8px",
    width: "32px",
    height: "32px",
    marginRight: 40,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 1px #0000003D",
    border: `1px solid ${props.background}`,
    opacity: "1",
  }),
  newUserIcon: {
    stroke: theme.palette.secondary.main,
  },
  styleFab: {
    boxShadow: "none",
  },
}));

const Privileges = ({ component: Component, container, ...props }) => {
  const {
    privilegesReducer,
    getPrivileges,
    modifyPrivilege,
    history,
    getPrivilegeChangedResponse,
    Loading
  } = props;

  const classes = useStyles();
  const [showPrivilegeAlert, setShowPrivilegeAlert] = useState(false);
  const [privilegeId, setPrivilegeId] = useState(0);
  const [privilegeInfo, setPrivilegeInfo] = useState({});
  const dispatch = useDispatch();
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });
  const [isFormShowed, setFormShowed] = useState(false);
  const [option, setOption] = useState(0);
  const theme = useTheme();
  let [isLoading, setLoading] = useState(false);
  if(Loading !== undefined){
    setLoading = Loading;
  }

  const columns = [
    {
      id: "name",
      label: "Nombre privilegio",
      minWidth: 40,
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "createdon",
      label: "Fecha de creación",
      minWidth: 140,
      haveDropDown: true,
      format: (value) => {
        let splited = value.split("T")[0];
        let newDate = splited.split("-");
        return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      },
    },
    {
      id: "sharedApps",
      label: "Aplicación",
      minWidth: 140,
      haveDropDown: true,
      children: (value) => {
        let result = value.split(",");
        result = result.map((item) => item);
        const typographyStyle = {
          textDecoration: result.length > 2 ? "underline" : "",
          color: theme.palette.primary.main,
          font: "normal normal normal 14px/19px Muli",
          letterSpacing: "0px",
          cursor: "pointer",
        };
        return (
          <>
            {result.length > 2 ? (
              <Tooltip title={result.join(", ")}>
                <Typography
                  className={classes.labels}
                  align={"center"}
                  style={typographyStyle}
                >
                  {value.includes(",")
                    ? `${result[0]}, ${result[1]} ...`
                    : result[0]}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                className={classes.labels}
                align={"center"}
                style={typographyStyle}
              >
                {value.includes(",") ? `${result[0]}, ${result[1]}` : result[0]}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      id: "licence",
      label: "Licencia",
      haveDropDown: true,
      minWidth: 40,
      component: {
        handleChange: (e, value, id) => handleConfirmClick(e, value, id),
      },
      tooltipMessage: "licencia",
      format: (value) => (value === 1 || value === false ? false : true),
    },
    {
      id: "idstate",
      label: "Estado",
      minWidth: 40,
      haveDropDown: true,
      align: "center",
      component: {
        handleChange: (e, value, id) => handleConfirmClick(e, value, id),
      },
      tooltipMessage: "privilegio",
      withIcon: false,
      handleIconClick: (evt, id) => handleDeleteClick(evt, id),
      format: (value) => (value === 1 || value === false ? false : true),
    },
  ];

  const updateChecked = (type) => {
    getPrivileges();
  };

  useEffect(() => {
    if (!Array.isArray(getPrivilegeChangedResponse)) {
      setConfirmInactivate({
        open: true,
        message: getPrivilegeChangedResponse,
        item: "inactivate",
      });
      dispatch({
        type: "GET_PRIVILEGE_CHANGED",
        payload: [],
      });
      // setFormShowed(false)
    }
  }, [getPrivilegeChangedResponse, dispatch]);

  useEffect(() => {
    getPrivileges();
  }, [getPrivileges]);

  useEffect(() => {
    if (!!privilegesReducer.length) {
      setLoading(true);
      console.log("isLoading"+isLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilegesReducer]);

  const handleConfirmChecked = () => {
    const result = privilegesReducer.filter(
      (item) => item.id === confirmInactivate.item
    )[0];
    let privilegeToBeModified;
    if (confirmInactivate.name === "idstate") {
      privilegeToBeModified = {
        id: result.id,
        licence: result.licence,
        isAuditable: result.isAuditable,
        idstate: result.idstate === 0 ? false : true,
      };
    } else if (confirmInactivate.name === "licence") {
      privilegeToBeModified = {
        id: result.id,
        idstate: result.idstate === 0 ? false : true,
        isAuditable: result.isAuditable,
        licence: !result.licence,
      };
    }
    if (confirmInactivate.item !== "inactivate") {
      modifyPrivilege(
        privilegeToBeModified,
        history,
        updateChecked,
        confirmInactivate.name,
        true
      );
    }
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
      item: "",
      checked: false,
      message: "",
    });
  };

  const handleConfirmClick = (e, id, name) => {
    setConfirmInactivate({
      open: true,
      item: id,
      name: name,
      showBtnAccept: true,
      showBtnCancel: true,
      checked: e.target.checked,
      message: `¿Está seguro que desea ${
        e.target.checked ? "activar" : "inactivar"
      }
      ${name === "licence" ? "esta licencia" : "este privilegio"}?`,
    });
  };

  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          sm: 7,
          xs: 12,
        },
      },
      menuOrder: {
        options: [
          {
            label: "Activo",
            columns: [
              {
                prop: "idstate",
                direction: -1,
              },
            ],
          },
          {
            label: "Inactivo",
            columns: [
              {
                prop: "idstate",
              },
            ],
          },
          {
            label: "Más reciente",
            columns: [
              {
                prop: "createdon",
                direction: 1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "createdon",
                direction: -1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
        ],
        cellSize: {
          sm: 2,
          xs: 2,
        },
      },
      // exportButton: {
      //   cellSize: {
      //     lg: 6,
      //     md: 2,
      //     sm: 2
      //   }
      // },
      children: () => {
        return (
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={2}
            xs={2}
            direction="row-reverse"
          >
            <p className={classes.buttonNewUser}>
              <Tooltip
                title={`Crear privilegio`}
                aria-label={`Nuevo privilegio`}
              >
                <Fab
                  size="small"
                  className={[classes.fab, classes.styleFab]}
                  onClick={() => {
                    setFormShowed(true);
                    setPrivilegeId(0);
                    setPrivilegeInfo({});
                  }}
                >
                  <NewUserIcon className={classes.newUserIcon} />
                </Fab>
              </Tooltip>
            </p>
          </Grid>
        );
      },
    };

    return button;
  };

  const handleCellClick = (evt, row) => {
    setPrivilegeId(row.id);
    setPrivilegeInfo(row);
    setFormShowed(true);
    setLoading(true);
  };
  /**
   *  Maneja el evento click de el icono basurero
   * @param {*} evt
   * @param {*} id
   */
  const handleDeleteClick = (evt, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      checked: evt.target.checked,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: true,
      message: `¿Deseas eliminar este privilegio?`,
    });
  };

  return (
    <ViewLayout
      headerTitle={"Privilegios"}
      handleAccept={handleConfirmChecked}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      showAlert={showPrivilegeAlert}
      setShowAlert={setShowPrivilegeAlert}
      isLoading={isLoading}
    >
      {isFormShowed ? (
        <PrivilegeForm
          Id={privilegeId}
          privilegeInfo={privilegeInfo}
          setPrivilegeId={setPrivilegeId}
          setCardState={setFormShowed}
          updateChecked={updateChecked}
          getPrivilegeChangedResponse={getPrivilegeChangedResponse}
          setLoading={setLoading}
          setShowPrivilegeAlert={setShowPrivilegeAlert}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={privilegesReducer}
          mainParam={"name"}
          buttons={getButtons()}
          havePagination={true}
          handleCellClick={handleCellClick}
          option={option}
          setOption={setOption}
          paramToDisable={"idstate"}
        />
      )}
    </ViewLayout>
  );
};

Privileges.propTypes = {
  getPrivileges: PropTypes.func.isRequired,
  modifyPrivilege: PropTypes.func.isRequired,
  getApplicationById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  privilegesReducer: state.privilegesReducer.getPrivilegesResponse,
  modifyPrivilege: state.privilegesReducer.getPrivilegeResponse,
  getApplicationsResponse: state.privilegesReducer.getApplicationsResponse,
  getPrivilegeChangedResponse:
    state.privilegesReducer.getPrivilegeChangedResponse,
});

const mapDispatchToProps = {
  getPrivileges,
  modifyPrivilege,
  getApplicationById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Privileges);
