import { useDialog } from '@pif-tr-components/components'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordForm } from '../../actions/auth.action'
import { GET_ERROR_RESET_PASSWORD, GET_RESPONSE_RESET_PASSWORD } from '../../actions/types'
import ButtonSaveChanges from '../../components/ButtonForm/ButtonSaveChanges'
import { ClientsView } from '../clients/Client.view'

const useStyles = makeStyles({
  errorText: {
    padding: "10px 15px",
  }
});

export const CredentialView = (props) => {

  const { userId, email, disabled } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getErrorResetPassword, getResponseResetPassword } = useSelector(store => store.authReducer)
  const [viewErrors, setViewErrors] = useState("")

  const [messageDialogData, setMessageDialogData] = useState("")
  const { Dialog: MessageDialog, onOpen: messageDialogOpen } = useDialog({
    bodyText: messageDialogData,
  })

  const resetPassword = () => {
    dispatch(
      resetPasswordForm({
        user: email,
        appname: "PIF",
      })
    )
  }

  useEffect(() => {
    if (getErrorResetPassword !== "") {
      setViewErrors(getErrorResetPassword);
      dispatch({
        type: GET_ERROR_RESET_PASSWORD,
        payload: "",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getErrorResetPassword])

  useEffect(() => {
    if (getResponseResetPassword !== "") {
      setMessageDialogData(getResponseResetPassword);
      messageDialogOpen()
      dispatch({
        type: GET_RESPONSE_RESET_PASSWORD,
        payload: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResponseResetPassword])


  return (
    <>
      <Grid container>
        <Typography margin="dense">
          Enviar correo para actualización de contraseña
        </Typography>
      </Grid>
      <Grid container>
        <ButtonSaveChanges
          handleClick={resetPassword}
          title={"Actualizar contraseña"}
          color="primary"
          variant="contained"
          id="buttonSave"
          margin="dense"
          isDisabled={disabled}
        />
      </Grid>
      <Grid>
        {viewErrors !== "" && (
          <div className={classes.errorText}>
            <Typography color="error" variant="subtitle2">
              {viewErrors}
            </Typography>
          </div>
        )}
      </Grid>
      <Grid container>
        <Divider style={{ height: "1px", width: "100%", marginTop: "20px", marginBottom: "20px" }} />
      </Grid>
      <Grid container>
        <Typography margin="dense">
          Clientes Oauth 2.0
        </Typography>
      </Grid>
      <ClientsView disabled={disabled} isRoute={false} userId={userId} />
      <MessageDialog />
    </>
  )
}
