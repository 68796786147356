import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import MenuItem from "@material-ui/core/MenuItem";
import "./OriginSignature.css";
import "./Pdf.css";
import { generateCertificadoAction } from "../../../actions/SignAction";
//import { getCertificates } from "../../../redux/actions/SignCunstomDocumentAction";
import { mailOtpSendClient } from "../../../actions/SignAction"
import { Rules } from "../../../helpers/RHFRules";
import FormHelperText from "@material-ui/core/FormHelperText";
import { MAX_NUMBER_ATTEMPS_ELECTRONIC } from '../../../types/ApiConstants';
import IconButton from "@material-ui/core/IconButton";
import Loading from '../../../components/Sign/Loading';
import { FormControl } from "@material-ui/core";

const certificadoOptions = [
  { id: "2", label: "Centralizado" },
  { id: "3", label: "Token" },
  { id: "4", label: "Firma electrónica" },
];

const defaultValues = {
  tipoCertificado: "",
  tsaId: "",
  tsaPassword: "",
};

const TextFieldVerify = withStyles({
  root: {
    '& input:valid + fieldset': {
      boxShadow: '0px 3px 0px #00000033 !important',
      border: '0.8387856483459473px solid #706F6F !important',
      borderRadius: '10px !important',
      padding: '5px !important',
    },
    '& input:valid:focus + fieldset': {
      boxShadow: '0px 3px 0px #00000033 !important',
      border: '0.8387856483459473px solid #706F6F !important',
      borderRadius: '10px !important',
      padding: '5px !important',
    },
    '& label.Mui-focused': {
      left: "-45px !important",
      marginTop: "-2px",
      font: 'normal normal normal 14px/22px Muli',
      color: '#707070',
    },
    "& label": {
      width: "90%",
      marginTop: "-9px",
      font: 'normal normal normal 18px/22px Muli',
      color: '#B2B2B2'
    },
    opacity: 0.7
  },
})(TextField);

const TextFieldVerifyStyle = withStyles({
  root: {
    '& input:valid + fieldset': {
      boxShadow: '0px 3px 0px #00000033',
      border: '0.8387856483459473px solid #706F6F',
      borderRadius: '10px',
      padding: '5px !important',
    },
    '& input:valid:focus + fieldset': {
      boxShadow: '0px 3px 0px #00000033',
      border: '0.8387856483459473px solid #706F6F',
      borderRadius: '10px',
      padding: '5px !important',
    },
    color: '#B2B2B2 !important',
    font: 'normal normal 600 18px/22px Muli'
  },
})(TextField);

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
  resize: {
    paddingLeft: "4%",
    fontSize: "15px",
    letterSpacing: "0px",
    color: "black",
    opacity: "1",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: 0,
    },
  },
  validateCheck: {
    font: "normal normal 600 16px/20px Muli",
    margin: "35px 48px 14px",
  },
  validateCode: {
    font: "normal normal 600 16px/20px Muli",
    margin: "37px 70px 14px",
  },
  validateLink: {
    font: "normal normal 600 16px/20px Muli",
    color: "#E55200",
    margin: "30px 90px 22px",
    textDecoration: "underline",
    textTransform: "inherit",
    cursor: "pointer",
  },
  textModalElectro: {
    font: "normal normal 100 16px/24px Muli",
    padding: "16px 0px 0px",
    color: "#4F4F4F",
  },
  textModalElectro2: {
    font: "normal normal 100 16px/24px Muli",
    padding: "28px 0px 0px",
    color: "#4F4F4F",
  },
  input: {
    height: 5
  },
  cssLabel: {
    font: 'normal normal normal 15px/22px Muli',
    textAlign: "center !important",
    color: '#707070 !important',
  },
}));

const CredentialsSing = ({ setStatusModalSign, filesSize, terminar }) => {
  const dispatch = useDispatch();
  const idTSA = useSelector(
    ({ SignCunstomDocumentReducer }) => SignCunstomDocumentReducer?.idTSA ?? []
  );
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [s3Limit] = useState(4);
  const [sendElecSign, setSendElecSign] = useState(0);
  const [value, setValue] = useState("");
  const [enableButton, setEnableButton] = useState(true);
  const [attemps, setAttemps] = useState(0);
  const [codeMail, setCodeMail] = useState("");
  const [outMail, setOutMail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, errors, control } = useForm({ defaultValues });

  const onSubmit = (data) => {
    if (value == 3) {
      terminar("Token", "Token");
    } else if (value == 4) {
      terminar("electrónica", "electrónica");
    } else {
      terminar(data.tsaId, data.tsaPassword);
    }
  };

  const handleChange = async (id) => {
    if (id == 4) {
      setEnableButton(true);
      if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
        enqueueSnackbar("Superó el límite de intentos, intente más tarde", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
      let tokenClie = localStorage.getItem("JWT");
      let resultado = JSON.parse(window.atob(tokenClie.split(".")[1]));
      if (resultado.email.length > 2) {
        let email = resultado.email.split("@");
        let cantidad = email[0].length >= 6 ? 6 : email[0].length;
        let string = "";
        if(cantidad > 2){
          for (let i = 0; i < cantidad - 2; i++) string += '*';
          setOutMail(string + email[0].slice(-2) + "@" + email[1]);
        }else{
          string = cantidad === 1 ? "*" : "**";
          setOutMail(string + "@" + email[1]);
        }
        setLoading(true);
        let dataMail = await dispatch(mailOtpSendClient(enqueueSnackbar));
        setLoading(false);
        if (dataMail) {
          setSendElecSign(1);
        } else {
          setSendElecSign(2);
        }
      } else {
        enqueueSnackbar("Error al obtener el correo", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }
    setValue(id);
  };

  const handleSend = async () => {
    if (value == 4 && codeMail !== "") {
      setEnableButton(true);
      if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
        enqueueSnackbar("Superó el límite de intentos, intente más tarde", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
      let firmaS3 = false;
      if (filesSize > s3Limit * 1024) {
        firmaS3 = true;
      }
      setLoading(true);
      let response = await dispatch(generateCertificadoAction(enqueueSnackbar, codeMail));
      setLoading(false);
      if (response) {
        if (response.status === 200) {
          terminar(response.data.result.certificateId, response.data.result.password, response.data.result.codeId,true);
        }
      }
      setAttemps(attemps + 1);
    }
  };

  const onNoCertificates = () => {
    handleChange(4);
  }

  /*useEffect(() => {
    (async () => {
      let state = await dispatch(getCertificates(onNoCertificates));
      if (state) setLoading(false);
    })()
  }, []);*/

  const handleInputChange = (event) => {
    if (event !== "") {
      setEnableButton(false);
      setCodeMail(event);
    } else {
      setEnableButton(true);
      setCodeMail("");
    }
  };

  if (loading) return <Loading />;

  if (sendElecSign == 1) {
    return (
      <Paper
        id="paperElectro1"
        elevation={3}
        className="sign-paper-source-signature"
      >
        <Grid
          container
          direction="row"
          className={classes.grid}
          justify="flex-end"
        >
          <IconButton
            edge="start"
            aria-label="upload file"
            onClick={() => setStatusModalSign(false)}
            className={"btn-close-sign-modal"}
          ></IconButton>
        </Grid>
        <div>
          <form>
            <FormControl
              autoComplete="off"
              noValidate
              className={classes.form}
            >
              <Typography className={classes.textModalElectro}>
                Hemos enviado un código a <span style={{ fontWeight: 'bold' }}>{outMail}</span>, <br></br>por favor ingrésalo a
                continuacion (puede haber<br></br>llegado a tu correo no deseado).
              </Typography>
              <Controller
                as={
                  <TextFieldVerify
                    name="codeValidate"
                    label={enableButton && "Código de verificación"}
                    variant="outlined"
                    autoFocus={true}
                    inputProps={{style: { textAlign: 'center' }}}
                    InputProps={{ autoComplete: "off", classes: { input: classes.input } }}
                  />
                }
                name="codeValidate"
                control={control}
                rules={Rules.required}
                className={classes.validateCode}
                onChange={([event]) => {
                  handleInputChange(event.target.value);
                }}
              />
              <Grid
                style={{ margin: "0px 0px 0px" }}
                container
                justify="center"
              >
                <Button
                  className={"btnGeneral18"}
                  id="buttonVerify"
                  disabled={enableButton}
                  style={{ width: '154px', height: '41px' }}
                  onClick={handleSend}
                >
                  Verificar
                </Button>
              </Grid>
              <Typography
                className={classes.validateLink}
                onClick={() => {
                  setSendElecSign(2);
                }}
              >
                ¿No has recibido el email?
              </Typography>
              <Typography className={classes.textModalElectro}>
                Debes tener en cuenta que un certificado <br></br> electrónico no tiene
                validez jurídica.
              </Typography>
            </FormControl>
          </form>
        </div>
      </Paper>
    );
  } else if (sendElecSign == 2) {
    return (
      <Paper
        id="paperElectro2"
        elevation={3}
        className="sign-paper-source-signature"
      >
        <Grid
          container
          direction="row"
          className={classes.grid}
          justify="space-between"
        >
          <IconButton
            edge="start"
            id="forwardCode"
            onClick={() => {
              setSendElecSign(1);
              setEnableButton(true);
            }}
            className={"btn-forward-sign-modal"}
          ></IconButton>
          <IconButton
            edge="start"
            aria-label="closeModal"
            onClick={() => setStatusModalSign(false)}
            className={"btn-close-sign-modal"}
          ></IconButton>
        </Grid>
        <div>
          <form>
            <FormControl
              autoComplete="off"
              noValidate
              className={classes.form}
            >
              <Typography className={"titlePopUpForwardMail"}>
                ¡Lo haremos de nuevo!
              </Typography>
              <Typography className={classes.textModalElectro2}>
                Reenviaremos a <span style={{fontWeight:"bold"}}>{outMail}</span><br></br> un nuevo
                código de verificación.
              </Typography>
              <Grid
                style={{ margin: "29px 0px 0px 0px" }}
                container
                justify="center"
              >
                <Button
                  className={"btnGeneral18"}
                  onClick={() => {
                    handleChange(4);
                  }}
                  style={{ width: '154px', height: '41px' }}
                >
                  Aceptar
                </Button>
              </Grid>
            </FormControl>
          </form>
        </div>
      </Paper>
    );
  } else {
    return (
      <Paper
        id={value == 3 ? "paperToken" : ""}
        elevation={3}
        className="sign-paper-source-signature "
      >
        <center>
          <Grid
            container
            direction="row"
            className={classes.grid}
            justify="flex-end"
          >
            <IconButton
              edge="start"
              aria-label="upload file"
              onClick={() => setStatusModalSign(false)}
              className={"btn-close-sign-modal"}
            ></IconButton>
          </Grid>
          <div class="content">
            <form
              className={classes.form}
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              align="center"
            >
              <Typography color="primary" className={"titlePopUpCertificate"}>
                Certificado
              </Typography>
              <Controller
                as={
                  <Select
                    displayEmpty
                    value={""}
                    FormHelperTextProps={{
                      style: { margin: "0 0 0 0px", color: "red" },
                    }}
                    helperText={
                      errors.tipoCertificado
                        ? errors.tipoCertificado.message
                        : null
                    }
                  >
                    <MenuItem value="" className={"opionCustom"}>
                      <Typography className="certificateText">
                        Seleccione
                      </Typography>
                    </MenuItem>
                    {certificadoOptions.map((option) => (
                      <MenuItem
                        key={option.id}
                        onClick={() => handleChange(option.id)}
                        value={option.id}
                        className={"opionCustom"}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="tipoCertificado"
                control={control}
                rules={Rules.required}
                className={"selectCustom"}
              />
              {errors.tipoCertificado && (
                <FormHelperText style={{ marginBottom: '-10px', marginLeft: '37px', color: 'red' }} error={!!errors.tipoCertificado}>
                  {errors.tipoCertificado.message}
                </FormHelperText>
              )}
              {value != 3 && (
                <Controller
                  as={
                    <Select
                      displayEmpty
                      value={""}
                      FormHelperTextProps={{
                        style: { margin: "0 0 0 5px", color: "red" },
                      }}
                      helperText={errors.tsaId ? errors.tsaId.message : null}
                    >
                      <MenuItem value="">
                        <Typography className="certificateText">ID</Typography>
                      </MenuItem>
                      {idTSA.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          className={"opionCustom"}
                        >
                          <Typography style={{ maxWidth: "219px" }}>
                            {option.descripcion}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  name="tsaId"
                  control={control}
                  rules={Rules.required}
                  className={"selectCustom"}
                />
              )}
              {errors.tsaId && (
                <FormHelperText style={{ marginBottom: '-10px', marginLeft: '37px', color: 'red' }} error={!!errors.tsaId}>
                  {errors.tsaId.message}
                </FormHelperText>
              )}
              {value != 3 && (
                <Controller
                  as={
                    <TextField
                      placeholder="Contraseña"
                      type="password"
                      FormHelperTextProps={{
                        style: { margin: "0 0 0 5px", color: "red" },
                      }}
                      helperText={
                        errors.tsaPassword ? errors.tsaPassword.message : null
                      }
                      InputProps={{ classes: { input: classes.resize } }}
                    />
                  }
                  name="tsaPassword"
                  control={control}
                  rules={Rules.required}
                  className={"selectCustom"}
                />
              )}
              <center>
                <Grid
                  style={{ margin: "28px 0px 0px" }}
                  container
                  justify="center"
                >
                  <Button type="submit" className={"btnGeneral18"}>
                    Aceptar
                  </Button>
                </Grid>
              </center>
            </form>
          </div>
        </center>
      </Paper>
    );
  }
};

export default CredentialsSing;
