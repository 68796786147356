import {
  GET_OBJECTTYPE,
  GET_OBJECTTYPES,
  GET_FORMATTYPES,
  GET_PROTOCOLTYPES,
  GET_SERVICETYPES,
  GET_SERVICECATEGORIES,
  GET_METHODTYPES,
  GET_SECURITYTYPES,
  GET_RESPONSECODETYPES,
  GET_INTEGRATIONACTIONS,
  GET_CUSTOMVIEWS,
  GET_SIGNATURESUPPLIER,
  GET_TRANSACTIONTYPES,
  SET_OBJECTTYPE_ERROR,
  GET_OBJECTTYPE_CHANGED,
  GET_DASHBOARDTYPES
} from "../actions/types";

const initialState = {
  getObjectTypeResponse: [],
  getObjectTypesResponse: [],
  setObjectTypeErrorResponse: [],
  getObjectTypeChangedResponse: [],
  getFormatTypesResponse: [],
  getProtocolTypesResponse: [],
  getServiceTypesResponse: [],
  getServiceCategoriesResponse: [],
  getMethodTypesResponse: [],
  getSecurityTypesResponse: [],
  getResponseCodeTypesResponse: [],
  getIntegrationActionsResponse: [],
  getCustomViewsResponse: [],
  signatureSupplierResponse: [],
  getTransactionTypesResponse: [],
  getDashboardTypesResponse: [],
};

/**
 * objecttypesReducer (set objecttype or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, objecttype}
 */
export default function objectTypesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_OBJECTTYPES:
      return {
        ...state,
        getObjectTypesResponse: action.payload,
      };
    case GET_OBJECTTYPE:
      return {
        ...state,
        getObjectTypeResponse: action.payload,
      };
    case GET_OBJECTTYPE_CHANGED:
      return {
        ...state,
        getObjectTypeChangedResponse: action.payload,
      };
    case SET_OBJECTTYPE_ERROR:
      return {
        ...state,
        setObjectTypeErrorResponse: action.payload,
      };
    case GET_FORMATTYPES:
      return {
        ...state,
        getFormatTypesResponse: action.payload,
      };
    case GET_PROTOCOLTYPES:
      return {
        ...state,
        getProtocolTypesResponse: action.payload,
      };
    case GET_SERVICETYPES:
      return {
        ...state,
        getServiceTypesResponse: action.payload,
      };
    case GET_SERVICECATEGORIES:
      return {
        ...state,
        getServiceCategoriesResponse: action.payload,
      };
    case GET_METHODTYPES:
      return {
        ...state,
        getMethodTypesResponse: action.payload,
      };
    case GET_SECURITYTYPES:
      return {
        ...state,
        getSecurityTypesResponse: action.payload,
      };
    case GET_RESPONSECODETYPES:
      return {
        ...state,
        getResponseCodeTypesResponse: action.payload,
      };
    case GET_INTEGRATIONACTIONS:
      return {
        ...state,
        getIntegrationActionsResponse: action.payload,
      };
    case GET_CUSTOMVIEWS:
      return {
        ...state,
        getCustomViewsResponse: action.payload,
      };
    case GET_SIGNATURESUPPLIER:
      return {
        ...state,
        signatureSupplierResponse: action.payload,
      };
    case GET_TRANSACTIONTYPES:
      return {
        ...state,
        getTransactionTypesResponse: action.payload,
      };
      case GET_DASHBOARDTYPES:
      return {
        ...state,
        getDashboardTypesResponse: action.payload,
      };
    default:
      return state;
  }
}
