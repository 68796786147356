import {
  GET_SERVICE,
  GET_SERVICES,
  SET_SERVICE_ERROR,
  GET_SERVICE_CHANGED,
} from "../actions/types";

const initialState = {
  getServiceResponse: [],
  getServicesResponse: [],
  setServiceErrorResponse: [],
  getServiceChangedResponse: []
};

/**
 * servicesReducer (set service or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, service}
 */
export default function servicesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        getServicesResponse: action.payload,
      };
    case GET_SERVICE:
      return {
        ...state,
        getServiceResponse: action.payload,
      };
    case GET_SERVICE_CHANGED:
      return {
        ...state,
        getServiceChangedResponse: action.payload,
      };
    case SET_SERVICE_ERROR:
      return {
        ...state,
        setServiceErrorResponse: action.payload,
      };
    default:
      return state;
  }
}
