import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {IconButton, useTheme, Grid, Tooltip, Typography, makeStyles, FormControlLabel} from "@material-ui/core";
import { HelpOutline, ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import FormLayout from "../../components/layouts/FormLayout";
import { validateEquals, isEmpty } from "../../utils/proprietaryHooks";
import { ButtonSaveChanges } from "../../components/ButtonForm/ButtonSaveChanges";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
import TabComponent from "../../components/TabsComponent/TabComponent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";

//#region Entity's dependencies
import { getApplications } from "../../actions/applications.action";
import { getDataTypes } from "../../actions/datatype.action";
import { getIntegrationTypes } from "../../actions/integrationtype.action";
import { getIntegrationActions } from "../../actions/objecttype.action";
import { createIntegration, modifyIntegration, getIntegrationById } from "../../actions/integrations.action";
import { GET_INTEGRATION, GET_INTEGRATION_CHANGED } from "../../actions/types";
import { messagesResponseIntegrations } from "../../utils/constants";
import Service from "../services/services.view";
import Homologation from "../homologations/homologations.view";
import ConfigurationForm from "../integrations/configurationForm.view";
//#endregion

/**
 * IntegrationForm Component ( full view for form to create/modify integration )
 *
 * @export Class Component
 * @class IntegrationForm
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
    borderBottom: `2px solid ${theme.palette.common.grey}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary.main.main,
      fontWeight: "bold",
    },
  },
  selected: {
    color: theme.palette.secondary.main.main,
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    margin: "0px 15px",
  },
  tabsItem: {
    textTransform: "none",
  },
  tabs: {
    borderBottom: "1px solid #e8e8e8",
  },
  btnApplications: {
    marginBottom: "9px",
    boxShadow: "0px 3px 3px #0000001A",
    borderRadius: "5px",
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  buttonSave: {
    margin: "3px 0px",
    flex: 1,
    float: "right",
  },
  errorText: {
    padding: "10px 15px",
  },
  categoryButtonFalse: { background: "#FFFFFF 0% 0% no-repeat padding-box" },
  categoryButtonTrue: {
    background: "#6D6E71 0% 0% no-repeat padding-box",
    color: "white !important",
  },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.text.disabled,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
  nextAction: {
    stroke: theme.palette.info.main,
  },
}));

const IntegrationForm = (props) => {
  const classes = useStyles();
  /* #region  Props */
  const {
    userPrivileges,
    privileges,
    recordId,
    record,
    initialTab,
    customerId,
    setRecordId,
    setCardState,
    updateChecked,
    setShowRecordAlert,
    setLoading,
    getRecordResponse,
    recordChanged,
    recordErrorResponse,
    messagesResponse,
    GET_RECORD_CHANGED,
    GET_RECORD,
    dataTypesResponse,
    integrationTypesResponse,
    applicationsResponse,
    integrationActionsResponse,
    modifyRecord,
    createRecord,
    getRecordById,
    getDataTypes,
    getIntegrationTypes,
    getApplications,
    getIntegrationActions
  } = props;
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(initialTab ?? 0);
  const [charactersToEnd] = useState(
    !!record.description ? 500 - record.description.length : 500
  );
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  const [enabledTabsForm, setEnabledTabsForm] = useState(
    !isEmpty(record) ? false : true
  );
  const [states] = useState(
    [
      {id:9,name:"EN DISEÑO",actionname:"DISEÑAR"}
      , {id:10,name:"PUBLICA",actionname:"PUBLICAR"}
      , {id:6,name:"EN EJECUCIÓN",actionname:"INICIAR"}
      , {id:8,name:"DETENIDA",actionname:"DETENER"}
      , {id:7,name:"RE-INICIADA",actionname:"RE-INICIAR"}
    ]
  );
  const [stateOptions, setStateOptions] = useState(
    states.map(rec=> {
      return {
        id: rec.id,
        name: record.idstate === rec.id ? rec.name.charAt(0).toUpperCase() + rec.name.slice(1).toLowerCase() : rec.actionname
      }
    })
  );
  const jsonParse = (value, initialValue = {}, callback)=>{
    let obj = initialValue;
    try{
      obj = JSON.parse(value);
    } catch (ex){
      if(callback)
        callback(ex);
    }
    return obj;
  }
  const initialStateForm = {
    id: 0,
    name: "",
    description: "",
    concurrence: 100,
    hasVPN: false,
    hasIPsWhitelist: false,
    rulesPojo: "[]",
    responseRulesPojo: "[]",
    fieldsPojo: "",
    idSource: 0,
    idSourceIntegrationType: 1,
    idIntegrationAction: 492,
    idDestination: 1,
    idDestinationIntegrationType: 1,
    idCustomer: customerId,
    idstate: 0,
    createdby: 0,
    modifiedby: 0,
    idbusinessunit: 0,
    idowner: 0,
    /***************************** */
    services:[
      {idServiceCategory: 488, name:"Servicio origen", code: "PIF.IC", idFormatType: 7, idProtocolType: 13, idServiceType: 19, idMethodType: 21, idSecurityType: 27, idApplication: 62, idstate: 0, pojo: "{}", responsePojo:"{}"},
      {idServiceCategory: 489, name:"Servicio destino", code: "PIF.IE", idFormatType: 7, idProtocolType: 13, idServiceType: 20, idMethodType: 21, idSecurityType: 27, idApplication: 61, idstate: 0, pojo: "{}", responsePojo:"{}" }
    ]
  };
  const setRecord = (rec) => {
    if(recordId){     
      let services = [];
      let sourceServices = rec.services.filter(ser=>ser.idstate === 0 && ser.idServiceCategory === 488);
      let destinationServices = rec.services.filter(ser=>ser.idstate === 0 && ser.idServiceCategory === 489);
      let newSourceService = {idServiceCategory: 488, name:"Servicio origen", code: "PIF.IC", idFormatType: 7, idProtocolType: 13, idServiceType: 19, idMethodType: 21, idSecurityType: 27, idApplication: 62, idstate: 0, pojo: "{}", responsePojo:"{}"};
      let newDestinationService = {idServiceCategory: 489, name:"Servicio destino", code: "PIF.IE", idFormatType: 7, idProtocolType: 13, idServiceType: 20, idMethodType: 21, idSecurityType: 27, idApplication: 61, idstate: 0, pojo: "{}", responsePojo:"{}" };
      
      services.push(sourceServices.length?sourceServices[0]:newSourceService);
      services.push(destinationServices.length?destinationServices[0]:newDestinationService);

      rec.services = services;
      rec.services.forEach((service)=>{
        service.pojoObject = jsonParse(service.pojo);
        service.responsePojoObject = jsonParse(service.responsePojo);
      });
      rec.rulesObject = jsonParse(rec.rulesPojo, []);
      rec.responseRulesObject = jsonParse(rec.responseRulesPojo,[]);
    }
    return !isEmpty(rec) && recordId
    ? {
      id: rec.id,
      name: rec.name,
      description: rec.description,
      concurrence: rec.concurrence,
      jwtURL: rec.jwtURL,
      hasVPN: rec.hasVPN,
      hasIPsWhitelist: rec.hasIPsWhitelist,
      rulesPojo: rec.rulesPojo,
      responseRulesPojo: rec.responseRulesPojo,
      fieldsPojo: rec.fieldsPojo,
      idSource: rec.idSource,
      idSourceIntegrationType: rec.idSourceIntegrationType,
      idIntegrationAction: rec.idIntegrationAction,
      idDestination: rec.idDestination,
      idDestinationIntegrationType: rec.idDestinationIntegrationType,
      idCustomer: rec.idCustomer,
      idstate: rec.idstate,
      createdby: rec.createdby,
      createdon: rec.createdon,
      modifiedby: rec.modifiedby,
      idbusinessunit: rec.idbusinessunit,
      idowner: rec.idowner,
      /***************************** */
      sourceIntegrationTypeName: rec.sourceIntegrationType.name,
      destinationIntegrationTypeName: rec.destinationIntegrationType.name,
      sourceName: rec.source?.name,
      destinationName: rec.destination?.name,
      idDataType: rec.sourceIntegrationType.idDataType,
      services: rec.services,
      homologations: rec.homologations,
      rulesObject: rec.rulesObject,
      responseRulesObject: rec.responseRulesObject,
    }
    : initialStateForm
  };
  const [formRecordData, setFormRecordData] = useState(
    setRecord(record)
  );
  const [DataTypes, setDataTypes] = useState(
    [
      {id:'1', name:'Datos maestros'},
      {id:'2', name:'Datos transaccionales'},
    ]
  );
  const [IntegrationTypes, setIntegrationTypes] = useState(
    [
      {id:'1', name:'Proveedores'},
      {id:'2', name:'Artículos'},
      {id:'5', name:'Búsquedas'},
    ]
  );
  const [Applications, setApplications] = useState(
    [
      {id:'62', name:'Coupa'},
      {id:'61', name:'SAP'},
      {id:'63', name:'AX'},
      {id:'64', name:'Orcale EBusiness'},
      {id:'57', name:'PIF'},
    ]
  );
  const [IntegrationActions, setIntegrationActions] = useState(
    [
      {id:'492', name:'Exportación'},
      {id:'493', name:'Validación'},
      {id:'494', name:'Validación y exportación'},
    ]
  );
  const theme = useTheme();
  /* #endregion */

  /* #region  useRef */
  const validators = useRef({
    isNewRecord: recordId === 0 ? true : false,
  });
  const originalInfo = useRef({
    originalData: formRecordData,
  });
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    idIntegrationType: Yup.number().required(requiredMessage),
    idSource: Yup.number().required(requiredMessage),
    idDestination: Yup.number().required(requiredMessage),
    name: Yup.string().max(200).required(requiredMessage),
    description: Yup.string().max(500),
    concurrence: Yup.number().required(requiredMessage),
  });
  const {
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: { ...formRecordData },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  const lastTab = 2; //Tab donde se comienza a colocar los hijos (1:N) del actual registro, si no tiene colocar último tab + 1
  //#endregion

  const dispatch = useDispatch();

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    if (validators.current.isNewRecord) {
      await trigger();
      if (!isEmpty(formState.errors)) {
        return;
      }
      setTab(newTab);
    } else if (enabledTabsForm && tab < lastTab) {
      if (!compareObjects(tab)) {
        setTab(newTab);
        showMessageSaveOrUpdate({
          changeTab: false,
          cancelEdit: false,
          back: false,
        });
      } else {
        setTab(newTab);
      }
    } else {
      setTab(newTab);
    }
  };

  const saveRecord = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validators.current.isNewRecord) {
      await trigger();
      if (isEmpty(formState.errors)) {
        const [recordSaveData] = createObjectSave(true);
        confirmSaveChanges(recordSaveData);
      }
    } else {
      const [recordSaveData] = createObjectSave( true);
      recordSaveData.idCustomer=customerId;
      confirmSaveChanges(recordSaveData);
    }
  };

  const confirmSaveChanges = (recordSaveData) => {
    if (validators.current.isNewRecord) {
      createRecord(recordSaveData, updateChecked);
    } else {
      modifyRecord(recordSaveData, updateChecked, false, tab);
      setStateOptions(
        states.map(rec=> {
          return {
            id: rec.id,
            name: recordSaveData.idstate === rec.id ? rec.name.charAt(0).toUpperCase() + rec.name.slice(1).toLowerCase() : rec.actionname
          }
        })
      );
    }
  };

  const NotChange = () => {
    dispatch({
      type: GET_RECORD_CHANGED,
      payload: messagesResponse.noChanges,
    });
  };

  const createObjectSave = (includeForm) => {
    let recordSaveData = jsonParse(
      JSON.stringify(includeForm ? { ...getValues() } : { id: getValues().id })
    );

    recordSaveData.services.forEach((service)=>{
      if(service.idServiceCategory === 488 || service.idServiceCategory === 489){
        service.name = Applications.filter(app=>app.id === (service.idServiceCategory === 488 ? recordSaveData.idSource:recordSaveData.idDestination))[0].name
                        + " - " + IntegrationTypes.filter(it=>it.id === (service.idServiceCategory === 488 ? recordSaveData.idSourceIntegrationType:recordSaveData.idDestinationIntegrationType))[0].name;
        service.idApplication = parseInt(service.idServiceCategory === 488 ? recordSaveData.idSource:recordSaveData.idDestination);
      }
      service.modifiedon = new Date();

      if(recordId){
        service.pojo = JSON.stringify(service.pojoObject);
        service.responsePojo = JSON.stringify(service.responsePojoObject);
      } else {
        service.createdon = new Date();
      }
    });

    if(recordId){
      recordSaveData.rulesPojo = JSON.stringify(recordSaveData.rulesObject);
      recordSaveData.responseRulesPojo = JSON.stringify(recordSaveData.responseRulesObject);
    } else {
      recordSaveData.idDestinationIntegrationType = recordSaveData.idSourceIntegrationType;
    }

    return [recordSaveData];
  };

  const compareObjects = (tab) => {
    setLoading(true);
    let response = validateEquals(
      { ...getValues() },
      originalInfo.current.originalData
    );
    setLoading(false);
    return response;
  };

  const backButton = () => {
    if (validators.current.isNewRecord) {
      resetFieldComplete();
    } else if (enabledTabsForm) {
      if (!compareObjects(0)) {
        showMessageSaveOrUpdate({
          back: true,
          newTab: 0,
          changeTab: false,
          cancelEdit: false,
        });
      } else {
        resetFieldComplete();
      }
    } else {
      resetFieldComplete();
    }
  };

  useEffect(() => {
    return () => {
      resetFieldComplete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Array.isArray(recordErrorResponse)) {
      setLoading(false);
    }
  }, [setLoading, recordErrorResponse]);

  useEffect(() => {
    if (!Array.isArray(recordChanged)) {
      setCardState(true);
      setShowRecordAlert(true);
      setConfirmInactivate({
        ...confirmInactivate,
        open: false,
        item: "inactivate",
      });
    }

    if (!!recordChanged) {
      if (recordChanged === messagesResponse.createdRecord) {
        resetFieldComplete();
      }

      if (recordChanged === messagesResponse.updatedRecordData) {
        originalInfo.current.originalData = jsonParse(
          JSON.stringify({ ...getValues() })
        );
        activeValidations();
      }

      if (recordChanged === messagesResponse.updatedRecordConfig) {
        if (!confirmInactivate.changeTab && !confirmInactivate.back && !confirmInactivate.cancelEdit) {
          resetFieldComplete()
        } else {
          
          activeValidations();
        }
      }

      if (recordChanged === messagesResponse.noChanges) {
        // Cuando no hay cambios
        activeValidations();
      }

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordChanged]);

  const resetFieldComplete = () => {
    setRecordId(0);
    setTab(0);
    setConfirmInactivate((state) => ({ ...state, newTab: -1 }));
    setCardState(false);
    setFormRecordData(initialStateForm);
    originalInfo.current = {};
    setLoading(false);
    setConfirmInactivate({
      open: false,
    });
    dispatch({
      type: GET_RECORD,
      payload: [],
    });
    dispatch({
      type: GET_RECORD_CHANGED,
      payload: [],
    });
  };

  const showMessageSaveOrUpdate = (props) => {
    setConfirmInactivate({
      ...confirmInactivate,
      ...props,
      open: true,
      message: (
        <div>
          Hiciste modificaciones
          <br />
          ¿Deseas actualizarlas?
        </div>
      ),
      showBtnAccept: true,
      showBtnCancel: true,
    });
  };

  const closeAlertDefault = () => {
    setConfirmInactivate({
      open: false,
      checked: false,
      back: false,
      cancelEdit: false,
      changeTab: false,
      newTab: 0,
    });
  };

  const activeValidations = () => {
    if (confirmInactivate.changeTab) {
      setTab(confirmInactivate.newTab);
    }

    if (confirmInactivate.back) {
      if (tab === 0) {
        resetFieldComplete();
      } else {
        setTab(0);
      }
    }

    if (confirmInactivate.cancelEdit) {
      setEnabledTabsForm(false);
    }
  };

  const handleCancel = () => {
    let clone = jsonParse(JSON.stringify(originalInfo.current.originalData));
    for (const key in clone) {
      setValue(key, clone[key]);
    }
    setFormRecordData(clone);
    activeValidations();
    closeAlertDefault();
  };

  const handleAccept = (e) => {
    saveRecord(e);
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleChangeEdit = (response) => {
    if (!response) {
      if (!compareObjects(tab)) {
        showMessageSaveOrUpdate({
          cancelEdit: true,
          newTab: 0,
          changeTab: false,
          back: false,
        });
      } else {
        setEnabledTabsForm(response);
      }
    } else {
      setEnabledTabsForm(response);
    }
  };

  const defineSubHeader = () => {
    let subheader = "";
    if (!validators.current.isNewRecord) {
      subheader = (enabledTabsForm && tab < lastTab ? "Editar " + items[tab].label.toLowerCase() : items[tab].label) ;
    } else {
      subheader = "Nueva integración | " + items[tab].label;
    }
    return subheader;
  };

  const handleChangeCounter = async (field, action) => {
    if (action === "up") {
      if (field !== "") {
        setValue(field, parseInt(getValues()[field]) + 1, { shouldValidate: true });
      }
    }

    if (action === "down") {
      if (field !== "") {
        if (getValues()[field] >= 1) {
          setValue(field, parseInt(getValues()[field]) - 1, { shouldValidate: true });
        } else {
          setValue(field, parseInt(getValues()[field]), { shouldValidate: true });
        }
      }
    }
  }

  useEffect(() => {
    if(recordId && enabledTabsForm){
      setLoading(true);
      getRecordById(recordId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecordById, enabledTabsForm]);

  useEffect(() => {
    if (getRecordResponse?.id) {
      let recordRefresh = setRecord(getRecordResponse);
      if(!validateEquals(recordRefresh, originalInfo.current.originalData)){
        setFormRecordData(recordRefresh);
        for (const key in recordRefresh) {
          setValue(key, recordRefresh[key]);
        }
        originalInfo.current.originalData = jsonParse(
          JSON.stringify({ ...getValues() })
        );
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, getRecordResponse]);

  useEffect(() => {
    getDataTypes();
    getIntegrationActions();
    if (!integrationTypesResponse)
      getIntegrationTypes();
    if (!applicationsResponse)
      getApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getDataTypes, getIntegrationTypes, getApplications, getIntegrationActions]);

  useEffect(() => {
    if (dataTypesResponse) {
      setDataTypes(dataTypesResponse)
      setLoading(false);
    }
  }, [setLoading, dataTypesResponse]);

  useEffect(() => {
    if (integrationTypesResponse) {
      setIntegrationTypes(integrationTypesResponse)
      setLoading(false);
    }
  }, [setLoading, integrationTypesResponse]);

  useEffect(() => {
    if (applicationsResponse) {
      setApplications(applicationsResponse)
      setLoading(false);
    }
  }, [setLoading, applicationsResponse]);

  useEffect(() => {
    if (integrationActionsResponse) {
      setIntegrationActions(integrationActionsResponse)
      setLoading(false);
    }
  }, [setLoading, integrationActionsResponse]);

  const items = [
    {
      label: "Datos básicos",
      tabContent: (
        <>
          <Grid item lg={5} md={10} sm={10} xs={12}>
            <ControlledSelect
              id="idDataType"
              name="idDataType"
              label="Tipo de dato"
              options={DataTypes}
              error={errors.idDataType}
              helperText= {errors.idDataType?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={5} md={10} sm={10} xs={12}>
            <ControlledSelect
              id="idSourceIntegrationType"
              name="idSourceIntegrationType"
              label="Integración"
              options={IntegrationTypes}
              error={errors.idSourceIntegrationType}
              helperText= {errors.idSourceIntegrationType?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={5} md={10} sm={10} xs={12}>
            <ControlledSelect
              id="idSource"
              name="idSource"
              label="Origen"
              options={Applications}
              error={errors.idSource}
              helperText= {errors.idSource?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={5} md={10} sm={10} xs={12}>
            <ControlledSelect
              id="idDestination"
              name="idDestination"
              label="Destino"
              options={Applications}
              error={errors.idDestination}
              helperText= {errors.idDestination?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={5} md={10} sm={10} xs={12}>
            <ControlledInputRoundedForm
              id="name"
              name="name"
              inputProps={{ maxLength: 100 }}
              label="Nombre Integración"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
              disabled={!enabledTabsForm}
              error={errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item lg={5} md={10} sm={10} xs={12}>
            <ControlledSelect
              id="idIntegrationAction"
              name="idIntegrationAction"
              label="Acción"
              options={IntegrationActions}
              error={errors.idIntegrationAction}
              helperText= {errors.idIntegrationAction?.message}
              control={control}
              disabled={!enabledTabsForm}
              isSearchable={true}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <ControlledInputRoundedForm
              id="description"
              name="description"
              label="Descripción"
              multiline
              rows={5}
              inputProps={{ maxLength: 500 }}
              helperText={charactersToEnd}
              fullWidth
              disabled={!enabledTabsForm}
              control={control}
            />
          </Grid>
          <Grid container item spacing={2} lg={5} md={10} sm={10} xs={12} alignItems='center'>
            <Grid item lg={10} xs={11}>
              <ControlledInputRoundedForm
                id="concurrence"
                name="concurrence"
                label="Concurrencia"
                control={control}
                fullWidth
                inputProps={{ min: "0", step: "1", max: "1000000000" }}
                onInput={(e) => {
                  e.target.value = Math.max(1, parseInt(e.target.value) ? parseInt(e.target.value) : 0).toString().slice(0, 3);
                }}
                disabled={!enabledTabsForm}
                error={errors.concurrence}
                helperText={errors.concurrence?.message}
                adornment={{
                  endAdornment: (
                    <Grid>
                      <Grid lg={12} className={classes.GridAdornmentIcon}>
                        <IconButton
                          aria-label="Button UP"
                          style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledTabsForm ? 1 : 0.5 }}
                          disabled={!enabledTabsForm}
                          onClick={(e) => handleChangeCounter("concurrence", "up")} >
                          <ArrowDropUp />
                        </IconButton>
                      </Grid>
                      <Grid lg={12} className={classes.GridAdornmentIcon}>
                        <IconButton
                          aria-label="Button down"
                          style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledTabsForm ? 1 : 0.5 }}
                          disabled={!enabledTabsForm}
                          onClick={(e) => handleChangeCounter("concurrence", "down")} >
                          <ArrowDropDown />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                }}
              />
            </Grid>
            <Grid item lg={2} s={1}>
              <Tooltip title={"Cantidad de transacciones concurrentes por día, el valor debe ser mayor o igual a 0"}>
                <HelpOutline className={classes.helpIcon} />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item lg={2} md={10} sm={10} xs={12} style={{ paddingLeft: "10px", paddingTop: "5px" }}>
            <FormControlLabel
              className={classes.textField}
              label="¿Tiene VPN?"
              variant="outlined"
              margin="dense"
              labelPlacement="end"
              control={
                <ControlledSwitchComponent
                  id="hasVPN"
                  name="hasVPN"
                  color="primary"
                  isDisabled={!enabledTabsForm}
                  control={control}
                />
              }
            />
          </Grid>
          <Grid item lg={3} md={10} sm={10} xs={12} style={{ paddingLeft: "10px", paddingTop: "5px" }}>
            <FormControlLabel
              className={classes.textField}
              label="¿Tiene IP lista blanca?"
              variant="outlined"
              margin="dense"
              labelPlacement="end"
              control={
                <ControlledSwitchComponent
                  id="hasIPsWhitelist"
                  name="hasIPsWhitelist"
                  color="primary"
                  isDisabled={!enabledTabsForm}
                  control={control}
                />
              }
            />
          </Grid>
          {recordId && (<Grid item lg={5} md={10} sm={10} xs={12}>
            <ControlledSelect
              id="idstate"
              name="idstate"
              label="Cambiar de estado"
              options={stateOptions}
              error={errors.idstate}
              helperText= {errors.idstate?.message}
              control={control}
              disabled={!enabledTabsForm && !userPrivileges.changeState}
              isSearchable={true}
            />
          </Grid>)}
        </>
      ),
    }
  ];

  if(!validators.current.isNewRecord){
    items.push(
      {
        label: "Mapeo",
        tabContent: (
          <>
            <ConfigurationForm
              record={formRecordData}
              control={control}
              errors={errors}
              Applications={Applications}
              IntegrationTypes={IntegrationTypes}
              enabledTabsForm={enabledTabsForm}
            />
          </>
        ),
      },
      {
        label: "Homologaciones",
        tabContent: (
          <>
            <Homologation
              Loading={setLoading}
              parentId={recordId}
              recordId={recordId}
              ConfirmInactivate={setConfirmInactivate}
              privileges={privileges}
            />
          </>
        ),
      },
      {
        label: "Servicios",
        tabContent: (
          <>
            <Service
              Loading={setLoading}
              parentId={recordId}
              recordId={recordId}
              ConfirmInactivate={setConfirmInactivate}
              privileges={privileges}
            />
          </>
        ),
      }
    );
  }

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        handleIconClick={backButton}
        subheaderBase={formRecordData.name}
        subheader={defineSubHeader()}
        isEditing={tab < lastTab && !validators.current.isNewRecord && userPrivileges.modify}
        enabledForm={enabledTabsForm}
        setEnabledForm={handleChangeEdit}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        handleCancel={handleCancel}
        modalMessage={`¿Desea modificar esta integración?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"integrations tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={items}
        />
        {!recordId && tab < 0 && (
        <Grid container item lg={12} md={12} sm={12} alignItems="center" style={{ paddingLeft: 20 }} direction="row-reverse">
          <Tooltip id="button-next" title={tab===0?"Configuración":""} style={{ float: "right", marginTop: "17px 2px 17px 17px" }}>
            <IconButton onClick={(e) => {
              const newTab = tab + 1;
              handleChangeTab(e, newTab);
            }}>
              <ArrowIcon className={classes.nextArrow} />
            </IconButton>
          </Tooltip>
          <Typography
              color="primary"
              className={classes.subtitle}
              variant="h6"
            >
              Siguiente
          </Typography> 
        </Grid>
        )}  { ((!recordId && !(tab < 0)) || ((recordId && enabledTabsForm && tab < lastTab) || (userPrivileges.changeState && tab === 0))) && (
        <Grid container item direction="row" lg={12} justify="center">
          <Grid container item style={{ marginTop: "10px", marginBottom: "20px" }} lg={4}>
            <ButtonSaveChanges
              title={!recordId ? "Crear" :"Guardar"}
              isDisabled={!enabledTabsForm && !userPrivileges.changeState}
              handleClick={(e) => {
                if (validators.current.isNewRecord) {
                  handleSubmit(saveRecord(e));
                } else {
                  setConfirmInactivate({
                    cancelEdit: true
                  });
                  if (!compareObjects(0)) {
                    handleSubmit(saveRecord(e));
                  } else {
                    NotChange();
                  }
                }
              }}
            />
          </Grid>
        </Grid>
        )}
      </FormLayout>
    </div>
  );
};

//#region entities's declarations 
IntegrationForm.propTypes = {
  createRecord: PropTypes.func.isRequired,
  modifyRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getRecordResponse: state.integrationsReducer.getIntegrationResponse,
  recordChanged: state.integrationsReducer.getIntegrationChangedResponse,
  recordErrorResponse: state.integrationsReducer.setIntegrationErrorResponse,
  messagesResponse: messagesResponseIntegrations,
  GET_RECORD_CHANGED: GET_INTEGRATION_CHANGED,
  GET_RECORD: GET_INTEGRATION,
  dataTypesResponse: state.dataTypesReducer.getDataTypesResponse,
  integrationTypesResponse: state.integrationTypesReducer.getIntegrationTypesResponse,
  applicationsResponse: state.applicationsReducer.getApplicationsResponse,
  integrationActionsResponse: state.objectTypesReducer.getIntegrationActionsResponse
});

const mapDispatchToProps = {
  createRecord: createIntegration,
  modifyRecord: modifyIntegration,
  getRecordById: getIntegrationById,
  getDataTypes,
  getIntegrationTypes,
  getApplications,
  getIntegrationActions
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationForm);
//#endregion