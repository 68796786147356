import axios from "axios";
import { API_SECURITY_ENDPOINT } from "../config/config";
import { GET_ENTERPRISE, GET_ENTERPRISEBYID, GET_ENTERPRISES, SET_ENTERPRISE_ERROR, GET_ENTERPRISE_CHANGED } from "./types";
import { getRecords, getRecordById, createRecord, modifyRecord } from "./base.action";

//acciones
export const getEnterprise = (username, appname) => async (
  dispatch,
  getState
) => {
  try {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `${localStorage.getItem("jwtToken")}`,
        userId: username,
        app: appname,
      },
    };

    const res = await axios.get(
      API_SECURITY_ENDPOINT + "/api/customer/GetCustomerByUser",
      headers
    );

    dispatch({
      type: GET_ENTERPRISE,
      payload: res.data.result,
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getEnterpriseByID = (pId) => async (
  dispatch,
  getState
) => {
  try {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `${localStorage.getItem("jwtToken")}`,
        pId: pId,
      },
    };

    const res = await axios.get(
      API_SECURITY_ENDPOINT + "/api/customer/GetById",
      headers
    );

    dispatch({
      type: GET_ENTERPRISEBYID,
      payload: res.data.result.records[0],
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export /**
 * Service Action getServiceById
 *
 * @method GET
 * @param {*} id
 */
  const getEnterpriseById = (id) => async (dispatch) => {
    dispatch(getRecordById("customer", id, GET_ENTERPRISE, SET_ENTERPRISE_ERROR));
  };

export /**
 * Enterprises Action getEnterprises
 *
 * @method GET
 */
  const getEnterprises = () => async (dispatch) => {
    dispatch(getRecords("customer", GET_ENTERPRISES, SET_ENTERPRISE_ERROR));
  };


export /**
  * Enterprise Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createEnterprise = (recordToCreate, updateChecked) => async (dispatch) => {
    recordToCreate.concurrence = parseInt(recordToCreate.concurrence);
    dispatch(createRecord("customer", recordToCreate, updateChecked, GET_ENTERPRISE_CHANGED, SET_ENTERPRISE_ERROR));
 };
 

export /**
 * Enterprise Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyEnterprise = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("customer", recordToUpdate, updateChecked, isPrincipalPage, GET_ENTERPRISE_CHANGED, SET_ENTERPRISE_ERROR));  
};