import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  ActivateUser,
  getApplications,
  getUserById,
  createUser,
  getUsers,
  getUserExists,
  getAuthenticationTypes,
} from "../../actions/user.action";
import { makeStyles } from "@material-ui/core/styles";
import UserRoles from "../users/userRoles.view";
import UserCustomer from "../users/userCustomer.view";
import Grid from "@material-ui/core/Grid";
import ServiceIcon from "@mui/icons-material/Settings";
import CredentialIcon from "@mui/icons-material/VerifiedUserOutlined";
//import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormLayout from "../../components/layouts/FormLayout";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
//import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";
import StyledRadioButton from "../../components/ButtonForm/StyledRadioButton.component";
import BorderedSection from "../../components/CardBody/borderedSection.component";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getIdpTypes } from "../../actions/objecttype.action";
import {
  isArrayEqual,
  isEmpty,
  isEmptyObject,
  isEqual,
  normalizeStrings,
} from "../../utils/proprietaryHooks";
import { actions } from "../../utils/constants";
import {
  GET_CUSTOMERS,
  GET_ROLES,
  GET_USER,
  GET_USER_CHANGED,
  GET_USER_UPDATED,
  SET_USER_EXISTS_RESPONSE,
  SET_USER_ACTIVATED,
  GET_APPLICATIONS,
  GET_USER_ROLES,
  GET_USER_APPLICATIONS,
} from "../../actions/types";
import ButtonSaveChanges from "../../components/ButtonForm/ButtonSaveChanges";
import AlertConfirm from "../../components/alertConfirm.component";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import TabComponent from "../../components/TabsComponent/TabComponent";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import { HelpOutline } from "@material-ui/icons";
import useCounter from "../../hooks/useCounter";
import { CredentialView } from "./credentials.view";
import { getSignatureSupplier } from "../../actions/objecttype.action";
/**
 * UserForm Component ( full view for form to create/modify user )
 *
 * @export Class Component
 * @class UserForm
 * @extends {Component}
 * @returns Redux connect
 */

const UserForm = (props) => {
  const classes = useStyles();
  const {
    user,
    setUser,
    setCardState,
    getApplications,
    getApplicationsResponse,
    getUserResponse,
    setUserErrorResponse,
    createUser,
    setLoading,
    ActivateUser,
    getUserChangedResponse,
    getUserById,
    getUserExistsresponse,
    getUserExists,
    getCustomersResponse,
    activatedResponse,
    getUsers,
    getAuthenticationTypes,
    authenticationTypes,
    getSignatureSupplier,
    signatureSuppliers,
  } = props;

  //#region   states
  const [tab, setTab] = useState(0);
  const [selectedAppId, setSelectedAppId] = useState(0);
  const [errorsRoles, setErrorsRoles] = useState("");
  const [errorsCustomer, setErrorsCustomer] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [haveBeenEdited, setHaveBeenEdited] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [editedCustomers, setEditedCustomers] = useState([]);
  const [enabledForm, setEnabledForm] = useState(user.id !== 0 ? false : true);
  const [typeNewUser, setTypeNewUser] = useState("newUser");
  const [inputExists, setInputExists] = useState("");
  const [applicationList, setApplicationList] = useState([]);
  const [originalRoles, setOriginalRoles] = useState({});
  const [idpTypes, setIdpTypes] = useState([]);
  const [authTypeId, setAuthTypeId] = useState(1);
  const sortData = useCallback(
    (data) => {
      let first = true;
      if (user.id !== 0 && !!selectedAppId) {
        let currentApp = firstTime.filter(
          (item) => item.id === selectedAppId
        )[0];
        first = !currentApp.edited;
      }
      if (user.id !== 0 && first) {
        data = data.sort((a, b) =>
          a.name === b.name ? 0 : a.name > b.name ? 1 : -1
        );
        data = data.sort((a, b) =>
          a.assigned === b.assigned ? 0 : a.assigned ? -1 : 1
        );
      }
      return data;
    },
    [user.id, selectedAppId, firstTime]
  );
  const rolesRef = useRef(null);
  const rolesData = useCounter({
    applicationList,
    appId: selectedAppId,
    sortData,
  });

  const [inputDuplicate, setInputDuplicate] = useState({
    userName: false,
    email: false,
    samlIdentifier: false,
  });
  const [deletedUser, setDeletedUser] = useState(0);
  const [activeButtons, setActiveButtons] = useState(false);
  const [isEditing, setIsEditing] = useState(!!user.id);
  const [msgActiveUser, setMsgActiveUser] = useState({
    open: false,
    botCancel: false,
    message: "",
    showBtnAccept: true,
  });
  const [userData, setUserData] = useState(
    !!user
      ? {
          id: user.id,
          names: user.names,
          surnames: user.surnames,
          email: user.email,
          document: user.document,
          userName: user.userName,
          phoneNumber: user.phoneNumber === "0" ? "" : user.phoneNumber,
          authenticationtypeid: user.authenticationtypeid,
          samlidentifier: user.samlidentifier,
          identityProviderId: user.identityproviderid,
          signatureSupplier: user.signatureSupplier,
          signatureURL: user.signatureURL,
          signatureClientId: user.signatureClientId,
          signatureClientSecret: user.signatureClientSecret,
          signatureScope: user.signatureScope,
          signatureApiKey: user.signatureApiKey,
        }
      : {
          id: "",
          names: "",
          surnames: "",
          email: "",
          document: "",
          userName: "",
          phoneNumber: "",
          authenticationtypeid: 1,
          samlidentifier: "",
          identityProviderId: "",
          signatureSupplier: 0,
          signatureURL: "",
          signatureClientId: "",
          signatureClientSecret: "",
          signatureScope: "",
          signatureApiKey: "",
        }
  );
  const [rolAppCounter, setRolAppCounter] = useState([]);
  const [stateShrink, setStateShrink] = useState({
    id: false,
    names: false,
    surnames: false,
    email: false,
    document: false,
    userName: false,
    phoneNumber: false,
    authenticationtypeid: false,
    samlidentifier: false,
    identityProviderId: false,
    signatureSupplier: false,
    signatureURL: false,
    signatureClientId: false,
    signatureClientSecret: false,
    signatureScope: false,
    signatureApiKey: false,
  });
  const [prevUser, setPrevUser] = useState({
    id: user.id,
    names: user.names,
    surnames: user.surnames,
    email: user.email,
    document: user.document,
    userName: user.userName,
    phoneNumber: user.phoneNumber === "0" ? "" : user.phoneNumber,
    authenticationtypeid: user.authenticationtypeid,
    samlidentifier: user.samlidentifier,
    identityProviderId: user.identityProviderId,
    signatureSupplier: user.signatureSupplier,
    signatureURL: user.signatureURL,
    signatureClientId: user.signatureClientId,
    signatureClientSecret: user.signatureClientSecret,
    signatureScope: user.signatureScope,
    signatureApiKey: user.signatureApiKey,
  });
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    tab: 0,
    newTab: 0,
    action: "",
    checked: false,
    delete: false,
    message: "",
    showBtnAccept: true,
    showBtnCancel: true,
  });
  const dispatch = useDispatch();
  //#endregion

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    names: Yup.string().max(100).required(requiredMessage),
    userName: Yup.string()
      .max(100)
      .test(
        "duplicate",
        "El nombre de usuario ingresado ya se encuentra utilizado",
        () => !inputDuplicate.userName
      )
      .required(requiredMessage),
    surnames: Yup.string().max(100).required(requiredMessage),
    document: Yup.string().max(13).required(requiredMessage),
    phoneNumber: Yup.string(),
    email: Yup.string()
      .max(100)
      .email("El correo no posee el formato correcto.")
      .test(
        "duplicate",
        "El correo electrónico ingresado ya se encuentra utilizado",
        () => !inputDuplicate.email
      )
      .required(requiredMessage),
    authenticationtypeid: Yup.string().required(requiredMessage),
    samlidentifier: Yup.string()
      .nullable(true)
      .test(
        "duplicate",
        "El identificador SAML ingresado ya se encuentra utilizado",
        () => !inputDuplicate.samlIdentifier
      )
      .when("authenticationtypeid", {
        is: (op) => op === "2",
        then: Yup.string().nullable(true).required(requiredMessage),
      }),
    identityProviderId: Yup.string()
      .nullable(true)
      .when("authenticationtypeid", {
        is: (op) => op === "2",
        then: Yup.string().nullable(true).required(requiredMessage),
      }),
  });
  const {
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    setValue,
    setError,
    control,
    clearErrors,
  } = useForm({
    defaultValues: { ...userData },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  //#endregion

  //#region  event handlers
  const handleChangeTab = async (event, newTab) => {
    let canChange = true;
    await trigger();
    if (!isEmpty(formState.errors)) {
      return;
    }
    if (newTab === 2 && user.id === 0 && !validateCustomerPrincipal()) {
      if (tab === 1) {
        setErrorsCustomer(true);
      }
      return;
    }
    let values = getValues();
    if (user.id !== 0) {
      /** @todo: must be checked if have been changed */
      let validate = validateChanges();
      if (validate) {
        setTab(newTab);
        // if (newTab === 0) {
        //   setUserValues(userData);
        // }
      } else if (!validate && enabledForm) {
        setConfirmInactivate({
          open: true,
          message: (
            <div>
              Hiciste Modificaciones <br />
              ¿deseas actualizarlas?
            </div>
          ),
          newTab,
          tab,
          action: actions.UPDATE,
          checked: true,
          showBtnAccept: true,
          showBtnCancel: true,
        });
        canChange = false;
      }
    } else {
      setTab(newTab);
      if (newTab === 1) {
        setUserData({ id: 0, ...values });
      }
    }
    if (canChange) {
      switch (newTab) {
        case 0: {
          if (isEmpty(userData)) {
            setLoading(true);
          }
          break;
        }
        case 1: {
          if (!editedCustomers.length) {
            setLoading(true);
          }
          break;
        }
        case 2: {
          if (!Object.keys(rolesData.items).length) {
            setLoading(true);
          }
          break;
        }
        default:
          break;
      }
    }
  };

  /**
   * check if initial object is equal to current object
   * @returns {boolean}
   */
  const validateChanges = () => {
    const theUser = {
      id: user.id,
      ...getValues(),
    };
    switch (tab) {
      case 0:
        return isEqual(normalizeStrings(prevUser), normalizeStrings(theUser));
      case 1:
        return isArrayEqual(getCustomersResponse, customers);
      case 2:
        return rolesRef.current.editedRoles.length ? false : true;
      case 3:
        return isEqual(normalizeStrings(prevUser), normalizeStrings(theUser));
      default:
        break;
    }
  };

  const setUserValues = useCallback(
    (data) => {
      if (isEmpty(data)) {
        data = userData;
      }
      for (const key in data) {
        setValue(key, userData[key]);
      }
    },
    [setValue, userData]
  );

  const validateCustomerPrincipal = () => {
    return customers.some((customer) => customer.principal === true);
  };

  const saveUser = async (isUpdate, sameTab) => {
    await trigger();
    if (!validateCustomerPrincipal() && customers.length) {
      setErrorsCustomer("*Se requiere una empresa principal");
      setLoading(false);
      setTab(1);
      return;
    }
    if (isEmpty(formState.errors)) {
      // revisión de roles y customers
      if (!isUpdate) {
        let customersToBeSaved = customers.filter(
          (item) => item.assigned || item.principal
        );
        let rolesToBeSaved = rolesRef.current.editedRoles;
        if (rolesToBeSaved.length === 0) {
          setErrorsRoles("*Se requiere mínimo un rol");
          return;
        } else {
          setErrorsRoles("");
        }
        setIsDisabled(!isDisabled);
        setLoading(true);
        let userSave = {
          ...userData,
          id: user.id,
          username: userData.userName,
        };
        const finalUserData = {
          ...userSave,
          customer: customersToBeSaved.map((item) => ({
            ...item,
            assigned: true,
          })),
          roles: rolesToBeSaved.map((item) => ({
            role: item.name,
            assigned: item.assigned,
          })),
        };
        createUser("create", finalUserData, restartFields);
        setIsDisabled(true);
      } else if (isUpdate) {
        let values = getValues();
        setLoading(true);
        const {
          phoneNumber,
          samlidentifier,
          signatureSupplier,
          signatureURL,
          signatureClientId,
          signatureClientSecret,
          signatureScope,
          signatureApiKey,
          ...rest
        } = values;
        values = isEmptyObject(rest) ? userData : values;
        let savedUser = {
          ...values,
          id: user.id,
        };
        if (confirmInactivate.tab === 1 || (sameTab && tab === 1)) {
          const edited = customers.filter(
            (item) => !editedCustomers.some((other) => isEqual(other, item))
          );
          if (savedUser === undefined) {
            savedUser = {
              ...savedUser,
              ...userData,
            };
          }
          savedUser = {
            ...savedUser,
            customer: edited.map((item) =>
              item.principal ? { ...item, assigned: item.principal } : item
            ),
          };
        } else if (confirmInactivate.tab === 2 || (sameTab && tab === 2)) {
          const toSaveCustomers = customers.filter(
            (item) => !editedCustomers.some((other) => isEqual(other, item))
          );
          let toSaveRoles = rolesRef.current.editedRoles;
          savedUser = {
            ...savedUser,
            customer: toSaveCustomers,
            roles: toSaveRoles.map((item) => ({
              role: item.name,
              assigned: item.assigned,
            })),
          };
        }
        createUser(actions.UPDATE, savedUser, restartPartialFields, tab);
        setIsDisabled(true);
        //setHaveBeenEdited(true);
      }
    }
  };

  const backButton = () => {
    /*if(tab===3){
      setTab(2)
    }
    if (tab === 2) {
      setTab(1);
    }

    if (tab === 1) {
      setTab(0);
    }
    if (tab === 0) {*/
    if (!!user.id && enabledForm && !validateChanges()) {
      setConfirmInactivate({
        open: true,
        showBtnAccept: true,
        showBtnCancel: true,
        message: (
          <div>
            Hiciste Modificaciones <br />
            ¿deseas actualizarlas?
          </div>
        ),
      });
    } else {
      restartFields();
    }
    //}
  };

  const restartPartialFields = () => {
    setLoading(false);
  };

  const restartFields = () => {
    setCardState(false);
    setTab(0);
    setHaveBeenEdited(false);
    setUserData({
      id: 0,
      names: "",
      surnames: "",
      email: "",
      document: "",
      userName: "",
      authenticationtypeid: 1,
      samlidentifier: "",
      identityProviderId: "",
      signatureSupplier: 0,
      signatureURL: "",
      signatureClientId: "",
      signatureClientSecret: "",
      signatureScope: "",
      signatureApiKey: "",
    });
    setUser({ id: 0 });
    getUsers();
    setLoading(true);
    setPrevUser({});
    //setUserData({});
    //assignValues({})
    setSelectedAppId(0);
    setIsDisabled(false);
    setEnabledForm(true);
    dispatch({
      type: "GET_CUSTOMERS",
      payload: [],
    });
    dispatch({
      type: "GET_USER",
      payload: [],
    });
    dispatch({
      type: GET_USER_APPLICATIONS,
      payload: [],
    });
    dispatch({
      type: GET_USER_ROLES,
      payload: [],
    });
  };

  //#endregion

  //#region  effects

  /**
   * efecto que controla la reactivación de usuario
   */

  useEffect(() => {
    getSignatureSupplier();
  }, [getSignatureSupplier]);

  useEffect(() => {
    return () => {
      setLoading(false);
      setCustomers([]);
      dispatch({
        type: "GET_CUSTOMERS",
        payload: [],
      });
      dispatch({
        type: GET_USER_APPLICATIONS,
        payload: [],
      });
      dispatch({
        type: GET_USER_ROLES,
        payload: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(getUserExistsresponse)) {
      if (getUserExistsresponse.informationCode === "A20") {
        setDeletedUser(getUserExistsresponse.users[0].id);
        setActiveButtons(true);
        setMsgActiveUser({
          open: true,
          textButtonAccept: "Aceptar",
          botCancel: true,
          showBtnAccept: true,
          message:
            "El nombre de usuario y/o correo ingresados ya ha sido utilizado por un usuario que ha sido eliminado. Selecciona una de las siguientes acciones para Continuar",
        });
        setInputDuplicate((state) => ({ ...state, [inputExists]: false }));
        clearErrors(inputExists);
      } else if (getUserExistsresponse.informationCode === "A10") {
        setInputDuplicate((state) => ({ ...state, [inputExists]: true }));
        setError([inputExists], {
          type: "manual",
          message:
            inputExists === "userName"
              ? "El nombre de usuario ingresado ya se encuentra utilizado"
              : inputExists === "email"
              ? "El correo electrónico ingresado ya se encuentra utilizado"
              : "El identificador SAML ingresado ya se encuentra utilizado",
        });
      } else {
        setInputDuplicate((state) => ({ ...state, [inputExists]: false }));
        clearErrors(inputExists);
      }
      dispatch({
        type: SET_USER_EXISTS_RESPONSE,
        payload: {},
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserExistsresponse, setLoading, dispatch, inputExists]);

  useEffect(() => {
    if (!isEmpty(getUserChangedResponse)) {
      setConfirmInactivate({
        ...confirmInactivate,
        action: 0,
        open: true,
        message: getUserChangedResponse,
        showBtnAccept: false,
        showBtnCancel: false,
        customFunction: () => setLoading(true),
      });
      //  getUserById(user.id);

      dispatch({
        type: GET_USER_UPDATED,
        payload: {},
      });
      dispatch({
        type: GET_USER_CHANGED,
        payload: {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserChangedResponse]);

  useEffect(() => {
    if (!isEmpty(getUserResponse) && user.id !== 0) {
      if (!haveBeenEdited) {
        setEnabledForm(false);
      }
      const values = {
        id: getUserResponse.id,
        names: getUserResponse.names,
        surnames: getUserResponse.surnames,
        email: getUserResponse.email,
        document: getUserResponse.document,
        userName: getUserResponse.userName,
        phoneNumber: getUserResponse.phoneNumber,
        authenticationtypeid: getUserResponse.authenticationtypeid,
        signatureSupplier: getUserResponse.signatureSupplier,
        samlidentifier: getUserResponse.samlidentifier,
        identityProviderId: getUserResponse.identityProviderId,
        signatureSupplier: getUserResponse.signatureSupplier,
        signatureURL: getUserResponse.signatureURL,
        signatureClientId: getUserResponse.signatureClientId,
        signatureClientSecret: getUserResponse.signatureClientSecret,
        signatureScope: getUserResponse.signatureScope,
        signatureApiKey: getUserResponse.signatureApiKey,
      };
      //
      setUserData(values);
      setPrevUser(values);
      // assignValues(true, values);
      setTimeout(() => {
        setLoading(false);
        setConfirmInactivate({
          open: false,
          checked: false,
        });
      }, 1000);
      setErrorsRoles("");
      dispatch({
        type: GET_USER,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserResponse, setLoading]);

  useEffect(() => {
    if (!isEmpty(setUserErrorResponse)) {
      setLoading(false);
      setErrorsRoles(setUserErrorResponse.result.information);
      if (tab === 0) {
        setConfirmInactivate({
          open: true,
          message: setUserErrorResponse.result.information,
          showBtnAccept: true,
          showBtnCancel: false,
          textButtonAccept: "Aceptar",
          handleAccept: () => {
            setConfirmInactivate({ open: false });
            dispatch({
              type: "SET_USER_ERROR",
              payload: {},
            });
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, setUserErrorResponse]);

  useEffect(() => {
    getApplications(user.id);
  }, [getApplications, user.id]);

  useEffect(() => {
    setErrorsRoles();
  }, [errorsCustomer]);

  useEffect(() => {
    if (!isEmpty(errorsRoles) && errorsRoles !== undefined) {
      setErrorsRoles(errorsRoles);
    }
  }, [errorsRoles]);

  useEffect(() => {
    let response = getApplicationsResponse[0];
    if (response !== undefined) {
      setFirstTime(
        getApplicationsResponse.map((item) => ({
          name: item.name,
          id: item.id,
          edited: false,
        }))
      );
      setRolAppCounter(
        JSON.parse(
          JSON.stringify(
            getApplicationsResponse.map((item) => ({
              id: item.id,
              name: item.name,
              rolCount: item.rolCount ? item.rolCount : 0,
              rolCountUnique: item.rolCountUnique ? item.rolCountUnique : 0,
              title: item.title,
            }))
          )
        )
      );
      const appIdTemp = getApplicationsResponse.sort(
        (a, b) =>
          b.rolCount + b.rolCountUnique - (a.rolCount + a.rolCountUnique)
      )[0];
      setSelectedAppId(appIdTemp.id);
    }
  }, [getApplicationsResponse]);

  useEffect(() => {
    if (prevUser.userName !== undefined) {
      for (const key in prevUser) {
        if (prevUser[key] !== undefined && !isEmpty(prevUser[key])) {
          setStateShrink((state) => ({ ...state, [key]: true }));
        }
      }
    }
  }, [prevUser]);

  useEffect(() => {
    setAuthTypeId(user?.authenticationtypeid);
  }, [user?.authenticationtypeid]);

  useEffect(() => {
    setAuthTypeId(control.getValues().authenticationtypeid)
  }, [control.getValues().authenticationtypeid]);

  useEffect(() => {
    if (!isEmpty(activatedResponse)) {
      if (activatedResponse.informationCode === "A10") {
        setCustomers([]);
        setEditedCustomers([]);
        const values = {
          ...activatedResponse.user,
          phoneNumber:
            activatedResponse.user.phoneNumber === "0"
              ? ""
              : activatedResponse.user.phoneNumber,
        };
        setUserData((state) => ({
          ...state,
          ...values,
        }));
        setUser((state) => ({
          ...state,
          ...values,
        }));
        setPrevUser((state) => ({
          ...state,
          ...values,
        }));
        for (const key in userData) {
          if (key === "phoneNumber") {
            setValue(
              key,
              activatedResponse.user[key] === "0"
                ? ""
                : activatedResponse.user[key]
            );
          } else {
            setValue(key, activatedResponse.user[key]);
          }
        }
        setIsEditing(true);
        setEnabledForm(false);
        getApplications(activatedResponse.user.id);
        setActiveButtons(false);
        setInputDuplicate({
          userName: false,
          email: false,
          samlIdentifier: false,
        });
        clearErrors("userName");
        clearErrors("email");
        clearErrors("samlIdentifier");
        setMsgActiveUser({
          open: true,
          botCancel: false,
          showBtnAccept: false,
          message: activatedResponse.information,
        });
        dispatch({
          type: GET_CUSTOMERS,
          payload: {},
        });
        dispatch({
          type: GET_APPLICATIONS,
          payload: {},
        });
        dispatch({
          type: GET_USER_ROLES,
          payload: {},
        });
        rolesData.clearAll([]);
        setApplicationList([]);
      } else {
        setActiveButtons(false);
        setMsgActiveUser({
          open: true,
          botCancel: false,
          message: activatedResponse.information,
        });
      }
      setLoading(false);
      setTimeout(() => {
        setMsgActiveUser({
          open: false,
          botCancel: false,
          message: "",
        });
      }, 1500);
      dispatch({
        type: SET_USER_ACTIVATED,
        payload: {},
      });
    }
  }, [
    activatedResponse,
    userData,
    setLoading,
    dispatch,
    getApplications,
    setUser,
    setValue,
    rolesData,
    clearErrors,
  ]);

  useEffect(() => {
    getAuthenticationTypes();
  }, [getAuthenticationTypes]);

  //#endregion

  const defineSubHeader = () => {
    let subheader = "";
    if (user.id !== 0) {
      switch (tab) {
        case 0:
          subheader = enabledForm ? "Editar datos básicos" : "Datos básicos";
          break;
        case 1:
          subheader = enabledForm
            ? "Editar empresas de usuario"
            : "Empresas de usuario";
          break;
        case 2:
          subheader = enabledForm
            ? "Editar roles de usuario"
            : "Roles de usuario";
          break;
        case 3:
          subheader = enabledForm
            ? "Editar credenciales de seguridad"
            : "Credenciales de seguridad";
          break;
        default:
          subheader = "Nuevo usuario";
          break;
      }
    } else {
      subheader = "Nuevo usuario";
    }
    return subheader;
  };

  const handleAccept = () => {
    if (confirmInactivate.item === actions.CREATE) {
      setConfirmInactivate({
        open: false,
        checked: false,
        item: 0,
      });
      setCardState(false);
    } else if (confirmInactivate.action === actions.UPDATE) {
      setConfirmInactivate({
        ...confirmInactivate,
        open: false,
        checked: false,
        item: 0,
      });
      saveUser(true);
    } else {
      let { newTab } = confirmInactivate;
      if (confirmInactivate.tab === 0 && confirmInactivate.newTab === 0) {
        if (tab === 2) {
          setCardState(false);
          restartFields();
        }
        newTab = undefined;
      } else if (confirmInactivate.tab === 0) {
        dispatch({
          type: GET_USER_UPDATED,
          payload: {},
        });
        getUserById(user.id);
        getApplications();
        setLoading(true);
      } else if (confirmInactivate.tab === 1) {
        dispatch({
          type: GET_CUSTOMERS,
          payload: [],
        });
        setCustomers([]);
      } else if (confirmInactivate.tab === 2) {
        dispatch({
          type: GET_ROLES,
          payload: [],
        });
        if (confirmInactivate.newTab !== 1) {
          setSelectedAppId(0);
          setCardState(false);
          restartFields();
        }
      }
      dispatch({
        type: GET_USER_CHANGED,
        payload: {},
      });
      if (confirmInactivate.tab === undefined) {
        setCardState(false);
        restartFields();
      }
      if (tab !== 2) {
        getUserById(user.id);
      }
      if (!!newTab) {
        setTab(newTab);
      }
    }
  };

  const closeMessage = () => {
    if (typeNewUser === "newUser") {
      setValue(inputExists, "");
    } else {
      // dispatch({
      //   type: GET_APPLICATIONS,
      //   payload: [],
      // });

      setLoading(true);
      ActivateUser({
        id: deletedUser,
      });
    }

    setMsgActiveUser({
      open: false,
      botCancel: false,
      message: "",
    });
    setActiveButtons(false);

    // dispatch({
    //   type: SET_USER_ERROR,
    //   payload: {},
    // });
    // if (typeNewUser === "actUser" && activeButtons) {
    //   setActiveButtons(false);
    //   ActivateUser({
    //     id: deletedUser,
    //   });
    //   setMsgActiveUser({
    //     open: false,
    //     botCancel: false,
    //     message: "",
    //   });
    // } else if (typeNewUser === "retUser") {
    //   setActiveButtons(false);
    //   setMsgActiveUser({
    //     open: false,
    //     botCancel: false,
    //     message: "",
    //   });
    //   window.open("/users", "_self");
    // } else {
    //   setActiveButtons(false);
    //   setMsgActiveUser({
    //     open: false,
    //     botCancel: false,
    //     message: "",
    //   });
    // }
  };

  const handleCancel = (cancelEditing) => {
    switch (tab) {
      case 0:
        if (!!user) {
          setUserValues({ ...userData });
        }
        break;
      case 1:
        if (!!user) {
          setCustomers(JSON.parse(JSON.stringify(editedCustomers)));
        }
        break;
      case 2:
        if (!!user) {
          if (rolesRef.current.editedRoles.length) {
            console.log(originalRoles);
            const roles = JSON.parse(JSON.stringify(originalRoles));
            rolesData.clearAll(roles);
          }
        }
        break;
      case 3:
        if (!!user) {
          setUserValues({ ...userData });
        }
        break;
      default:
        break;
    }
    if (cancelEditing === undefined) {
      setConfirmInactivate({
        open: false,
      });

      if (
        (confirmInactivate.tab === 0 && confirmInactivate.newTab < 1) ||
        confirmInactivate.tab === undefined
      ) {
        // setCardState(false);
        // restartFields();
        //
      }

      if (confirmInactivate.newTab !== undefined) {
        setTab(confirmInactivate.newTab);
      }

      if (confirmInactivate.newTab > tab) {
        if (
          (confirmInactivate.newTab === 1 && !customers.length) ||
          (confirmInactivate.newTab === 2 && !isEmpty(rolesRef.current))
        )
          setLoading(true);
      }
    }
  };

  const handleChange = (event) => {
    setTypeNewUser(event.target.value);
  };

  const addSpaces = (text, breakpoint, spacesQty) => {
    if (breakpoint < 0) {
      throw new Error("Invalid break position");
    }
    let leftPart = text.substring(0, breakpoint);
    let rightPart = text.substring(breakpoint);
    [...Array(spacesQty)].map((i) => {
      return (leftPart += " ");
    });
    return leftPart + rightPart;
  };

  const removeSpaces = (text) => {
    let rightPart = text.substring(6);
    return "(+57) " + rightPart.replace(/ /g, "");
  };

  const replaceIllegalCharacter = (value) => {
    var chars = value.split("");
    let response = "";
    for (const letra of chars) {
      if (letra.match("^[0-9\r]*$") !== null) {
        response += letra;
      }
    }
    return response;
  };

  const onChangePhoneNumber = (e) => {
    if (e.target.value.includes("(+57) ")) {
      let rightPart = e.target.value.substring(6);
      setValue("phoneNumber", "(+57) " + replaceIllegalCharacter(rightPart));
    } else {
      setValue("phoneNumber", "(+57) ");
    }
  };

  const onBlurPhoneNumber = () => {
    if (getValues().phoneNumber === "(+57) ") {
      setStateShrink((state) => ({ ...state, phoneNumber: false }));
      setValue("phoneNumber", "");
    } else {
      if (getValues().phoneNumber.length === 14) {
        setValue("phoneNumber", addSpaces(getValues().phoneNumber, 7, 1));
      }

      if (getValues().phoneNumber.length === 16) {
        setValue("phoneNumber", addSpaces(getValues().phoneNumber, 9, 1));
      }
    }
  };

  const onFocusPhoneNumber = () => {
    setStateShrink((state) => ({ ...state, phoneNumber: true }));
    if (
      getValues().phoneNumber === "" ||
      getValues().phoneNumber === undefined
    ) {
      setValue("phoneNumber", "(+57) ");
    } else {
      if (getValues().phoneNumber.includes("(+57) ")) {
        setValue("phoneNumber", removeSpaces(getValues().phoneNumber));
      } else {
        setValue("phoneNumber", "(+57) " + getValues().phoneNumber);
      }
    }
  };

  const jsonParse = (value, initialValue = {}, callback) => {
    let obj = initialValue;
    try {
      obj = JSON.parse(value);
    } catch (ex) {
      if (callback) callback(ex);
    }
    return obj;
  };

  const { getIdpResponse } = useSelector((store) => store.userReducer);

  useEffect(() => {
    dispatch(getIdpTypes());
  }, [getIdpTypes]);

  useEffect(() => {
    if (getIdpResponse && getIdpResponse.length) {
      const types = [];
      getIdpResponse
        .sort((a, b) => {
          return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
        })
        .forEach((idpType) => {

          //console.log(user)
          const objidpType = jsonParse(idpType.description);
          if (objidpType.id) {
            types.push({
              value: objidpType.value,
              name: objidpType.name,
              id: idpType.id,
              idstate: objidpType.idstate,
            });
          }
        });
      setIdpTypes(types);
    }
  }, [getIdpResponse]);

  const createView = () => {
    const items = [
      {
        label: "Datos",
        tabContent: (
          <Grid container>
            <Grid item container direction="row" spacing={2}>
              <Grid item lg={5} md={10} sm={12}>
                <ControlledInputRoundedForm
                  id="userName"
                  name="userName"
                  inputProps={{ maxLength: 100 }}
                  stateShrink={stateShrink.userName}
                  setStateShrink={setStateShrink}
                  pattern={/[^a-zA-Z0-9._\-@]/g}
                  disabled={!enabledForm}
                  label="Nombre de usuario"
                  fullWidth
                  control={control}
                  error={errors.userName}
                  helperText={errors.userName?.message}
                  onBlur={() => {
                    if (
                      !isEmpty(getValues().userName) &&
                      getValues().userName !== prevUser.userName
                    ) {
                      setLoading(true);
                      setInputExists("userName");
                      getUserExists({ userName: getValues().userName });
                    } else {
                      clearErrors("userName");
                      setInputDuplicate((state) => ({
                        ...state,
                        userName: false,
                      }));
                    }
                  }}
                />
              </Grid>
              <Grid item lg={5} md={10} sm={12}>
                <ControlledInputRoundedForm
                  id="email"
                  name="email"
                  inputProps={{ maxLength: 100 }}
                  stateShrink={stateShrink.email}
                  setStateShrink={setStateShrink}
                  disabled={!enabledForm}
                  pattern={/[^a-zA-Z0-9._\-@+]/g}
                  label="Correo electrónico"
                  fullWidth
                  control={control}
                  error={errors.email}
                  helperText={errors.email?.message}
                  onBlur={() => {
                    if (
                      !isEmpty(getValues().email) &&
                      getValues().email !== prevUser.email
                    ) {
                      setLoading(true);
                      setInputExists("email");
                      getUserExists({ email: getValues().email });
                    } else {
                      clearErrors("email");
                      setInputDuplicate((state) => ({
                        ...state,
                        email: false,
                      }));
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item lg={5} md={10} sm={12}>
                <ControlledInputRoundedForm
                  id="names"
                  name="names"
                  label="Nombres"
                  fullWidth
                  control={control}
                  stateShrink={stateShrink.names}
                  setStateShrink={setStateShrink}
                  pattern={/[^a-zA-ZÑñÁÉÍÓÚáéíóú\s]/g}
                  disabled={!enabledForm}
                  inputProps={{ maxLength: 100 }}
                  error={errors.names}
                  helperText={errors.names?.message}
                />
              </Grid>
              <Grid item lg={5} md={10} sm={12}>
                <ControlledInputRoundedForm
                  id="surnames"
                  name="surnames"
                  pattern={/[^a-zA-ZÑñÁÉÍÓÚáéíóú\s]/g}
                  inputProps={{ maxLength: 100 }}
                  disabled={!enabledForm}
                  stateShrink={stateShrink.surnames}
                  setStateShrink={setStateShrink}
                  label="Apellidos"
                  fullWidth
                  control={control}
                  error={errors.surnames}
                  helperText={errors.surnames?.message}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item lg={5} md={10} sm={12}>
                <ControlledInputRoundedForm
                  id="document"
                  name="document"
                  disabled={!enabledForm}
                  pattern={/[^a-zA-Z0-9]/g}
                  inputProps={{ maxLength: 13 }}
                  stateShrink={stateShrink.document}
                  setStateShrink={setStateShrink}
                  label="Número de documento"
                  fullWidth
                  control={control}
                  error={errors.document}
                  helperText={errors.document?.message}
                />
              </Grid>
              <Grid item lg={6} md={10} sm={12}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  lg={11}
                >
                  <Grid container item lg={11} md={11} sm={11}>
                    <ControlledInputRoundedForm
                      id="phoneNumber"
                      name="phoneNumber"
                      disabled={!enabledForm}
                      // pattern={/[^0-9+]/g}
                      inputProps={{ maxLength: 16 }}
                      label="Teléfono fijo o celular"
                      fullWidth
                      stateShrink={stateShrink.phoneNumber}
                      setStateShrink={setStateShrink}
                      control={control}
                      error={errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      onChange={(e) => onChangePhoneNumber(e)}
                      onFocus={onFocusPhoneNumber}
                      onBlur={onBlurPhoneNumber}
                    />
                  </Grid>
                  <Grid container item lg={1} md={1} sm={1}>
                    <Tooltip
                      title={
                        <div>
                          Teléfono Fijo: Ej. 1 1234567
                          <br />
                          Celular: Ej. 123 1234567
                        </div>
                      }
                    >
                      <HelpOutline className={classes.helpIcon} />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item lg={5} md={10} sm={12}>
                <ControlledSelect
                  id="authenticationtypeid"
                  name="authenticationtypeid"
                  label="Tipo de autenticación"
                  options={authenticationTypes}
                  error={errors.authenticationtypeid}
                  helperText={errors.authenticationtypeid?.message}
                  control={control}
                  disabled={!enabledForm}
                  isSearchable={true}
                />
              </Grid>
              {authTypeId === 2 && (
                <Grid item lg={5} md={10} sm={12}>
                  <ControlledInputRoundedForm
                    id="samlidentifier"
                    name="samlidentifier"
                    disabled={!enabledForm}
                    stateShrink={stateShrink.samlidentifier}
                    setStateShrink={setStateShrink}
                    label="Identificador SAML"
                    fullWidth
                    control={control}
                    error={errors.samlidentifier}
                    helperText={errors.samlidentifier?.message}
                    onBlur={() => {
                      if (
                        !isEmpty(getValues().samlidentifier) &&
                        getValues().samlidentifier !== prevUser.samlidentifier
                      ) {
                        setLoading(true);
                        setInputExists("samlIdentifier");
                        getUserExists({
                          SamlIdentifier: getValues().samlidentifier,
                        });
                      } else {
                        clearErrors("samlIdentifier");
                        setInputDuplicate((state) => ({
                          ...state,
                          samlIdentifier: false,
                        }));
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {authTypeId === 2 && (
              <Grid item container direction="row" spacing={2}>
                <Grid item lg={5} md={10} sm={12}>
                  <ControlledSelect
                    id="identityProviderId"
                    name="identityProviderId"
                    label="ID de proveedor de identidad"
                    options={idpTypes}
                    error={errors.identityProviderId}
                    helperText={errors.identityProviderId?.message}
                    control={control}
                    disabled={!enabledForm}
                    isSearchable={true}
                  />
                </Grid>
              </Grid>
            )}

            <Grid item container direction="row" spacing={2}>
              {!!user.id && enabledForm ? (
                <Grid
                  container
                  item
                  lg={10}
                  md={10}
                  sm={12}
                  justify="center"
                  style={{ marginTop: "15px" }}
                >
                  <ButtonSaveChanges
                    title={"Actualizar"}
                    isDisabled={!enabledForm}
                    variant="contained"
                    color="secondary"
                    handleClick={(e) => {
                      if (enabledForm && !validateChanges()) {
                        saveUser(true);
                      } else {
                        setLoading(true);
                        setConfirmInactivate({
                          open: true,
                          message: <div>No hay cambios que aplicar</div>,
                          newTab: tab,
                          tab,
                          checked: true,
                        });
                        setTimeout(() => {
                          setLoading(false);
                        }, 500);
                      }
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
            {/*<Grid container item lg={12} md={12} sm={12} direction="row-reverse" >
              <Tooltip
                id="button-next"
                title="Siguiente"
                style={{ float: "right", marginTop: "18px" }}
              >
                <IconButton onClick={(e) => handleChangeTab(e, 1)}>
                  <ArrowIcon className={classes.nextArrow} />
                </IconButton>
              </Tooltip>
                  </Grid>*/}
          </Grid>
        ),
      },
      {
        label: "empresas",
        tabContent: (
          <Grid container lg={12} md={12} sm={12}>
            <UserCustomer
              userId={user.id}
              customers={customers}
              setCustomers={setCustomers}
              setEditedCustomers={setEditedCustomers}
              setErrorsCustomer={setErrorsCustomer}
              setLoading={setLoading}
              isEnabled={!!enabledForm}
            />
            {!!user.id && enabledForm ? (
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                justify="center"
                style={{ marginTop: "15px" }}
              >
                <ButtonSaveChanges
                  title={"Actualizar"}
                  isDisabled={!enabledForm}
                  variant="contained"
                  color="secondary"
                  handleClick={(e) => {
                    const principal = customers.some(
                      (customer) => customer.principal === true
                    );
                    if (principal) {
                      if (enabledForm && !validateChanges()) {
                        setConfirmInactivate({
                          open: true,
                          message: (
                            <div>
                              Hiciste Modificaciones <br />
                              ¿deseas actualizarlas?
                            </div>
                          ),
                          newTab: tab,
                          tab,
                          showBtnAccept: true,
                          showBtnCancel: true,
                          action: actions.UPDATE,
                          checked: true,
                        });
                      } else {
                        setLoading(true);
                        setTimeout(() => {
                          setLoading(false);
                        }, 500);
                      }
                    } else {
                      setErrorsCustomer(true);
                    }
                  }}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item lg={12} md={12} sm={12}>
              {errorsCustomer && (
                <div className={classes.errorText}>
                  <Typography color="error" variant="subtitle2">
                    {"*Se requiere una empresa principal"}
                  </Typography>
                </div>
              )}
            </Grid>
            {/*
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              direction="row-reverse"
            >
              <Tooltip
                id="button-next"
                title="Siguiente"
                style={{ float: "left" }}
              >
                <IconButton
                  onClick={(e) => {
                    handleChangeTab(e, 2);
                  }}
                >
                  <ArrowIcon className={classes.nextArrow} />
                </IconButton>
              </Tooltip>
            </Grid>*/}
          </Grid>
        ),
      },
      {
        label: "roles",
        tabContent: (
          <Grid container item justify="space-between">
            <UserRoles
              selectedAppID={selectedAppId}
              userId={user.id}
              setLoading={setLoading}
              setErrors={setErrorsRoles}
              setIsDisabled={setIsDisabled}
              appList={getApplicationsResponse}
              sortData={sortData}
              isEnabled={enabledForm}
              rolAppCounter={rolAppCounter}
              errors={errorsRoles}
              ref={rolesRef}
              rolesData={rolesData}
              applicationList={applicationList}
              setApplicationList={setApplicationList}
              setSelectedAppId={setSelectedAppId}
              setOriginalRoles={setOriginalRoles}
            />
            {enabledForm && (
              <Grid
                container
                item
                direction="row-reverse"
                lg={12}
                sm={12}
                md={12}
                style={{ marginTop: 20 }}
                justify="flex-start"
              >
                <ButtonSaveChanges
                  title={!userData.id ? "Guardar" : "Actualizar"}
                  color="primary"
                  variant="contained"
                  id="buttonSave"
                  className={classes.buttonSave}
                  margin="dense"
                  isDisabled={!enabledForm}
                  handleClick={() => {
                    if (!!userData.id && enabledForm && !validateChanges()) {
                      setConfirmInactivate({
                        open: true,
                        message: (
                          <div>
                            Hiciste Modificaciones <br />
                            ¿deseas actualizarlas?
                          </div>
                        ),
                        newTab: tab,
                        tab,
                        showBtnAccept: true,
                        showBtnCancel: true,
                        action: actions.UPDATE,
                        checked: true,
                      });
                    } else if (userData.id === 0) {
                      saveUser();
                    } else {
                      //restartFields();
                      //setCardState(false);
                      setConfirmInactivate({
                        open: true,
                        message: <div>No hay cambios que aplicar</div>,
                        checked: true,
                      });
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        ),
      },
    ];

    if (!!userData.id) {
      items.push({
        label: "Seguridad",
        tabContent: (
          <Grid container item justify="space-between" alignItems="stretch">
            <BorderedSection
              id="credential"
              icon={CredentialIcon}
              title="Credenciales"
              borderStyle="borderStyle1"
              item
              xs={12}
            >
              <CredentialView
                disabled={!enabledForm}
                userId={userData.id}
                email={userData.email}
              />
            </BorderedSection>
            <BorderedSection
              id="signature"
              icon={ServiceIcon}
              title="Configurar proveedor de firma"
              borderStyle="borderStyle1"
              style={{ marginTop: "30px" }}
              item
              xs={12}
            >
              <Grid container>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <ControlledSelect
                    id="signatureSupplier"
                    name="signatureSupplier"
                    label="Proveedor de firmas"
                    options={signatureSuppliers}
                    error={errors.signatureSupplier}
                    helperText={errors.signatureSupplier?.message}
                    control={control}
                    disabled={!enabledForm}
                    isSearchable={true}
                  />
                </Grid>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                  <ControlledInputRoundedForm
                    id="signatureURL"
                    name="signatureURL"
                    inputProps={{ maxLength: 200 }}
                    label="URL del servicio"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledForm}
                    error={errors.signatureURL}
                    helperText={errors.signatureURL?.message}
                  />
                </Grid>
                <BorderedSection
                  id="signatureOauth"
                  title="Oauth 2.0"
                  item
                  xs={6}
                >
                  <ControlledInputRoundedForm
                    id="signatureClientId"
                    name="signatureClientId"
                    inputProps={{ maxLength: 200 }}
                    label="Client Id"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledForm}
                    error={errors.signatureClientId}
                    helperText={errors.signatureClientId?.message}
                  />
                  <ControlledInputRoundedForm
                    id="signatureClientSecret"
                    name="signatureClientSecret"
                    inputProps={{ maxLength: 200 }}
                    label="Client Secret"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledForm}
                    error={errors.signatureClientSecret}
                    helperText={errors.signatureClientSecret?.message}
                  />
                  <ControlledInputRoundedForm
                    id="signatureScope"
                    name="signatureScope"
                    inputProps={{ maxLength: 200 }}
                    label="Scope"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledForm}
                    error={errors.signatureScope}
                    helperText={errors.signatureScope?.message}
                  />
                </BorderedSection>
                <BorderedSection id="signatureApi" title="Api key" item xs={6}>
                  <ControlledInputRoundedForm
                    id="signatureApiKey"
                    name="signatureApiKey"
                    inputProps={{ maxLength: 200 }}
                    label="API Key"
                    fullWidth
                    control={control}
                    //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                    disabled={!enabledForm}
                    error={errors.signatureApiKey}
                    helperText={errors.signatureApiKey?.message}
                  />
                </BorderedSection>
              </Grid>
            </BorderedSection>
            <Grid item container direction="row" spacing={2}>
              {!!user.id && enabledForm ? (
                <Grid
                  container
                  item
                  lg={10}
                  md={10}
                  sm={12}
                  justify="center"
                  style={{ marginTop: "15px" }}
                >
                  <ButtonSaveChanges
                    title={"Actualizar"}
                    isDisabled={!enabledForm}
                    variant="contained"
                    color="secondary"
                    handleClick={(e) => {
                      if (enabledForm && !validateChanges()) {
                        saveUser(true);
                      } else {
                        setLoading(true);
                        setConfirmInactivate({
                          open: true,
                          message: <div>No hay cambios que aplicar</div>,
                          newTab: tab,
                          tab,
                          checked: true,
                        });
                        setTimeout(() => {
                          setLoading(false);
                        }, 500);
                      }
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ),
      });
    }
    return items;
  };

  return (
    <FormLayout
      handleSubmit={handleSubmit(saveUser)}
      handleIconClick={() => {
        backButton();
      }}
      subheaderBase={userData.userName}
      subheader={defineSubHeader()}
      isEditing={isEditing}
      enabledForm={enabledForm}
      setEnabledForm={setEnabledForm}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      handleEditButton={() => {
        clearErrors();
        setEnabledForm(!enabledForm);
        handleCancel(true);
      }}
      handleAccept={handleAccept}
      handleCancel={handleCancel}
    >
      <TabComponent
        aria={"user tabs"}
        handleChangeTab={handleChangeTab}
        tab={tab}
        children={createView()}
      />
      <AlertConfirm
        isOpen={msgActiveUser.open}
        modalMessage={msgActiveUser.message}
        handleAccept={closeMessage}
        showBtnCancel={false}
        isSecondStyle={activeButtons ? true : undefined}
        showBtnAccept={msgActiveUser.showBtnAccept}
        textButtonAccept={"Aceptar"}
        additionalButtons={
          activeButtons ? (
            <RadioGroup
              aria-label="optionUserNew"
              name="optionUserNew"
              value={typeNewUser}
              onChange={handleChange}
            >
              <FormControlLabel
                value="newUser"
                control={
                  <StyledRadioButton checked={typeNewUser === "newUser"} />
                }
                label="Crear nuevo usuario."
              />
              <FormControlLabel
                value="actUser"
                control={<StyledRadioButton />}
                label="Reactivar usuario."
              />
              <br />
            </RadioGroup>
          ) : (
            ""
          )
        }
      />
    </FormLayout>
  );
};
UserForm.propTypes = {
  getApplications: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  ActivateUser: PropTypes.func.isRequired,
  getAuthenticationTypes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getApplicationsResponse: state.userReducer.getApplicationsResponse,
  getUserResponse: state.userReducer.getUserResponse,
  createUser: state.userReducer.createUser,
  getCustomersResponse: state.userReducer.getCustomersResponse,
  setUserErrorResponse: state.userReducer.setUserErrorResponse,
  getUserChangedResponse: state.userReducer.getUserChangedResponse,
  activatedResponse: state.userReducer.activatedResponse,
  getUserExistsresponse: state.userReducer.getUserExistsresponse,
  authenticationTypes: state.userReducer.getAuthenticationTypesResponse,
  signatureSuppliers: state.objectTypesReducer.signatureSupplierResponse,
});

const mapDispatchToProps = {
  ActivateUser,
  getApplications,
  getUserById,
  createUser,
  getUsers,
  getUserExists,
  getAuthenticationTypes,
  getSignatureSupplier,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
    borderBottom: `2px solid ${theme.palette.common.grey}`,
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary.main.main,
      fontWeight: "bold",
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {
    color: theme.palette.secondary.main.main,
    fontWeight: "bold",
  },
  card: {
    //margin: "0px 15px",
    borderRadius: "10px",
  },
  tabsStyle: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabsItem: {
    fontSize: "12px",
    textTransform: "capitalize",
    padding: "21px",
    marginTop: "0px",
  },
  btnApplications: {
    marginBottom: "9px",
    boxShadow: "0px 3px 3px #0000001A",
    borderRadius: "5px",
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  buttonSave: {
    margin: "10px 15px",
    flex: 1,
    float: "right",
    boxShadow: `0px 4px 3px ${theme.palette.common.grey}`,
    borderRadius: 10,
  },
  errorText: {
    padding: "10px 15px",
  },
  categoryButtonFalse: { background: "#FFFFFF 0% 0% no-repeat padding-box" },
  categoryButtonTrue: {
    background: "#6D6E71 0% 0% no-repeat padding-box",
    color: "white !important",
  },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.text.disabled,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
  colorSuccess: {
    color: "#4caf50",
  },
  colorError: {
    color: "#f44336",
  },
  helpIcon: {
    color: theme.palette.secondary.main,
  },
}));
