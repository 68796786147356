import { Grid } from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { createMenu, GetMenuByApp, ModifyMenu } from "../../../actions/menu.action";
import ControlledSelect from "../../InputForm/ControlledSelect";
import { convertPrivilegesToList, generateKeyId, isEmpty } from "../../../utils/proprietaryHooks";
import { MenuSettingsContainer } from "../MenuSettingsContainer";
import { ModalForm } from "../../../views/menuSettings/ModalForm";
import { ItemEditedModal } from "../../Modal/ItemEditedModal";
import { useDispatch, useSelector } from "react-redux";
import { getPrivileges } from "../../../actions/privileges.action";
import FullLoader from "../../Loader/FullLoader.component";
import { UseDialog } from "@pif-tr-components/components";
import { GET_MENU_CHANGE } from "../../../actions/types";

export const CustomMenuSettings = forwardRef((props, ref) => {
  const { menuLocation, menu } = props;
  const { currentMenu, setCurrentMenu, idMenu } = menu;

  //#region object definition
  const [open, setOpen] = React.useState(false);
  const [applications, setApplications] = React.useState([]);
  const [messageChangeMenu, setMessageChangeMenu] = useState("¡Menú guardado exitosamente!")
  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      application: "",
      state: false,
    },
  });

  const initialValues = {
    name: "",
    privilege: "",
    url: "",
    isActivate: false,
    enterprises: "",
    description: "",
    isFunction: false,
    functionName: "",
    sizeIcon: "24px"
  };
  const { control, watch } = form;
  const modalRef = useRef(null);
  const menuSettingRef = useRef(null);
  const getPrivilegesRef = useRef(false)
  const [editedModalOpen, setEditedModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [privilegesList, setPrivilegesList] = React.useState([]);
  const [initialFormValues, setInitialFormValues] = React.useState(
    initialValues
  );
  const dispatch = useDispatch();
  const privileges = useSelector(({ privilegesReducer }) => {
    return privilegesReducer.getPrivilegesResponse;
  });
  const menuChange = useSelector(({ menuReducer }) => {
    return menuReducer.getMenuChangeResponse
  })
  const [currentApp, setCurrentApp] = React.useState({
    name: "",
    id: 0,
    items: [],
  });

  const { Dialog, onOpen } = UseDialog({
    bodyText: messageChangeMenu,
  });

  //#endregion
  useEffect(() => {
    if (currentApp.name !== "" && getPrivilegesRef.current) {
      setLoading(true);
      dispatch(getPrivileges(currentApp.name));
      getPrivileges.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp.name]);

  useEffect(() => {
    if (!isEmpty(privileges)) {
      setLoading(false);
      setPrivilegesList(privileges);
    }
  }, [privileges]);

  useEffect(() => {
    const app = watch("application");
    if (app !== "" && app !== undefined) {
      getPrivilegesRef.current = true;
      dispatch(GetMenuByApp(app, menuLocation));
      setCurrentApp((oldState) => ({
        ...oldState,
        name: applications.find((item) => item.id === app).name,
      }));
      setCurrentMenu([])
      menuSettingRef.current = menuSettingRef.current.addInitialDataMenu([]);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("application")]);

  useEffect(() => {
    if (!isEmpty(menuChange)) {
      setLoading(false)
      onOpen();
      dispatch({
        type: GET_MENU_CHANGE,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuChange])


  //#region custom methods

  /**
   *  save each change on items
   * @param {*} iconName
   * @param {*} isEdit
   * @param {*} itemValues
   */
  const saveItem = (iconName, isPersonalizedIcon, action, itemValues) => {
    setOpen(false);
    let values = {};
    if (action) {
      const menuValues = menuSettingRef.current.values.menu1;
      const searchValues = {
        edit: { ...itemValues, iconName, isPersonalizedIcon },
        state: { ...itemValues },
        delete: {},
      };
      const newMenu = menuSettingRef.current.searchItemUpdateAndDelete(
        itemValues.key,
        menuValues,
        action,
        searchValues[action.toLowerCase()]
      );
      menuSettingRef.current.addInitialDataMenu(newMenu);
      setCurrentMenu(newMenu);
      if (action === "Edit") {
        const isMessage = localStorage.getItem("remember");
        if (!isMessage) {
          setEditedModalOpen(true);
        }
      }
    } else {
      // create item
      const { getValues } = modalRef.current.form;
      values = getValues();
      const item = {
        application: watch("application"),
        ...values,
        key: generateKeyId(10),
        menuLocation: 1,
        isPersonalizedIcon,
        iconName,

        ...modalRef.current.logo,
      };
      const previousMenu = [...currentMenu, item];
      setCurrentMenu(previousMenu);
      menuSettingRef.current.addItemMenu(item);
    }
    handleClose();
  };

  const handleClose = () => {
    setInitialFormValues(initialValues);
    modalRef.current.restartModal();
  };

  const saveMenu = (useHooksData, data, message) => {
    setLoading(true)
    useHooksData = typeof useHooksData !== "boolean" ? false : useHooksData
    let valuesComplete = useHooksData ? data : menuSettingRef.current.values.menu1;
    valuesComplete = convertPrivilegesToList(valuesComplete)

    if (message !== undefined) {
      setMessageChangeMenu(message)
    } else {
      setMessageChangeMenu("¡Menú guardado exitosamente!")
    }

    if (idMenu === 0) {
      const objectCreate = {
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(removeTitle(valuesComplete)),
      }
      dispatch(createMenu(objectCreate));
    } else {
      const objectModify = {
        id: idMenu,
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(removeTitle(valuesComplete)),
      }
      dispatch(ModifyMenu(objectModify));
    }
    if (useHooksData) {
      menuSettingRef.current.addInitialDataMenu(data)
      setCurrentMenu(data);
    }
  }

  const removeTitle = (menus) => {
    let objectMenus = [];
    if (menus !== undefined && menus.length > 0) {
      for (const menu of menus) {
        let objectMenuChildren = [];
        if (menu.children !== undefined && menu.children.length > 0) {
          objectMenuChildren = removeTitle(menu.children);
        }
        const { title, ...rest } = menu;
        if (objectMenuChildren.length !== 0) {
          objectMenus.push({ ...rest, children: objectMenuChildren });
        } else {
          objectMenus.push(rest);
        }
      }
    }
    return objectMenus;
  };

  //#endregion

  // useImperativeHandle(ref, () => ({}), []);

  return (
    <div style={{ width: "100%" }}>
      <div>
        <Grid container>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <ControlledSelect
              control={control}
              id="application"
              name="application"
              options={applications}
              label={'Aplicación'}
            />
          </Grid>
        </Grid>
        <MenuSettingsContainer
          setInitialFormValues={setInitialFormValues}
          ref={menuSettingRef}
          saveMenu={saveMenu}
          applications={applications}
          setApplications={setApplications}
          currentApp={currentApp}
          setCurrentApp={setCurrentApp}
          form={form}
          setOpen={setOpen}
          menu={menu}
        />
        <ModalForm
          initialValues={initialFormValues}
          ref={modalRef}
          open={open}
          setOpen={setOpen}
          handleSave={saveItem}
          handleClose={handleClose}
          appName={currentApp}
          privileges={privilegesList}
        />
        <Dialog />
        <ItemEditedModal
          open={editedModalOpen}
          setOpen={setEditedModalOpen}
        />
        <FullLoader open={loading} />
      </div>
    </div>
  );
});
