import { combineReducers } from "redux/es/redux";
import authReducer from "./auth.reducer";
import errorReducer from "./error.reducer";
import loadingReducer from "./loading.reducer";
import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";
import privilegesReducer from "./privileges.reducer";
import rolesReducer from "./roles.reducer";
import resetOtpReducer from "./resetOtp.reducer";
import enterpriseReducer from "./enterprise.reducer";
import applicationsReducer from "./applications.reducer";
import notificationReducer from "./notification.reducer";
import subjectReducer from "./subjects.redecer";
import clientReducer from "./Client.reducer";
import monithorReducer from "./monithor.reducer";
import integrationsReducer from "./integrations.reducer";
import integrationTypesReducer from "./integrationtypes.reducer";
import dataTypesReducer from "./dataTypes.reducer";
import documentTypesReducer from "./documentTypes.reducer";
import objectTypesReducer from "./objectTypes.reducer";
import servicesReducer from "./services.reducer";
import responseCodesReducer from "./responseCodes.reducer";
import homologationsReducer from "./homologations.reducer";
import homologationValuesReducer from "./homologationValues.reducer";
import signCustomDocumentReducer from "./signCustomDocument.reducer";
import sign from './sign.reducer';
import home from './home.reducer';
import customFormsReducer from './customform.reducer';
import customViewsReducer from "./customviews.reducer";

export default combineReducers({
  authReducer,
  errorReducer,
  loadingReducer,
  menuReducer,
  userReducer,
  privilegesReducer,
  rolesReducer,
  resetOtpReducer,
  enterpriseReducer,
  applicationsReducer,
  notificationReducer,
  subjectReducer,
  clientReducer,
  monithorReducer,
  integrationsReducer,
  integrationTypesReducer,
  dataTypesReducer,
  documentTypesReducer,
  objectTypesReducer,
  servicesReducer,
  responseCodesReducer,
  homologationsReducer,
  homologationValuesReducer,
  signCustomDocumentReducer,
  sign,
  home,
  customFormsReducer,
  customViewsReducer
});
