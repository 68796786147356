import React from 'react'

export const ClockIcon = ({
    color
}) => {
    return (
        <svg id="Componente_192_88" data-name="Componente 192 – 88" xmlns="http://www.w3.org/2000/svg" width="150.737" height="116.649" viewBox="0 0 150.737 116.649">
            <g id="Grupo_3274" data-name="Grupo 3274" transform="translate(17.648 0)">
                <g id="Elipse_162" data-name="Elipse 162" transform="translate(0 0)" fill="#fff" stroke="#3f3d56" stroke-width="2">
                    <circle cx="53" cy="53" r="53" stroke="none" />
                    <circle cx="53" cy="53" r="52" fill="none" />
                </g>
                <rect id="Rectángulo_1432" data-name="Rectángulo 1432" width="3.719" height="37.193" rx="1.86" transform="translate(51.14 16.737)" fill={color} />
                <circle id="Elipse_163" data-name="Elipse 163" cx="3.719" cy="3.719" r="3.719" transform="translate(49.281 49.281)" fill={color} />
                <g id="Grupo_3271" data-name="Grupo 3271" transform="translate(3.619 3.62)">
                    <rect id="Rectángulo_1435" data-name="Rectángulo 1435" width="1.543" height="10.802" transform="translate(48.603)" fill={color} />
                    <rect id="Rectángulo_1436" data-name="Rectángulo 1436" width="1.543" height="10.802" transform="translate(13.922 15.008) rotate(-45)" fill={color} />
                    <rect id="Rectángulo_1437" data-name="Rectángulo 1437" width="1.543" height="10.802" transform="translate(0 50.151) rotate(-90)" fill={color} />
                    <rect id="Rectángulo_1438" data-name="Rectángulo 1438" width="1.543" height="10.802" transform="translate(15.004 84.841) rotate(-135)" fill={color} />
                    <rect id="Rectángulo_1439" data-name="Rectángulo 1439" width="1.543" height="10.802" transform="translate(77.205 22.645) rotate(-135)" fill={color} />
                    <rect id="Rectángulo_1440" data-name="Rectángulo 1440" width="1.543" height="10.802" transform="translate(87.95 50.151) rotate(-90)" fill={color} />
                    <rect id="Rectángulo_1441" data-name="Rectángulo 1441" width="1.543" height="10.802" transform="translate(76.111 77.204) rotate(-45)" fill={color} />
                    <rect id="Rectángulo_1442" data-name="Rectángulo 1442" width="1.543" height="10.802" transform="translate(48.603 87.957)" fill={color} />
                </g>
                <circle id="Elipse_168" data-name="Elipse 168" cx="53" cy="53" r="53" transform="translate(-17.648 10.649)" fill="rgba(0,0,0,0.1)" />
            </g>
            <g id="Grupo_3283" data-name="Grupo 3283" transform="matrix(0.94, 0.342, -0.342, 0.94, 130.604, 16.065)">
                <g id="Rectángulo_1450" data-name="Rectángulo 1450" transform="translate(2.038)" fill={color} stroke="#fff" stroke-width="4">
                    <rect width="13.932" height="42.772" rx="6.966" stroke="none" />
                    <rect x="-2" y="-2" width="17.932" height="46.772" rx="8.966" fill="none" />
                </g>
                <g id="Elipse_176" data-name="Elipse 176" transform="translate(0 48.645)" fill={color} stroke="#fff" stroke-width="4">
                    <circle cx="8.127" cy="8.127" r="8.127" stroke="none" />
                    <circle cx="8.127" cy="8.127" r="10.127" fill="none" />
                </g>
            </g>
        </svg>

    )
}
