import { Controller, useForm } from "react-hook-form";
import {
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import PageBackground from "../../components/pageBackground.component";
import CardBody from "../../components/CardBody/cardBody.component";
import PrimaryButton from "../../components/primaryButton.component";
import { yupResolver } from "@hookform/resolvers/yup";
import "./calculator.css"

import * as Yup from "yup";
import { HelpOutline } from "@material-ui/icons";

export function CustomViewsCalculator() {
  const [result, setResult] = useState(0);

  const useStyles = makeStyles((theme) => ({
    input: {
      fontWeight: "500",
      color: "#363535",
      paddingLeft: 6,
      fontFamily: "muli, saint-serif",
      fontSize: "18px",
      "&:hover": {
        color: "#4F4F4F",
        fontWeight: "400",
        border: "none",
      },
      borderRadius: "0px 12px 12px 0px",
      marginTop: 4,
    },
    root: {
      paddingLeft: "0px",
      boxShadow: "0px 4px 3px #00000033",
      borderRadius: "12px",
      "& .MuiInputBase-root": {
        color: "red",
      },
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    helpIcon: {
      color: "#da163d",
    },
  }));

  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    quantityOrder: Yup.string().required("Campo obligatorio"),
    amountOrder: Yup.string().required("Campo obligatorio"),
    amountReceive: Yup.string().required("Campo obligatorio"),
  });

  const {
    control,
    // handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      quantityOrder: "",
      amountOrder: "",
      amountReceive: "",
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const calculate = async (e) => {
    e.preventDefault();
    debugger;
    const quantityLine = parseFloat(getValues().quantityOrder);
    const amountLine = parseFloat(getValues().amountOrder);
    const amounReceive = parseFloat(getValues().amountReceive);

    const value = (amounReceive * quantityLine) / amountLine;
    setResult(value.toFixed(6));
  };
  return (
    <div className="calculator">
      <div className="card_login">
        <PageBackground></PageBackground>
        <CardBody title="Calculadora">
          <form id="login-validation" autoComplete="off">
            <hr></hr>
            <Grid container spacing={2}>
              <Grid item lg={11} md={11} sm={11} xs={11}>
                <Controller
                  as={TextField}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  name="quantityOrder"
                  control={control}
                  placeholder="Cantidad linea"
                  fullWidth={true}
                  error={!!errors.quantityOrder}
                  helperText={
                    errors.quantityOrder ? errors.quantityOrder.message : null
                  }
                  InputProps={{
                    classes,
                  }}
                />
              </Grid>
              <Grid
                container
                item
                lg={1}
                md={1}
                sm={1}
                xs={1}
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip
                  title={
                    "Cantidad del pedido de la linea, se puede ver en la tabla linea de la orden de compra"
                  }
                >
                  <HelpOutline className={classes.helpIcon} />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={11} md={11} sm={11} xs={11}>
                <Controller
                  as={TextField}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  name="amountOrder"
                  control={control}
                  placeholder="Monto linea"
                  fullWidth={true}
                  error={!!errors.amountOrder}
                  type={"text"}
                  helperText={
                    errors.amountOrder ? errors.amountOrder.message : null
                  }
                  InputProps={{
                    classes,
                  }}
                />
              </Grid>
              <Grid
                container
                item
                lg={1}
                md={1}
                sm={1}
                xs={1}
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip
                  title={
                    "Monto total de la linea, se puede ver en la tabla linea de la orden de compra"
                  }
                >
                  <HelpOutline className={classes.helpIcon} />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={11} md={11} sm={11} xs={11}>
                <Controller
                  as={TextField}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  name="amountReceive"
                  control={control}
                  placeholder="Monto a recibir"
                  fullWidth={true}
                  error={!!errors.amountReceive}
                  type={"text"}
                  helperText={
                    errors.amountReceive ? errors.amountReceive.message : null
                  }
                  InputProps={{
                    classes,
                  }}
                />
              </Grid>
              <Grid
                container
                item
                lg={1}
                md={1}
                sm={1}
                xs={1}
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip title={"Monto que se va a recibir"}>
                  <HelpOutline className={classes.helpIcon} />
                </Tooltip>
              </Grid>
            </Grid>

            <br />
            <br />
            <Grid container spacing={2}>
              <Grid
                item
                lg={11}
                md={11}
                sm={11}
                xs={11}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h5">
                  Cantidad a ingresar: {result}
                </Typography>
              </Grid>
              <Grid
                container
                item
                lg={1}
                md={1}
                sm={1}
                xs={1}
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip
                  title={
                    "Valor que se debe agregar en el campo Cantidad de coupa"
                  }
                >
                  <HelpOutline className={classes.helpIcon} />
                </Tooltip>
              </Grid>
            </Grid>

            <br />
            <PrimaryButton
              txtBtn={"Calcular"}
              loading={false}
              onClick={(e) => {
                calculate(e);
              }}
            />
            <hr></hr>
          </form>
        </CardBody>
      </div>
    </div>
  );
}
