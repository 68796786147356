import axios from "axios";
import {
  defaultHeaders,
  defaultResponses,
  defaultHeadersText,
} from "../config/config";
import {
  GET_DOCUMENT_TO_SIGN,
  ERROR_GET_DOCUMENT_TO_SIGN,
  RUN_SIGNATURE_CIRCUIT_RESPONSE,
  RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE
} from "../types/SignTypes";

var ApiUrl = "";

export /**
 * Actualizar ApiUrl del servicio monithor
 */
const setUrlService = (URL) => {
  ApiUrl = URL;
};

export /**
 * Obtiene el documento contrato a firmar
 *
 * @method GET
 */
const getDocumentToSign = (trasanctionId, enqueueSnackbar) => async (dispatch) => {
  
  try {
    var response = await axios({
      method: 'GET',
      url: `${ApiUrl}/signature/download/${trasanctionId}`,
      responseType: 'blob',
    });

    if (response.status === 200) {
      dispatch({
          type: GET_DOCUMENT_TO_SIGN,
          payload: response.data,
      });
    } else {
      enqueueSnackbar(
        "Ocurrió un error inesperado al procesar la transacción",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      dispatch({
          type: ERROR_GET_DOCUMENT_TO_SIGN,
          payload: response.data,
      });
    }
  } catch (err) {
    enqueueSnackbar(
      "Ocurrió un error inesperado al procesar la transacción",
      {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }
    );
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: ERROR_GET_DOCUMENT_TO_SIGN,
      payload: response,
    });
  }
};

export /**
 * Lanzar circuito de firmas
 *
 * @method POST
 */
const runSignaturesCircuit = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    var response = await axios.post(
      `${ApiUrl}/signature`,
      data,
      defaultHeadersText()
    );
    if (response.status === 200) {
      dispatch({
          type: RUN_SIGNATURE_CIRCUIT_RESPONSE,
          payload: response.data.result.information
      });
     } else {
      enqueueSnackbar(
        "Ocurrió un error inesperado al procesar la transacción",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      dispatch({
          type: RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE,
          payload: response.data.result.information
      });
     }
  } catch (err) {
    enqueueSnackbar(
      "Ocurrió un error inesperado al procesar la transacción",
      {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }
    );
    console.error(err);
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE,
      payload: response,
    });
  }
};