import React, { useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import OrderIcon from '../IconsLibrary/OrderIcon';

const StyledButton = withStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    borderRadius: 3,
    border: "1px solid #A8A8A8",
    height: 37,
    padding: '0 30px',
    boxShadow: 'none',
    color: "#6D6E71 !important",
    opacity: "1",
    '&&:hover': {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      boxShadow: 'none',
      border: 'none'
    }
  },
  label: {
    textTransform: 'capitalize',
  },
}))(Button);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    background: "#FFFFFF !important"
  },

})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '& .MuiListItem-button':{
      '&:hover':{
        backgroundColor: theme.palette.common.white
      }
    }
    

  },

}))(MenuItem);

const MenuOrder = ({ options, selectItem, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [active,setActive]= useState(false);
  const theme = useTheme() 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event, index) => {
    selectItem(index)
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        size="small"
        {...props}
        onClick={handleClick}
        onMouseOver={()=>setActive(true)}
        onMouseLeave={()=> setActive(false)}
        startIcon={<OrderIcon width={40} active={active}
        
          style={{color: active ?  theme.palette.common.white : theme.palette.info.main }}
          className={{
          background: "#f1f1f1",
          '&:hover': {
            background: "#ffffff",
          }
        }} />}
      >
        Ordenar
      </StyledButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </StyledMenuItem>
        ))}
      </StyledMenu>

    </div>
  );

}

export default MenuOrder;