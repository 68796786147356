import { GET_CERTIFICATES, IS_UNIQUE_ELECTRONIC, LOADING, LIST_SIGNS, POP, LIST_MANUSCRITA, GET_DOCUMENT_TO_SIGN, ERROR_GET_DOCUMENT_TO_SIGN, RUN_SIGNATURE_CIRCUIT_RESPONSE, RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE } from '../types/SignTypes';

const INITIAL_STATE = {
  certificates: {},
  isUniqueElectronic: false,
  loading: false,
  listSigns: [],
  pop: 0,
  listManuscrita: [],
  getDocumentToSignResponse: "",
  getDocumentToSignResponseError: "",
  runSignaturesCircuitResponse: "",
  runSignaturesCircuitResponseError: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POP:
      return {
        ...state,
        pop: action.payload,
      };
    case IS_UNIQUE_ELECTRONIC:
      return {
        ...state,
        isUniqueElectronic: action.payload,
      };
    case GET_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LIST_SIGNS:
      return {
        ...state,
        listSigns: action.payload,
      };
    case LIST_MANUSCRITA:
      return {
        ...state,
        listManuscrita: action.payload,
      };
    case GET_DOCUMENT_TO_SIGN:
      return {
        ...state,
        getDocumentToSignResponse: action.payload,
      };
    case ERROR_GET_DOCUMENT_TO_SIGN:
      return {
        ...state,
        getDocumentToSignResponseError: action.payload,
      };
    case RUN_SIGNATURE_CIRCUIT_RESPONSE:
      return {
        ...state,
        runSignaturesCircuitResponse: action.payload,
      };
    case RUN_SIGNATURE_CIRCUIT_ERROR_RESPONSE:
      return {
        ...state,
        runSignaturesCircuitResponseError: action.payload,
      };
    default:
      return state;
  }
};
