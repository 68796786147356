import { makeStyles } from "@material-ui/core";

export const useStylesMenuSettings = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: "10px 15px",
  },
  paperTable: {
    width: "100%",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.light}`,
  },
  paperSelectMenu: {
    width: "100%",
    borderRadius: "10px",
    padding: "10px",
    border: `1px solid ${theme.palette.info.light}`,
  },
  container: {
    maxHeight: 440,
  },
  searchTextField: {
    maxHeight: "25px",
    padding: "10px 23px",
    marginBottom: "12px",
  },
  buttonSave: {
    margin: "15px 3px 20px 3px",
    flex: 1,
    float: "right",
    background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: 10,
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main + "D4",
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  errorText: {
    padding: "10px 15px",
  },
  buttonSelectImage: {
    background: "white",
    color: "#6D6E71 !important",
    "&:hover": {
      background: theme.palette.primary.light,
      color: "white !important",
      "& .MuiSvgIcon-colorPrimary": {
        color: "white",
      },
    },
  },
  titleSelectTypeMenu: {
    color: theme.palette.primary.light,
    marginTop: "10px",
    marginBottom: "10px",
  },
  paperModal: {
    position: "relative",
    width: "50%",
    height: "70%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperSelectIcon: {
    height: "70%",
  },
  colorSearchAdornment: {
    color: "#C6C6C6",
  },
  titleTree: {
    color: "#868686",
    backgroundColor: "#EFEFEF",
    border: "1px solid #C1C1C1",
    paddingRight: "12px",
    borderRadius: "10px",
    minWidth: 350,
    height: 40,
    // margin: "10px 0px",
  },
  icon: {
    color: `${theme.palette.primary.light} !important`,
  },
  titleItem:{
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "15px"
  },
  containerTitleItem:{
    paddingTop: "15px !important"
  }
}));
