import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { getAppsForNotification } from '../../actions/applications.action';
import { createUpdateNotification, getListNotifications, getTemplatesByApp } from '../../actions/notifications.action';
import { getSubjectsByTemplesId } from '../../actions/subjects.action';
import { CHANGE_NOTIFICATION, GET_ALL_TEMPLATES } from '../../actions/types';
import { TypeAlertNotification, typeFieldNotification } from '../../utils/constants';
import { addDaysNow, formatDate, isEmpty, makeId, validateEquals } from '../../utils/proprietaryHooks';
import FormLayout from '../../components/layouts/FormLayout'
import ButtonSaveChanges from '../../components/ButtonForm/ButtonSaveChanges';
import { MomoizedNotificationFormRightView, } from './NotificationFormRightView';
import { MemoizedNotificationFormLeftView, } from './NotificationFormLeftView';
import { useStylesNotifications } from './notification.style';



export const NotificationsFormController = (props) => {

  const {
    setCardState,
    setLoading,
    currentNotification,
    setCurrentNotification
  } = props;

  const classes = useStylesNotifications();

  const requiredMessage = "Campo obligatorio.";

  /* #region  Dispatch y selectors */
  const dispatch = useDispatch();
  const { getApplicationsNotificationResponse } = useSelector(state => state.applicationsReducer);
  const { getAllTemplates, changeNotification } = useSelector(state => state.notificationReducer);
  const { getAllSubjects } = useSelector(state => state.subjectReducer);
  /* #endregion */

  /* #region  Objectos initials  */
  const object = {
    reasonNotification: "",
    template: "",
    issue: "",
    dateSend: formatDate(new Date(), "yyyy-mm-dd"),
  }

  const initialValues = !isEmpty(currentNotification)
    ? {
      reasonNotification: currentNotification.reason,
      template: `${currentNotification.templateId}`,
      issue: `${currentNotification.subjectId}`,
      dateSend: formatDate(new Date(currentNotification.executionDate), "yyyy-mm-dd"),
    }
    : object

  const objectValidator = {
    reasonNotification: Yup.string().required(requiredMessage),
    template: Yup.string().max(50).required(requiredMessage),
    dateSend: Yup.date(),
    issue: Yup.string().required(requiredMessage),
  }
  /* #endregion */

  /* #region  Ref for validate changes */
  const originalInfo = useRef({
    reason: !isEmpty(currentNotification) && currentNotification.reason,
    subjectId: !isEmpty(currentNotification) && currentNotification.subjectId,
    templateId: !isEmpty(currentNotification) && currentNotification.templateId,
    variables: !isEmpty(currentNotification) && currentNotification.variables,
    executionDate: !isEmpty(currentNotification) && formatDate(new Date(currentNotification.executionDate), "yyyy-mm-dd")
  });

  const originalContent = useRef({
    applications: {},
    templates: {},
    templateId: {}
  });

  const initialDate = useRef({
    today: new Date(),
    nextDay: addDaysNow(1)
  });

  const validateAction = useRef({
    isNew: isEmpty(currentNotification),
    isChangeOnEdit: false
  })

  const isFirstRender = useRef(!isEmpty(currentNotification) ? 0 : 10)

  /* #endregion */

  /* #region  States */
  const [enabledTabsForm, setEnabledTabsForm] = useState(!isEmpty(currentNotification) ? false : true);
  const [forcedUpdate, setForcedUpdate] = useState(makeId(5))
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    back: false,
    item: "",
    cancelEdit: false,
    infoContent: {}
  });
  const [appsToSelect, setAppsToSelect] = useState([]); // Guarda todas las aplicaciones y las diferencia por las marcadas
  const [templatesData, setTemplatesData] = useState([]); // Guarda todas las plantillas 
  const [subjects, setSubjects] = useState([]); // Gaurda todos los Asuntos parametrizados para cada plantilla
  const [currentTemplate, setCurrentTemplate] = useState({}) // Almacena la informacion de la plantilla seleccionada
  const [validatorsYup, setValidatorsYup] = useState(objectValidator) // Objecto usado para validar los campos
  /* #endregion */

  /* #region  React hook form */
  const validationSchema = Yup.object().shape(validatorsYup);
  const controlForm = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  const {
    getValues,
    setValue,
    watch,
    formState,
    trigger,
    reset,
    errors,
    control
  } = controlForm;
  /* #endregion */

  /* #region  Effects Section */
  useEffect(() => {
    // TODO: Effect para solicitar la lista de aplicaciones
    setLoading(true);
    dispatch(getAppsForNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // TODO: Effect para organizar la lista de aplicaciones
    // TODO: Si se esta editando, se marcan las aplicaciones que lleguen en el currentNotification
    if (!!getApplicationsNotificationResponse.length) {
      if (!validateAction.current.isNew) {
        const listSharedApps = currentNotification.sharedApps.split(",");
        const applicationsSelected = [...getApplicationsNotificationResponse.map(item => {
          return {
            id: item.id,
            name: item.name,
            title: item.title,
            check: listSharedApps.includes(item.title) ? true : false
          }
        })];
        setAppsToSelect(applicationsSelected);
        originalContent.current.applications = JSON.parse(JSON.stringify(applicationsSelected));
      } else {
        setAppsToSelect([...getApplicationsNotificationResponse.map(item => {
          return {
            id: item.id,
            name: item.name,
            title: item.title,
            check: false
          }
        })]);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationsNotificationResponse])

  useEffect(() => {
    // TODO: Effect que se ejecuta cada vez que se cambia una aplicación
    // Se va a usar para volver a los valores originales de la notificación
    // TODO: tambien se realiza la petición de las templates dependiendo de las aplicaciones seleccionadas
    const ids = appsToSelect.filter(x => x.check).map(x => x.id);
    if (ids.length > 0) {
      setLoading(true);
      dispatch(getTemplatesByApp(ids));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appsToSelect, forcedUpdate])

  useEffect(() => {
    // TODO: Lista de todas las aplicaciones,tiene diferente funcionalidad si esta creando o editando una notificacion
    // TODO: Si esta editando y la template no esta en la nueva lista de templates se genera un mensaje informando que perderá los cambios
    if (!isEmpty(getAllTemplates)) {
      setLoading(false);
      if (validateAction.current.isNew) {
        setTemplatesData(getAllTemplates);
        setValue("template", "");
        setValue("issue", "")
        setCurrentTemplate({})
        setValidatorsYup(objectValidator)
      } else {
        if (getAllTemplates.length > 0) {

          const templateId = parseInt(getValues("template"));
          const newTemplate = getAllTemplates.find(x => x.id === templateId);
          if (newTemplate === undefined) {
            setConfirmInactivate(state => ({
              ...state,
              open: true,
              message: (
                <div>
                  La plantilla seleccionada no se encuentra habilitada para estas aplicaciones. 
                  <br />
                  <br />
                  ¿Deseas continuar?
                </div>
              ),
              item: TypeAlertNotification.CHANGE_APPLICATION,
              back: false,
              cancelEdit: false,
              showBtnAccept: true,
              showBtnCancel: true,
              infoContent: getAllTemplates
            }));

          } else {
            setTemplatesData(getAllTemplates);
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllTemplates]);

  useEffect(() => {
    // TODO: Este effect solo se ejecuta cuando es edición
    if (templatesData.length > 0 && !validateAction.current.isNew) {
      const templateId = currentNotification.templateId;
      dispatch(getSubjectsByTemplesId(templateId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesData])

  useEffect(() => {
    // TODO: Este efecto se ejecuta cada vez que se cambie el template
    // TODO: Tambien se realiza la peticion de los asuntos segun la template seleccionada
    if (watch("template") !== "") {
      if (isFirstRender.current !== 0) {
        const templateId = watch("template");
        if (currentNotification.templateId !== templateId) {
          if (!validateAction.current.isNew) {
            validateAction.current.isChangeOnEdit = true;
          }
          setValue("issue", "");
          setLoading(true);

          dispatch(getSubjectsByTemplesId(templateId))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("template")])

  useEffect(() => {
    // TODO: Llega la lista de asunto y se renderiza
    if (!isEmpty(getAllSubjects)) {
      setLoading(false);
      setSubjects(getAllSubjects);
      const templateId = parseInt(watch("template")) ? parseInt(watch("template")) : watch("template");
      if (validateAction.current.isNew) {
        chargeDataCurrentTemplate(templateId, false);
      } else {
        if (validateAction.current.isChangeOnEdit) {
          chargeDataCurrentTemplate(templateId, false);
        } else {
          chargeDataCurrentTemplate(templateId, true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllSubjects])

  useEffect(() => {
    // TODO: Effect que captura los cambios en las notificaciones y muestra el mensaje indicado
    if (!isEmpty(changeNotification)) {
      setConfirmInactivate(state => ({
        ...state,
        open: true,
        message: changeNotification,
        item: isEmpty(currentNotification) ? TypeAlertNotification.CREATED : TypeAlertNotification.MODIFIED,
        showBtnAccept: false,
        showBtnCancel: false,
        infoContent: {}
      }));
      setLoading(true);
      dispatch(getListNotifications())
      dispatch({
        type: CHANGE_NOTIFICATION,
        payload: {}
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeNotification])

  useEffect(() => {
    // TODO: Effect para restablecer los valores de los estados cuando se salga del formulario de creacion o edición de notificaciones
    return () => {
      restartField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* #endregion */

  /* #region  Functions for create objects and save information */

  const resetForm = (variables, renderCurrentNotification, originalData) => {
    let objectVariables = {};
    let objectValidator = {};
    variables.map(item => {
      if (item.type === typeFieldNotification.TIME) {
        objectVariables = {
          ...objectVariables,
          [item.hour.name]: isEmpty(currentNotification) || !renderCurrentNotification
            ? 0
            : originalData === undefined
              ? JSON.parse(currentNotification.variables).filter(x => x.type === typeFieldNotification.TIME).find(x => x.hour.name === item.hour.name).hour.value
              : JSON.parse(originalData.Variables).filter(x => x.type === typeFieldNotification.TIME).find(x => x.hour.name === item.hour.name).hour.value
        };
        objectValidator = {
          ...objectValidator,
          [item.hour.name]: Yup.number().required(requiredMessage)
        };
        objectVariables = {
          ...objectVariables,
          [item.minutes.name]: isEmpty(currentNotification) || !renderCurrentNotification
            ? 0
            : originalData === undefined
              ? JSON.parse(currentNotification.variables).filter(x => x.type === typeFieldNotification.TIME).find(x => x.minutes.name === item.minutes.name).minutes.value
              : JSON.parse(originalData.Variables).filter(x => x.type === typeFieldNotification.TIME).find(x => x.minutes.name === item.minutes.name).minutes.value
        };
        objectValidator = {
          ...objectValidator,
          [item.minutes.name]: Yup.number().required(requiredMessage)
        };
        if (item.complement !== undefined) {
          objectVariables = {
            ...objectVariables,
            [item.complement.name]: isEmpty(currentNotification) || !renderCurrentNotification
              ? false
              : originalData === undefined
                ? JSON.parse(currentNotification.variables).filter(x => x.type === typeFieldNotification.TIME && x.complement !== undefined).find(x => x.complement.name === item.complement.name).complement.value
                : JSON.parse(originalData.Variables).filter(x => x.type === typeFieldNotification.TIME && x.complement !== undefined).find(x => x.complement.name === item.complement.name).complement.value
          };
          objectValidator = {
            ...objectValidator,
            [item.complement.name]: Yup.boolean()
          };
        }
      } else if (item.type === typeFieldNotification.CHECK) {
        objectVariables = {
          ...objectVariables,
          [item.name]: isEmpty(currentNotification) || !renderCurrentNotification
            ? false
            : originalData === undefined
              ? JSON.parse(currentNotification.variables).filter(x => x.type === typeFieldNotification.CHECK).find(x => x.name === item.name).value
              : JSON.parse(originalData.Variables).filter(x => x.type === typeFieldNotification.CHECK).find(x => x.name === item.name).value
        };
        objectValidator = {
          ...objectValidator,
          [item.name]: Yup.boolean()
        };
      } else if (item.type === typeFieldNotification.DATE) {
        objectVariables = {
          ...objectVariables,
          [item.name]: isEmpty(currentNotification) || !renderCurrentNotification
            ? formatDate(initialDate.current.nextDay, "yyyy-mm-dd")
            : originalData === undefined
              ? JSON.parse(currentNotification.variables).filter(x => x.type === typeFieldNotification.DATE).find(x => x.name === item.name).value
              : JSON.parse(originalData.Variables).filter(x => x.type === typeFieldNotification.DATE).find(x => x.name === item.name).value
        };
        objectValidator = {
          ...objectValidator,
          [item.name]: Yup.string().required(requiredMessage)
        };
      } else {
        objectVariables = {
          ...objectVariables,
          [item.name]: isEmpty(currentNotification) || !renderCurrentNotification
            ? ""
            : originalData === undefined
              ? JSON.parse(currentNotification.variables).find(x => x.name === item.name).value
              : JSON.parse(originalData.Variables).find(x => x.name === item.name).value
        };
        objectValidator = {
          ...objectValidator,
          [item.name]: Yup.string().required(requiredMessage)
        };
      }
      return item;
    })
    return [objectVariables, objectValidator];
  }

  const chargeDataCurrentTemplate = (idTemplate, renderCurrentNotification, originalData) => {
    const template = templatesData.find(x => x.id === idTemplate)
    if (template !== undefined) {
      const parseVariables = JSON.parse(template.variables);
      setCurrentTemplate({ ...template, variables: parseVariables })
      const [objVariables, objValidator] = resetForm(parseVariables, renderCurrentNotification, originalData);
      let values = getValues();
      if (!validateAction.current.isNew) {
        if (validateAction.current.isChangeOnEdit) {
          values = {
            ...values,
            issue: "",
            dateSend: formatDate(new Date(), "yyyy-mm-dd"),
          }
        }
      }
      reset({ ...values, ...objVariables })
      setValidatorsYup(state => ({
        ...state, ...objValidator
      }))
      isFirstRender.current = isFirstRender.current + 1;
    }
  }

  const saveNotification = async () => {
    await trigger();
    if (!isEmpty(formState.errors)) {
      return;
    }

    const data = extractData();

    setLoading(true);
    if (!isEmpty(currentNotification)) {
      dispatch(createUpdateNotification(data, "Modify"));
    } else {
      dispatch(createUpdateNotification(data, "Create"));
    }
  }

  const extractData = () => {
    let variables = [];
    if (!isEmpty(currentTemplate)) {
      currentTemplate.variables.map(variable => {
        let info;
        if (variable.type === typeFieldNotification.TIME) {
          info = {
            type: variable.type,
            hour: {
              name: variable.hour.name,
              value: getValues(variable.hour.name)
            },
            minutes: {
              name: variable.minutes.name,
              value: getValues(variable.minutes.name)
            }
          };
          if (variable.complement !== undefined) {
            info = {
              ...info,
              complement: {
                name: variable.complement.name,
                value: getValues(variable.complement.name),
                label: variable.complement.label
              }
            }
          }
        } else if (variable.type === typeFieldNotification.CHECK) {
          info = {
            type: variable.type,
            name: variable.name,
            value: getValues(variable.name),
            label: variable.label
          };
        } else {
          info = {
            type: variable.type,
            name: variable.name,
            value: getValues(variable.name)
          };
        }
        variables.push(info);
        return variable;
      });
    }

    const data = {
      id: currentNotification.id || 0,
      reason: getValues().reasonNotification,
      subjectId: parseInt(getValues().issue),
      templateId: parseInt(getValues().template),
      applicationIds: [...appsToSelect.filter(item => item.check).map(item => item.id)],
      variables: JSON.stringify(variables),
      executionDate: getValues().dateSend
    };

    return data;
  }

  const validateOriginalInfo = () => {
    const currentData = extractData();
    let currentDataForValidate = {};
    const originalInforCopy = {
      ...JSON.parse(JSON.stringify(originalInfo.current)),
      applicationIds: originalContent.current.applications.filter(x => x.check).map(x => x.id)
    }

    for (const key in originalInforCopy) {
      currentDataForValidate = { ...currentDataForValidate, [key]: currentData[key] }
    }
    const resp = !validateEquals(currentDataForValidate, originalInforCopy)
    return resp;
  }
  /* #endregion */

  /* #region  Functions for navigations */
  const backButton = () => {
    if (!isEmpty(currentNotification)) {
      if (validateOriginalInfo()) {
        setConfirmInactivate(state => ({
          ...state,
          open: true,
          message: (
            <div>
              Hiciste modificaciones
              <br />
              ¿Deseas actualizarlas?
            </div>
          ),
          item: TypeAlertNotification.SAVED_CHANGES,
          back: true,
          showBtnAccept: true,
          showBtnCancel: true,
          infoContent: {}
        }));
      } else {
        restartField()
      }
    } else {
      restartField()
    }
  }

  const handleCancel = () => {
    if (confirmInactivate.back) {
      restartField();
    }

    if (confirmInactivate.item === TypeAlertNotification.CHANGE_APPLICATION) {
      setAppsToSelect(originalContent.current.applications);
      setConfirmInactivate(state => ({
        ...state, open: false, item: "", infoContent: {}
      }))
    }
  }

  const restartField = () => {
    setAppsToSelect([])
    setTemplatesData([])
    setSubjects([])
    setCurrentTemplate({})
    setCardState(false)
    setCurrentNotification({})
    setConfirmInactivate({})
    dispatch({
      type: GET_ALL_TEMPLATES,
      payload: [],
    });
  }

  const handleAccept = () => {
    if (confirmInactivate.item === TypeAlertNotification.CREATED || confirmInactivate.item === TypeAlertNotification.MODIFIED) {
      restartField()
    }

    if (confirmInactivate.item === TypeAlertNotification.SAVED_CHANGES) {
      saveNotification();
    }

    if (confirmInactivate.item === TypeAlertNotification.CHANGE_APPLICATION) {
      setTemplatesData(confirmInactivate.infoContent);
      setValue("template", "");
      setValue("issue", "");
      setCurrentTemplate({})
      setValidatorsYup(objectValidator)
    }

    setConfirmInactivate(state => ({
      ...state, open: false, item: "", infoContent: {}
    }))
  }

  const handleChangeEdit = (response) => {
    if (!response) {
      isFirstRender.current = 0;
      validateAction.current.isChangeOnEdit = false;
      setAppsToSelect(originalContent.current.applications)
      setForcedUpdate(makeId(5))
      // // setTemplatesData(originalContent.current.templates);
      setValue("reasonNotification", originalInfo.current.reason);
      setValue("issue", originalInfo.current.subjectId);
      setValue("template", originalInfo.current.templateId);
      setValue("dateSend", originalInfo.current.executionDate);
    }
    setEnabledTabsForm(response);
  }
  /* #endregion */

  const handleChange = (event) => {
    setAppsToSelect([...appsToSelect.map(item => {
      if (item.name === event.target.name) {
        item.check = event.target.checked
      }
      return item;
    })])
  };

  const createSubHeader = () => {
    if (isEmpty(currentNotification)) {
      return "Nueva notificación";
    } else {
      if (enabledTabsForm) {
        return "Edición de la notificación";
      } else {
        return "Detalle de la notificación";
      }
    }
  }

  return (
    <FormLayout
      handleSubmit={(e) => {
        e.preventDefault()
        saveNotification()
      }}
      handleIconClick={() => backButton()}
      subheader={createSubHeader()}
      isEditing={isEmpty(currentNotification) ? false : true}
      enabledForm={enabledTabsForm}
      setEnabledForm={handleChangeEdit}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      handleCancel={handleCancel}
      modalMessage={`¿Desea modificar esta notificación?`}
      handleAccept={handleAccept}
    >
      <Grid container justify="space-between" alignItems="stretch" spacing={3}>
        <MemoizedNotificationFormLeftView
          enabledTabsForm={enabledTabsForm}
          appsToSelect={appsToSelect}
          handleChange={handleChange}
          templatesData={templatesData}
          errors={errors}
          control={control}
        />
        <MomoizedNotificationFormRightView
          enabledTabsForm={enabledTabsForm}
          subjects={subjects}
          currentTemplate={currentTemplate}
          initialDate={initialDate}
          errors={errors}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
        <Grid item lg={12}>
          <ButtonSaveChanges
            type="submit"
            id="buttonSave"
            style={classes.buttonSave}
            margin="dense"
            title={!isEmpty(currentNotification) ? "Actualizar" : 'Guardar'}
            isDisabled={isEmpty(currentTemplate) ? true : false}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}
