import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ViewLayout from "../../components/layouts/ViewLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import NewUserIcon from "../../components/IconsLibrary/NewUserIcon";

//#region Entity's dependencies
import { getServicesByCustomer, modifyService } from "../../actions/service.action";
import ServiceForm from "../services/servicesForm.view";
import { messagesResponseServices } from "../../utils/constants";
import { SET_SERVICE_ERROR, GET_SERVICE_CHANGED } from "../../actions/types";
//#endregion

/**
 * Services Component ( full view for list of Services)
 *
 * @export Class Component
 * @class Services
 * @extends {Component}
 * @returns Redux connect
 */
const useStyles = makeStyles((theme) => ({
  newUserIcon: {
    stroke: theme.palette.secondary.main,
  },
  styleFab: {
    boxShadow: "none",
  },
}));

const Services = ({ component: Component, container, ...props }) => {
  const {
    privileges,
    parentId,
    records,
    getRecords,
    modifyRecord,
    recordChanged,
    currentEnterprise,
    recordErrorResponse,
    messagesResponse,
    GET_RECORD_CHANGED,
    SET_RECORD_ERROR,
    Loading
  } = props;
  const classes = useStyles();

  //#region States
  const [showRecordAlert, setShowRecordAlert] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [recordInfo, setRecordInfo] = useState({});
  const dispatch = useDispatch();
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });
  const [isFormShowed, setFormShowed] = useState(false);
  const [option, setOption] = useState(0);
  let [isLoading, setLoading] = useState(false);
  if(Loading !== undefined){
    setLoading = Loading;
  }
  const [userPrivileges] = useState({
    read: privileges.filter(p => p.includes("SERVICE_READ")).length > 0,
    create: privileges.filter(p => p.includes("SERVICE_CREATE")).length > 0,
    modify: privileges.filter(p => p.includes("SERVICE_MODIFY")).length > 0,
    delete: privileges.filter(p => p.includes("SERVICE_DELETE")).length > 0
  });
  //#endregion

  //#region Effects
  useEffect(() => {
    if (!Array.isArray(recordChanged)) {
      setConfirmInactivate({
        open: true,
        message: recordChanged,
        item: "inactivate",
      });
      dispatch({
        type: GET_RECORD_CHANGED,
        payload: [],
      });
    }
    if (!Array.isArray(recordErrorResponse) && recordErrorResponse !== messagesResponse.notPermission) {
      setConfirmInactivate({
        open: true,
        message: recordErrorResponse,
        showBtnAccept: true,
        textButtonAccept: "Cerrar",
        item: "inactivate",
      });
      dispatch({
        type: SET_RECORD_ERROR,
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordChanged, dispatch, recordErrorResponse]);

  useEffect(() => {
    if(currentEnterprise.id)
      getRecords(currentEnterprise.id, parentId);
    
  }, [getRecords,currentEnterprise,parentId,isFormShowed]);

  useEffect(() => {
    if (!!records) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);
  //#endregion

  //#region custom
  const columns = [
    {
      id: "code",
      label: "Componente",
      align: "center",
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "serviceCategory.name",
      label: "Tipo",
      align: "center",
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "name",
      label: "Nombre",
      align: "center",
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "serviceType.name",
      label: "Llamado",
      align: "center",
      haveDropDown: true,
      wordLength: 100,
    },
    {
      id: "createdon",
      label: "Fecha de creación",
      align: "center",
      haveDropDown: true,
      format: (value) => {
        let splited = value.split("T")[0];
        let newDate = splited.split("-");
        return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      },
    },
    {
      id: "idstate",
      label: "Estado",
      minWidth: 40,
      haveDropDown: true,
      align: "center",
      component: {
        handleChange: (e, value, id) => handleStateClick(e, value, id),
      },
      tooltipMessage: "servicio",
      withIcon: true,
      handleIconClick: (ev, id) => handleDeleteClick(ev, id),
      format: (value) => (value === 1 || value === false ? false : true),
    },
  ];

  /**
   * Manage the click event for of change state
   * @param {*} e
   * @param {*} id
   * @param {*} name
   */
  const handleStateClick = (e, id, name) => {
    setConfirmInactivate({
      open: true,
      item: id,
      name: name,
      showBtnAccept: true,
      showBtnCancel: true,
      checked: e.target.checked,
      message: `¿Está seguro que desea ${
        e.target.checked ? "activar" : "inactivar"
      }
      ${"este servicio"}?`,
    });
  };

  /**
   * Manage the click event of the delete icon
   * @param {*} ev
   * @param {*} id
   */
   const handleDeleteClick = (ev, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: true,
      message: '¿Deseas eliminar este servicio?',
    });
  };

  /**
   * Manage the click event of the confirmation or not of the popup
   */
  const handleConfirmChecked = () => {
    let result = records.filter(
      (item) => item.id === confirmInactivate.item
    )[0];

    if(result){
      let recordToBeModified = {
            id: confirmInactivate.item,
            code: result.code,
            componentVersion: result.componentVersion,
            repositoryName: result.repositoryName,
            name: result.name,
            description: result.description,
            idIntegration: result.idIntegration,
            idApplication: result.idApplication,
            idServiceType: result.idServiceType,
            idProtocolType: result.idProtocolType,
            idFormatType: result.idFormatType,
            idMethodType: result.idMethodType,
            idSecurityType: result.idSecurityType,
            metadataServiceURL: result.metadataServiceURL,
            filter: result.filter,
            cronKey: result.cronKey,
            cronPeriodicity: result.cronPeriodicity,
            callOutName: result.callOutName,
            schedulledRestart: result.schedulledRestart,
            oauthURL: result.oauthURL,
            pojo: result.pojo,
            serviceURL: result.serviceURL,
            headers: result.headers,
            payload: result.payload,
            async: result.async,
            asyncCallbackURL: result.asyncCallbackURL,
            clientId: result.clientId,
            clienteSecret: result.clienteSecret,
            scope: result.scope,
            user: result.user,
            pass: result.pass,
            jwtURL: result.jwtURL,
            idstate: confirmInactivate.checked ? 0 : 1,
            createdby: result.createdby,
            createdon: result.createdon,
            modifiedby: result.modifiedby,
            idbusinessunit: result.idbusinessunit,
            idowner: result.idowner
          };
      if (confirmInactivate.name === "idstate") {
        recordToBeModified.idstate = confirmInactivate.checked ? 0 : 1;
        modifyRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      } else if (confirmInactivate.delete) {
        recordToBeModified.idstate = 2;
        modifyRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      }
    }
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
      item: "",
      checked: false,
      message: "",
    });
  };

  /**
   * Get the buttons definations of the custom table
   */
  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          sm: 7,
          xs: 12,
        },
      },
      menuOrder: {
        options: [
          {
            label: "Activo",
            columns: [
              {
                prop: "idstate",
                direction: -1,
              },
            ],
          },
          {
            label: "Inactivo",
            columns: [
              {
                prop: "idstate",
              },
            ],
          },
          {
            label: "Más reciente",
            columns: [
              {
                prop: "createdon",
                direction: 1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "createdon",
                direction: -1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
        ],
        cellSize: {
          sm: 2,
          xs: 2,
        },
      },
      children: () => {
        return (
          <Grid container item lg={12} md={12} sm={2} xs={2} direction="row-reverse">
              {userPrivileges.create && (<Tooltip title={`Crear`} aria-label={`Nuevo`} >
                <Fab
                  size="small"
                  className={[classes.fab, classes.styleFab]}
                  onClick={() => {
                    setFormShowed(true);
                    setRecordId(0);
                    setRecordInfo({});
                  }}
                >
                  <NewUserIcon />
                </Fab>
              </Tooltip>)}
          </Grid>
        );
      },
    };

    return button;
  };

  /**
   * if record is updated, this method get records again
   */
  const updateChecked = (record) => {
    if(currentEnterprise.id)
      getRecords(currentEnterprise.id);
  };

  /**
   * Manage the click event of the selected cell
   */
  const handleCellClick = (evt, row) => {
    setRecordId(row.id);
    setRecordInfo(row);
    setFormShowed(true);
    setLoading(true);
  };

  return (
    <ViewLayout
      headerTitle={"Servicios"}
      handleAccept={handleConfirmChecked}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      showAlert={showRecordAlert}
      setShowAlert={setShowRecordAlert}
      isLoading={isLoading}
      parentId={parentId}
    >
      {isFormShowed ? (
        <ServiceForm
          recordId={recordId}
          record={recordInfo}
          setRecordId={setRecordId}
          setCardState={setFormShowed}
          updateChecked={updateChecked}
          setLoading={setLoading}
          setShowRecordAlert={setShowRecordAlert}
          userPrivileges={userPrivileges}
          privileges={privileges}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={records}
          mainParam={"name"}
          buttons={getButtons()}
          havePagination={true}
          handleCellClick={handleCellClick}
          option={option}
          setOption={setOption}
          paramToDisable={"idstate"}
        />
      )}
    </ViewLayout>
  );
};

//#endregion

//#region entities's declarations 
Services.propTypes = {
  getRecords: PropTypes.func.isRequired,
  modifyRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  records: state.servicesReducer.getServicesResponse,
  recordChanged: state.servicesReducer.getServiceChangedResponse,
  recordErrorResponse: state.servicesReducer.setServiceErrorResponse,
  currentEnterprise: state.enterpriseReducer.getEnterpriseByIDResponse,
  messagesResponse: messagesResponseServices,
  GET_RECORD_CHANGED: GET_SERVICE_CHANGED,
  SET_RECORD_ERROR: SET_SERVICE_ERROR
});

const mapDispatchToProps = {
  getRecords: getServicesByCustomer,
  modifyRecord: modifyService
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
//#endregion