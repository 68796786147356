import {
  GET_PRIVILEGE,
  GET_PRIVILEGES,
  GET_APPLICATIONS,
  SET_PRIVILEGE_ERROR,
  GET_PRIVILEGE_CHANGED,
} from "../actions/types";

const initialState = {
  getPrivilegeResponse: [],
  getPrivilegesResponse: [],
  getApplicationsResponse: [],
  setPrivilegeErrorResponse: [],
  getPrivilegeChangedResponse: []
};

/**
 * privilegesReducer (set privilege or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, privilege}
 */
export default function privilegesReducer (state = initialState, action) {
  switch (action.type) {
    case GET_PRIVILEGES:
      return {
        ...state,
        getPrivilegesResponse: action.payload,
      };
    case GET_PRIVILEGE:
      return {
        ...state,
        getPrivilegeResponse: action.payload,
      };
    case GET_PRIVILEGE_CHANGED:
      return {
        ...state,
        getPrivilegeChangedResponse: action.payload,
      };
    case SET_PRIVILEGE_ERROR:
      return {
        ...state,
        setPrivilegeErrorResponse: action.payload,
      };
    case GET_APPLICATIONS:
      return {
        ...state,
        getApplicationsResponse: action.payload,
      };
    default:
      return state;
  }
}
