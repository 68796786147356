import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { formatTimeUnit } from '../utils/proprietaryHooks';
import { DEFAULTRESPONSE, APPLICATION_CREATED, APPLICATION_ERROR, APPLICATION_MODIFIED, APPLICATION_ACTIVATED, GET_ALL_APPLICATIONS, AUTH_IS_FREE_TRIAL_APP, GET_ALL_APPLICATIONS_MENU, GET_ALL_APPLICATIONS_NOTIFICATION } from './types';


export const getApplications = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_SECURITY_ENDPOINT}/api/application/list`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const applications = response.data.result.records;
      dispatch({
        type: GET_ALL_APPLICATIONS,
        payload: applications
          .filter((app) => app.name !== "RESETOTP")
          .map((item) => ({
            ...item,
            amountTimeComb: `${item.amountTime} ${formatTimeUnit(
              item.timeUnit
            )}`,
          })),
      });
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const getInfoApplicationIsFreeTrial = (appName, setLoading) => async (dispatch) => {
  try {
      const response = await axios.get(`${API_SECURITY_ENDPOINT}/api/application/isFreeTrial`, { headers: { appName } });
      if (response.data.statusCode === 200 && response.data.result.isFreeTrial) {
          dispatch({
              type: AUTH_IS_FREE_TRIAL_APP,
              payload: { url: response.data.result.urlFreeTrial, code: response.data.result.codeFreeTrial },
          });
      }else{
          setLoading(false);
      }
  } catch (error) {
      console.log(error);
      setLoading(false);
  }
}


export const createApplication = (application, tab, activate) => async (
  dispatch
) => {
  try {
    const action = !!application.id ? "modify" : "create";
    const response = await axios.post(
      `${API_SECURITY_ENDPOINT}/api/application/${action}`,
      application,
      defaultHeaders()
    );
    let status = 0;
    let data = {};
    if (response !== undefined) {
      data = response.data;
      status = response.status;
    }

    if (status === 200 || status === 201) {
      if (!!application.id && !activate) {
        dispatch({
          type: APPLICATION_MODIFIED,
          payload: !!tab ? tab : data,
        });
      } else if (activate) {
        dispatch({
          type: APPLICATION_ACTIVATED,
          payload: !!tab ? tab : data,
        });
      } else {
        dispatch({
          type: APPLICATION_CREATED,
          payload: data,
        });
      }
    } else {
      dispatch({
        type: APPLICATION_ERROR,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: error.response.data,
    });
  }
};

export const getAppsForMenu = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_SECURITY_ENDPOINT}/api/application/getApplicationsForAssignMenu`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const applications = response.data.result.records;
      dispatch({
        type: GET_ALL_APPLICATIONS_MENU,
        payload: applications,
      });
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const getAppsForNotification = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_SECURITY_ENDPOINT}/api/application/getApplicationsForAssignNotification`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const applications = response.data.result;
      dispatch({
        type: GET_ALL_APPLICATIONS_NOTIFICATION,
        payload: applications,
      });
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: APPLICATION_ERROR,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};
