import { Grid, Typography } from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { GetMenuByApp } from "../../../actions/menu.action";
import ControlledSelect from "../../InputForm/ControlledSelect";
import { generateKeyId, isEmpty } from "../../../utils/proprietaryHooks";
import { MenuSettingsContainer } from "../MenuSettingsContainer";
import { ModalForm } from "../../../views/menuSettings/ModalForm";
import { ItemEditedModal } from "../../Modal/ItemEditedModal";
import { useDispatch, useSelector } from "react-redux";

import { getPrivileges } from "../../../actions/privileges.action";
import FullLoader from "../../Loader/FullLoader.component";
import MenuTree from "../../../views/menuSettings/menutree";
import { useTree } from "../../../hooks/useTree";
import { UseDialog } from "@pif-tr-components/components";

export const UserMenuSettings = forwardRef((props, ref) => {
  const { menuLocation, menu } = props;
  //#region object definition
  const [open, setOpen] = React.useState(false);
  const [applications, setApplications] = React.useState([]);
  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      application: "",
      state: false,
    },
  });

  const initialValues = {
    name: "",
    privilege: "",
    url: "",
    isActivate: false,
    description: "",
  };
  const { control, watch } = form;
  const modalRef = useRef(null);
  const menuSettingRef = useRef(null);
  const [editedModalOpen, setEditedModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [privilegesList, setPrivilegesList] = React.useState([]);
  const [initialFormValues, setInitialFormValues] = React.useState(
    initialValues
  );
  const [userMenu, setUserMenu] = useState([]);
  const [appMenu, setAppMenu] = useState([]);
  const dispatch = useDispatch();
  const privileges = useSelector(({ privilegesReducer }) => {
    return privilegesReducer.getPrivilegesResponse;
  });
  const [currentApp, setCurrentApp] = React.useState({
    name: "",
    id: 0,
    items: [],
  });

  const itemAction = () => {};

  const { values: userValues, addInitialDataMenu, addItemMenu } = useTree(
    itemAction
  );

  const { Dialog, onOpen } = UseDialog({
    bodyText: "¡Menú guardado exitosamente!",
  });

  //#endregion

  //#region
  useEffect(() => {
    if (isEmpty(privileges) && currentApp.name !== "") {
      setLoading(true);
      dispatch(getPrivileges(currentApp.name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp.name]);

  useEffect(() => {
    if (!isEmpty(privileges)) {
      setLoading(false);
      setPrivilegesList(privileges);
    }
  }, [privileges]);

  useEffect(() => {
    const app = watch("application");
    if (app !== "" && app !== undefined) {
      dispatch(GetMenuByApp(app, menuLocation));
      setCurrentApp((oldState) => ({
        ...oldState,
        name: applications.find((item) => item.id === app).name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("application")]);

  useEffect(() => {
    if (menu.currentMenu.user) {
      setUserMenu(menu.currentMenu.user);
    }

    if (menu.currentMenu.application) {
      setAppMenu(menu.currentMenu.application);
      addInitialDataMenu(menu.currentMenu.user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu.currentMenu]);

  //#endregion

  //#region custom methods

  /**
   *  save each change on items
   * @param {*} iconName
   * @param {*} isEdit
   * @param {*} itemValues
   */
  const saveItem = (iconName, action, itemValues) => {
    setOpen(false);
    const { section } = itemValues;
    let selectedPrivilege = {};
    let values = {};
    if (action === "Edit" || action === undefined) {
      selectedPrivilege = privilegesList.find(
        (item) => item.id === itemValues.privilege
      );
    }
    if (action) {
      const menuValues =
        section === 0 ? userValues.menu1 : menuSettingRef.current.values.menu1;
      const searchValues = {
        edit: { ...itemValues, iconName, privilege: selectedPrivilege.name },
        state: { ...itemValues },
        delete: {},
      };
      const newMenu = menuSettingRef.current.searchItemUpdateAndDelete(
        itemValues.key,
        menuValues,
        action,
        searchValues[action.toLowerCase()]
      );
      if (section === 0) {
        addInitialDataMenu(newMenu);
        setUserMenu(newMenu);
      } else {
        menuSettingRef.current.addInitialDataMenu(newMenu);
        menuSettingRef.current.setMenu(newMenu);
      }

      if (action === "Edit") {
        const isMessage = localStorage.getItem("remember");
        if (!isMessage) {
          setEditedModalOpen(true);
        }
      }
    } else {
      const { getValues } = modalRef.current.form;
      values = getValues();
      const item = {
        application: watch("application"),
        ...values,
        key: generateKeyId(10),
        menuLocation: 1,
        privilege: selectedPrivilege.name,
        iconName,
        ...modalRef.current.logo,
      };
      if (section === 0) {
        const previousMenu = [...menuSettingRef.current.menu, item];
        menuSettingRef.current.setMenu(previousMenu);
        menuSettingRef.current.addItemMenu(item);
      } else {
        const previousMenu = [...userMenu, item];
        setUserMenu(previousMenu);
        addItemMenu(item);
      }

      onOpen();
    }

    setInitialFormValues(initialValues);
    modalRef.current.restartModal();
  };

  const handleClose = () => {
    setInitialFormValues(initialValues);
    modalRef.current.restartModal();
  };

  //#endregion

  const appsSection = () => {
    return (
      <div>
        <Typography variant="h6">{"Seccion usuario"}</Typography>

        {userValues.menu1.length !== 0 ? (
          <MenuTree
            treeData={userValues.menu1}
            setTreeData={addInitialDataMenu}
          />
        ) : (
          <span>No hay datos para mostrar</span>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <FullLoader open={loading} />
      ) : (
        <div>
          <Grid container>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <ControlledSelect
                control={control}
                id="application"
                name="application"
                options={applications}
                label="Aplicación"
              />
            </Grid>
          </Grid>
          <MenuSettingsContainer
            setInitialFormValues={setInitialFormValues}
            ref={menuSettingRef}
            saveMenu={saveItem}
            applications={applications}
            setApplications={setApplications}
            currentApp={currentApp}
            setCurrentApp={setCurrentApp}
            form={form}
            setOpen={setOpen}
            menu={{ currentMenu: appMenu, setCurrentMenu: setAppMenu }}
            children={appsSection()}
            valuesTitle={"Seccion aplicacion"}
          />
          <ModalForm
            initialValues={initialFormValues}
            ref={modalRef}
            open={open}
            setOpen={setOpen}
            handleSave={saveItem}
            handleClose={handleClose}
            appName={currentApp}
            privileges={privilegesList}
            section={[
              { id: 0, name: "Sección usuario", value: 0 },
              { id: 1, name: "Sección Aplicación", value: 1 },
            ]}
          />
          <Dialog />
          <ItemEditedModal
            open={editedModalOpen}
            setOpen={setEditedModalOpen}
          />
        </div>
      )}
    </div>
  );
});
