import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

const ControlledSelect = (
  { control, name, label, options = [], handleChange, error, helperText, ...props },
  rest
) => {
  const errorColor = error ? "#D80000" : "";
  const classes = useStyles({ error: errorColor });
  return (
    <FormControl style={{ width: "100%" }} error={error}>
      <Controller
        name={name}
        control={control}
        {...props}
        as={
          <Select
            error={error}
            onChange={() => {
              handleChange();
            }}
            fullWidth
            className={classes.root}
            classes={{
              select: classes.selected,
            }}
            size="small"
            disableUnderline
            SelectProps={{
              color: "primary",
            }}
            label={label}
            variant="filled"
            {...rest}
            {...props}
          >
            {options.map((option) => (
              <MenuItem
                className={classes.muiList}
                key={option.id}
                value={option.id}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        }
      />
      <InputLabel
        style={{color: '#b2b2b2', marginLeft: '15px', marginTop: "8px"}}
      >{label}</InputLabel>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    //  background: "#FFFFFF 0% 0% no-repeat padding-box",
    backgroundColor: "#FFFFFF !important",
    "& .MuiSelect-root": {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 3px #0000001A",
      border: "1px solid #D5D5D5",
      borderRadius: "10px",
      opacity: 1,
      margin: 2,
    },
    "& .MuiSelect-root.Mui-selected": {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.light,
    },
    "& .MuiInputBase-input": {
      textAlign: "left",
      font: "normal normal normal 16px/17px Muli",
      letterSpacing: "0px",
      color: "#575757",
      opacity: 1,
    },
    "& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled": {
      color: theme.palette.primary.light,
    },
  },
  selected: {
    border: "1px solid blue",
  },
  error: {
    border: "1px solid red",
  },
  muiList: {
    root: {
      width: "100%",
    },
  },
}));

ControlledSelect.propTypes = {
  control: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
};

export default ControlledSelect;
