import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
  //  minWidth: "1024px",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: "0",
    left: "0",
    backgroundImage: theme.pageBackground.backgroundImage,
    backgroundSize: theme.pageBackground.backgroundSize,
    zIndex: "-999999",
  },
}));

const PageBackground = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={`${classes.root} 'Page-background'`}></div>
    </div>
  );
};

export default PageBackground;
