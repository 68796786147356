import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/HighlightOff";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
//import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  titleAlert: {
    color: "#003582",
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cancelButton: {
    marginRight: "31px",
  },
  dialogContent: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  promoForm: {
    flexDirection: "row",
    border: "1px solid #F1F1F1",
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={10000} ref={ref} {...props} />;
  //console.log(props);
  // return <Fade in={true} timeout={10000} ref={ref} {...props} />;
});

const AlertConfirmOtp = (props) => {
  const {
    isOpen,
    onClose,
    tituloModal,
    modalMessage,
    textButtonAcept,
    showBtnCancel,
    showBtnAccept,
    handleAccept,
    showBtnClose,
    //fadeOut,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Typography align="center" color="secondary" margin="dense">
            {tituloModal}
          </Typography>
          {showBtnClose && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContent}>
            {modalMessage}
          </DialogContentText>
          {props.children}
          <Grid container justify="center">
            <Grid item xs={12}>
              {showBtnCancel && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClose}
                  className={classes.cancelButton}
                >
                  Cancelar
                </Button>
              )}

              {showBtnAccept && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAccept}
                >
                  {textButtonAcept || "Aceptar"}
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AlertConfirmOtp;
