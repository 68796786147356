import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ViewLayout from "../../components/layouts/ViewLayout";

//#region Entity's dependencies
import MonitoringPplForm from "./monitoringPplForm.view";
//#endregion

const MonitoringPpl = ({ component: Component, container, ...props }) => {
  const {
    privileges,
    record,
    Loading,
    user
  } = props;

  //#region States
  const [showRecordAlert, setShowRecordAlert] = useState(false);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });
  const [recordInfo, setRecordInfo] = useState({});
  const [isFormShowed, setFormShowed] = useState(false);
  let [isLoading, setLoading] = useState(false);
  // if(Loading !== undefined){
  //   setLoading = Loading;
  // }
  const [userPrivileges] = useState({
    dashboard: privileges.filter(p => p.includes("PIF_MONITHOR_DASHBOARD")).length > 0,
    error: privileges.filter(p => p.includes("PIF_MONITHOR_ERROR")).length > 0 ? true : true,
    transaction: privileges.filter(p => p.includes("PIF_MONITHOR_TRANSACTION")).length > 0,
  }
);
  //#endregion

  //#region Effects
  useEffect(() => {
    if(record.id){
      setRecordInfo(record);
      setFormShowed(true);
    }
  }, [record]);
  //#endregion

  //#region custom

  return (
    <ViewLayout
      headerTitle={"Monitoreo"}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      showAlert={showRecordAlert}
      setShowAlert={setShowRecordAlert}
      isLoading={isLoading}
      visibleIcon={true}
    >
      {isFormShowed ? (
        <MonitoringPplForm
          recordId={recordInfo.id}
          enterpriseRecord={recordInfo}
          setCardState={setFormShowed}
          setLoading={setLoading}
          userPrivileges={userPrivileges}
          user={user}
        />
      ) : ( <></>
        // <CustomTable
        //   columns={columns}
        //   data={records}
        //   mainParam={"name"}
        //   buttons={getButtons()}
        //   havePagination={true}
        //   handleCellClick={handleCellClick}
        //   option={option}
        //   setOption={setOption}
        //   paramToDisable={"idstate"}
        // />
      )}
    </ViewLayout>
  );
};

//#endregion

//#region entities's declarations 
MonitoringPpl.propTypes = {
  
};

const mapStateToProps = (state) => ({
  record: state.enterpriseReducer.getEnterpriseByIDResponse,
});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringPpl);
//#endregion