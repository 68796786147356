import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  TablePagination,
} from "@material-ui/core";
import ViewLayout from "../../components/layouts/ViewLayout";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonSaveChanges from "../../components/ButtonForm/ButtonSaveChanges";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountSuppliers,
  getAllRecordsSuppliers,
  exportExcelSuppliers,
  setUrlMonithorService
} from "../../actions/customviews.action";
import CustomTable from "../../components/Table/CustomTable.component";
import FullLoader from "../../components/Loader/FullLoader.component";

export function CustomViewsSuppliers() {
  const [option, setOption] = useState(null);
  const [datatable, setDatatable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [viewErrors, setViewErrors] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});
  let params = new URLSearchParams(window.location.search);
  const [environmentURL, setEnvironmentURL] = useState(
    params.get("env") ? `${params.get("env")}` : ""
  );

  const { getEnterpriseByIDResponse } = useSelector(
    (store) => store.enterpriseReducer
  );

  useEffect(() => {
    if (getEnterpriseByIDResponse.monitoringService) {
      setEnvironmentURL(getEnterpriseByIDResponse.monitoringService);
    }

    if (environmentURL) {
      setUrlMonithorService(environmentURL);
    }
  }, [getEnterpriseByIDResponse, environmentURL]);

  const useStyles = makeStyles((theme) => ({
    img: {
      width: "25px",
    },
    content: {
      flexGrow: 1,
      padding: "0px",
      justifyContent: "flex-start",
      overFlowX: "hidden",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
      paddingLeft: "10px",
      paddingRight: "10px",
      background: "#e0e0e0 0% 0% no-repeat padding-box",
    },
    imageApp: {
      width: "150px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      height: "43px",
      backgroundImage: theme.logo.backgroundImage,
      paddingTop: "14px",
      flex: 1,
    },
    toolbar: theme.mixins.toolbar,
    title: {
      color: theme.palette.primary.light,
      fontSize: "25px",
    },
    styleVermas: {
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAllData, getErrorAllData, getCountAllRecords } = useSelector(
    (store) => store.customViewsReducer
  );
  useEffect(() => {
    if (!!getErrorAllData) {
      setIsLoading(false);
    }
  }, [getErrorAllData]);

  useEffect(() => {
    if (getAllData.state) {
      setIsLoading(false);
      setDatatable(getAllData.data);
    }
  }, [getAllData]);

  useEffect(() => {
    if (getCountAllRecords !== 0) {
      setTotalRows(getCountAllRecords);
    }
  }, [getCountAllRecords]);

  const validationSchema = Yup.object().shape({
    mandante: Yup.string(),
    supplierName: Yup.string(),
    societyCode: Yup.string(),
    societyCodeEnd: Yup.string(),
    buyOrganizationCode: Yup.string(),
    buyOrganizationCodeEnd: Yup.string(),
    nit: Yup.string(),
    codeCoupa: Yup.string(),
    codeSAP: Yup.string(),
  });
  const { control, errors, getValues } = useForm({
    defaultValues: {
      mandante: "",
      supplierName: "",
      societyCode: "",
      societyCodeEnd: "",
      buyOrganizationCode: "",
      buyOrganizationCodeEnd: "",
      nit: "",
      codeCoupa: "",
      codeSAP: "",
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
  });

  const searchData = (selfpage, selfRowPerPage, executeCount) => {
    const [isAllClear, dataSearch] = validateData();
    if (isAllClear) {
      setViewErrors("Debe llenar al menos un campo");
    } else {
      setIsLoading(true);
      if (executeCount) {
        dispatch(getCountSuppliers(dataSearch));
        setPage(0);
      }
      const currentPage = selfpage !== undefined ? selfpage : page;
      const currentRowsPerPage =
        selfRowPerPage !== undefined ? selfRowPerPage : rowsPerPage;
      dispatch(
        getAllRecordsSuppliers({
          ...dataSearch,
          rowPerPage: currentRowsPerPage,
          page: currentRowsPerPage * currentPage,
        })
      );
    }
  };

  const validateData = () => {
    let dataSearch = {};
    const data = getValues();
    let isAllClear = true;
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== "") {
        dataSearch = { ...dataSearch, [key]: data[key] };
        isAllClear = false;
        setViewErrors("");
      }
    });
    return [isAllClear, dataSearch];
  };

  const columns = [
    {
      id: "mandante",
      label: "Mandante",
      haveDropDown: true,
      align: "center",
      minWidth: 100,
    },
    {
      id: "Codigo_sociedad",
      label: "Sociedad",
      haveDropDown: true,
      align: "center",
      minWidth: 90,
    },
    {
      id: "Nombre_sociedad",
      label: "Nombre sociedad",
      haveDropDown: true,
      align: "center",
      minWidth: 140,
    },
    {
      id: "organizacion_compra",
      label: "Organización compra",
      haveDropDown: true,
      align: "center",
      minWidth: 170,
    },
    {
      id: "Codigo_coupa",
      label: "ID Coupa",
      haveDropDown: true,
      align: "center",
      minWidth: 90,
    },
    {
      id: "Codigo_SAP",
      label: "ID SAP",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "Nombre",
      label: "Nombre",
      haveDropDown: true,
      align: "center",
      minWidth: 200,
    },
    {
      id: "NIT",
      label: "Nit",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "Estado",
      label: "Estado",
      haveDropDown: true,
      align: "center",
      minWidth: 80,
    },
    // {
    //   id: "actions",
    //   label: "",
    //   haveDropDown: false,
    //   align: "left",
    //   children: (_, row) => {
    //     return (
    //       <Typography
    //         className={classes.styleVermas}
    //         onClick={() => handleClickButton(row)}
    //       >
    //         Ver más...
    //       </Typography>
    //     );
    //   },
    // },
  ];

  // const handleClickButton = (row) => {
  //   setCurrentRecord(row);
  //   setShowDetail(true);
  // };

  const handleChangePage = (event, newPage) => {
    searchData(newPage, undefined, false);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowPerPage = parseInt(event.target.value, 10);
    searchData(undefined, rowPerPage, false);
    setRowsPerPage(rowPerPage);
    setPage(0);
  };

  return (
    <>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        <ViewLayout headerTitle={"Búsqueda de proveedores en CMI"}>
          <>
            <Grid container>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ padding: "20px" }}
                spacing={2}
              >
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="mandante"
                    name="mandante"
                    label="Mandante"
                    control={control}
                    fullWidth
                    error={errors.mandante}
                    helperText={errors.mandante?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="supplierName"
                    name="supplierName"
                    label="Nombre del proveedor"
                    control={control}
                    fullWidth
                    error={errors.supplierName}
                    helperText={errors.supplierName?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="societyCode"
                    name="societyCode"
                    label="Código de sociedad inicial"
                    control={control}
                    fullWidth
                    error={errors.societyCode}
                    helperText={errors.societyCode?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="societyCodeEnd"
                    name="societyCodeEnd"
                    label="Código de sociedad final"
                    control={control}
                    fullWidth
                    error={errors.societyCodeEnd}
                    helperText={errors.societyCodeEnd?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="buyOrganizationCode"
                    name="buyOrganizationCode"
                    label="Organización de compra inicial"
                    control={control}
                    fullWidth
                    error={errors.buyOrganizationCode}
                    helperText={errors.buyOrganizationCode?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="buyOrganizationCodeEnd"
                    name="buyOrganizationCodeEnd"
                    label="Organización de compra final"
                    control={control}
                    fullWidth
                    error={errors.buyOrganizationCodeEnd}
                    helperText={errors.buyOrganizationCodeEnd?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="nit"
                    name="nit"
                    label="NIT"
                    control={control}
                    fullWidth
                    error={errors.nit}
                    helperText={errors.nit?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="codeCoupa"
                    name="codeCoupa"
                    label="Código Coupa"
                    control={control}
                    fullWidth
                    error={errors.codeCoupa}
                    helperText={errors.codeCoupa?.message}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="codeSAP"
                    name="codeSAP"
                    label="Código SAP"
                    control={control}
                    fullWidth
                    error={errors.codeSAP}
                    helperText={errors.codeSAP?.message}
                  />
                </Grid>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={2}
                >
                  <Grid item>
                    <ButtonSaveChanges
                      title="Buscar"
                      handleClick={() => {
                        searchData(0, undefined, true);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonSaveChanges
                      title="Exportar"
                      handleClick={() => {
                        exportExcelSuppliers({
                          mandante: getValues().mandante,
                          supplierName: getValues().supplierName,
                          societyCode: getValues().societyCode,
                          societyCodeEnd: getValues().societyCodeEnd,
                          buyOrganizationCode: getValues().buyOrganizationCode,
                          buyOrganizationCodeEnd:
                            getValues().buyOrganizationCodeEnd,
                          nit: getValues().nit,
                          codeCoupa: getValues().codeCoupa,
                          codeSAP: getValues().codeSAP,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                  {viewErrors !== "" && (
                    <div className={classes.errorText}>
                      <Typography color="error" variant="subtitle2">
                        {viewErrors}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <CustomTable
              columns={columns}
              mainParam={"name"}
              data={datatable}
              option={option}
              setOption={setOption}
              paramToDisable={"idstate"}
              havePagination={datatable.length > 10 ? true : false}
            />
            {totalRows > 10 ? (
              <Grid>
                <TablePagination
                  component="div"
                  count={totalRows}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 30]}
                />
              </Grid>
            ) : (
              <></>
            )}
          </>
        </ViewLayout>
        <FullLoader open={isLoading} />
      </main>
    </>
  );
}
